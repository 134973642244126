import { useQuery } from 'react-query'
import { sortCardsByDefaultThenValidity } from '../Utils'
import {
  type InitialData,
  ZodError,
  initialDataSchema,
  type PaymentMethod,
  issuerNames,
  IssuerName,
} from '@mobi/api-types'
import { reportErrorIfNeeded } from '../../../Utils/sentry'
import { DepositUrl, get } from '../../../Utils/network'
import { queryKeys } from '../../../Utils'

export const useInitialData = (accountNumber: number | null) => {
  return useQuery<InitialData>({
    queryKey: [queryKeys.depositInitialData, accountNumber],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: accountNumber !== null,
    select: data => {
      data.paymentMethods.sort(sortCardsByDefaultThenValidity)
      return data
    },
  })
}

const queryFn = async (): Promise<InitialData> => {
  const url = DepositUrl.InitialData
  try {
    const initialData = await get(url, initialDataSchema.parse)
    initialData.paymentMethods = initialData.paymentMethods.filter((paymentMethod: PaymentMethod) =>
      issuerNames.includes(paymentMethod.issuerName as IssuerName)
    )
    return initialData
  } catch (error) {
    const msg = 'failed to retrieve payment tokens'
    let exception = 'Unknown error'

    if (error instanceof ZodError) {
      exception = 'Invalid server response'
    } else if (error instanceof Error) {
      exception = error.message
    }

    reportErrorIfNeeded({
      message: `${url} exception: ${msg} - ${exception}`,
    })

    throw new Error(msg)
  }
}
