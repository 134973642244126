import dayjs from 'dayjs'
import React from 'react'
import { NotificationButton } from '../../../Notifications/NotificationButton/NotificationButton'
import type { RaceStatus } from '@core/Areas/Racing/Hooks/useRaceList/types'
import { buildToteRaceUri } from '../../helpers'

type Props = {
  raceStatus: RaceStatus
  raceTime: Date
  meetingId: string
  raceNumber: number
  meetingName: string
  meetingDate: string
  isHeaderPinned?: boolean
}

/** Race alert button -  RN only */
export const RaceNotificationButton: React.FC<Props> = ({
  raceStatus,
  raceTime,
  meetingId,
  raceNumber,
  meetingDate,
  meetingName,
  isHeaderPinned,
}) => {
  const expiryTime = React.useMemo(() => dayjs(raceTime).subtract(1, 'm'), [raceTime])
  const checkIfTimerExpired = React.useCallback(() => expiryTime.isBefore(Date.now()), [expiryTime])

  const [hasTimerExpired, setHasTimerExpired] = React.useState(checkIfTimerExpired)

  React.useEffect(() => {
    const hasExpired = checkIfTimerExpired()
    setHasTimerExpired(hasExpired)

    if (hasExpired) return undefined

    const timerId = setInterval(() => {
      if (!checkIfTimerExpired()) return
      setHasTimerExpired(true)
      clearInterval(timerId)
    }, 1000)
    return () => clearInterval(timerId)
  }, [checkIfTimerExpired])

  const link = React.useMemo(
    () =>
      window.location.origin +
      buildToteRaceUri({
        meetingId,
        meetingDate,
        raceNumber,
      }),
    [meetingId, meetingDate, raceNumber]
  )

  if (raceStatus === 'Released' || raceStatus === 'Abandoned' || hasTimerExpired) return null

  return (
    <NotificationButton
      ast={raceTime.toISOString()}
      eventId={`${dayjs(meetingDate).format('YYYY-MM-DD')}#${meetingId}#${raceNumber}`} // this event id should match the format in the kineses event stream
      eventType='Race'
      deepLink={link}
      eventName={`${meetingName} - RACE ${raceNumber}`}
      isHeaderPinned={isHeaderPinned}
    />
  )
}
