import React, { type FC, Suspense, lazy } from 'react'
import styled from '@emotion/styled'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableProperties } from '@core/Utils/hooks'
import { MainContainer } from '@core/Components/Containers'
import { OverlayClose } from '@core/Components/Overlay'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { type DepositFlow } from '@mobi/account/Areas/Deposit/typings/types'
import { colors } from '@mobi/component-library/Theme/Common'
import { needsExtraBottomPadding } from '../Navigation/Components/TabNavigation/Components/WebOnlyNav/WebOnlyNav'

const App = lazy(() => import('@mobi/account/Areas/Deposit/App'))

export type DepositProps = {
  asModal?: boolean
  flow: DepositFlow
}

export const Deposit: FC<DepositProps> = ({ asModal = false, flow }) => {
  const { accountNumber, accountBalance } = useObservableProperties(
    userAccountState$,
    ['accountNumber', 'accountBalance'],
    {
      accountNumber: null,
      accountBalance: 0,
    }
  )
  const isBodyScrollLocked = accountNumber !== null && asModal
  const containerElementRef = useBodyScrollLock(isBodyScrollLocked)

  // This page cannot be loaded if the user isn't logged in anyway
  if (accountNumber === null) {
    return null
  }

  return (
    <Suspense fallback={<Spinner />}>
      <DepositMainContainer ref={containerElementRef}>
        <App
          accountNumber={accountNumber}
          accountBalance={accountBalance ?? 0}
          asModal={asModal}
          onClose={asModal ? OverlayClose : undefined}
          flow={flow}
        />
        {asModal && <ModalBackdrop aria-hidden='true' />}
      </DepositMainContainer>
    </Suspense>
  )
}

const DepositMainContainer = styled(MainContainer)({
  background: colors.white,
  overflowY: 'auto',
  overscrollBehavior: 'contain',
  minHeight: '100vh',
  paddingBottom: needsExtraBottomPadding ? '5rem' : '0',
})

const ModalBackdrop = styled.div({
  position: 'fixed',
  zIndex: -1,
  top: 0,
  left: `calc(-50vw)`,
  right: 0,
  bottom: 0,
  width: '150vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.5)',
  animation: `fadeIn 400ms ease forwards`,
})
