import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { ReceiptItem, BetslipItem } from '../../driver'
import { List } from 'immutable'
import { toMoney } from '@mobi/utils/money'

interface BetsPlacedMessageComponentProps {
  items: List<BetslipItem>
  multiReceipt: ReceiptItem | null
}

export function BetsPlacedMessage({ items, multiReceipt }: BetsPlacedMessageComponentProps) {
  let placedBetsCount = 0
  let placedBetsAmount = 0
  items.forEach(item => {
    if (item.receipt) {
      placedBetsCount++
      placedBetsAmount += item.receipt.amountPaid as number
    }
  })
  if (multiReceipt) {
    placedBetsCount++
    placedBetsAmount += multiReceipt.amountPaid as number
  }

  if (placedBetsCount === 0) {
    return null
  }
  return (
    <NoticeBox
      title={`${placedBetsCount} bet${placedBetsCount > 1 ? 's' : ''} placed totalling ${toMoney(
        placedBetsAmount
      )}`}
      noticeBoxType={NoticeBoxTypes.Success}
      hasBorder={false}
    />
  )
}
