import { Money } from '@core/Components/Text/Money'
import { toMoney } from '@mobi/utils/money'
import React from 'react'
import {
  ReceiptCampaignTextStyled,
  ReceiptListItemStyled,
  ReceiptListItemTextStyled,
  ReceiptListRowStyled,
  ReceiptListStyled,
} from '../Receipt/Receipt.styles'

export type BonusDetailsNotificationProps = {
  betCost: number
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  bonusCashBalance: number | null
  accountBalance: number | null
}

export function BonusDetailsNotification({
  ...props
}: BonusDetailsNotificationProps): JSX.Element | null {
  if (props.isUsingBonusBet) return createBonusBetDetails(props)

  // unfortunately we need to check if customer has bonus cash available because 'isUsingBonusCash' is incorrectly assigned as true when customer has no bonus cash available!
  if (props.isUsingBonusCash && props.bonusCashBalance) return createBonusCashDetails(props)

  return null
}

function createBonusBetDetails(props: BonusDetailsNotificationProps): JSX.Element {
  return (
    <ReceiptListStyled data-testid='notif-bonus-bet-details'>
      <ReceiptListRowStyled role='listitem'>
        <ReceiptListItemStyled>
          <ReceiptCampaignTextStyled isBonusBet={true}>Bonus Bet</ReceiptCampaignTextStyled>
        </ReceiptListItemStyled>
        <ReceiptListItemTextStyled>
          <ReceiptCampaignTextStyled isBonusBet={true}>
            <Money amount={-(props.betCost ?? 0)} />
          </ReceiptCampaignTextStyled>
        </ReceiptListItemTextStyled>
      </ReceiptListRowStyled>

      <ReceiptListRowStyled role='listitem'>
        <ReceiptListItemStyled>Account Funds</ReceiptListItemStyled>
        <ReceiptListItemTextStyled>{toMoney(0)}</ReceiptListItemTextStyled>
      </ReceiptListRowStyled>
    </ReceiptListStyled>
  )
}

function createBonusCashDetails(props: BonusDetailsNotificationProps): JSX.Element {
  const bonusCashCost = Math.min(props.betCost, props.bonusCashBalance ?? 0)
  const accountFundsCost = props.betCost - bonusCashCost
  return (
    <ReceiptListStyled data-testid='notif-bonus-cash-details'>
      <ReceiptListRowStyled role='listitem'>
        <ReceiptListItemStyled>
          <ReceiptCampaignTextStyled isBonusBet={false}>Bonus Cash</ReceiptCampaignTextStyled>
        </ReceiptListItemStyled>
        <ReceiptListItemTextStyled>
          <ReceiptCampaignTextStyled isBonusBet={false}>
            <Money amount={-(bonusCashCost ?? 0)} />
          </ReceiptCampaignTextStyled>
        </ReceiptListItemTextStyled>
      </ReceiptListRowStyled>

      <ReceiptListRowStyled role='listitem'>
        <ReceiptListItemStyled>Account Funds</ReceiptListItemStyled>
        <ReceiptListItemTextStyled>{toMoney(accountFundsCost)}</ReceiptListItemTextStyled>
      </ReceiptListRowStyled>
    </ReceiptListStyled>
  )
}
