import React from 'react'
import type { Map } from 'immutable'
import type { EventDetails } from '@mobi/betslip/types'
import { isFobDetails } from '@mobi/betslip/helpers/typeGuards'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useObservableImmutable } from '@core/Utils/hooks'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { ErrorMessage } from '@core/Components/Messages'
import { state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import {
  MarketStyled,
  MarketPillStyled,
  MarketNameStyled,
  MarketTypeStyled,
  CompetitionAndTournamentStyled,
  EventStyled,
  SingleSelectionStyled,
} from '../Common/Header.styles'

export const FobHeader: React.FC = () => {
  const { selectionDetails: selectionDetailsMap, bettingType } = useObservableImmutable(
    quickbetState$,
    ['selectionDetails', 'bettingType']
  )

  const selectionDetails = (
    selectionDetailsMap as unknown as Map<string, string> | undefined
  )?.toJS() as EventDetails

  if (!isFobDetails(selectionDetails)) {
    return !PRODUCTION ? (
      <ErrorMessage>selectionDetails incompatible with SportsHeader</ErrorMessage>
    ) : null
  }

  const {
    sportIconCode,
    tournamentName,
    competitionName,
    eventName,
    marketName,
    propositionName,
    handicapName,
  } = selectionDetails

  return (
    <>
      <Grid>
        <GridRow padding='0 0 1.2rem 0'>
          <GridCell width='3.5rem'>
            <Icon size='3rem' type={sportIconCode} />
          </GridCell>

          <GridCell valign='middle'>
            {tournamentName ? (
              <CompetitionAndTournamentStyled>
                {competitionName} {tournamentName}
              </CompetitionAndTournamentStyled>
            ) : (
              <CompetitionAndTournamentStyled>{competitionName}</CompetitionAndTournamentStyled>
            )}

            {eventName !== marketName && eventName && <EventStyled>{eventName}</EventStyled>}
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell valign='middle'>
            <MarketStyled>
              <MarketPillStyled>
                <MarketNameStyled>{marketName}</MarketNameStyled>

                {bettingType === 'fixed-odds-racing' && <MarketTypeStyled>Fixed</MarketTypeStyled>}
              </MarketPillStyled>
            </MarketStyled>
          </GridCell>
        </GridRow>
      </Grid>

      <SingleSelectionStyled>
        {propositionName} {handicapName ? handicapName : ''}
      </SingleSelectionStyled>
    </>
  )
}
