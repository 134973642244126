import type { BetSelection, FobSportsSelection, SportsDetails } from '@mobi/betslip/types'
import type { QuickbetSelection } from '@core/Areas/Quickbet/signals'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SportsProposition = any

export function createSportsQuickbetSelection({
  sportId,
  sportName,
  tournamentId,
  tournamentName,
  eventName,
  eventId,
  advertisedStartTime,
  bettingClosingTime,
  marketId,
  marketName,
  outcomeId,
  outcomeName,
  price,
  externalBetId,
  urlPath,
  marketTypeCode,
}: SportsProposition): QuickbetSelection {
  const selection: FobSportsSelection = {
    externalBetId,
    type: 'fob-sports',
    propositionSeq: outcomeId.toString(),
    propositionName: outcomeName,
    winPrice: price,
    winPriceLastSeen: null,
    placePrice: null,
    placePriceLastSeen: null,
    priceSource: 'api',
  }

  const selectionDetails: SportsDetails = {
    sportId,
    sportName,
    tournamentId,
    tournamentName,
    eventName,
    eventId,
    marketId,
    marketName,
    advertisedStartTime,
    bettingClosingTime,
    urlPath,
    marketTypeCode,
    outcomeId,
  }

  return {
    bettingType: 'fixed-odds-sports',
    shouldAllowPlaceInvestment: false,
    isEachWayAvailable: false,

    selection,
    selectionDetails,
  }
}

export function createSportsNewBetslipSelection({
  sportId,
  sportName,
  tournamentId,
  tournamentName,
  eventName,
  eventId,
  advertisedStartTime,
  bettingClosingTime,
  marketId,
  marketName,
  outcomeId,
  outcomeName,
  price,
  externalBetId,
  marketTypeCode,
}: SportsProposition): BetSelection {
  const selection: FobSportsSelection = {
    externalBetId,
    type: 'fob-sports',
    propositionSeq: outcomeId.toString(),
    propositionName: outcomeName,
    winPrice: price,
    winPriceLastSeen: null,
    placePrice: null,
    placePriceLastSeen: null,
    priceSource: 'api',
  }

  const selectionDetails: SportsDetails = {
    sportId,
    sportName,
    tournamentId,
    tournamentName,
    eventName,
    eventId,
    marketId,
    marketName,
    advertisedStartTime,
    bettingClosingTime,
    marketTypeCode,
    outcomeId,
  }

  return {
    bettingType: 'fixed-odds-sports',
    shouldAllowPlaceInvestment: false,
    isEachWayAvailable: false,

    selection,
    selectionDetails,
  }
}
