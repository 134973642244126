import React from 'react'
import { isRaceDetails } from '@mobi/betslip/helpers/typeGuards'
import { dayjs } from '@mobi/utils/date'
import { BetslipItem, BetslipState, state$ } from '@core/Areas/Betslip/driver'
import {
  getBetsInMulti,
  hasInvestment,
  hasNotBeenPlaced,
  isValidMulti,
} from '@core/Areas/Betslip/helpers/state'
import { trackOptimoveEvent } from '../optimove'

export const useTrackOptimoveBetslipAbandoned = () => {
  React.useEffect(() => {
    return () => {
      state$.take(1).subscribe(betslipState => {
        const items = betslipState.items
        const { multiReceipt, multiInvestment, multiBetError } = betslipState.toJS() as BetslipState

        if (items.isEmpty() || (!items.some(hasInvestment) && !items.some(item => item.isInMulti)))
          return

        const soonestRef = { ast: null }

        const multiItems = getBetsInMulti(items)
        if (!multiReceipt && isValidMulti(multiInvestment, multiBetError, multiItems)) {
          multiItems.forEach(item => handleUpdatingSoonestAst(item, soonestRef))
          if (soonestRef.ast) trackWithMinutesToNextRace(soonestRef.ast)
          return
        }

        const singleItems = items.filter(hasInvestment).filter(hasNotBeenPlaced)
        if (singleItems.count() > 0) {
          singleItems.forEach(item => handleUpdatingSoonestAst(item, soonestRef))
          if (soonestRef.ast) trackWithMinutesToNextRace(soonestRef.ast)
          return
        }
      })
    }
  }, [])
}

// Local Helpers

function handleUpdatingSoonestAst(item: BetslipItem, data: Record<'ast', string | null>) {
  if (isRaceDetails(item.selectionDetails)) {
    const itemAst = item.selectionDetails.races[0].raceTime
    if (!data.ast || dayjs(dayjs(itemAst).toISOString()).isBefore(dayjs(data.ast).toISOString())) {
      data.ast = itemAst
    }
  }
}

function trackWithMinutesToNextRace(ast: string) {
  const astISOString = dayjs(ast).toISOString()
  const nowISOString = dayjs().toISOString()
  if (dayjs(astISOString).isBefore(nowISOString)) return

  const minutes_to_next_race = Math.floor(dayjs(astISOString).diff(nowISOString, 'm'))
  if (minutes_to_next_race > 5) {
    trackOptimoveEvent({
      eventName: 'betslip_abandoned_w_valid_bets',
      data: { minutes_to_next_race },
    })
  }
}
