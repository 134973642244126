import React from 'react'
import { useSelector } from 'react-redux'
import type { Iterable } from 'immutable'
import {
  isFobMatchedSelection,
  isFobSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { type BetslipItem, state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import type { SmartBetslipState } from '@core/Areas/Blackbook/types'
import { store } from '@core/Store'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { BetSlipItem, ToteSelection } from '@mobi/betslip/types'
import { startListening } from '@core/Store/listenerMiddleware'
import { isAnyOf } from '@reduxjs/toolkit'
import {
  addItemsToBetSlip,
  clearAllBetSlipItems,
  removeItemFromBetSlipById,
  clearAllReceiptsFromItems,
  removeItemsWithReceipt,
  removeItemsWithFatalError,
} from '@mobi/betslip/Store/Bets'
import dayjs from 'dayjs'

/**
 * @param isNewBetSlipFeatureActive
 * @param includeTote true if logic should be applied to totes instead of fobs (the 2 are calculated separately)
 * @returns
 */
export function useSmartBetSlip(
  isNewBetSlipFeatureActive: boolean,
  opts: { includeTote?: boolean } = {}
) {
  const includeTote = opts.includeTote || false
  const isSmartBetslipActive = useSelector(getEnhancedBetslipSetting)

  const [state, setState] = React.useState<SmartBetslipState>({
    shouldAutoAddToBetslip: false,
    isSmartBetslipFeatureActive: isSmartBetslipActive,
    betslipFobPropSeqs: [],
    betslipToteIds: [],
  })

  React.useEffect(() => {
    if (!isSmartBetslipActive || !isNewBetSlipFeatureActive) {
      return
    }

    const handler = () => {
      const items = selectBetSlipItems(store.getState())
      setState(deriveReactStateFromBetSlip(includeTote, isSmartBetslipActive, items))
    }
    handler()
    const dispose = startListening({
      matcher: isAnyOf(
        addItemsToBetSlip,
        clearAllBetSlipItems,
        removeItemFromBetSlipById,
        clearAllReceiptsFromItems,
        removeItemsWithReceipt,
        removeItemsWithFatalError
      ),
      effect: handler,
    })
    return () => dispose()
  }, [isSmartBetslipActive, isNewBetSlipFeatureActive, includeTote])

  React.useEffect(() => {
    if (!isSmartBetslipActive || isNewBetSlipFeatureActive) {
      return
    }

    const subscription = betslipState$
      .map(x => x.items)
      .distinctUntilChanged()
      .map(state => deriveReactStateFromLegacyBetslip(includeTote, isSmartBetslipActive, state))
      .subscribe(propSeqs => {
        setState(propSeqs)
      })
    return () => subscription.dispose()
  }, [isSmartBetslipActive, isNewBetSlipFeatureActive, includeTote])

  React.useEffect(() => {
    if (!isSmartBetslipActive) {
      setState({
        shouldAutoAddToBetslip: false,
        isSmartBetslipFeatureActive: isSmartBetslipActive,
        betslipFobPropSeqs: [],
        betslipToteIds: [],
      })
    }
  }, [isSmartBetslipActive])

  return state
}

// =============
// Local Helpers
// =============

function deriveReactStateFromLegacyBetslip(
  includeTote: boolean,
  isSmartBetslipFeatureActive: boolean,
  items: Iterable<number, BetslipItem>
): SmartBetslipState {
  const arr = items.toArray()
  return deriveReactStateFromBetSlip(includeTote, isSmartBetslipFeatureActive, arr)
}

function deriveReactStateFromBetSlip(
  includeTote: boolean,
  isSmartBetslipFeatureActive: boolean,
  items: BetSlipItem[] | BetslipItem[]
): SmartBetslipState {
  const fobArr = items.filter(x => x && isFobSelection(x.selection))

  const propSeqs = fobArr
    .filter(x => !x.receipt)
    .flatMap(item =>
      item && isFobMatchedSelection(item.selection) ? [Number(item.selection.propositionSeq)] : []
    )

  let totes: string[] = []

  if (includeTote) {
    const totesArr = items.filter(x => x && isToteSelection(x.selection))
    totes = totesArr
      .filter(x => !x.receipt)
      .flatMap(item =>
        item && isToteSelection(item.selection)
          ? item.selection.selectionString.split('.').map(ac => {
              const sel = item.selection as ToteSelection
              return `${dayjs(sel.fixtureDate).format('YYYY-MM-DD')}#${sel.fixtureId}#${sel.raceNumber}#${ac}`
            })
          : []
      )
  }
  return {
    shouldAutoAddToBetslip: items.length > 0,
    betslipFobPropSeqs: propSeqs,
    betslipToteIds: totes,
    isSmartBetslipFeatureActive,
  }
}
