import { state$ as kambiState$ } from '@core/Areas/Kambi/driver'

interface KambiHistoryResponse {
  historyCoupons: unknown
  range: { start: number; size: number; more: boolean }
}

export const kambiPlayerApi = () => {
  return {
    PendingBetHistory: pendingBetHistory,
  }
}

async function pendingBetHistory(): Promise<KambiHistoryResponse | undefined> {
  const kambiState = await kambiState$.take(1).toPromise()
  const playerSessionId = kambiState.get('playerSessionId')

  if (!playerSessionId) return undefined

  let betHistory
  try {
    const response = await fetch(
      `${window._kc.apiPlayerBaseUrl}coupon/history?range_size=100&range_start=0&status=PENDING`,
      {
        credentials: 'same-origin',
        headers: {
          authorization: `Bearer ${playerSessionId}`,
        },
      }
    )

    betHistory =
      response.status === 204
        ? (response.text() as unknown as KambiHistoryResponse)
        : response.json()
  } catch {
    // eslint-disable-next-line no-console
    console.error('Fail to fetch sports bet history')
    return undefined
  }

  return betHistory
}
