import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { font } from '@mobi/component-library/Theme/Common'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { AppRoutes } from '@core/AppRoutes'
import { PlayerButton, ResizeIcon } from './Components/Buttons'
import { PendingBetsDrawerButton } from './Components/PendingBetsDrawerButton'
import {
  setIsMuted,
  setIsPlaying,
  setCloseSkyVideoPlayer,
  setSkyVideoPlayerStatus,
  setIsControlShowing,
} from '@core/Areas/SkyVideoPlayer/Store'
import { useControlToggle } from './helpers/useControlToggle'
import { findAndStartChildVideoElement } from '../../helpers'
import { ProgressBar } from './Components/ProgressBar/ProgressBar'
import { SkyChannelSelector } from './Components/SkyChannelSelector'
import { toTitleCase } from '@mobi/utils/string'

export const VISION_DRAG_HANDLE_CLASSNAME = 'js-vision-floating-draggable'

export const VideoPlayerControls: React.FC<{
  parentContainer?: React.RefObject<HTMLDivElement>
  onPlayClick: () => void
  onPauseClick: () => void
  onRestartClick: () => void
  onSeekClick: (seekSeconds: number) => void
}> = ({ parentContainer, onPlayClick, onPauseClick, onRestartClick, onSeekClick }) => {
  const dispatch = useAppDispatch()
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)
  const isPlaying = useAppSelector(state => state.skyVideoPlayer.isPlaying)
  const elapsedSeconds = useAppSelector(state => state.skyVideoPlayer.raceReplayElapsedSeconds)
  const durationSeconds = useAppSelector(state => state.skyVideoPlayer.raceReplayDurationSeconds)
  const isFinished = durationSeconds > 0 && elapsedSeconds == durationSeconds

  const isMuted = useAppSelector(state => state.skyVideoPlayer.isMuted)
  const isFullscreen = useAppSelector(state => state.skyVideoPlayer.isFullscreen)
  const isControlShowing = useAppSelector(state => state.skyVideoPlayer.isControlShowing)
  const selectedChannel = useAppSelector(state => state.skyVideoPlayer.selectedChannel)
  const raceReplayTitle = useAppSelector(state => state.skyVideoPlayer.raceReplayTitle)

  const history = useHistory()
  const { pathname } = useLocation()

  const topBarRef = React.useRef(null)
  const centerBarRef = React.useRef(null)
  const bottomBarRef = React.useRef(null)

  const [isInteractingWithProgressBar, setIsInteractingWithProgressBar] = React.useState(false)

  useControlToggle({
    isPlaying,
    isMuted,
    isFullscreen,
    isControlShowing,
    selectedChannel,
    isInteractingWithProgressBar,
    topBarElement: topBarRef.current,
    centerBarElement: centerBarRef.current,
    bottomBarElement: bottomBarRef.current,
  })

  const handleFullscreenClick = (isFullscreen: boolean) =>
    dispatch(setSkyVideoPlayerStatus({ playerStatus: isFullscreen ? 'fullscreen' : undefined }))

  const handleCloseSkyPlayer = () => {
    dispatch(setCloseSkyVideoPlayer())
  }

  const handleSkyRacePageClose = () => {
    handleCloseSkyPlayer()
    history.push(AppRoutes.Home)
  }

  const handleToggleControls = () => {
    if (playerStatus !== 'floating') {
      dispatch(setIsControlShowing(!isControlShowing))
      findAndStartChildVideoElement(parentContainer?.current ?? undefined)
    }
  }

  const isOnSkyRacePage = pathname === AppRoutes.RaceCardSky
  const isPlayerFullscreen = playerStatus === 'fullscreen'
  const isPlayerFloating = playerStatus === 'floating'
  const isPlayerDocked = playerStatus === 'docked'

  const shouldRenderResizeButton = isPlayerFloating && !isFullscreen

  const isReplay = selectedChannel == 'Replay'

  const handleSkipBackward = () => {
    const backSkip = elapsedSeconds - 15
    onSeekClick(backSkip > 0 ? backSkip : 0)
  }

  const handleSkipForward = () => {
    const forwardSkip = elapsedSeconds + 15
    onSeekClick(forwardSkip <= durationSeconds ? forwardSkip : durationSeconds)
  }

  const topBarControls = (
    <TopBarStyled ref={topBarRef}>
      <div>
        {shouldRenderResizeButton && <ResizeIcon />}

        {(isPlayerDocked || isPlayerFullscreen) && (
          <PlayerButton
            type='close'
            onClick={isOnSkyRacePage ? handleSkyRacePageClose : handleCloseSkyPlayer}
          />
        )}
      </div>

      {isReplay ? (
        <ReplayOverlayStyled>
          <div className={LocalConstants.ReplayBadgeClassName}>REPLAY</div>
          {raceReplayTitle && (isPlayerDocked || isPlayerFullscreen) && (
            <>
              <div className={LocalConstants.InfoClassName}>
                <div className={LocalConstants.StarterInfoClassName}>
                  {raceReplayTitle?.starterNumber} {toTitleCase(raceReplayTitle?.starterName ?? '')}
                </div>
                {raceReplayTitle?.starterName && <div>•</div>}
                {`R${raceReplayTitle?.raceNumber} ${raceReplayTitle?.trackName?.toUpperCase()}`}
              </div>
              {raceReplayTitle?.position && (
                <div className={LocalConstants.PositionBadgeClassName}>
                  {raceReplayTitle.position}
                </div>
              )}
            </>
          )}
        </ReplayOverlayStyled>
      ) : (
        <div>
          <SkyChannelSelector selectedChannel={selectedChannel} />
        </div>
      )}

      <div>
        {isPlayerFloating &&
          (isOnSkyRacePage ? (
            <PlayerButton
              type='docked'
              onClick={() => dispatch(setSkyVideoPlayerStatus({ playerStatus: 'docked' }))}
            />
          ) : (
            <PlayerButton type='close' onClick={handleCloseSkyPlayer} />
          ))}

        {isPlayerDocked && (
          <PlayerButton
            type='floating'
            onClick={() => dispatch(setSkyVideoPlayerStatus({ playerStatus: 'floating' }))}
          />
        )}

        {isPlayerFullscreen && <PendingBetsDrawerButton />}
      </div>
    </TopBarStyled>
  )

  const centerBarControls = (
    <CenterBarStyled
      isDimmed={isFinished}
      isPlayerFullscreen={isPlayerFullscreen}
      ref={centerBarRef}
    >
      {isFinished ? (
        <PlayerButton onClick={onRestartClick} type='replay' />
      ) : (
        <>
          <PlayerButton onClick={handleSkipBackward} type='back15' />
          {isPlaying ? (
            <PlayerButton onClick={onPauseClick} type='pause' />
          ) : (
            <PlayerButton onClick={onPlayClick} type='play' />
          )}
          <PlayerButton onClick={handleSkipForward} type='forward15' />
        </>
      )}
    </CenterBarStyled>
  )

  const bottomBarControls = (
    <BottomBarStyled ref={bottomBarRef} isPlayerFullscreen={isPlayerFullscreen}>
      {isMuted ? (
        <PlayerButton onClick={() => dispatch(setIsMuted(false))} type='mute' />
      ) : (
        <PlayerButton onClick={() => dispatch(setIsMuted(true))} type='volume' />
      )}

      {isReplay && (
        <ProgressBar
          elapsedSeconds={elapsedSeconds}
          durationSeconds={durationSeconds}
          isFullscreen={isFullscreen}
          onClick={onSeekClick}
          onInteractionEnd={() => setIsInteractingWithProgressBar(false)}
          onInteractionStart={() => setIsInteractingWithProgressBar(true)}
        />
      )}

      {!isPlayerFullscreen && !isFullscreen ? (
        <PlayerButton onClick={() => handleFullscreenClick(true)} type='fullscreen' />
      ) : (
        <PlayerButton onClick={() => handleFullscreenClick(false)} type='exitfullscreen' />
      )}
    </BottomBarStyled>
  )

  return (
    <WrapperStyled onClick={handleToggleControls}>
      {isReplay ? (
        <>
          <PlayerDragHandleStyled className={VISION_DRAG_HANDLE_CLASSNAME} />
          {topBarControls}
          {centerBarControls}
          {bottomBarControls}
        </>
      ) : (
        <>
          <BigPlayButtonWrapperStyled>
            {!isPlaying && (
              <PlayerButton type='play' onClick={() => dispatch(setIsPlaying(true))} />
            )}
            <PlayerDragHandleStyled className={VISION_DRAG_HANDLE_CLASSNAME} />
          </BigPlayButtonWrapperStyled>
          {isPlaying && (
            <>
              {topBarControls}
              {bottomBarControls}
            </>
          )}
        </>
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const enum LocalConstants {
  ReplayBadgeClassName = 'replay-badge',
  InfoClassName = 'info',
  StarterInfoClassName = 'starter-info',
  PositionBadgeClassName = 'position-badge',
}

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  overflow: 'hidden',
})

const BigPlayButtonWrapperStyled = styled.div({
  zIndex: 1,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',

  '> button': {
    flex: 1,
    width: '100%',
    background: 0,
    fontSize: '4rem',
  },
})

const ControlBarStyled = styled.div({
  zIndex: 3,
  position: 'absolute',
  left: 0,
  right: 0,
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transform: 'translateY(0)',
  willChange: 'transform',
  color: hexColors.white,
  fontSize: '2.4rem',
  pointerEvents: 'none',
  transition: 'transform ease-in-out 0.2s',
})

const TopBarStyled = styled(ControlBarStyled)({
  top: 0,
  padding: '0.5rem 0.5rem 0',

  '& > div': {
    minWidth: '3.6rem',
  },

  '> div:nth-of-type(1), > div:nth-of-type(3)': {
    flex: 1,
  },

  '> div:nth-of-type(3)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pointerEvents: 'all',
})

const BottomBarStyled = styled(ControlBarStyled)<{ isPlayerFullscreen: boolean }>(
  ({ isPlayerFullscreen }) => ({
    bottom: 0,
    // Padding increased in fullscreen to avoid user touching bottom dock when dragging the progress bar on mobile
    padding: isPlayerFullscreen ? '0 0.5rem 1.5rem' : '0 0.5rem 0.5rem',
  })
)

const CenterBarStyled = styled.div<{ isDimmed: boolean; isPlayerFullscreen: boolean }>(props => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: props.isPlayerFullscreen ? '63px' : '21px',
  zIndex: 2,
  pointerEvents: 'none',
  willChange: 'transform',
  transition: 'background-color 0.3s',
  ...(props.isDimmed && {
    backgroundColor: 'rgba(5, 5, 5, 0.52)',
  }),
  '> *': {
    pointerEvents: 'all',
  },
  svg: {
    width: '3rem',
  },
}))

const PlayerDragHandleStyled = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
})

const ReplayOverlayStyled = styled.div({
  width: 'auto',
  height: '67%',
  padding: '0.5rem',
  background: 'rgba(5, 5, 5, 0.52)',
  borderRadius: '0.7rem',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  fontSize: font.size.sm.fontSize,
  fontFamily: font.family.primary,
  fontWeight: font.weight.semibold,
  lineHeight: font.size.sm.lineHeight,
  wordWrap: 'break-word',

  [`.${LocalConstants.ReplayBadgeClassName}`]: {
    width: '4.7rem',
    alignSelf: 'stretch',
    padding: '0.4rem',
    background: hexColors.pattensBlue,
    borderRadius: '0.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    textAlign: 'center',
    color: hexColors.darkCyan,
  },

  [`.${LocalConstants.InfoClassName}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.4rem',
    marginLeft: '0.7rem',
    textAlign: 'center',
    fontWeight: font.weight.medium,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [`.${LocalConstants.StarterInfoClassName}`]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  [`.${LocalConstants.PositionBadgeClassName}`]: {
    width: '2.8rem',
    alignSelf: 'stretch',
    padding: '0.2rem',
    marginLeft: '0.7rem',
    background: 'rgba(250, 251, 251, 0.3)',
    borderRadius: '0.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.4rem',
    overflow: 'hidden',
  },
})
