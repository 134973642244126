import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { colors, layering, measurements, spacing } from '@mobi/component-library/Theme/Common'
import { Drawer } from '@mobi/component-library/Overlays/Drawer'
import { greaterOrEqualThan } from '@mobi/component-library/Utils/media'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import { hexColors } from '@mobi/settings'
import Toast from '@core/Components/Toast'
import { Search } from '@core/Areas/Search/Search'
import { OverlayOpen } from '@core/Components/Overlay/signals'
import {
  AppHeaderLogo,
  BackButton as BackButton,
  HamburgerButton,
  LoginButton,
  SignUpButton,
} from './Components/Buttons/Buttons'
import { useKambiVisible, useStickyOnScroll } from './hooks'
import { useLogon, usePrevious } from '@core/Utils/hooks'
import {
  AccountDropDownBGStyled,
  BG_ANIMATION_TIMEOUT_MS,
  Wallet,
} from './Components/AccountDropDown'
import { HamburgerMenu } from './Components/HamburgerMenu/HamburgerMenu'
import { isReactNativeApp, subscribeToNative } from '@mobi/web-native-comms/web'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { BalancePill } from './Components/Balance/BalancePill'
import {
  getIsAppHeaderStickyEnabled,
  getIsAppHeaderVisible,
  getIsDropDownOpen,
  getIsHamburgerMenuOpen,
  getShouldDisableAppHeaderRender,
} from './Store/selectors'
import { toggleIsDropDownOpen, toggleIsHamburgerMenuOpen } from './Store'
import { MyBetsButtons } from './Components/MyBetsButtons'
import { AppHeaderButtonStyled } from './Components/Buttons/Buttons.styles'

export const AppHeader: React.FC = () => {
  const appHeaderRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const isDropDownOpen = useSelector(getIsDropDownOpen)
  const isAppHeaderVisible = useSelector(getIsAppHeaderVisible)
  const isHamburgerMenuOpen = useSelector(getIsHamburgerMenuOpen)
  const isStickyEnabled = useSelector(getIsAppHeaderStickyEnabled)
  const shouldDisableAppHeaderRender = useSelector(getShouldDisableAppHeaderRender)

  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)

  const { isLoggedIn } = useLogon()

  const shouldRenderBG = useRenderTimeoutControl({
    shouldRender: isDropDownOpen,
    timeoutMs: BG_ANIMATION_TIMEOUT_MS,
  })

  const [isKambiVisible, isKambiBetslipMaximized] = useKambiVisible()

  useStickyOnScroll(appHeaderRef, isAppHeaderVisible)

  React.useEffect(() => {
    if ((isDropDownOpen && !isAppHeaderVisible) || pathname !== prevPathname) {
      dispatch(toggleIsDropDownOpen(false))
    }
  }, [isAppHeaderVisible, isDropDownOpen, pathname, prevPathname, dispatch])

  React.useEffect(() => {
    if (!isReactNativeApp) return
    const { dispose } = subscribeToNative('PROMPT_LOGIN', showLogin)
    return () => dispose()
  }, [])

  if (shouldDisableAppHeaderRender) return null

  const shouldRenderBackButton =
    (pathname !== '/' && !pathname.startsWith('/deposit')) || isKambiVisible

  const shouldLowerZIndex =
    (isKambiVisible && isKambiBetslipMaximized && !shouldRenderBG) ||
    (!isStickyEnabled && !shouldRenderBG)

  return (
    <>
      <ContainerStyled>
        <header
          ref={appHeaderRef}
          style={{
            position: isStickyEnabled ? 'fixed' : 'relative',
            zIndex: shouldLowerZIndex ? layering.baseLayer : layering.appHeader,
          }}
        >
          <div data-header-inner>
            <div>
              <HamburgerButton />

              {shouldRenderBackButton ? <BackButton /> : <span style={{ minWidth: '0.4rem' }} />}

              <AppHeaderLogo />

              {!isKambiVisible && (
                <AppHeaderButtonStyled
                  onClick={() => OverlayOpen(Search)}
                  data-testid='header-search-btn'
                  data-appheader-search
                  aria-label='Search'
                  role='button'
                >
                  <Icon name='LineSearchSm' size='2rem' color={colors.white} />
                </AppHeaderButtonStyled>
              )}
            </div>

            <div>
              {isLoggedIn ? (
                <BalancePill renderContext='app-header' />
              ) : (
                <>
                  <LoginButton />
                  <SignUpButton />
                </>
              )}

              <MyBetsButtons
                shouldDisplayPendingBets={!!isLoggedIn}
                shouldDisplayBetSlip={!isKambiVisible}
              />
            </div>
          </div>

          {isLoggedIn && <Wallet />}
        </header>
      </ContainerStyled>

      <Drawer
        position='left'
        isOpen={isHamburgerMenuOpen}
        maxWidth='min(30rem, 100vw - 6rem)'
        onDismiss={() => dispatch(toggleIsHamburgerMenuOpen(false))}
      >
        <HamburgerMenu onClose={() => dispatch(toggleIsHamburgerMenuOpen(false))} />
      </Drawer>

      {shouldRenderBG && (
        <AccountDropDownBGStyled
          onClick={() => dispatch(toggleIsDropDownOpen(false))}
          isVisible={isDropDownOpen}
        />
      )}

      <Toast isHeaderVisible={isAppHeaderVisible} />
    </>
  )
}

// ======
// Styles
// ======

const ContainerStyled = styled.div({
  boxSizing: 'border-box',
  backgroundColor: hexColors.blueDiamond,
  position: 'relative',
  height: measurements.mobi.headerHeight,
  minWidth: measurements.mobi.minWidth,
  maxWidth: '80em',
  margin: '0 auto',

  '> header': {
    top: 0,
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    backgroundColor: hexColors.blueDiamond,
    color: colors.white,
    height: measurements.mobi.headerHeight,
    width: '100%',
    margin: '0 auto',
    padding: 0,
    maxWidth: measurements.mobi.maxWidth,
    minWidth: '30em',
    transform: 'translateY(0)',

    '> div[data-header-inner]': {
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      gap: '0.2rem',
      height: measurements.mobi.headerHeight,
      maxHeight: measurements.mobi.headerHeight,
      padding: spacing.smx1,
      paddingLeft: 0,
      backgroundColor: hexColors.blueDiamond,

      '> div': {
        display: 'flex',
        alignItems: 'stretch',
      },

      '> div:nth-of-type(1)': {
        flex: 1,
        gap: '0.2rem',

        '[data-appheader-search]': {
          marginLeft: 'auto',
        },
      },

      '> div:nth-of-type(2)': {
        justifyContent: 'flex-end',
        gap: spacing.smx2,

        '> button': {
          flexShrink: 0,
          height: 'auto',
          paddingInline: spacing.smx2,
        },
      },

      [greaterOrEqualThan('px350')]: {
        padding: `${spacing.smx1} ${spacing.sm} ${spacing.smx1} 0`,

        '> div:nth-of-type(2)': {
          '> button': { paddingInline: spacing.smx1 },
        },
      },

      [greaterOrEqualThan('px400')]: {
        '> div:nth-of-type(2)': {
          gap: spacing.smx1,

          '> button': { paddingInline: spacing.sm },
        },
      },
    },
  },
})
