/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import type { IDisposable } from './IDisposable'
import type { IEventAggregator } from '../Messaging/IEventAggregator'
import { injectable } from 'inversify'

@injectable()
export abstract class Disposable implements IDisposable {
  constructor(eventAggregator: IEventAggregator) {
    this.evtAggregator = eventAggregator
    this.disposableEventAggregatorSubscriptions = new Map<string, Function[]>()
    this.disposed = false
    this.onDisposal = () => undefined
  }

  protected registerDisposals(func: Function) {
    this.onDisposal = func
  }

  public dispose() {
    if (this.disposed) return
    this.disposed = true
    this.onDisposal()
    for (const topic of this.disposableEventAggregatorSubscriptions.keys()) {
      this.removeSubscriptions(topic)
    }
    this.disposableEventAggregatorSubscriptions.clear()
  }

  public mergeDisposables(other: Map<string, Function[]>) {
    for (const topic of other.keys()) {
      const otherFunctions = other.get(topic)

      if (!otherFunctions) {
        continue
      }

      if (!this.disposableEventAggregatorSubscriptions.has(topic)) {
        this.disposableEventAggregatorSubscriptions.set(topic, otherFunctions)
      }

      const existingFunctions = this.disposableEventAggregatorSubscriptions.get(topic)

      if (existingFunctions) {
        this.disposableEventAggregatorSubscriptions.set(
          topic,
          existingFunctions.concat(otherFunctions)
        )
      }
    }
  }

  protected safeSubscribe(topic: string, func: Function) {
    if (!this.disposableEventAggregatorSubscriptions.has(topic)) {
      this.disposableEventAggregatorSubscriptions.set(topic, [])
    }

    this.disposableEventAggregatorSubscriptions.get(topic)?.push(func)

    this.evtAggregator.subscribe(topic, func)
  }

  protected removeSubscriptions(topic: string) {
    const callbacks = this.disposableEventAggregatorSubscriptions.get(topic)

    if (callbacks) {
      for (const func of callbacks) {
        this.evtAggregator.unsubscribe(topic, func)
      }
    }
    this.disposableEventAggregatorSubscriptions.delete(topic)
  }

  protected evtAggregator: IEventAggregator
  private disposed: boolean
  private disposableEventAggregatorSubscriptions: Map<string, Function[]>
  private onDisposal: Function
}
