import React, { useState } from 'react'
import {
  ButtonGroupItemStyled,
  ButtonGroupStyled,
} from '@core/Components/Buttons/ButtonGroup.styles'
import {
  NumberButtonsStyled,
  NumberButtonStyled,
  FavouriteNumbersSection,
  MyNumbersButtonStyled,
  SaveNumbersButtonStyled,
} from './FavouriteNumbers.styles'
import { LoadNumbers, state$, ToggleGameNumber } from './driver'
import { SaveFavouriteNumbers, state$ as savedState$ } from './savedFavouriteNumbers'
import { useObservableProperties } from '@core/Utils/hooks'

export function NumberSelection(): JSX.Element | null {
  const [game, setGame] = useState<number>(1)

  const { gameOne, gameTwo } = useObservableProperties(state$, ['gameOne', 'gameTwo'], {
    gameOne: [],
    gameTwo: [],
  })

  const favourites = useObservableProperties(savedState$, ['gameOne', 'gameTwo'], {
    gameOne: [],
    gameTwo: [],
  })

  const selectedNumbers = game === 1 ? gameOne : gameTwo
  const usingSavedNumbers =
    (game == 1 && favourites.gameOne.length > 0 && gameOne.join() == favourites.gameOne.join()) ||
    (game == 2 && favourites.gameTwo.length > 0 && gameTwo.join() == favourites.gameTwo.join())

  const showMyNumbersButton =
    (game == 1 && favourites.gameOne.length > 0) || (game == 2 && favourites.gameTwo.length > 0)

  const isSaveButtonDisabled = selectedNumbers.length === 0 && !showMyNumbersButton
  const isDeleteButtonAvailable = selectedNumbers.length === 0 && showMyNumbersButton

  return (
    <FavouriteNumbersSection>
      <ButtonGroupStyled>
        <ButtonGroupItemStyled
          onClick={() => {
            setGame(1)
          }}
          selected={game === 1}
          {...{ 'data-tid-game1button': '' }}
        >
          GAME 1
        </ButtonGroupItemStyled>
        <ButtonGroupItemStyled
          onClick={() => {
            setGame(2)
          }}
          selected={game === 2}
          {...{ 'data-tid-game2button': '' }}
        >
          GAME 2 (OPTIONAL)
        </ButtonGroupItemStyled>
      </ButtonGroupStyled>
      <p style={{ textAlign: 'center' }}>Select between 7 &amp; 16 numbers</p>
      <NumberButtonsStyled>
        <SaveNumbersButtonStyled
          data-tid-save-favnumbers
          onClick={() => SaveFavouriteNumbers({ numbers: selectedNumbers, game: game })}
          disabled={isSaveButtonDisabled}
        >
          {isDeleteButtonAvailable ? 'Delete Saved No.s' : 'Save My Numbers'}
        </SaveNumbersButtonStyled>
        {showMyNumbersButton && (
          <MyNumbersButtonStyled
            data-tid-mynumbers={game}
            color={usingSavedNumbers ? 'dark' : 'secondary'}
            disabled={
              (game == 1 && favourites.gameOne.length == 0) ||
              (game == 2 && favourites.gameTwo.length == 0)
            }
            onClick={() =>
              LoadNumbers({
                numbers: !usingSavedNumbers
                  ? game == 1
                    ? favourites.gameOne
                    : favourites.gameTwo
                  : [],
                game: game,
              })
            }
          >
            Use My Saved Numbers
          </MyNumbersButtonStyled>
        )}
        <NumberButtons
          numbers={30}
          selectedNumbers={selectedNumbers}
          onClickHandler={(number: number) => {
            ToggleGameNumber({ game: game, number: number })
          }}
        />
      </NumberButtonsStyled>
    </FavouriteNumbersSection>
  )
}

export function NumberButtons({
  numbers,
  selectedNumbers,
  onClickHandler,
}: {
  numbers: number
  selectedNumbers: readonly number[]
  onClickHandler: (number: number) => void
}): JSX.Element | null {
  return (
    <NumberButtonsStyled>
      {Array(numbers)
        .fill('')
        .map((x, i) => {
          const number = ++i
          const isSelected = selectedNumbers.indexOf(number) >= 0
          const testId = { 'data-tid-number-button': number }
          return (
            <NumberButtonStyled
              {...testId}
              key={`favourite-number-${number}`}
              color={isSelected ? 'dark' : 'secondary'}
              onClick={() => onClickHandler(number)}
            >
              {number}
            </NumberButtonStyled>
          )
        })}
    </NumberButtonsStyled>
  )
}
