import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

export type triggerBiometricsPromptProps = {
  onSuccess: (accountNumber: number, password: string) => void
}

export const triggerBiometricsPrompt = ({
  onSuccess,
}: triggerBiometricsPromptProps): (() => void) => {
  if (!isReactNativeApp) return () => null

  sendToNative('BIOMETRIC_AUTH_REQUEST')

  const { dispose } = subscribeToNative('BIOMETRIC_AUTH_SUCCESS', ({ accountNumber, password }) => {
    onSuccess(accountNumber, password)
  })

  return dispose
}
