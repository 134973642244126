import * as ko from 'knockout'
import Guard from '@classic/AppUtils/Framework/Guard'
import { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import { RunnerNumber, RunnerExpanded } from '@core/Areas/RaceCard/Components'
import { IBaseFinisherViewModel } from './IBaseFinisherViewModel'
import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { FeatureFlags } from '@mobi/settings'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'

export default class BaseFinisherViewModel extends Disposable implements IBaseFinisherViewModel {
  private raceNumber!: ko.Observable<number>
  public raceInformation!: ObservableResultsPage
  public shouldDisplayFavouriteInStarterName!: ko.Observable<boolean>
  public finisher!: IObservableFinisher
  public showField!: boolean
  public key!: string
  public ldSubscription!: Rx.IDisposable
  public isFieldSummaryV2Active: ko.Observable<boolean> = ko.observable(false)
  public numberOfStartersInRace!: ko.PureComputed<number>
  public RunnerExpanded: typeof RunnerExpanded
  public RunnerNumber: typeof RunnerNumber
  public isRunnerExpanded = ko.observable(false)
  public isExpandable = ko.observable(true)

  constructor(eventAggregator: IEventAggregator) {
    super(eventAggregator)

    this.RunnerNumber = RunnerNumber
    this.RunnerExpanded = RunnerExpanded
  }

  protected initBase(params: {
    raceInformation: ObservableResultsPage
    finisher: IObservableFinisher
    showField: boolean
  }) {
    Guard.notNull(params.finisher)
    Guard.notNull(params.showField)

    this.raceInformation = params.raceInformation
    this.raceNumber = params.raceInformation.meetingInformation.selectedRace.raceNumber
    this.finisher = params.finisher
    this.showField = params.showField
    this.shouldDisplayFavouriteInStarterName = ko.observable(this.finisher.isFavourite())
    this.key = this.finisher.type()[0] + this.finisher.name().toUpperCase()
    this.numberOfStartersInRace = ko.pureComputed(() => -1) //  Needs a dummy value for the form-info to load. Use -1 as we don't want to fire the signal
    this.ldSubscription = launchDarkState$.subscribe(record => {
      const fieldSummaryV2 = record.features.get(FeatureFlags.FIELD_SUMMARY_V2.key)
      if (fieldSummaryV2 !== this.isFieldSummaryV2Active()) {
        this.isFieldSummaryV2Active(fieldSummaryV2)
      }
    })

    if (this.finisher.name() === '*** VACANT BOX ***') {
      this.isExpandable(false)
    }

    this.safeSubscribe(
      'all-form-selected-command',
      (arg: { raceNumber: number; selected: boolean }) => {
        if (this.raceNumber() !== arg.raceNumber) return

        this.isRunnerExpanded(arg.selected)
      }
    )

    this.safeSubscribe(
      'show-formdata-command',
      (command: { raceNumber: number; starterNumber: number }) => {
        if (this.raceNumber() !== command.raceNumber) return
        if (this.finisher.number() !== command.starterNumber) return

        this.isRunnerExpanded(!this.isRunnerExpanded())
      }
    )

    this.configureDisposal()
  }

  public showFormData() {
    if (!this.isRunnerExpanded())
      trackEvent(analyticsKeys.formOpened, {
        meetingName: this.raceInformation.meetingInformation.meetingName(),
        meetingCode: this.raceInformation.meetingInformation.meetingCode().toLowerCase(),
      })
    else trackKey(analyticsKeys.formClosed)

    this.evtAggregator.publish('show-formdata-command', {
      raceNumber: this.raceNumber(),
      starterNumber: this.finisher.number(),
    })
  }

  private configureDisposal() {
    this.registerDisposals(() => {
      this.ldSubscription?.dispose()
    })
  }
}
