import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import {
  sameAsColumnsDependendentOnSelectedFieldIndex,
  cascadeSameAsSelectedThroughColumns,
  cascadeSameAsDeselectedThroughColumns,
} from './SameAsAlgorithms'
import Guard from '../../../../AppUtils/Framework/Guard'

export default class CheckBoxFieldProcessor {
  public process(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    index: number,
    selected: boolean
  ) {
    Guard.notNull(bettingContext)
    Guard.notNull(selections)
    Guard.notNull(starters)
    Guard.greaterThanOrEqualZero(index)

    this.processFieldSelection(
      starters,
      selections,
      bettingContext,
      bettingContext.checkBoxOptionsSelected() ? 0 : index,
      selected
    )
  }

  private processFieldSelection(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    index: number,
    selected: boolean
  ) {
    bettingContext.toggleFieldAt(index)

    if (bettingContext.selectedBetType().multiBet()) index = 0

    this.toggleStarterSelection(
      bettingContext,
      starters,
      selections,
      bettingContext.rovingBanker() || bettingContext.isLegIn() ? 1 : index,
      selected
    )

    if (!bettingContext.sameAsSelectedAtAnyIndex() || bettingContext.isBoxed()) return

    bettingContext.turnOffSameAsForColumn(index + 1)
    this.updateSameAsColumns(bettingContext, index, starters)
  }

  private toggleStarterSelection(
    bettingContext: BettingInformation,
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    index: number,
    selected: boolean
  ) {
    selections([])

    const newStarters = starters()
      .filter(starter => !starter.isScratched())
      .map(starter => starter.selection() as CheckBoxSelection)
      .reduce<IObservableStarter[]>((newStarters, selection) => {
        if (!selected) {
          selection.clearAt(index + 1)
        } else if (
          selected &&
          (bettingContext.rovingBanker() || bettingContext.isLegIn()) &&
          !selection.selectedAt(0)
        ) {
          selection.enableAt(index + 1)
        } else if (
          selected &&
          (bettingContext.rovingBanker() || bettingContext.isLegIn()) &&
          selection.selectedAt(0)
        ) {
          selection.clearAt(index + 1)
        } else if (selected) {
          selection.enableAt(index + 1)
        }

        if (selection.anySelected()) {
          newStarters.push(selection.starter)
        }

        return newStarters
      }, [])

    selections(newStarters)
  }

  private updateSameAsColumns(
    bettingContext: BettingInformation,
    fieldIndex: number,
    starters: ko.ObservableArray<IObservableStarter>
  ) {
    if (!bettingContext.sameAsSelectedAtAnyIndex()) return

    const deps = sameAsColumnsDependendentOnSelectedFieldIndex(bettingContext, fieldIndex)

    if (bettingContext.fieldSelectedAtIndex(fieldIndex)) {
      cascadeSameAsSelectedThroughColumns(bettingContext, starters(), deps)
    } else {
      cascadeSameAsDeselectedThroughColumns(
        bettingContext,
        starters().map(x => x.selection() as CheckBoxSelection),
        deps
      )
    }
  }
}
