import type {
  AddressSuggestions,
  StreetTypeSuggestions,
  SuburbStateSuggestions,
} from '@mobi/api-types'
import { get } from '../../../Utils'

export function searchSuburbStatePostcodes(search: string) {
  return get<SuburbStateSuggestions>(
    `/$_/api/signup/GetSuburbStatePostcodeList/?searchString=${encodeURIComponent(search)}`
  )
}

export function searchStreetTypes(search: string) {
  return get<StreetTypeSuggestions>(
    `/$_/api/signup/GetStreetTypeList/?searchString=${encodeURIComponent(search)}`
  )
}

export function searchAddresses(search: string) {
  return get<AddressSuggestions>(
    `/$_/api/signup/GetAddressList/?searchString=${encodeURIComponent(search)}`
  )
}

type SearchFunction = (search: string) => Promise<{ value: string }[]>

export function withValueMapping(searchFn: SearchFunction) {
  return (search: string) =>
    searchFn(search).then(results =>
      results.map(({ value }) => {
        return {
          id: value,
          value,
        }
      })
    )
}
