import { declareResourceType, IKeyMap } from 'rwwa-data-access'
import * as apiService from '@classic/Foundation/Services/ApiService'

export interface FavouriteNumberBettingPool {
  poolDate: Date
  poolNumber: number
  lastUpdated: Date
  expectedCloseTime: Date
  jackpot: number
  hasJackpot: boolean
  poolTotal: number
}

export interface UpcomingFavouriteNumberPools {
  weekendPools: FavouriteNumberBettingPool[]
  midweekPools: FavouriteNumberBettingPool[]
}

export const UpcomingPoolsKey = 'current'

export const FavouriteNumbersPools = declareResourceType<
  UpcomingFavouriteNumberPools,
  UpcomingFavouriteNumberPools
>({
  fetchSingle,
  typeKey: 'upcomingPools',
  maxToKeep: 1,
  softTimeToLive: 120000,
})

async function fetchSingle(key: string): Promise<IKeyMap<IKeyMap<UpcomingFavouriteNumberPools>>> {
  const data = await apiService.get<UpcomingFavouriteNumberPools>({
    url: '/api/favourite-numbers/upcoming-pools',
  })
  return { upcomingPools: { [key]: data } }
}
