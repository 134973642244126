import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { IObservableFinisher } from '../Model/Observables/IObservableFinisher'
import ObservableDogFinisher from '../Model/Observables/ObservableDogFinisher'
import { BaseFinisherCodeBuilder } from './BaseFinisherCodeBuilder'

export default class DogFinisherBuilder extends BaseFinisherCodeBuilder {
  build(source: dtos.FinisherDataTransferObject): IObservableFinisher {
    const result = super.addBaseInformation(
      new ObservableDogFinisher(),
      source
    ) as ObservableDogFinisher
    result.template('dog-finishers-template')
    result.boxNumber(source.Box)
    result.tag('Dog')
    result.stripeCount(source.StripeCount)
    return result
  }
}
