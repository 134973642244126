import { RootState } from '@core/Store'

export const getIsAppHeaderVisible = ({ appHeader }: RootState) => appHeader.isAppHeaderVisible
export const getIsBalanceVisible = ({ appHeader }: RootState) => appHeader.isBalanceVisible
export const getIsDropDownOpen = ({ appHeader }: RootState) => appHeader.isDropDownOpen
export const getIsAppHeaderStickyEnabled = ({ appHeader }: RootState) => appHeader.isStickyEnabled
export const getIsHamburgerMenuOpen = ({ appHeader }: RootState) => appHeader.isHamburgerMenuOpen
export const getShouldDisableAppHeaderRender = ({ appHeader }: RootState) =>
  appHeader.shouldDisableAppHeaderRender

export const selectIsPreviousPendingBetsSportsOnly = ({ appHeader }: RootState) =>
  appHeader.isPreviousPendingBetsSportsOnly
