import React, { memo } from 'react'
import { VerificationButton } from '../Common/Buttons/VerificationButton'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'
import { FooterMessageStyled } from '../Common/Layouts/FooterMessage.styled'

type Props = {
  isBusy: boolean
  allowToProceed: boolean
  onSubmit: VoidFunction
  footerMessage?: string
  footerMessageInProgress?: string
}

export const DocumentNextButton = memo<Props>(
  ({
    isBusy,
    allowToProceed,
    onSubmit,
    footerMessage = 'Complete all required fields before continuing.',
    footerMessageInProgress = 'Validation in progress. Please wait...',
  }) => {
    return (
      <>
        <VerificationButton
          color='tab'
          onClick={onSubmit}
          disabled={isBusy || !allowToProceed}
          showIcon={!isBusy}
        >
          {isBusy ? <BusyIndicator isBusy={true} /> : 'Next'}
        </VerificationButton>
        {isBusy && <FooterMessageStyled>{footerMessageInProgress}</FooterMessageStyled>}
        {!isBusy && !allowToProceed && <FooterMessageStyled>{footerMessage}</FooterMessageStyled>}
      </>
    )
  }
)
