import dayjs from 'dayjs'
import React from 'react'
import { NotificationTime } from '../../types'

const notificationTimeOptions: NotificationTime[] = [1, 5, 10, 15]

/** a hook to only render valid notification times and rerender component when a time lapses so it is now hidden */
export const useValidNotificationOptions = (eventStartTime: string): NotificationTime[] => {
  const now = dayjs()
  const startTime = dayjs(eventStartTime)
  const minutesTillStart = startTime.diff(now, 'minute')
  const [validTimes, setValidTimes] = React.useState(() =>
    notificationTimeOptions.filter(option => option <= minutesTillStart)
  )

  React.useEffect(() => {
    if (!validTimes.length) {
      return
    }
    const now = dayjs()
    const nextNotificationInterval = Math.max(...validTimes)
    const notificationTime = startTime.subtract(nextNotificationInterval, 'minute')
    const timeTillNotification = notificationTime.valueOf() - now.valueOf()
    const timer = setTimeout(() => {
      // notification was sent out. re render component with that time option filtered out
      const now = dayjs()
      const minutesTillStart = startTime.diff(now, 'minute')
      setValidTimes(notificationTimeOptions.filter(option => option <= minutesTillStart))
    }, timeTillNotification)

    return () => clearTimeout(timer)
  }, [startTime, validTimes])

  return validTimes
}
