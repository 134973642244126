const defaults: CurrencySettings = {
  errorOnInvalid: false,
  pattern: '!#',
  negativePattern: '-!#',
}

export class Currency {
  public get value() {
    return roundToTwoDecimals(this._value)
  }
  public get rawValue() {
    return this._value
  }
  private readonly _value: number
  private _settings: CurrencySettings

  constructor(value: number | string | Currency, opts?: Partial<CurrencySettings>) {
    const settings = { ...defaults, ...opts }
    const v = this.parse(value, settings)
    this._value = v
    this._settings = settings
  }

  add(number: number | string | Currency): Currency {
    return new Currency(this._value + this.parse(number, this._settings), this._settings)
  }

  subtract(number: number | string | Currency): Currency {
    return new Currency(this._value - this.parse(number, this._settings), this._settings)
  }

  multiply(number: number): Currency {
    return new Currency(this._value * number, this._settings)
  }

  divide(number: number | string | Currency): Currency {
    return new Currency(this._value / this.parse(number, this._settings, false), this._settings)
  }

  dollars(): number {
    return ~~this._value
  }

  format(options?: Partial<Pick<CurrencySettings, 'pattern' | 'negativePattern'>>): string {
    const { pattern, negativePattern } = { ...this._settings, ...options }
    const split = Math.abs(this._value).toFixed(2).split('.')
    const dollars = split[0]
    const cents = split[1]

    return (this._value >= 0 ? pattern : negativePattern)
      .replace('!', '$')
      .replace('#', dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1' + ',') + (cents ? '.' + cents : ''))
  }

  toString(): string {
    return roundToTwoDecimals(this._value).toFixed(2)
  }

  toJSON(): number {
    return parseFloat(this.toString())
  }

  private parse(
    value: number | string | Currency,
    opts: CurrencySettings,
    useRounding = false
  ): number {
    let v = 0
    const { errorOnInvalid } = opts

    if (typeof value === 'number' || value instanceof Currency) {
      v = value instanceof Currency ? value._value : value
    } else if (typeof value === 'string') {
      const regex = new RegExp(`[^-\\d.]`, 'g')
      const decimalString = new RegExp(`\\.`, 'g')
      v =
        parseFloat(
          value
            .replace(/\((.*)\)/, '-$1') // allow negative e.g. (1.99)
            .replace(regex, '') // replace any non numeric values
            .replace(decimalString, '.') // convert any decimal values
        ) || 0
    } else {
      if (errorOnInvalid) {
        throw new Error('Invalid Input')
      }
      v = 0
    }

    return useRounding ? roundToTwoDecimals(v) : v
  }
}

// =============
// Local Helpers
// =============

const roundToTwoDecimals = (value: number): number => {
  const s = value.toString()
  if (s.includes('e')) {
    // grotty hax wont work so do it with rounding errors
    return Math.round(value * 100) / 100
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Number(Math.round((s + 'e+2') as any) + 'e-2') // this is some grotty hax to round without errors in js
}

// =====
// Types
// =====

interface CurrencySettings {
  errorOnInvalid: boolean
  pattern: string
  negativePattern: string
}
