import { RemoveSingleBet } from '@core/Areas/Betslip/signals'
import { useIsNewBetSlipFeatureActive } from '@core/Areas/BetSlipNew/hooks'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { useRacePageData } from '@core/Areas/Racing/Hooks/useRacePageData/useRacePageData'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'
import { MeetingInformationDataTransferObject, StarterDataTransferObject } from '@mobi/api-types'
import * as amplify from '@classic/AppUtils/Framework/Messaging/amplifyjs'
import { isFobMatchedSelection, isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { removeItemFromBetSlipById } from '@mobi/betslip/Store/Bets'
import { FobMatchedSelection, RaceDetails, ToteSelection } from '@mobi/betslip/types'
import { dayjs } from '@mobi/utils/date'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '@core/Store'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { state$ } from '@core/Areas/Betslip/driver'
import { useSmartBetSlip } from '@core/Areas/Racing/Components/SmartBetSlip/hooks/useSmartBetSlip'
import { setSelectedProposition } from '@core/Areas/RaceCard/Store'
import { getSelectedProposition } from '@core/Areas/RaceCard/Store/selectors'
import type { Selection } from '@mobi/betslip/types'
import { getRaceStarter } from '../helpers/helpers'
import { makeToteSelection } from '@core/Areas/Racing/Components/BettableTips/helpers'

export const useSmartBetslipRunner = ({
  fixtureId,
  fixtureDate,
  acceptorNumber,
  raceNumber,
}: {
  fixtureId: string
  fixtureDate: string | Date
  raceNumber: number | string
  acceptorNumber: number
}) => {
  const isSmartBetslipSettingActive = useSelector(getEnhancedBetslipSetting)
  const isNewBetSlipFeatureActive = useIsNewBetSlipFeatureActive()
  const fobWinPlaceSelectionButtonRef = React.useRef<HTMLButtonElement>(null)
  const toteWinPlaceSelectionButtonRef = React.useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const { race } = useRacePageData({
    meetingDate: fixtureDate,
    meetingId: fixtureId,
    raceNumber,
  })
  const selectedProposition = useSelector(getSelectedProposition)
  const thisToteId = React.useMemo(
    () => `${dayjs(fixtureDate).format('YYYY-MM-DD')}#${fixtureId}#${raceNumber}#${acceptorNumber}`,
    [fixtureDate, fixtureId, raceNumber, acceptorNumber]
  )
  const raceStarter = getRaceStarter(acceptorNumber, race)
  const [runnerPropSeq, setRunnerPropSeq] = React.useState<number | null>(null)
  const [runnerToteId, setRunnerToteId] = React.useState<string | null>(null)
  const smartBetslipState = useSmartBetSlip(isNewBetSlipFeatureActive, { includeTote: true })

  const handleSmartBetslipFobSelection = React.useCallback(
    (shouldToteDeselectionOverride: boolean) => {
      if (!race || !raceStarter) {
        // throw here?
        return
      }
      // clear any tote solections
      amplify.publish('clear-all-selections-command')
      dispatch(setSelectedProposition(null))
      if (shouldToteDeselectionOverride || selectedProposition) {
        // if the knockout code has selection, or SP is selected, then we dont want to deselect a fob since the ui
        // does not have any indication it is selected. instead we just delesect the tote and then do nothing
        // when tote is moved fully to redux we can check it all here without an external argument
        return
      }

      if (runnerPropSeq) {
        // remove it
        if (isNewBetSlipFeatureActive) {
          const betSlipItems = selectBetSlipItems(store.getState())
          const item = betSlipItems.find(
            x =>
              isFobMatchedSelection(x.selection) &&
              Number(x.selection.propositionSeq) === runnerPropSeq
          )
          if (item) {
            dispatch(removeItemFromBetSlipById({ id: item.id }))
          }
        } else {
          state$.take(1).subscribe(state => {
            const item = state.items.find(
              x =>
                isFobMatchedSelection(x.selection) &&
                Number(x.selection.propositionSeq) === runnerPropSeq
            )
            if (item) {
              RemoveSingleBet({ id: item.id })
            }
          })
        }
      } else {
        handleFobAddToBetSlip({
          fixtureDate,
          fixtureId,
          raceStarter,
          acceptorNumber,
          meeting: race.MeetingInformation,
          animationElement: fobWinPlaceSelectionButtonRef.current,
          isNewBetSlipFeatureActive,
          shouldAutoAdd: smartBetslipState.shouldAutoAddToBetslip,
        })
      }
    },
    [
      raceStarter,
      runnerPropSeq,
      isNewBetSlipFeatureActive,
      acceptorNumber,
      race,
      fixtureDate,
      fixtureId,
      dispatch,
      selectedProposition,
      smartBetslipState.shouldAutoAddToBetslip,
    ]
  )

  const handleSmartBetslipToteSelection = React.useCallback(
    (shouldFobDeselectionOverride: boolean) => {
      if (!race || !raceStarter) {
        // throw here?
        return
      }
      // clear any tote solections
      amplify.publish('clear-all-selections-command')
      dispatch(setSelectedProposition(null))
      if (shouldFobDeselectionOverride || selectedProposition) {
        // if the knockout code has selection, or SP is selected, then we dont want to deselect this tote since the ui
        // does not have any indication it is selected. instead we just deselect the fob and then do nothing
        // when betslip is moved fully to redux we can check it all here without an external argument
        return
      }

      if (runnerToteId) {
        // remove it
        if (isNewBetSlipFeatureActive) {
          const betSlipItems = selectBetSlipItems(store.getState())
          const item = betSlipItems.find(x =>
            isSameToteSelection(x.selection, fixtureId, fixtureDate, raceNumber, acceptorNumber)
          )
          if (item) {
            dispatch(removeItemFromBetSlipById({ id: item.id }))
          }
        } else {
          state$.take(1).subscribe(state => {
            const item = state.items.find(x =>
              isSameToteSelection(x.selection, fixtureId, fixtureDate, raceNumber, acceptorNumber)
            )
            if (item) {
              RemoveSingleBet({ id: item.id })
            }
          })
        }
      } else {
        handleToteAddToBetSlip({
          fixtureDate,
          fixtureId,
          raceStarter,
          acceptorNumber,
          meeting: race.MeetingInformation,
          animationElement: toteWinPlaceSelectionButtonRef.current,
          isNewBetSlipFeatureActive,
          shouldAutoAdd: smartBetslipState.shouldAutoAddToBetslip,
        })
      }
    },
    [
      raceStarter,
      runnerToteId,
      isNewBetSlipFeatureActive,
      acceptorNumber,
      race,
      fixtureId,
      dispatch,
      fixtureDate,
      raceNumber,
      selectedProposition,
      smartBetslipState.shouldAutoAddToBetslip,
    ]
  )

  React.useEffect(() => {
    if (!isSmartBetslipSettingActive || !smartBetslipState) return

    const hasRunnerFob = smartBetslipState.betslipFobPropSeqs.includes(
      raceStarter?.FixedOddsInfo?.PropositionSequence as number
    )
    setRunnerPropSeq(
      hasRunnerFob && raceStarter?.FixedOddsInfo
        ? raceStarter.FixedOddsInfo.PropositionSequence
        : null
    )
  }, [isSmartBetslipSettingActive, raceStarter, smartBetslipState])

  React.useEffect(() => {
    if (!isSmartBetslipSettingActive || !smartBetslipState) return

    const hasRunner = smartBetslipState.betslipToteIds.includes(thisToteId)
    setRunnerToteId(hasRunner ? thisToteId : null)
  }, [isSmartBetslipSettingActive, smartBetslipState, thisToteId])

  const isRunnerInBetSlipFob = !!runnerPropSeq
  const isRunnerInBetSlipTote = !!runnerToteId
  return {
    /** true when smart betslip feature and setting active */
    shouldUseSmartBetslip: smartBetslipState.isSmartBetslipFeatureActive,
    isRunnerInBetSlipFob: isRunnerInBetSlipFob,
    isRunnerInBetSlipTote: isRunnerInBetSlipTote,
    /** true when the ui win selection button should be highlighted */
    shouldHighlightRunnerFobSelection: isRunnerInBetSlipFob && !selectedProposition,
    shouldHighlightRunnerToteSelection: isRunnerInBetSlipTote && !selectedProposition,
    /** this func will add the runner to betslip when u click the win place selection button */
    handleSmartBetslipFobSelection,
    handleSmartBetslipToteSelection,
    /** Ref to element for clicky animation  */
    fobWinPlaceSelectionButtonRef,
    /** Ref to element for clicky animation  */
    toteWinPlaceSelectionButtonRef,
  }
}

function handleFobAddToBetSlip(args: {
  fixtureId: string
  fixtureDate: string | Date
  acceptorNumber: number
  isNewBetSlipFeatureActive: boolean
  raceStarter: StarterDataTransferObject
  meeting: MeetingInformationDataTransferObject
  animationElement: HTMLButtonElement | null
  shouldAutoAdd: boolean
}) {
  if (!args.raceStarter.FixedOddsInfo) {
    return
  }
  const selectedRace = args.meeting.SelectedRace
  const shouldAllowPlaceInvestment =
    selectedRace.IsFixedOddsRace && selectedRace.FixedOddsInfo.IsPlaceAvailable
  const selection: FobMatchedSelection = {
    type: 'fob-matched',
    fixtureId: args.fixtureId,
    fixtureDate: dayjs(args.fixtureDate).format('YYYY-MM-DD'),
    raceNumber: selectedRace.RaceNumber,
    acceptorNumber: args.acceptorNumber,
    propositionSeq: args.raceStarter.FixedOddsInfo.PropositionSequence.toString(),
    winPrice: Number(args.raceStarter.FixedOddsInfo.DisplayWinDividend),
    winPriceLastSeen: null,
    placePrice:
      args.raceStarter.FixedOddsInfo.DisplayPlaceDividend !== '-'
        ? Number(args.raceStarter.FixedOddsInfo.DisplayPlaceDividend)
        : null,
    placePriceLastSeen: null,
    priceSource: 'selection',
  }

  const raceDetails: RaceDetails = {
    races: [
      {
        key: selectedRace.Key,
        raceNumber: selectedRace.RaceNumber,
        meetingName: args.meeting.MeetingName,
        meetingCode: args.meeting.MeetingCode,
        meetingId: args.meeting.MeetingId,
        meetingDate: dayjs(args.fixtureDate).format('YYYY-MM-DD'),
        distance: selectedRace.Distance,
        raceTime: selectedRace.RaceTime,
        acceptorKeys: [],
        type: selectedRace.RaceType,
        isFixedOddsRace: selectedRace.IsFixedOddsRace,
      },
    ],
    acceptors: [
      {
        key: `${selectedRace.Key}-${args.raceStarter.Number}`,
        name: args.raceStarter.Name,
        number: args.raceStarter.Number,
        type: args.raceStarter.Type,
        meetingName: args.meeting.MeetingName,
        isScratched: args.raceStarter.IsScratched,
        scratchType: args.raceStarter.ScratchType,

        imageUrl: args.raceStarter.SilkImages.large.url,
        imageUrlWithSizes: args.raceStarter.SilkImages,
        fixedOdds: {
          win: '',
          place: '',
          isSuspended: false,
        },
        toteOdds: { win: '', place: '' },
      },
    ],
  }

  const quickBetSelection: QuickbetSelection = {
    bettingType: 'fixed-odds-racing',
    selection,
    selectionDetails: raceDetails,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment,
  }

  handleBetSelection({
    betFlow: !args.shouldAutoAdd
      ? {
          location: 'Quickbet',
          options: {},
        }
      : args.isNewBetSlipFeatureActive
        ? {
            location: 'BetSlipNew',
            options: { clickedElement: args.animationElement as HTMLButtonElement },
          }
        : { location: 'Betslip' },
    selection: quickBetSelection,
  })
}

function handleToteAddToBetSlip(args: {
  fixtureId: string
  fixtureDate: string | Date
  acceptorNumber: number
  isNewBetSlipFeatureActive: boolean
  raceStarter: StarterDataTransferObject
  meeting: MeetingInformationDataTransferObject
  animationElement: HTMLButtonElement | null
  shouldAutoAdd: boolean
}) {
  if (!args.raceStarter.IsToteEnabled) {
    return
  }
  const selectedRace = args.meeting.SelectedRace
  const shouldAllowPlaceInvestment = selectedRace.HasPlacePool
  const selection: ToteSelection = makeToteSelection(args.meeting, args.acceptorNumber)

  const raceDetails: RaceDetails = {
    races: [
      {
        key: selectedRace.Key,
        raceNumber: selectedRace.RaceNumber,
        meetingName: args.meeting.MeetingName,
        meetingCode: args.meeting.MeetingCode,
        meetingId: args.meeting.MeetingId,
        meetingDate: dayjs(args.fixtureDate).format('YYYY-MM-DD'),
        distance: selectedRace.Distance,
        raceTime: selectedRace.RaceTime,
        acceptorKeys: [],
        type: selectedRace.RaceType,
        isFixedOddsRace: selectedRace.IsFixedOddsRace,
      },
    ],
    acceptors: [
      {
        key: `${selectedRace.Key}-${args.raceStarter.Number}`,
        name: args.raceStarter.Name,
        number: args.raceStarter.Number,
        type: args.raceStarter.Type,
        meetingName: args.meeting.MeetingName,
        isScratched: args.raceStarter.IsScratched,
        scratchType: args.raceStarter.ScratchType,

        imageUrl: args.raceStarter.SilkImages.large.url,
        imageUrlWithSizes: args.raceStarter.SilkImages,
        fixedOdds: {
          win: '',
          place: '',
          isSuspended: false,
        },
        toteOdds: { win: '', place: '' },
      },
    ],
  }

  const quickBetSelection: QuickbetSelection = {
    bettingType: 'tote-racing',
    selection,
    selectionDetails: raceDetails,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment,
  }

  handleBetSelection({
    betFlow: !args.shouldAutoAdd
      ? {
          location: 'Quickbet',
          options: {},
        }
      : args.isNewBetSlipFeatureActive
        ? {
            location: 'BetSlipNew',
            options: { clickedElement: args.animationElement as HTMLButtonElement },
          }
        : { location: 'Betslip' },
    selection: quickBetSelection,
  })
}

function isSameToteSelection(
  selection: Selection | null,
  fixtureId: string,
  fixtureDate: string | Date,
  raceNumber: number | string,
  acceptorNumber: number
) {
  if (!isToteSelection(selection)) {
    return false
  }
  for (const acceptor of selection.selectionString.split('.')) {
    if (
      selection.fixtureDate === dayjs(fixtureDate).format('YYYY-MM-DD') &&
      selection.fixtureId === fixtureId &&
      selection.raceNumber === raceNumber &&
      Number(acceptor) === acceptorNumber
    ) {
      return true
    }
  }
  return false
}
