import type { IObservableStarter } from '../Model/Observables/IObservableStarter'
import type { IObservableFinisher } from '../Model/Observables/IObservableFinisher'
import type { SortOption } from './types'

export default {
  sortNumber(
    direction: SortOption['direction'] = 'Ascending'
  ): (left: IObservableStarter, right: IObservableStarter) => number {
    return (left: IObservableStarter, right: IObservableStarter) => {
      let result = direction === 'Ascending' ? 1 : -1
      if (left.number() < right.number()) {
        result = result * -1
      } else if (left.number() > right.number()) {
        result = result * 1
      } else {
        result = result * 0
      }
      return result
    }
  },

  sortPlacing(
    direction: SortOption['direction'] = 'Ascending'
  ): (left: IObservableFinisher, right: IObservableFinisher) => number {
    return (left: IObservableFinisher, right: IObservableFinisher) => {
      let result = direction === 'Ascending' ? 1 : -1
      if (left.placing() < right.placing()) {
        result = result * -1
      } else if (left.placing() > right.placing()) {
        result = result * 1
      } else {
        result = result * 0
      }
      return result
    }
  },

  sortName(
    direction: SortOption['direction'] = 'Ascending'
  ): (left: IObservableStarter, right: IObservableStarter) => number {
    return (left: IObservableStarter, right: IObservableStarter) => {
      let result = direction === 'Ascending' ? 1 : -1
      if (left.name() < right.name()) {
        result = result * -1
      } else if (left.name() > right.name()) {
        result = result * 1
      } else {
        result = result * 0
      }
      return result
    }
  },

  sortPrice(
    direction: SortOption['direction'] = 'Ascending'
  ): (left: IObservableStarter, right: IObservableStarter) => number {
    return (left: IObservableStarter, right: IObservableStarter) => {
      let result = direction === 'Ascending' ? 1 : -1
      if (+left.displayWinDividend() < +right.displayWinDividend()) {
        result = result * -1
      } else if (+left.displayWinDividend() > +right.displayWinDividend()) {
        result = result * 1
      } else {
        if (left.number() < right.number()) {
          result = result * -1
        } else if (left.number() > right.number()) {
          result = result * 1
        } else {
          result = result * 0
        }
      }
      return result
    }
  },

  sortFixedPrice(
    direction: SortOption['direction'] = 'Ascending'
  ): (left: IObservableStarter, right: IObservableStarter) => number {
    return (left: IObservableStarter, right: IObservableStarter) => {
      let result = direction === 'Ascending' ? 1 : -1
      if (
        +left.fixedOddsStarterInfo.displayWinDividend() <
        +right.fixedOddsStarterInfo.displayWinDividend()
      ) {
        result = result * -1
      } else if (
        +left.fixedOddsStarterInfo.displayWinDividend() >
        +right.fixedOddsStarterInfo.displayWinDividend()
      ) {
        result = result * 1
      } else {
        if (left.number() < right.number()) {
          result = result * -1
        } else if (left.number() > right.number()) {
          result = result * 1
        } else {
          result = result * 0
        }
      }
      return result
    }
  },

  sortSameRaceMultiWinPrice(
    direction: SortOption['direction'] = 'Ascending'
  ): (left: IObservableStarter, right: IObservableStarter) => number {
    return (left: IObservableStarter, right: IObservableStarter) => {
      const leftWin = left.fixedOddsStarterInfo.sameRaceMultiPrices.win() ?? 0
      const rightWin = right.fixedOddsStarterInfo.sameRaceMultiPrices.win() ?? 0
      const leftMissing = isSameRaceMultiScratchedSuspendedOrWithoutPrice(left)
      const rightMissing = isSameRaceMultiScratchedSuspendedOrWithoutPrice(right)
      let result = 0

      if (leftMissing && rightMissing) {
        // both absent means both equally bad
        result = 0
      } else if (rightMissing) {
        // if absent, keep order to make sure it stays behind
        result = -1
      } else if (leftMissing) {
        // if absent, switch order to make sure it stays behind
        result = 1
      } else {
        // positive result of subtraction swaps left and right
        result = leftWin - rightWin

        if (result === 0) {
          // use starters' numbers, they should never be equal
          result = left.number() - right.number()
        }

        result = direction === 'Ascending' ? result : -result
      }

      return result
    }
  },

  for({
    property,
    direction,
  }: SortOption): (left: IObservableStarter, right: IObservableStarter) => number {
    switch (property) {
      case 'Number':
        return this.sortNumber(direction)
      case 'FixedPrice':
        return this.sortFixedPrice(direction)
      case 'Price':
        return this.sortPrice(direction)
      case 'Name':
        return this.sortName(direction)
      case 'SameRaceMultiWinPrice':
        return this.sortSameRaceMultiWinPrice(direction)
      default:
        throw new Error('invalid sortProperty')
    }
  },

  filter(property: SortOption['property']): (element: IObservableStarter) => boolean {
    if (property === 'FixedPrice') {
      return value => {
        return (
          !value.fixedOddsStarterInfo ||
          !value.hasFixedOdds() ||
          !value.fixedOddsStarterInfo.hasWinDividend() ||
          !value.fixedOddsStarterInfo.displayWinDividend() ||
          value.fixedOddsStarterInfo.displayWinDividend().length === 0 ||
          value.fixedOddsStarterInfo.isSuspended() ||
          value.fixedOddsStarterInfo.isScratched()
        )
      }
    } else {
      return value =>
        !value.isToteEnabled() ||
        value.isScratchedToteAndFob() ||
        value.isScratched() ||
        !value.displayWinDividend() ||
        value.displayWinDividend().length === 0 ||
        value.displayWinDividend() === '-'
    }
  },

  shouldFilter(property: SortOption['property']): boolean {
    return property === 'FixedPrice' || property === 'Price'
  },
}

export function isSameRaceMultiScratchedSuspendedOrWithoutPrice(starter: IObservableStarter) {
  return (
    starter.fixedOddsStarterInfo.isScratched() ||
    starter.fixedOddsStarterInfo.isSuspended() ||
    (starter.fixedOddsStarterInfo.sameRaceMultiPrices.win() ?? 0) == 0
  )
}
