import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { CHANGE_BET_TYPE_EVENT } from '@core/Areas/RaceCard/constants'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import type { IBettingInformationManager } from '@classic/Betting-v2/Model/IBettingInformationManager'
import type { IStartersService } from '@classic/Betting-v2/Services/IStartersService'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import Guard from '@classic/AppUtils/Framework/Guard'
import type { IBettingStateManagerViewModel } from './IBettingStateManagerViewModel'

const lastSelectedBetType: ko.Observable<BetType | null> = ko.observable(null)

export const resetMatchedRaceBetTypePersist = () => lastSelectedBetType(null)

@injectable()
export class BettingStateManagerViewModel
  extends Disposable
  implements IBettingStateManagerViewModel
{
  private bettingInformationManager: IBettingInformationManager
  private mettingInformationSubscriptions!: ko.Subscription

  public startersService: IStartersService
  public bettingInformation!: BettingInformation
  public raceInformation!: ObservableRacePage
  public ready: ko.Observable<boolean>

  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('IBettingInformationManager') bettingInformationManager: IBettingInformationManager,
    @inject('IStartersService') startersService: IStartersService
  ) {
    super(eventAggregator)

    this.bettingInformationManager = bettingInformationManager
    this.startersService = startersService
    this.ready = ko.observable<boolean>(false).extend({ notify: 'always' })
  }

  public init(params: { raceInformation: ObservableRacePage }) {
    this.ready(false)
    Guard.notNull(params)
    Guard.notNull(params.raceInformation)
    Guard.notNull(params.raceInformation.meetingInformation)

    if (lastSelectedBetType() !== null) {
      const isLastBetTypeAvailable = params.raceInformation
        .availableBetTypes()
        .some(({ betType }) => betType() === lastSelectedBetType())
      if (!isLastBetTypeAvailable) resetMatchedRaceBetTypePersist()
    }

    this.raceInformation = params.raceInformation
    this.bettingInformation =
      params.raceInformation.provisionBettingInformation(lastSelectedBetType)
    this.bettingInformation.selections.setUpForLegs(params.raceInformation, false)
    this.bettingInformation.setUpFields()
    this.bettingInformationManager.init(this.raceInformation, this.bettingInformation)
    this.configureDisposal()
    this.setupSubscriptionsAndEventNotifications()
    this.ready(true)
  }

  protected setupSubscriptionsAndEventNotifications() {
    this.subscribeToMeetingChanges()
    this.subscribeToBetTypeChanges()
    this.subscribeToSortChange()
  }

  private subscribeToMeetingChanges() {
    this.mettingInformationSubscriptions = this.raceInformation.mergeDone.subscribe(mergeIsDone => {
      if (!mergeIsDone) {
        this.ready(false)
      } else {
        const oldRaceNumber = this.bettingInformation.raceNumber

        this.bettingInformation.merge(
          this.raceInformation.meetingInformation.meetingId(),
          this.raceInformation.meetingInformation.selectedRace.raceNumber(),
          this.raceInformation.meetingInformation.meetingDate(),
          this.raceInformation.betIdentifier,
          this.raceInformation.meetingInformation.selectedRace.fixedPlacesPaying()
        )

        const raceChanged = oldRaceNumber != this.bettingInformation.raceNumber
        this.bettingInformationManager.update(raceChanged)

        this.ready(true)
      }
    })
  }

  protected subscribeToBetTypeChanges() {
    this.safeSubscribe(CHANGE_BET_TYPE_EVENT, (params: { betType: BetType }) => {
      this.ready(false)
      this.raceInformation.resetStartersSelections()
      if (params.betType !== BetType.Mystery) lastSelectedBetType(params.betType)
      this.bettingInformationManager.processBetTypeChange(params.betType)
      this.ready(true)
    })
  }

  protected subscribeToSortChange() {
    this.safeSubscribe('sort-starters', (params: { raceNumber: number; option: SortOption }) => {
      this.ready(false)
      this.bettingInformationManager.processSort(params)
      this.bettingInformation.setSortOption(params.option)
      this.ready(true)
    })
  }

  private configureDisposal() {
    this.registerDisposals(() => this.mettingInformationSubscriptions.dispose())
  }
}
