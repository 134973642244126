import React from 'react'
import { Observable } from 'rx'
import { ConfirmAllBetsSuccessful } from '@core/Areas/Betslip/signals'
import { ConfirmBetslipBetsSucceeded, ConfirmBetSucceeded } from '@core/Areas/Quickbet/signals'
import { UpdateUserData as KambiPlaySessionIdRegistered } from '@core/Areas/Kambi/driver'
import { invalidateKambiPendingTicketsQueryData } from '@core/Data/Account/PendingTicketsCount/helpers'
import { invalidateRacingPendingTicketsQueryData } from '@core/Data/Account/PendingTicketsCount/helpers'
import { startListening } from '@core/Store/listenerMiddleware'
import { confirmAllBets } from '@mobi/betslip/Store/Workflow/asyncActions'

export const useInvalidatePendingCountOnEvents = () => {
  // Invalidate on bet placement w/ Quickbet and legacy Betslip
  React.useEffect(() => {
    const subscription = Observable.merge(
      ConfirmBetSucceeded.signal$,
      ConfirmAllBetsSuccessful.signal$,
      ConfirmBetslipBetsSucceeded.signal$
    )
      .debounce(500)
      .subscribe(invalidateRacingPendingTicketsQueryData)
    return () => subscription.dispose()
  }, [])

  // Invalidate on bet placement w/ new BetSlip
  React.useEffect(() => {
    const dispose = startListening({
      actionCreator: confirmAllBets.fulfilled,
      effect: invalidateRacingPendingTicketsQueryData,
    })
    return () => dispose()
  }, [])

  // Ensure kambi ticket count is correct by forcing update on session id
  React.useEffect(() => {
    const subscription = KambiPlaySessionIdRegistered.signal$.subscribe(() => {
      invalidateKambiPendingTicketsQueryData()
    })
    return () => subscription.dispose()
  }, [])
}
