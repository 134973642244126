import styled from '@emotion/styled'
import { ButtonBlock } from '../../../Common/Buttons'
import { gapX } from '../../../Utils/gap'

export const VerificationButtonStyled = styled(ButtonBlock)(
  {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    fontSize: '1.7rem',
    lineHeight: 1,

    '&:not(:disabled)': {
      cursor: 'pointer',
    },
  },
  gapX('0.5rem')
)
