import React from 'react'
import styled from '@emotion/styled'
import { colors, hexColors } from '@mobi/settings'
import { dayjs } from '@mobi/utils'
import { DirectionalIcon } from '@core/Components/Icons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { CalendarDatePicker } from '@core/Components/DatePicker/DatePicker'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import {
  trackMeetingsCalendarOpened,
  trackMeetingsDayNextArrow,
  trackMeetingsDayPrevArrow,
  trackMeetingsDayQuickSelect,
} from '@core/Areas/Meetings/analytics'
import type { useMeetingsData } from '@core/Areas/Meetings/hooks'
import { radius } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  TabLabelSize = '1.4rem',
  TabCalendarClass = 'meetings__tabs__calendar',
  TabCalendarOpenClass = 'meetings__tabs__calendar--open',
  TabActiveClass = 'meetings__tabs__tab--active',
  DateFormat = 'YYYY-MM-DD',
}

export const PrimaryDateNavigation: React.FC<DateTabNavProps> = ({ data, state, handlers }) => {
  const dateTabsElRef = React.useRef<HTMLDivElement>(null)
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false)

  React.useEffect(() => {
    setIsCalendarOpen(false)
  }, [state.currentDate])

  // Scroll active tab into view
  React.useEffect(() => {
    if (data.isLoading || !dateTabsElRef.current) return
    const activeTabEl = document.querySelector('.' + LocalConstants.TabActiveClass)
    if (!activeTabEl) return

    const parentEl = dateTabsElRef.current
    const childRect = activeTabEl.getBoundingClientRect()
    const parentRect = parentEl.getBoundingClientRect()

    if (childRect.left < parentRect.left)
      parentEl.scrollLeft = -(parentRect.left - childRect.left + childRect.width)

    if (childRect.right > parentRect.right)
      parentEl.scrollLeft = parentRect.right - childRect.left + childRect.width
  }, [state.currentDate, data.isLoading])

  const handleCalendarClick = () => {
    trackMeetingsCalendarOpened()
    setIsCalendarOpen(curr => !curr)
  }

  const tabDates = sortTabDates(data.dates, state.raceCode, state.currentDate)

  return (
    <>
      <DateTabsWrapperStyled data-testid='meetings-date-nav'>
        <div>
          <button
            className={LocalConstants.TabCalendarClass}
            onClick={() => {
              trackMeetingsDayPrevArrow()
              handlers.setDate(
                dayjs(state.currentDate).subtract(1, 'day').format(LocalConstants.DateFormat)
              )
            }}
            data-testid='meetings-date-previous-day'
          >
            <DirectionalIcon size='2.5rem' direction='left' />
          </button>

          <button
            className={LocalConstants.TabCalendarClass}
            onClick={handleCalendarClick}
            data-testid='meetings-date-picker'
          >
            <Icon
              className={`${isCalendarOpen ? LocalConstants.TabCalendarOpenClass : ''}`}
              type='calendar'
              size='2.2rem'
            />
          </button>

          <button
            className={LocalConstants.TabCalendarClass}
            onClick={() => {
              trackMeetingsDayNextArrow()
              handlers.setDate(
                dayjs(state.currentDate).add(1, 'day').format(LocalConstants.DateFormat)
              )
            }}
            data-testid='meetings-date-next-day'
          >
            <DirectionalIcon size='2.5rem' direction='right' />
          </button>
        </div>

        <div ref={dateTabsElRef}>
          {data.isLoading ? (
            <>
              <button data-testid='meetings-date-tab--loading'>
                <LoadingPlaceholder width='8rem' height='1.8rem' borderRadius={radius.lg} />
              </button>
              <button data-testid='meetings-date-tab--loading'>
                <LoadingPlaceholder width='6rem' height='1.8rem' borderRadius={radius.lg} />
              </button>
              <button data-testid='meetings-date-tab--loading'>
                <LoadingPlaceholder width='8rem' height='1.8rem' borderRadius={radius.lg} />
              </button>
            </>
          ) : (
            tabDates.map(({ date, label }) => (
              <button
                data-label={label}
                data-testid='meetings-date-tab'
                key={date}
                onClick={() => {
                  trackMeetingsDayQuickSelect({ label })
                  handlers.setDate(date)
                }}
                className={state.currentDate === date ? LocalConstants.TabActiveClass : ''}
              >
                {label}
              </button>
            ))
          )}
        </div>
      </DateTabsWrapperStyled>

      {isCalendarOpen && (
        <CalendarDatePicker
          areaName='meetings'
          value={dayjs(state.currentDate).toDate()}
          onChange={value => {
            setIsCalendarOpen(false)
            handlers.setDate(value)
          }}
        />
      )}
    </>
  )
}

// =============
// Local Helpers
// =============

const sortTabDates: SortTabDates = (dates, raceCode, currentDate) => {
  const datesForCode = dates?.[toFirstLetterUppercase(raceCode)] || []
  const datesForCodeMapped = datesForCode.map(date => ({ date, label: getDateLabel(date) }))

  if (!dayjs(currentDate).isYesterday()) {
    datesForCodeMapped.unshift({
      date: dayjs().subtract(1, 'day').format(LocalConstants.DateFormat),
      label: 'Yesterday',
    })
    datesForCodeMapped.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1))
  }

  if (!datesForCode.includes(currentDate)) {
    const isDateSuppliedInOtherCodes = Object.values(dates || {})
      .flat()
      .includes(currentDate)

    if (isDateSuppliedInOtherCodes)
      datesForCodeMapped.push({ date: currentDate, label: getDateLabel(currentDate) })
    else datesForCodeMapped.unshift({ date: currentDate, label: getDateLabel(currentDate, true) })
  }

  return datesForCodeMapped
}

function getDateLabel(date: string, forceExplicitDate = false): string {
  if (dayjs(date).isToday()) return 'Today'
  if (dayjs(date).isTomorrow()) return 'Tomorrow'
  if (dayjs(date).isYesterday()) return 'Yesterday'

  if (
    forceExplicitDate ||
    dayjs(date).isBefore(dayjs()) ||
    dayjs(date).isAfter(dayjs().add(6, 'day'))
  ) {
    if (dayjs(date).year() !== dayjs().year()) return dayjs(date).format('ddd MMM Do YYYY')
    return dayjs(date).format('ddd MMM Do')
  }

  return dayjs(date).format('dddd')
}

const toFirstLetterUppercase = (input: string): string =>
  input.charAt(0).toUpperCase() + input.slice(1)

// ======
// Styles
// ======

const DateTabsWrapperStyled = styled('div')({
  display: 'flex',
  background: hexColors.white,
  borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,
  minHeight: '5rem',

  '& > div:nth-of-type(1)': {
    flexShrink: 0,
    display: 'flex',
    borderRight: '0.1rem solid ' + hexColors.gainsboro,
  },

  '& > div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',

    overflowX: 'auto',
    scrollBehavior: 'smooth',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  button: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5rem',
    padding: '0 1rem',
    fontSize: LocalConstants.TabLabelSize,
    color: hexColors.nero,
    whiteSpace: 'nowrap',
    background: 'white',
    userSelect: 'none',
    border: 0,

    [`&.${LocalConstants.TabCalendarClass}`]: {
      width: '4rem',
      minWidth: '4rem',
      padding: 0,

      svg: {
        transition: 'transform ease 0.1s',

        [`&.${LocalConstants.TabCalendarOpenClass}`]: {
          transform: 'rotate(12deg) translateY(0.2rem)',
          color: colors.brand.primary,
        },
      },
    },

    [`&.${LocalConstants.TabActiveClass}`]: {
      color: colors.brand.primary,

      '&::after': {
        content: 'attr(data-label)',
        display: 'block',
        overflow: 'hidden',
        position: 'absolute',
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        width: 'auto',
        height: '0.5rem',
        fontSize: LocalConstants.TabLabelSize,
        background: colors.brand.primary,
        color: colors.brand.primary,
        borderRadius: '0.25rem',
      },
    },
  },
})

// =====
// Types
// =====

type DateTabNavProps = ReturnType<typeof useMeetingsData>

type SortTabDates = (
  dates: DateTabNavProps['data']['dates'],
  raceCode: DateTabNavProps['state']['raceCode'],
  currentDate: DateTabNavProps['state']['currentDate']
) => { date: string; label: string }[]
