import { PlanSeq } from '@mobi/api-types'
import type { BetSlipItem } from '@mobi/betslip/types'
import { getToteProductPlanSequence } from '@mobi/betslip/helpers/betting'
import {
  isFobSelection,
  isFobSportsSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'

export const getPlanSequence = (item: BetSlipItem) => {
  const planSeq: number = PlanSeq.Unknown

  if (!item) {
    return planSeq
  }

  if (isToteSelection(item.selection)) {
    return getToteProductPlanSequence(item.selection.betType, true, item.selection.isRovingBanker)
  }

  if (isFobSportsSelection(item.selection)) {
    return PlanSeq.FOBSports
  }

  if (isFobSelection(item.selection)) {
    return PlanSeq.FOBRacing
  }

  return planSeq
}
