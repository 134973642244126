import { Currency, roundEstimateDown } from '@mobi/utils/money'
import type {
  BetSlipItem,
  BetSpecialOffer,
  BettingType,
  FobSelection,
  RacingBetType,
} from '@mobi/betslip/types'
import { isStartingPriceSelection, isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { calculateProjectedBoostReward } from '@mobi/betslip/helpers/superPicks'

export function calculateEstReturnSingleItem(betslipItem: BetSlipItem) {
  const {
    bettingType,
    isEachWay,
    selection,
    investment: { win, place, bonusBet },
    selectedSuperPickOffer,
  } = betslipItem

  if (isStartingPriceSelection(selection)) return 0

  const { winPrice, placePrice } = selection as FobSelection
  return calculateProjectedReturn(
    bettingType,
    winPrice,
    placePrice || 0,
    win.value,
    place.value || 0,
    isEachWay,
    bonusBet?.value,
    selectedSuperPickOffer
  )
}

export function calculateEstReturnForSingles(items: BetSlipItem[]) {
  const singleItems = items.filter(
    item => item.bettingType === 'fixed-odds-racing' || item.bettingType === 'fixed-odds-sports'
  )
  if (singleItems.length === 0) {
    return null
  }
  return singleItems.reduce(
    (total: number, nextItem: BetSlipItem) => total + calculateEstReturnSingleItem(nextItem),
    0
  )
}

export function calculateEstReturnForSinglesReceipt(items: BetSlipItem[]) {
  if (items.length === 0) {
    return null
  }
  return items.reduce(
    (total: Currency, nextItem: BetSlipItem) =>
      total.add(calculateEstReturnSingleItemReceipt(nextItem)),
    new Currency(0)
  ).value
}

export function calculateBetCostSingleItem(betslipItem: BetSlipItem): number {
  const {
    bettingType,
    isEachWay,
    investment: { win, place },
  } = betslipItem
  if (isToteSelection(betslipItem.selection)) {
    return calculateBetCost(
      bettingType,
      win.value,
      place.value,
      isEachWay,
      betslipItem.selection.betType,
      betslipItem.selection.numberOfCombinations
    )
  }
  return calculateBetCost(bettingType, win.value, place.value, isEachWay)
}

export function calculateBetCostSingleItemFromReceipt(betslipItem: BetSlipItem): number {
  const { receipt } = betslipItem
  if (!!receipt && 'winInvestment' in receipt && 'placeInvestment' in receipt) {
    return receipt.winInvestment || 0 + receipt.placeInvestment || 0
  }

  if (!!receipt && 'betCost' in receipt) {
    return receipt.betCost
  }

  return 0
}

// =============
// Local Helpers
// =============

function calculateBetCost(
  bettingType: BettingType | null,
  winInvestment: number,
  placeInvestment: number,
  isEachWay: boolean,
  betType?: RacingBetType,
  numberOfCombinations = 1
): number {
  switch (bettingType) {
    case 'fixed-odds-sports': {
      return winInvestment
    }
    case 'fixed-odds-racing': {
      return isEachWay
        ? new Currency(winInvestment).multiply(2).value
        : new Currency(winInvestment).add(placeInvestment).value
    }
    case 'tote-racing': {
      switch (betType) {
        case 'Win & Place': {
          const totalInvestment = new Currency(winInvestment).add(placeInvestment)
          return new Currency(totalInvestment).multiply(numberOfCombinations).value
        }
        case 'Exacta':
        case 'Quinella':
        case 'Trifecta':
        case 'First 4':
        case 'Double':
        case 'Quaddie': {
          return winInvestment
        }
        case 'All Up': {
          return new Currency(winInvestment).multiply(numberOfCombinations).value
        }
        default: {
          return 0
        }
      }
    }
    case 'mystery-quick-pick':
    case 'tote-sports-tipping': {
      return new Currency(winInvestment).multiply(numberOfCombinations).value
    }
    case 'favourite-numbers': {
      return new Currency(0.5).multiply(numberOfCombinations).value
    }
    default:
      return 0
  }
}

function calculateProjectedReturn(
  bettingType: BettingType | null,
  winPrice: number | undefined = 0,
  placePrice: number | undefined = 0,
  winInvestment: number,
  placeInvestment: number,
  isEachWay: boolean,
  bonusBetValue?: number,
  specialOffer?: BetSpecialOffer | null
): number {
  if (isEachWay) {
    const winEstimate = roundEstimateDown((winPrice ?? 0) * winInvestment, 2)
    const placeEstimate = roundEstimateDown((placePrice ?? 0) * winInvestment, 2)
    const projectedReturn = winEstimate + placeEstimate

    return projectedReturn
  }

  switch (bettingType) {
    case 'fixed-odds-racing': {
      let boostReward = 0
      if (specialOffer) {
        boostReward = calculateProjectedBoostReward(specialOffer, {
          win: winInvestment,
          place: placeInvestment,
        })
      }

      const winEstimate = roundEstimateDown(new Currency(winInvestment).multiply(winPrice).value, 2)

      const placeEstimate = roundEstimateDown(
        new Currency(placeInvestment).multiply(placePrice).value,
        2
      )
      const projectedReturn = winEstimate + placeEstimate + boostReward - (bonusBetValue ?? 0)

      return roundEstimateDown(projectedReturn, 2)
    }

    case 'fixed-odds-sports': {
      const winEstimate = roundEstimateDown(winInvestment * (winPrice ?? 0), 2)
      const projectedReturn = winEstimate - (bonusBetValue ?? 0)

      return roundEstimateDown(projectedReturn, 2)
    }

    default: {
      return 0
    }
  }
}

function calculateEstReturnSingleItemReceipt(betsSlipItem: BetSlipItem) {
  const { receipt } = betsSlipItem

  if (!receipt || !('projectedWinPayout' in receipt) || !('projectedPlacePayout' in receipt)) {
    return 0
  }

  return new Currency(receipt.projectedWinPayout || 0).add(receipt.projectedPlacePayout || 0).value
}
