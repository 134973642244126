import { declareResourceType, IKeyMap } from 'rwwa-data-access'
import * as apiService from '@classic/Foundation/Services/ApiService'

interface FavouriteNumberPool {
  carryover: string
  dividendPayable: string
  isNextAvailable: boolean
  isPreviousAvailable: boolean
  jackpotAmount: string
  legs: Leg[]
  numbersRequired: string
  poolDate: string
  poolResultDisplayDate: string
  poolResultDisplayDateTitle: string
  poolTotal: string
  winningEntries: number
}

interface Leg {
  race: string
  isLive: boolean
  isWinner: boolean
  isSub: boolean
}

export const FavouriteNumberPoolResults = declareResourceType<
  FavouriteNumberPool,
  FavouriteNumberPool
>({
  fetchSingle,
  typeKey: 'poolResults',
  maxToKeep: 10,
  softTimeToLive: 120000,
})

async function fetchSingle(key: string): Promise<IKeyMap<IKeyMap<FavouriteNumberPool>>> {
  let action = ''
  let body = {}
  const splitKey = key.split('|')
  if (splitKey.length == 2) {
    action = splitKey[0] == 'prev' ? 'prev-pool' : 'next-pool'
    body = splitKey[1]
  } else {
    action = 'current-pool'
  }

  const data = await apiService.post<FavouriteNumberPool>({
    url: `/api/favourite-numbers/${action}`,
    body: body,
  })

  return { poolResults: { [key]: data } }
}
