import styled from '@emotion/styled'
import { colors } from '@mobi/component-library/Theme/Common'

export const AppHeaderButtonStyled = styled.button({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  minWidth: '3rem',
  width: 'clamp(3rem, 100%, 4rem)',
  textDecoration: 'none',
  userSelect: 'none',
  cursor: 'pointer',
  background: 0,
  border: 0,
  padding: 0,
  touchAction: 'manipulation',
  color: colors.white,
})
