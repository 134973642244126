import React from 'react'
import { injectable, inject } from 'inversify'
import type { IErrorHandler } from '@classic/Betting-v2/Utils/IErrorHandler'
import type { IEntitlementService } from '@classic/Betting-v2/Services/IEntitlementService'
import type { ILoginHelper } from '@classic/AppUtils/Framework/Utils/ILoginHelper'
import type { IAppWindow } from '@classic/AppUtils/Framework/WindowManagement/IAppWindow'
import { OverlayClose, OverlayOpen } from '@core/Components/Overlay'
import { GiddyUpSlice } from '@core/Areas/Racing/Components/RacingFormSliceJS/GiddyUp'

@injectable()
export class FormGiddyUpRaceInformation {
  private entitlementService: IEntitlementService
  private loginHelper: ILoginHelper
  private errorHandler: IErrorHandler
  private appWindow: IAppWindow

  constructor(
    @inject('IEntitlementService') entitlementService: IEntitlementService,
    @inject('ILoginHelper') logInHelper: ILoginHelper,
    @inject('IErrorHandler') errorHandler: IErrorHandler,
    @inject('IAppWindow') appWindow: IAppWindow
  ) {
    this.entitlementService = entitlementService
    this.loginHelper = logInHelper
    this.errorHandler = errorHandler
    this.appWindow = appWindow
  }

  private displayGiddyUpForm(
    giddyUpRaceKey: string,
    giddyUpFixtureKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStarterFunc: (starterNumber: number) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRaceDetailFunc: () => any,
    selectAcceptorFunc: (acceptorNumber: number, isFixed: boolean) => void,
    clearSelectionFunc: () => void,
    selectQuaddieFunc: (raceNumbers: number[], acceptorNumbers: number[][]) => void
  ): void {
    const raceInfo = {
      raceKey: giddyUpRaceKey,
      fixtureKey: giddyUpFixtureKey,
      getAcceptor: (starterKey: string) => {
        let result: Record<string, unknown> = {}

        // starterKey e.g. '562439-1'
        const [, starterNumber] = starterKey.split('-')
        // TD: Note: Assumes currently viewed race.  When multiple races will need to
        // refactor mobi to collect race-ids and/or starter ids, OR initiate giddyup js earlier
        // and have it collect the data for races/meetings.
        if (!starterNumber) return defaultResult

        const starter = getStarterFunc(Number(starterNumber))
        if (!starter) return defaultResult

        const fixedOddsInfo = starter.fixedOddsFinisherInfo
          ? starter.fixedOddsFinisherInfo
          : starter.fixedOddsStarterInfo
        const silkImage = starter.getSilkImage(this.appWindow)

        result = {
          starterKey,
          name: starter.name(),
          isScratched: starter.isScratched(),
          isEmergency: starter.isEmergency(),
          emergencyNum: starter.emergencyNum(),
          hasBetSelected: false,
          scratchType: starter.scratchType(),
          silkImage: {
            cssurl: silkImage
              ? silkImage.getCssBackgroundFor(starter.number(), starter.type())
              : '',
            url: '',
            width: silkImage ? silkImage.size.width : '',
          },
          fixedOdds: {
            win: fixedOddsInfo.displayWinDividend(),
            place: fixedOddsInfo.displayPlaceDividend(),
            isSuspended: fixedOddsInfo.isSuspended(),
            isScratched: fixedOddsInfo.isScratched(),
          },
          toteOdds: {
            win: starter.displayWinDividend(),
            place: starter.displayPlaceDividend(),
          },
        }

        if (Object.prototype.hasOwnProperty.call(starter, 'barrier')) {
          result.barrier = starter.barrier()
        }
        if (Object.prototype.hasOwnProperty.call(starter, 'boxNumber')) {
          result.boxNumber = starter.boxNumber()
        }
        if (Object.prototype.hasOwnProperty.call(starter, 'handicap')) {
          result.handicap = starter.handicap()
        }

        // Server API (RaceController.GetAcceptors) returns different results based on the race status
        // - open  --> returns RaceStarters
        // - other (jumped, resulted, abandoned, released, etc) --> returns Finishers.. which includes the 'placing' property used for determining 'hasJumped'
        result.hasJumped = Object.prototype.hasOwnProperty.call(starter, 'placing')

        return result
      },
      getRaceDetail: () => getRaceDetailFunc() || defaultRace,
      selectAcceptor: (acceptorNumber: number, isFixed: boolean) =>
        selectAcceptorFunc(acceptorNumber, isFixed),
      clearSelection: clearSelectionFunc,
      selectQuaddie: (raceNumbers: number[], acceptorNumbers: number[][]) => {
        selectQuaddieFunc(raceNumbers, acceptorNumbers)
      },
    }
    OverlayOpen(() => <GiddyUpSlice raceInfo={raceInfo} />)
  }

  public openGiddyUp(
    raceKey: string,
    fixtureKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStarterFunc: (starterNumber: number) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRaceDetailFunc: () => any,
    selectAcceptorFunc: (acceptorNumber: number, isFixed: boolean) => void,
    clearSelectionFunc: () => void,
    selectQuaddieFunc: (raceNumbers: number[], acceptorNumbers: number[][]) => void
  ) {
    const promise = this.entitlementService.isEntitled('giddyUp')
    promise
      .then(result => {
        if (result != undefined) {
          if (result.success && result.data.isGiddyUpEntitled) {
            this.displayGiddyUpForm(
              raceKey,
              fixtureKey,
              getStarterFunc,
              getRaceDetailFunc,
              selectAcceptorFunc,
              clearSelectionFunc,
              selectQuaddieFunc
            )
          } else if (result.errorCode === 403) {
            this.loginHelper.presentLogIn()
          } else {
            this.errorHandler.showWarningMessage('', result.data.reasonText)
          }
        }
      })
      .catch(() => {
        const message =
          'If you require assistance please contact our Customer Service Centre on 1300 36 36 88'
        this.errorHandler.showErrorMessage('Service is currently unavailable', message)
      })
  }

  public close() {
    OverlayClose()
  }
}

// Local Helpers

const defaultRace = {
  raceCourse: 'Missing',
  raceNumber: 1,
  distance: '0',
  trackCondition: '',
  weatherClass: '',
  raceStatus: '',
  raceTimeLocal: 0,
}

const defaultResult = {
  name: 'Missing information',
  number: 1,
  isEmergency: false,
  isScratched: false,
  hasBetSelected: false,
  scratchType: '',
  silkImage: { cssurl: '', url: '' },
  fixedOdds: { win: 0.0, isSuspended: false },
  toteOdds: { win: 0.0 },
}
