import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import type { State } from 'rwwa-data-access'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { saveContactDetails, ContactDetails } from '../../Data/Account/contactDetails'
import { Address, BetAccountHolder } from '@mobi/api-types'
import { serializeContactDetails, buildAddress } from './data-transforms'
import { ReinitialiseReverificationDetails } from '../Reverification/driver'

export interface ContactDetailsFields {
  betAccountHolderNumber: number | null
  fullName: string
  preferredName: string
  dateOfBirth: string
  email: string
  phoneMobile: string
  phoneHome: string
  phoneWork: string
  addressResidentialMoniker: string
  addressResidential: Address | null
  addressPostal: Address | null
  addressPostalMoniker: string
  addressPostalSame: boolean
  driversLicenceNumber: string
  driversLicenceState: string
  driversLicenceCardNumber: string
  passportNumber: string
  passportCountry: string
  medicareBlueYellowExpiryDate: Date | null
  medicareGreenExpiry: string
  medicareCardColour: string
  medicareMiddleNameOnCard: string
  medicareNumber: string
  medicareReference: string
  agreeToVerifyAdditionalDetails?: boolean
  nameDateOfBirthChanged: boolean
  nameDateOfBirthChangedInitial: boolean
  isInternationalCustomer: boolean
}

export interface ValidationErrors {
  email: string | boolean
  phoneMobile: string | boolean
  phoneHome: string | boolean
  phoneWork: string | boolean
  addressResidentialMoniker: string | boolean
  addressPostalMoniker: string | boolean
  driversLicenceNumber: string | boolean
  driversLicenceState: string | boolean
  driversLicenceCardNumber: string | boolean
  passportNumber: string | boolean
  passportCountry: string | boolean
  medicareBlueYellowExpiryDate: string | boolean
  medicareGreenExpiry: string | boolean
  medicareCardColour: string | boolean
  medicareNumber: string | boolean
  medicareReference: string | boolean
}

export interface ValidationErrorsRecord
  extends TypedRecord<ValidationErrorsRecord>,
    ValidationErrors {}

export type ContactDetailsState = {
  updateSuccess: boolean
  loading: boolean
  updateFailed: boolean
  isReverificationSuccess: boolean | null
  isAdditionalDetailsChanged: boolean
  verifyResidentialAddress: boolean
  verifyPostalAddress: boolean
  snapshotAfterUpdate?: ContactDetailsState
  validationErrors: ValidationErrorsRecord
} & ContactDetailsFields

export const defaultValidationErrors: ValidationErrors = {
  email: false,
  phoneMobile: false,
  phoneHome: false,
  phoneWork: false,
  addressResidentialMoniker: false,
  addressPostalMoniker: false,
  driversLicenceNumber: false,
  driversLicenceState: false,
  driversLicenceCardNumber: false,
  passportNumber: false,
  passportCountry: false,
  medicareBlueYellowExpiryDate: false,
  medicareGreenExpiry: false,
  medicareCardColour: false,
  medicareNumber: false,
  medicareReference: false,
}

const ValidationErrorsFactory = makeTypedFactory<ValidationErrors, ValidationErrorsRecord>(
  defaultValidationErrors
)

export const defaultContactDetailsState: ContactDetailsState = {
  betAccountHolderNumber: null,
  updateSuccess: false,
  loading: false,
  fullName: '',
  preferredName: '',
  dateOfBirth: '',
  email: '',
  phoneMobile: '',
  phoneHome: '',
  phoneWork: '',
  addressResidential: null,
  addressResidentialMoniker: '',
  verifyResidentialAddress: false,
  addressPostal: null,
  addressPostalMoniker: '',
  verifyPostalAddress: false,
  addressPostalSame: false,
  driversLicenceNumber: '',
  driversLicenceState: '',
  driversLicenceCardNumber: '',
  passportNumber: '',
  passportCountry: '',
  medicareBlueYellowExpiryDate: null,
  medicareGreenExpiry: '',
  medicareCardColour: '',
  medicareMiddleNameOnCard: '',
  medicareNumber: '',
  medicareReference: '',
  isReverificationSuccess: null,
  updateFailed: false,
  isAdditionalDetailsChanged: false,
  agreeToVerifyAdditionalDetails: true,
  isInternationalCustomer: false,
  snapshotAfterUpdate: undefined,
  validationErrors: ValidationErrorsFactory(),
  nameDateOfBirthChanged: false,
  nameDateOfBirthChangedInitial: false,
}

export enum ValidationFields {
  email = 'email',
  phoneMobile = 'phoneMobile',
  phoneHome = 'phoneHome',
  phoneWork = 'phoneWork',
  addressResidentialMoniker = 'addressResidentialMoniker',
  addressPostalMoniker = 'addressPostalMoniker',
  driversLicenceNumber = 'driversLicenceNumber',
  driversLicenceState = 'driversLicenceState',
  driversLicenceCardNumber = 'driversLicenceCardNumber',
  passportNumber = 'passportNumber',
  passportCountry = 'passportCountry',
  medicareBlueYellowExpiryDate = 'medicareBlueYellowExpiryDate',
  medicareGreenExpiry = 'medicareGreenExpiry',
  medicareCardColour = 'medicareCardColour',
  medicareNumber = 'medicareNumber',
  medicareReference = 'medicareReference',
}

export const UpdateFields = createSignal<ContactDetailsFields>('UpdateFields')
export const SaveButtonClicked = createSignal('SaveButtonClicked')
export const Reset = createSignal('Reset')
export const UpdateSuccess = createSignal('UpdateSuccess')
export const UpdateFailed = createSignal('UpdateFailed')
export const ReverificationSucceeded = createSignal('ReverificationSucceeded')
export const ReverificationFailed = createSignal('ReverificationFailed')
export const ChangeValue = createSignal<{
  field: keyof ContactDetailsFields
  value: string | boolean | Date
}>('ChangeValue')
export const ValidateField = createSignal<{ field: keyof ContactDetailsFields }>('ValidateField')
export const ChangeResidentialAddress = createSignal<{
  addressResidentialMoniker: string
  addressResidential?: Address
}>('ChangeResidentialAddress')
export const ChangePostalAddress = createSignal<{
  addressPostalMoniker: string
  addressPostal?: Address
}>('ChangePostalAddress')

export interface ContactDetailsStateRecord
  extends TypedRecord<ContactDetailsStateRecord>,
    ContactDetailsState {}
export const ContactDetailsStateFactory = makeTypedFactory<
  ContactDetailsState,
  ContactDetailsStateRecord
>(defaultContactDetailsState)

export function contactDetailsDriver(
  state = ContactDetailsStateFactory(),
  signal: Signal
): ContactDetailsStateRecord {
  switch (signal.tag) {
    case UpdateFields: {
      const contactDetails: ContactDetailsFields = signal.data
      const addressResidential = contactDetails.addressResidential && {
        ...contactDetails.addressResidential,
      }
      const addressPostal = contactDetails.addressPostal && { ...contactDetails.addressPostal }
      const newState = state.merge({
        ...contactDetails,
        addressResidential,
        addressResidentialMoniker: buildAddress(addressResidential as Address),
        addressPostal,
        addressPostalMoniker: buildAddress(addressPostal as Address),
      })
      return newState.merge({
        snapshotAfterUpdate: newState,
      })
    }

    case Reset: {
      return state.merge({
        ...defaultContactDetailsState,
      })
    }

    case SaveButtonClicked: {
      // don't try and save again if it's saving now
      if (state.get('loading')) {
        return state
      }

      const validationErrors = validateAllFields(state)
      const isValid = isFieldValidationSuccess(
        state.addressPostalSame,
        state.isAdditionalDetailsChanged,
        !!state.agreeToVerifyAdditionalDetails,
        validationErrors
      )
      if (!isValid) {
        return state.merge({
          validationErrors,
        })
      }

      save(state)

      return state.merge({
        loading: true,
      })
    }

    case ChangeResidentialAddress: {
      const { addressResidentialMoniker, addressResidential } = signal.data
      let newState = state.set('addressResidentialMoniker', addressResidentialMoniker)
      const verifyResidentialAddress =
        haveResidentailAddressChanged(newState) && !addressResidential

      const fieldError = validateAField(ValidationFields.addressResidentialMoniker, newState)

      if (newState.hasIn(['validationErrors', 'addressResidentialMoniker'])) {
        newState = newState.setIn(['validationErrors', 'addressResidentialMoniker'], fieldError)
      }

      return newState.merge({
        addressResidentialMoniker,
        addressResidential: addressResidential || state.get('addressResidential'),
        verifyResidentialAddress,
      })
    }

    case ChangePostalAddress: {
      const { addressPostalMoniker, addressPostal } = signal.data
      let newState = state.set('addressPostalMoniker', addressPostalMoniker)
      const verifyPostalAddress = havePostalAddressChanged(newState) && !addressPostal

      const fieldError = validateAField(ValidationFields.addressPostalMoniker, newState)

      if (newState.hasIn(['validationErrors', 'addressPostalMoniker'])) {
        newState = newState.setIn(['validationErrors', 'addressPostalMoniker'], fieldError)
      }
      return newState.merge({
        addressPostalMoniker,
        addressPostal: addressPostal || state.get('addressPostal'),
        verifyPostalAddress,
      })
    }

    case ChangeValue: {
      const { field, value } = signal.data
      let newState = state.set(field, value)
      const fieldError = validateAField(field, newState)
      const isAdditionalDetailsChanged = haveAdditionalDetailsChanged(newState)

      if (newState.hasIn(['validationErrors', field])) {
        newState = newState.setIn(['validationErrors', field], fieldError)
      }

      return newState.merge({
        isAdditionalDetailsChanged,
      })
    }

    case ValidateField: {
      const { field } = signal.data
      const fieldError = validateAField(field, state)

      return state.setIn(['validationErrors', field], fieldError)
    }

    case UpdateSuccess: {
      return state.merge({
        loading: false,
        updateSuccess: true,
        isReverificationSuccess: false,
        updateFailed: false,
      })
    }

    case ReverificationSucceeded: {
      return state.merge({
        loading: false,
        isReverificationSuccess: true,
      })
    }

    case ReverificationFailed: {
      return state.merge({
        loading: false,
        isReverificationSuccess: false,
        updateFailed: false, // for reverification to have failed, the save must have succeeded
      })
    }

    case UpdateFailed: {
      return state.merge({
        loading: false,
        updateSuccess: false,
        updateFailed: true,
      })
    }

    default:
      return state
  }
}

function validateAllFields(state: ContactDetailsStateRecord): ValidationErrors {
  return {
    email: validateAField(ValidationFields.email, state),
    phoneMobile: validateAField(ValidationFields.phoneMobile, state),
    phoneHome: validateAField(ValidationFields.phoneHome, state),
    phoneWork: validateAField(ValidationFields.phoneWork, state),
    addressResidentialMoniker: validateAField(ValidationFields.addressResidentialMoniker, state),
    addressPostalMoniker: validateAField(ValidationFields.addressPostalMoniker, state),
    driversLicenceNumber: validateAField(ValidationFields.driversLicenceNumber, state),
    driversLicenceState: validateAField(ValidationFields.driversLicenceState, state),
    driversLicenceCardNumber: validateAField(ValidationFields.driversLicenceCardNumber, state),
    passportNumber: validateAField(ValidationFields.passportNumber, state),
    passportCountry: validateAField(ValidationFields.passportCountry, state),
    medicareBlueYellowExpiryDate: validateAField(
      ValidationFields.medicareBlueYellowExpiryDate,
      state
    ),
    medicareGreenExpiry: validateAField(ValidationFields.medicareGreenExpiry, state),
    medicareCardColour: validateAField(ValidationFields.medicareCardColour, state),
    medicareNumber: validateAField(ValidationFields.medicareNumber, state),
    medicareReference: validateAField(ValidationFields.medicareReference, state),
  }
}

export function validateAField(
  field: ValidationFields,
  state: ContactDetailsStateRecord
): string | false {
  const value = state.get(field)
  const medicareNumber = state.get('medicareNumber')
  const medicareColor = state.get('medicareCardColour').trim().toLowerCase()

  switch (field) {
    case ValidationFields.email:
      return !isEmailValid(value) ? 'Please enter a valid email address' : false
    case ValidationFields.phoneMobile:
      return !isPhoneNumberValid(value) ? 'Please enter a valid Australian mobile number' : false
    case ValidationFields.phoneHome:
    case ValidationFields.phoneWork:
      return value && !isPhoneNumberValid(value)
        ? 'Please enter a valid Australian phone number'
        : false
    case ValidationFields.addressPostalMoniker:
    case ValidationFields.addressResidentialMoniker:
      return !value ? 'Please enter a valid address' : false
    case ValidationFields.driversLicenceState:
      return !!state.get('driversLicenceNumber') && !value ? 'Please select a state' : false
    case ValidationFields.driversLicenceNumber:
      return (!value && !!state.get('driversLicenceState')) ||
        (value && !isLicenceNumberValid(value))
        ? 'Please enter a valid licence number'
        : false
    case ValidationFields.driversLicenceCardNumber: {
      if (state.get('driversLicenceState') && value && !isLicenceCardNumberValid(value)) {
        return 'Please enter a valid licence card number'
      }

      return false
    }
    case ValidationFields.passportCountry:
      return !isPassportCountryValid({
        passportNumber: state.get('passportNumber'),
        passportCountry: value,
      })
        ? 'Please select a country'
        : false
    case ValidationFields.passportNumber:
      return !isPassportNumberValid({
        passportNumber: value,
        passportCountry: state.get('passportCountry'),
      })
        ? 'Please enter a valid passport number'
        : false
    case ValidationFields.medicareNumber: {
      const haveReferenceOrColor = !!state.get('medicareReference') || !!medicareColor
      return (haveReferenceOrColor && !value) || (!!value && !/^\d{10}$/.test(value))
        ? 'Please enter a valid medicare number'
        : false
    }
    case ValidationFields.medicareReference:
      return !!medicareNumber && !value ? '1 - 9' : false
    case ValidationFields.medicareCardColour:
      return !!medicareNumber && !value ? 'Please select a card color' : false
    case ValidationFields.medicareGreenExpiry: {
      if (medicareColor === 'green') {
        return !!medicareNumber && !value ? 'Please select a valid expiry' : false
      }
      return false
    }
    case ValidationFields.medicareBlueYellowExpiryDate: {
      if (medicareColor === 'yellow' || medicareColor === 'blue') {
        return !!medicareNumber && !value ? 'Please select a valid expiry' : false
      }
      return false
    }
    default:
      return false
  }
}

export function isEmailValid(email: string) {
  const emailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailValidation.test(email)
}

export function isPhoneNumberValid(phone: string) {
  const phoneValidation = /^0[0-8]\d{8}$/
  return phoneValidation.test(phone)
}

export function isPassportNumberValid({
  passportNumber,
  passportCountry,
}: Pick<ContactDetailsFields, 'passportNumber' | 'passportCountry'>) {
  return !!passportNumber || !passportCountry
}

export function isPassportCountryValid({
  passportNumber,
  passportCountry,
}: Pick<ContactDetailsFields, 'passportNumber' | 'passportCountry'>) {
  return !!passportCountry || !passportNumber
}

export function isLicenceNumberValid(licenceNumber: string) {
  // eslint-disable-next-line no-useless-escape
  return /^[a-zA-Z0-9 \-]+$/.test(licenceNumber)
}

export const isLicenceCardNumberValid = (licenceCardNumber: string) =>
  /^[a-z0-9]+$/i.test(licenceCardNumber)

export function isFieldValidationSuccess(
  addressPostalSame: boolean,
  isAdditionalDetailsChanged: boolean,
  agreeToVerifyAdditionalDetails: boolean,
  validationErrors: ValidationErrors
): boolean {
  return (
    !validationErrors.email &&
    !validationErrors.phoneMobile &&
    !validationErrors.addressResidentialMoniker &&
    (addressPostalSame || !validationErrors.addressPostalMoniker) &&
    !validationErrors.driversLicenceNumber &&
    !validationErrors.driversLicenceState &&
    !validationErrors.passportCountry &&
    !validationErrors.passportNumber &&
    !validationErrors.medicareNumber &&
    !validationErrors.medicareReference &&
    !validationErrors.medicareCardColour &&
    !validationErrors.medicareBlueYellowExpiryDate &&
    !validationErrors.medicareGreenExpiry &&
    (!isAdditionalDetailsChanged || agreeToVerifyAdditionalDetails)
  )
}

export function save(state: ContactDetailsStateRecord) {
  saveContactDetails(serializeContactDetails(state.toJS()))
    .then(resp => {
      if (resp.isReverificationSuccess) {
        ReverificationSucceeded()
      } else if (resp.isReverificationSuccess === false) {
        ReverificationFailed()
      } else if (resp.isContactDetailsUpdateSuccess) {
        UpdateSuccess()
      } else {
        UpdateFailed()
      }
    })
    .catch(() => {
      UpdateFailed()
    })
    .finally(() => {
      // always refresh contact details and reverification details
      ContactDetails.hardInvalidate(
        null as unknown as State<BetAccountHolder>,
        `${state.betAccountHolderNumber}`
      )
      ReinitialiseReverificationDetails()

      queryClient
        .invalidateQueries(
          {
            queryKey: [queryKeys.userContactDetails],
          },
          {
            cancelRefetch: true,
          }
        )
        .then(() => {
          //
        })
    })
}

export function haveAdditionalDetailsChanged(currentState: ContactDetailsStateRecord): boolean {
  const snapshotAfterUpdate = currentState.get('snapshotAfterUpdate') as ContactDetailsState

  if (!snapshotAfterUpdate) {
    return false
  }

  return (
    currentState.driversLicenceNumber !== snapshotAfterUpdate.driversLicenceNumber ||
    currentState.driversLicenceState !== snapshotAfterUpdate.driversLicenceState ||
    currentState.medicareBlueYellowExpiryDate !==
      snapshotAfterUpdate.medicareBlueYellowExpiryDate ||
    currentState.medicareCardColour !== snapshotAfterUpdate.medicareCardColour ||
    currentState.medicareGreenExpiry !== snapshotAfterUpdate.medicareGreenExpiry ||
    currentState.medicareMiddleNameOnCard !== snapshotAfterUpdate.medicareMiddleNameOnCard ||
    currentState.medicareNumber !== snapshotAfterUpdate.medicareNumber ||
    currentState.medicareReference !== snapshotAfterUpdate.medicareReference ||
    currentState.passportCountry !== snapshotAfterUpdate.passportCountry ||
    currentState.passportNumber !== snapshotAfterUpdate.passportNumber
  )
}

export function haveResidentailAddressChanged(currentState: ContactDetailsStateRecord): boolean {
  const snapshotAfterUpdate = currentState.get('snapshotAfterUpdate') as ContactDetailsState

  if (!snapshotAfterUpdate) {
    return false
  }

  return currentState.addressResidentialMoniker !== snapshotAfterUpdate.addressResidentialMoniker
}

export function havePostalAddressChanged(currentState: ContactDetailsStateRecord): boolean {
  const snapshotAfterUpdate = currentState.get('snapshotAfterUpdate') as ContactDetailsState

  if (!snapshotAfterUpdate) {
    return false
  }

  return currentState.addressPostalMoniker !== snapshotAfterUpdate.addressPostalMoniker
}

export const state$ = attachDriver<ContactDetailsStateRecord>({
  path: 'contactDetails',
  driver: contactDetailsDriver,
})
