import React from 'react'
import { isToteRacePushEvent, ToteRaceEventPushEvent } from 'tabtouch-push-contract'

import {
  HomeScrollableContainerStyled as ScrollableContainer,
  HomeScrollHeaderLinkStyled as Link,
  HomeScrollHeaderStyled as Header,
  HomeScrollListItemsStyled,
} from '@core/Areas/Home/Home.styles'
import {
  isToteRaceChangePushEvent,
  MaxNumberOfRacesForHome,
  useNextRaces,
} from '@core/Areas/NextEvents/utils'
import { ErrorMessage, Message } from '@core/Components/Messages'
import { NextRace } from '@core/Data/Racing/nextRaces'
import { event$ } from '@mobi/utils/awsiot'

import { HomeNextRacesLoading } from './HomeNextRacesLoading'
import { HomeNextRacesTile } from './HomeNextRacesTile'

export const HomeNextRaces: React.FC = () => {
  const { isLoading, error, data, refetch } = useNextRaces()

  React.useEffect(() => {
    const iotSubscription = event$
      .filter(x => isToteRacePushEvent(x.payload))
      .filter(x => isToteRaceChangePushEvent((x.payload as ToteRaceEventPushEvent).status))
      .subscribe(() => {
        refetch()
      })

    return () => iotSubscription.dispose()
  }, [refetch])

  if (!data || isLoading) {
    return <HomeNextRacesLoading />
  }

  if (error) {
    return <ErrorMessage>Error loading next races.</ErrorMessage>
  }

  const nextRaces = filterNextRacesForHome(data)
  if (!nextRaces || !nextRaces.length) {
    return <Message>No next races.</Message>
  }

  return (
    <>
      <Header>
        <div>Next Races</div>
        <Link data-tid-home-next-events='home-next-events-see-all' to='#next-events'>
          See All
        </Link>
      </Header>

      <ScrollableContainer>
        <HomeScrollListItemsStyled>
          {nextRaces.map((race, index) => {
            const key = `${race.MeetingDate.substring(0, 10)}_${race.MeetingID}_${
              race.RaceNumber
            }_${index}`
            return <HomeNextRacesTile key={key} race={race} />
          })}
        </HomeScrollListItemsStyled>
      </ScrollableContainer>
    </>
  )
}

function filterNextRacesForHome(races: NextRace[]): NextRace[] | null {
  return races instanceof Array ? races.slice(0, MaxNumberOfRacesForHome) : null
}
