import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalItem } from './types'

// =============
// Async actions
// =============

// Used to set exit animation before removal
/** @deprecated - Use Overlays/Modal/Modal.tsx instead */
export const closeModal = createAsyncThunk(
  'modal/closeModal',
  ({ id, exitAnimation }: CloseModalArgs, thunkAPI) => {
    if (exitAnimation) thunkAPI.dispatch(modalSlice.actions.setExitAnimation({ id, exitAnimation }))
    return { id }
  }
)

// =====
// Store
// =====

const modalInitialState: ModalStore = {
  items: [],
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: modalInitialState,
  reducers: {
    openModal({ items }, { payload }: PayloadAction<ModalItem>) {
      const index = items.findIndex(item => item.id === payload.id)
      if (index === -1) {
        items.push(payload)
      } else {
        items[index] = payload
      }
    },
    preventDismissal({ items }, { payload }: PayloadAction<string>) {
      items.filter(item => item.id === payload).map(item => (item.isDismissable = false))
    },
    allowDismissal({ items }, { payload }: PayloadAction<string>) {
      items.filter(item => item.id === payload).map(item => (item.isDismissable = true))
    },
    setExitAnimation(state, { payload: { id, exitAnimation } }: SetExitAnimationArgs) {
      const item = state.items.find(item => item.id === id)
      if (item) item.exitAnimation = exitAnimation
    },
    closeAllModals(state) {
      state.items = []
    },
  },
  extraReducers: builder => {
    builder.addCase(closeModal.fulfilled, (state, action) => {
      state.items = state.items.filter(item => item.id !== action.payload.id)
    })
  },
})

/** @deprecated - Use Overlays/Modal/Modal.tsx instead */
export const { openModal, closeAllModals, preventDismissal, allowDismissal } = modalSlice.actions
export default modalSlice.reducer

// =====
// Types
// =====

export interface ModalStore {
  items: ModalItem[]
}

type CloseModalArgs = Pick<ModalItem, 'id' | 'exitAnimation'>
type SetExitAnimationArgs = PayloadAction<Pick<ModalItem, 'id' | 'exitAnimation'>>
