import { BetEnquiryResponseDataTransferObject } from '../DataTransferObjects/DataTransferObjects'
import { IBetEnquiryService } from './IBetEnquiryService'
import { ILegacyBetModel } from '../Model/ILegacyBetModel'
import { injectable } from 'inversify'

@injectable()
export class BetEnquiryService implements IBetEnquiryService {
  private fromJson(response: Response): Promise<BetEnquiryResponseDataTransferObject> {
    return response.json()
  }

  private request(url: string, betViewModel: ILegacyBetModel): Promise<Response> {
    const requestDataJson = JSON.stringify(betViewModel)
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    const requestInit = {
      method: 'post',
      headers: headers,
      body: requestDataJson,
      credentials: 'same-origin' as RequestCredentials,
    }
    const request = new Request(url, requestInit)

    return window.fetch(request)
  }

  public enquireBet(betViewModel: ILegacyBetModel): Promise<BetEnquiryResponseDataTransferObject> {
    const promise = this.request(`/api/totebet/betcost`, betViewModel)
    return promise.then(response => this.fromJson(response)).then()
  }

  public enquireAllUpBet(
    betViewModelDto: ILegacyBetModel
  ): Promise<BetEnquiryResponseDataTransferObject> {
    const promise = this.request(`/api/totebet/betcostallup`, betViewModelDto)
    return promise.then(response => this.fromJson(response)).then()
  }
}
