import React from 'react'
import { state$ as structuredSearchState$ } from '../Driver/StructuredSearchDriver'
import { useObservableImmutable } from '@core/Utils/hooks'
import * as DateTimeProvider from '@classic/Foundation/DateTimeProvider'
import { StructuredSearchResult } from '@core/Data/StructuredSearch/StructuredSearchResult'
import {
  StructuredSearchResultStyled,
  NoResultsStyled,
  StructuredSearchFixedOddsLabelStyled,
  StructuredSearchResultInfoHeaderStyled,
} from './StructuredSearchResults.styles'
import { getRedirectUrl } from './utils'
import { StructuredSearchResultComponent } from './StructuredSearchResult'
import { Icon } from '@mobi/component-library/Common/Icon'
import { colors } from '@core/Settings'
import { StructuredSearchTextLabel } from './StructuredSearchTextLabel'
import { StructuredSearchTextLabelFontSize } from './StructuredSearchTextLabelStyled.styles'

function handleClick(searchResult: StructuredSearchResult) {
  const url = getRedirectUrl(searchResult)
  window.location.href = url
}

export function StructuredSearchResults(): JSX.Element | null {
  const { searchResults } = useObservableImmutable(structuredSearchState$, ['searchResults'])
  const results = searchResults?.get('results')
  const currentDateMilli = DateTimeProvider.now()

  if (!results) {
    return null
  }

  if (results.count() === 0) {
    return <NoResults />
  }

  const mappedResults = results.toJS().map(result => (
    <StructuredSearchResultStyled
      key={result.id}
      onClick={() => handleClick(result)}
      data-tid-structured-search-result={result.id}
    >
      <StructuredSearchResultComponent result={result} currentDateMilli={currentDateMilli} />
    </StructuredSearchResultStyled>
  ))

  return (
    <div data-tid-structured-search-results=''>
      <StructuredSearchResultInfoHeaderStyled>
        <StructuredSearchFixedOddsLabelStyled>Fixed</StructuredSearchFixedOddsLabelStyled>
      </StructuredSearchResultInfoHeaderStyled>
      {mappedResults}
    </div>
  )
}

export function NoResults(): JSX.Element {
  return (
    <NoResultsStyled>
      <Icon type='warningCircled' size='6rem' color={colors.structuredSearch.initialText} />
      <StructuredSearchTextLabel size={StructuredSearchTextLabelFontSize.Large}>
        No Results Found
      </StructuredSearchTextLabel>
      <StructuredSearchTextLabel size={StructuredSearchTextLabelFontSize.Small}>
        Try making your criteria less specific
      </StructuredSearchTextLabel>
    </NoResultsStyled>
  )
}
