import * as ko from 'knockout'
import { IObservableFinisher } from './IObservableFinisher'

export default class ObservableRaceFinishers {
  constructor() {
    this.finishers = ko.observableArray<IObservableFinisher>([])
  }

  public addFinishersForRace(finisher: IObservableFinisher) {
    this.finishers.push(finisher)
  }

  public merge(other: ObservableRaceFinishers, forceClear?: boolean) {
    if (forceClear || this.finishers().length != other.finishers().length) {
      this.finishers(other.finishers())
      return
    }

    const otherFinishers = new Map<number, IObservableFinisher>()
    other.finishers().forEach(f => otherFinishers.set(f.number(), f))
    for (const finisher of this.finishers()) {
      finisher.untypedMerge(otherFinishers.get(finisher.number()) as IObservableFinisher)
    }
  }

  public getFinisher(finisherNumber: number) {
    const justWithFinisher = ko.utils.arrayFilter(
      this.finishers(),
      finisher => finisher.number() === finisherNumber
    )

    return justWithFinisher.length === 1 ? justWithFinisher[0] : null
  }

  public removeAll() {
    this.finishers([])
  }

  public finishers: ko.ObservableArray<IObservableFinisher>
}
