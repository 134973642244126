import * as ko from 'knockout'
import type { ISelectionResultProcessor } from '@classic/Betting-v2/Components/Core/SelectionResults/ISelectionResultProcessor'
import type { ISelectionProcessor } from '@classic/Betting-v2/Components/Core/Processors/ISelectionProcessor'
import type { IBetTypeValidator } from '@classic/Betting-v2/Validation/IBetTypeValidator'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import Guard from '@classic/AppUtils/Framework/Guard'
import { BetType } from './BetType'

interface IBetTypeInformationParams {
  betType: BetType
  name: string
  supportsFlexi: boolean
  sortOptions: SortOption['property'][]
  template: string
  betSelectionTemplate: string
  multiBet: boolean
  multiSelections: boolean
  checkBoxCount: number
  fieldCheckBoxCount: number
  sameAsCheckBoxCount: number
  processor: ISelectionProcessor
  legs: number
  selectionResultProcessor: ISelectionResultProcessor
  validator: IBetTypeValidator
}

export default class BetTypeInformation {
  constructor(params: IBetTypeInformationParams) {
    Guard.stringNotNullOrEmpty(params.name)
    Guard.arrayNotNullOrEmpty(params.sortOptions)
    Guard.stringNotNullOrEmpty(params.template)
    Guard.stringNotNullOrEmpty(params.betSelectionTemplate)
    Guard.greaterThanOrEqualZero(params.checkBoxCount)
    Guard.greaterThanOrEqualZero(params.fieldCheckBoxCount)
    Guard.greaterThanOrEqualZero(params.sameAsCheckBoxCount)
    Guard.greaterThanZero(params.legs)
    Guard.notNull(params.processor)
    Guard.notNull(params.selectionResultProcessor)

    this.betType = ko.observable(params.betType)
    this.name = ko.observable(params.name)
    this.supportsFlexi = params.supportsFlexi
    this.sortOptions = ko.observableArray(params.sortOptions)
    this.template = ko.observable(params.template)
    this.multiBet = ko.observable(params.multiBet)
    this.legs = ko.observable(params.legs)
    this.multiSelections = ko.observable(params.multiSelections)
    this.checkBoxCount = ko.observable(params.checkBoxCount)
    this.fieldCheckBoxCount = ko.observable(params.fieldCheckBoxCount)
    this.sameAsCheckBoxCount = ko.observable(params.sameAsCheckBoxCount)
    this.betSelectionTemplate = ko.observable(params.betSelectionTemplate)
    this.selectionStringProcessor = params.selectionResultProcessor
    this.processor = params.processor
    this.validator = params.validator
    this.isAbandoned = ko.observable<boolean>(false)
    this.displayText = ko.observable(params.name)
  }

  public isAllUp(): boolean {
    return this.betType() === BetType.AllUp
  }

  public isMystery(): boolean {
    return this.betType() === BetType.Mystery
  }

  public isQuinella(): boolean {
    return this.betType() === BetType.Quinella
  }

  public isWinPlace(): boolean {
    return this.betType() === BetType.WinPlace
  }

  public isSameRaceMulti(): boolean {
    return this.betType() === BetType.SameRaceMulti
  }

  public isExacta(): boolean {
    return this.betType() === BetType.Exacta
  }

  public isTrifecta(): boolean {
    return this.betType() === BetType.Trifecta
  }

  public isFirst4(): boolean {
    return this.betType() === BetType.First4
  }

  public isDouble(): boolean {
    return this.betType() === BetType.Double
  }

  public isQuaddie(): boolean {
    return this.betType() === BetType.Quaddie
  }

  /**
   * @returns true if punter can keep selections after placing the bet
   */
  public canKeepSelections(): boolean {
    return [
      BetType.First4,
      BetType.Trifecta,
      BetType.Quinella,
      BetType.Exacta,
      BetType.Quaddie,
      BetType.Double,
      BetType.AllUp,
    ].includes(this.betType())
  }

  public hasCheckboxes(): boolean {
    return (
      this.betType() === BetType.Trifecta ||
      this.betType() === BetType.First4 ||
      this.betType() === BetType.Quinella ||
      this.betType() === BetType.Exacta
    )
  }

  public isSingleLegType(): boolean {
    return (
      this.betType() === BetType.WinPlace ||
      this.betType() === BetType.Exacta ||
      this.betType() === BetType.Trifecta ||
      this.betType() === BetType.First4 ||
      this.betType() === BetType.Quinella
    )
  }

  public isMultiLegType(): boolean {
    return this.betType() === BetType.Double || this.betType() === BetType.Quaddie
  }

  public isNoveltyBet(): boolean {
    return (
      this.betType() === BetType.Exacta ||
      this.betType() === BetType.Trifecta ||
      this.betType() === BetType.First4 ||
      this.betType() === BetType.Quinella
    )
  }

  public supportsSort(sortOption: SortOption['property']): boolean {
    return this.sortOptions().includes(sortOption)
  }

  public betType: ko.Observable<BetType>
  public name: ko.Observable<string>
  public supportsFlexi: boolean
  public sortOptions: ko.ObservableArray<SortOption['property']>
  public template: ko.Observable<string>
  public multiBet: ko.Observable<boolean>
  public legs: ko.Observable<number>
  public multiSelections: ko.Observable<boolean>
  public checkBoxCount: ko.Observable<number>
  public betSelectionTemplate: ko.Observable<string>
  public fieldCheckBoxCount: ko.Observable<number>
  public sameAsCheckBoxCount: ko.Observable<number>
  public processor: ISelectionProcessor
  public selectionStringProcessor: ISelectionResultProcessor
  public validator: IBetTypeValidator
  public isAbandoned: ko.Observable<boolean>
  public displayText: ko.Observable<string>
}
