import { createIntent, attachDriver, Signal } from 'rwwa-rx-state-machine'
import * as immutable from 'immutable'
import moment from 'moment'

/** @deprecated DO NOT USE */
export const navigateHome = createIntent('NavigateHome')
/** @deprecated DO NOT USE */
export const navigateToContactDetailsEdit = createIntent('NavigateToEditContactDetails')
/** @deprecated DO NOT USE */
export const navigateToDepositLimitsView = createIntent('NavigateToDepositLimits')
/** @deprecated DO NOT USE */
export const navigateToDepositLimitsEdit = createIntent('NavigateToEditDepositLimits')
/** @deprecated DO NOT USE */
export const navigateToPendingBets = createIntent('NavigateToPendingBets')
/** @deprecated DO NOT USE */
export const navigateToNextEvents = createIntent('NavigateToNextEvents')
/** @deprecated DO NOT USE */
export const navigateBack = createIntent('Navigate Back')
/** @deprecated DO NOT USE */
export const navigateHistoryBack = createIntent('Navigate History Back')
/** @deprecated DO NOT USE */
export const pushHandlerTag = createIntent('PushHandlerTag')
/** @deprecated DO NOT USE */
export const popHandlerTag = createIntent('PopHandlerTag')
/** @deprecated DO NOT USE */
export const modalNavigateBack = createIntent('ModalNavigateBack')
/** @deprecated DO NOT USE */
export const navigateToLogout = createIntent('NavigateToLogout')

/** @deprecated DO NOT USE */
export const navigateToMeeting = createIntent('NavigateToMeeting')
/** @deprecated DO NOT USE */
export const navigateToRace = createIntent('NavigateToRace')
/** @deprecated DO NOT USE */
export const navigateToFixedMeeting = createIntent('NavigateToFixedMeeting')
/** @deprecated DO NOT USE */
export const navigateToFixedRace = createIntent('NavigateToFixedRace')
/** @deprecated DO NOT USE */
export const navigateToFixedProduct = createIntent('NavigateToFixedProduct')

/** @deprecated DO NOT USE */
export const navigationState$ = attachDriver({
  path: 'navigation',
  driver: navigationDriver,
}).distinctUntilChanged()

function navigationDriver(state: immutable.OrderedMap<string, unknown>, intent: Signal) {
  state = state || immutable.OrderedMap()

  switch (intent.tag) {
    case navigateHome: {
      window.location.hash = ''
      break
    }

    case navigateHistoryBack: {
      window.history.back()
      break
    }

    case navigateToContactDetailsEdit: {
      const { isDesktop, betAccountHolderNumber } = intent.data
      window.location.href = isDesktop
        ? `/account/mypreferences/contactdetailsedit/${betAccountHolderNumber}`
        : `#account/contactdetails/edit/${betAccountHolderNumber}`
      break
    }

    case navigateToDepositLimitsView: {
      const isDesktop = intent.data.isDesktop
      const saveSuccess = intent.data.saveSuccess || false
      const accountHolderNumber = intent.data.accountHolderNumber

      let url = isDesktop
        ? `/account/depositlimits?saveSuccess=${saveSuccess}`
        : `#account/deposit-limits?saveSuccess=${saveSuccess}`

      if (accountHolderNumber) {
        url += `&accountHolderNumber=${accountHolderNumber}`
      }
      window.location.href = url
      break
    }

    case navigateToDepositLimitsEdit: {
      const { isDesktop, accountHolderNumber } = intent.data
      const url = isDesktop
        ? `/account/depositlimits/edit/${accountHolderNumber}`
        : `#account/deposit-limits/edit/${accountHolderNumber}`
      window.location.href = url
      break
    }

    // TODO remove when Meetings page is taken off the LaunchDarkly flag for everyone
    case navigateToMeeting: {
      window.location.hash =
        '#tote/meetings/' +
        intent.data.id +
        '?date=' +
        moment(intent.data.date).format('YYYY-MM-DD')
      break
    }
    case navigateToRace: {
      window.location.hash =
        '#tote/meetings/' +
        (intent.data.id || intent.data.fixtureId) +
        '/' +
        intent.data.raceNumber +
        '?date=' +
        moment(intent.data.date || intent.data.fixtureDate).format('YYYY-MM-DD')
      break
    }
    case navigateToFixedMeeting: {
      window.location.hash =
        '#fobracing/foo/races/' +
        intent.data.id.toLowerCase() +
        '/' +
        moment(intent.data.date).format('YYYY-MM-DD') +
        '?selectionDate=' +
        moment(intent.data.date).format('YYYY-MM-DD')
      break
    }
    case navigateToFixedRace: {
      window.location.hash =
        '#fobracing/propositions/foo/races/' +
        intent.data.id.toLowerCase() +
        '?selectionDate=' +
        moment(intent.data.date).format('YYYY-MM-DD')
      break
    }
    case navigateToFixedProduct: {
      const date = moment(intent.data.date).format('YYYY-MM-DD')
      window.location.hash =
        '#fobracing/' +
        intent.data.id +
        '/' +
        intent.data.code +
        '/' +
        date +
        '?selectiondate=' +
        date
      break
    }

    case navigateToPendingBets: {
      window.location.hash = '#account/activity/pending'
      break
    }

    case navigateToNextEvents: {
      window.location.hash = '#next-events'
      break
    }

    case pushHandlerTag: {
      return state.set(intent.data, intent.data)
    }

    case popHandlerTag: {
      return state.delete(intent.data)
    }

    case modalNavigateBack: {
      if (state.size > 0) navigateBack(state.last())

      break
    }
    case navigateToLogout: {
      const { isDesktop } = intent.data
      const url = isDesktop ? '/account/logoff' : '#account/logout'
      window.location.href = url
      break
    }
  }

  return state
}
