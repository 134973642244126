import type {
  ToteSelection,
  AllUpSelection,
  BetLegType,
  BetSlipItem,
  BetSlipBetRequest,
  RequestType,
} from '@mobi/betslip/types'
import {
  isAllUpSelection,
  isFobMatchedSelection,
  isFobPropositionSelection,
  isFobSelection,
  isFobSportsSelection,
  isStartingPriceMatchedSelection,
  isStartingPriceSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import { getToteProductPlanSequence } from '@mobi/betslip/helpers/betting'
import { calculateBoosts, determineLegTypeFromInvestments } from './calculator/misc'
import { getBetsInMulti, getBetsToPlace, hasNoFatalErrors, isValidMulti } from './state'

export function buildBetSlipRequest(type: RequestType, state: BetSlipBetsState): BetSlipBetRequest {
  const fobItems: BetSlipBetRequest['fixedOddsBets'] = []
  const toteItems: BetSlipBetRequest['toteBets'] = []
  const allUpItems: BetSlipBetRequest['allUpBets'] = []

  const isRefreshing = type === 'refresh'

  const singleItems = isRefreshing
    ? state.items.filter(hasNoFatalErrors)
    : getBetsToPlace(state.items)

  singleItems.forEach((item, index) => {
    if (!isToteSelection(item.selection)) {
      const request = buildFixedOddsBet(item, index, isRefreshing)
      fobItems.push(request)
      return
    }

    if (isAllUpSelection(item.selection)) {
      allUpItems.push(buildAllUpBet(item, index))
      return
    }

    if (isToteSelection(item.selection)) {
      const request = buildToteBet(item, index, isRefreshing)
      toteItems.push(request)
      return
    }
  })

  const multiItems = !state.multiReceipt ? getBetsInMulti(state.items) : undefined

  if (
    multiItems &&
    multiItems.length > 0 &&
    isValidMulti(state.multiInvestment, state.multiBetError, multiItems)
  ) {
    const request = buildMultiFixedOddsBet(state.multiInvestment, multiItems, singleItems.length)
    fobItems.push(request)
  }

  return {
    fixedOddsBets: fobItems,
    toteBets: toteItems,
    allUpBets: allUpItems,
    favouriteNumbersBets: [],
    toteSportsBets: [],
  }
}

// =============
// Local Helpers
// =============

function buildMultiFixedOddsBet(
  spend: BetSlipBetsState['multiInvestment'],
  multis: BetSlipItem[],
  position: number
): BetSlipBetRequest['fixedOddsBets'][0] {
  const legs = multis.map(item => buildFixedOddsBetLeg(item, true))

  const campaign = { id: -1, rewardType: 'None' }

  if (spend.bonusBetId) {
    campaign.id = spend.bonusBetId
    campaign.rewardType = 'BonusBetReward'
  } else if (!spend.isBonusCash) {
    campaign.id = 0
    campaign.rewardType = 'BonusCashReward'
  }

  return {
    id: Date.now().toString(),
    investmentWin: spend.value || undefined,
    formulaInvestment: buildValidFormulas(spend, legs.length),
    legs,
    specials: [],
    position,
    selectedCampaign: campaign.id != -1 ? campaign : undefined,
  }
}

function buildValidFormulas(
  multiInvestment: BetSlipBetsState['multiInvestment'],
  numberOfValidLegs: number
): BetSlipBetRequest['fixedOddsBets'][0]['formulaInvestment'] {
  const formulaInvestment: BetSlipBetRequest['fixedOddsBets'][0]['formulaInvestment'] = {}
  if (numberOfValidLegs >= 2 && multiInvestment.f1) {
    formulaInvestment.singles = multiInvestment.f1
  }
  if (numberOfValidLegs >= 3 && multiInvestment.f2) {
    formulaInvestment.doubles = multiInvestment.f2
  }
  if (numberOfValidLegs >= 4 && multiInvestment.f3) {
    formulaInvestment.trebles = multiInvestment.f3
  }
  if (numberOfValidLegs >= 5 && multiInvestment.f4) {
    formulaInvestment.pick4 = multiInvestment.f4
  }
  if (numberOfValidLegs >= 6 && multiInvestment.f5) {
    formulaInvestment.pick5 = multiInvestment.f5
  }
  return formulaInvestment
}

function buildFixedOddsBet(
  item: BetSlipItem,
  position: number,
  isRefreshing = false
): BetSlipBetRequest['fixedOddsBets'][0] {
  const [winBoost, placeBoost] = calculateBoosts(item.selectedSuperPickOffer || null)
  return {
    id: item.id || '',
    investmentWin: item.investment.win.value || undefined,
    investmentPlace: item.investment.place.value || undefined,
    legs: [buildFixedOddsBetLeg(item, false)],
    specials:
      item.selectedSuperPickOffer && item.selectedSuperPickOffer.specialSeq && !isRefreshing
        ? [
            {
              specialSeq: item.selectedSuperPickOffer.specialSeq,
              boostPrice: winBoost || placeBoost,
            },
          ]
        : [],
    position,
    selectedCampaign: item.campaign
      ? { id: item.campaign.id, rewardType: item.campaign.rewardType }
      : undefined,
  }
}

function buildFixedOddsBetLeg(
  item: BetSlipItem,
  isMultiBetLeg: boolean
): BetSlipBetRequest['fixedOddsBets'][0]['legs'][0] {
  let handicap = 0
  let priceWin
  let pricePlace
  let sportDetails
  let raceDetails
  let externalBetId

  const isStartingPrice = isStartingPriceSelection(item.selection)

  if (isFobPropositionSelection(item.selection)) {
    handicap = item.selection.handicap || 0
    sportDetails = {
      propositionSeq: item.selection.propositionSeq,
      variantSeq: item.selection.variantSeq,
    }
  }

  if (isFobMatchedSelection(item.selection) || isStartingPriceMatchedSelection(item.selection)) {
    raceDetails = createRacingDetails(
      item.selection.fixtureDate,
      item.selection.fixtureId,
      item.selection.raceNumber,
      item.selection.acceptorNumber
    )
  }

  let legType: BetLegType | undefined = undefined

  if (isFobSportsSelection(item.selection)) {
    externalBetId = item.selection.externalBetId
    sportDetails = {}
  } else {
    if (isStartingPrice) {
      legType = 'SP'
    } else if (isMultiBetLeg) {
      legType = item.multiLegBetType || 'W'
    } else if (item.isEachWayAvailable) {
      item.isEachWay ? (legType = 'EW') : (legType = 'W')
    } else {
      legType = determineLegTypeFromInvestments(
        item.investment.win.value,
        item.investment.place.value
      )
    }
  }

  if (isFobSelection(item.selection)) {
    priceWin = item.selection.winPrice || undefined
    pricePlace = item.selection.placePrice || undefined
  } else if (isStartingPrice) {
    priceWin = null
    pricePlace = null
  }

  return {
    id: item.id || '',
    legType,
    handicap,
    priceWin,
    pricePlace,
    externalBetId,
    sportDetails,
    raceDetails,
  }
}

function buildToteBet(
  item: BetSlipItem,
  position: number,
  isRefreshing = false
): BetSlipBetRequest['toteBets'][0] {
  const selection = item.selection as ToteSelection
  const hasWinInvestment = !!item.investment.win.value && item.investment.win.value > 0
  const hasPlaceInvestment = !!item.investment.place.value && item.investment.place.value > 0
  const productPlanSeq = getToteProductPlanSequence(
    selection.betType,
    isRefreshing,
    selection.isRovingBanker,
    hasWinInvestment,
    hasPlaceInvestment
  )
  return {
    betTransactionId: item.id || '',
    productPlanSeq,
    fixtureId: selection.fixtureId,
    fixtureDate: selection.fixtureDate,
    raceNumber: selection.raceNumber,
    selections: selection.selectionString,
    investmentWin: item.investment.win.value === 0 ? undefined : item.investment.win.value,
    investmentPlace: item.investment.place.value === 0 ? undefined : item.investment.place.value,
    position,
  }
}

function buildAllUpBet(item: BetSlipItem, position: number): BetSlipBetRequest['allUpBets'][0] {
  const selection = item.selection as AllUpSelection
  const productPlanSeq = getToteProductPlanSequence(selection.betType, false)
  const raceDetails: AllUpSelection['details'] = []
  selection.details.forEach(selectionDetail => {
    if (selectionDetail.poolType === 'EW') {
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: 'W',
        betSelections: selectionDetail.betSelections,
      })
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: 'P',
        betSelections: selectionDetail.betSelections,
      })
    } else {
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: selectionDetail.poolType,
        betSelections: selectionDetail.betSelections,
      })
    }
  })
  return {
    betTransactionId: item.id || '',
    productPlanSeq,
    fixtureId: selection.fixtureId,
    fixtureDate: selection.fixtureDate,
    raceDetails,
    formulas: selection.formulas.filter(formula => formula.isSelected),
    investment: item.investment.win.value,
    position,
  }
}

const createRacingDetails = (
  fixtureDate: string,
  fixtureId: string,
  raceNumber: number,
  starterNumber?: number
): RaceDetails => ({ fixtureDate, fixtureId, raceNumber, starterNumber })

// =====
// Types
// =====

interface RaceDetails {
  fixtureDate?: string
  fixtureId: string
  raceNumber: number
  starterNumber?: number
  propositionCode?: string
}
