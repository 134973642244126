import React from 'react'
import Rx from 'rx'
import { Loading, Errored, ErroredInstance, State, Repository } from 'rwwa-data-access'
import { logError } from '../../Utils'

/** @deprecated DO NOT USE */
export function connect<
  // Ensure that TSpecification is a map of Repos
  TSpecification extends {
    [K in keyof TSpecification]: Repository<TSpecification[K]['__type_marker__']>
  },
>(specification: TSpecification) {
  // The type of values passed to the inner component from data access
  type TAccessed = {
    [K in keyof TSpecification]: TSpecification[K]['__type_marker__'] | Loading | Errored
  }

  // The keys we require to use this component
  type TRemoteKeys = {
    [K in keyof TSpecification]: string
  }

  const keys = Object.keys(specification)
  // @ts-expect-error Legacy - deprecated
  const repos = keys.map(key => specification[key] as Repository<unknown>)

  type Observed = { key: string; state: State<unknown> }[]
  const latest$: Rx.Observable<Observed> = Rx.Observable.combineLatest(
    repos.map(repo => repo.data$),
    (...latest) => latest.map((value, index) => ({ key: keys[index], state: value }))
  )

  function factory<T extends object>(
    Inner: React.FC<TAccessed & T>
  ): React.ComponentClass<TRemoteKeys & T>
  function factory<T extends object>(
    Inner: React.ComponentClass<TAccessed & T>
  ): React.ComponentClass<TRemoteKeys & T>
  function factory<T extends object>(
    Inner: React.FC<TAccessed & T> | React.ComponentClass<TAccessed & T>
  ) {
    return class extends React.Component<TRemoteKeys & T, { currentState: Observed | null }> {
      private subscription?: Rx.IDisposable

      constructor(props: Readonly<TRemoteKeys & T>) {
        super(props)
        this.state = { currentState: null }
      }

      public UNSAFE_componentWillMount() {
        this.subscription = latest$.subscribe(latest => {
          try {
            this.setState({ currentState: latest })
          } catch (e) {
            logError(e as Error)
          }
        })
      }

      public componentWillUnmount() {
        if (this.subscription) {
          this.subscription.dispose()
        }
      }

      public shouldComponentUpdate(
        nextProps: Readonly<TRemoteKeys & T>,
        nextState: { currentState: Observed }
      ) {
        if (this.props !== nextProps) {
          if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            return true
          }
        }

        if (!this.state.currentState || !nextState.currentState) {
          return true
        }

        return (
          this.state.currentState.length !== nextState.currentState.length ||
          !this.state.currentState.every(
            (map, index) => map.state === nextState.currentState[index].state
          )
        )
      }

      public render() {
        if (this.state.currentState === null) {
          return <div />
        }

        const props = { ...(this.props as object) }

        this.state.currentState.forEach(specRow => {
          const resourceKey = (this.props as Record<string, unknown>)[specRow.key] as string

          if (resourceKey) {
            const instance = (
              (specification as Record<string, unknown>)[specRow.key] as Repository<unknown>
            ).getFrom(specRow.state, resourceKey)
            ;(props as Record<string, unknown>)[specRow.key] = instance
          } else {
            ;(props as Record<string, unknown>)[specRow.key] = ErroredInstance
          }
        })

        return React.createElement(Inner, props as unknown as null)
      }
    }
  }

  return factory
}
