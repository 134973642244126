import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationTime } from '../types'

export const initialState: NotificationState = {
  notifications: {},
}

const slice = createSlice({
  name: 'notification-button',
  initialState: initialState,
  reducers: {
    registerNotification(
      state,
      action: PayloadAction<{ eventId: string; notificationTimes: NotificationTime[] }>
    ) {
      if (action.payload?.eventId && action.payload.notificationTimes.length) {
        state.notifications = {
          ...state.notifications,
          [action.payload.eventId]: action.payload.notificationTimes,
        }
      }
    },

    registerNotifications(
      state,
      action: PayloadAction<{ eventId: string; notificationTimes: NotificationTime[] }[]>
    ) {
      const filtered = action.payload.filter(x => x.notificationTimes?.length)
      if (!filtered.length) {
        return
      }
      state.notifications = {
        ...state.notifications,
        ...Object.fromEntries(filtered.map(x => [x.eventId, x.notificationTimes])),
      }
    },

    unregisterNotification(state, action: PayloadAction<string>) {
      if (action.payload in state.notifications) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.notifications[action.payload]
        state.notifications = {
          ...state.notifications,
        }
      }
    },
  },
})

export const { registerNotification, unregisterNotification, registerNotifications } = slice.actions

export default slice.reducer

// =====
// Types
// =====
export interface NotificationState {
  /** map of eventId: list of notification times in minutes  */
  notifications: Record<string, NotificationTime[]>
}
