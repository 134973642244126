import { lazy } from 'react'

export const SvgComponents = {
  GiddyUpLogo: lazy(() => import('./GiddyUpLogo')),
  GooglePay: lazy(() => import('./GooglePay')),
  SkyRacing: lazy(() => import('./SkyRacing')),
  TABLogo: lazy(() => import('./TABLogo')),
  PayIDLogo: lazy(() => import('./PayIDLogo')),
  TABtouchLogo: lazy(() => import('./TABtouchLogo')),
  TABtouchBrandLogo: lazy(() => import('./TABtouchBrandLogo')),
}
