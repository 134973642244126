import React from 'react'
import Rx from 'rx'
import dayjs from 'dayjs'
import * as ko from 'knockout'
import { Query } from 'react-query'

import '@classic/include-betting-v2'
import '@classic/Specials/IOC/inversify.config'
import '@classic/Specials/Components/PyosMessage/register'
import '@classic/Specials/Components/PyosSelect/register'
import '@classic/AppUtils/Framework/CustomBindings/SlideBinding'

import { useAppDispatch } from '@core/Store/hooks'
import { navigateHome } from '@classic/AppUtils/Framework/Intent/navigation'
import * as msgBus from '@classic/Foundation/Services/MessageBusService'
import * as DateTimeProvider from '@classic/Foundation/DateTimeProvider'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { toISODate } from '@mobi/utils'
import { setRacePageReactQueryKey } from '../Store'

export const useLegacyRaceCardIntegration = ({
  meetingId,
  meetingDate,
  raceNumber,
}: {
  meetingId: string
  meetingDate: string
  raceNumber: string
}) => {
  const dispatch = useAppDispatch()
  const koElementRef = React.useRef<HTMLDivElement>(null)
  const componentModelRef = React.useRef<ReturnType<typeof validateParams>>(null)

  const raceToRaceNavigation$Ref = React.useRef(
    new Rx.Subject<{
      meetingId: string
      meetingDate: Date
    }>()
  )

  // Invalidate query cache
  React.useEffect(() => {
    const rqInvalidationSubscription = raceToRaceNavigation$Ref.current
      .debounce(5000)
      .subscribe(({ meetingId, meetingDate }) => {
        queryClient.removeQueries({
          predicate: inactiveRacePageQueryPredicate(meetingId, meetingDate),
        })
      })

    return () => {
      rqInvalidationSubscription.dispose()
      invalidateReactQueryAll()
    }
  }, [])

  // Clean knockout bindings on unmount
  React.useEffect(() => {
    if (!koElementRef.current) return undefined
    const el = koElementRef.current
    return () => {
      ko.cleanNode(el)
    }
  }, [])

  // Update knockout data bindings
  React.useEffect(() => {
    if (!koElementRef.current) return undefined
    const el = koElementRef.current

    const incomingData: RouteParametersAssertion = {
      meetingId,
      raceNumber,
      meetingDate: meetingDate
        ? meetingDate
        : dayjs(DateTimeProvider.todayAsAwst()).format('YYYY-MM-DD'),
    }
    const newModel = validateParams(incomingData)
    if (!newModel) return navigateHome()

    const isKoComponentBinded = !!ko.dataFor(el)

    if (isKoComponentBinded && isOnlyRaceNumberChange(componentModelRef.current, newModel)) {
      componentModelRef.current = newModel
      raceToRaceNavigation$Ref.current.onNext({
        meetingId: newModel.meetingId,
        meetingDate: newModel.meetingDate,
      })
      msgBus.publish('race-route-updated-command', { raceNumber: newModel.raceNumber })
      return
    }

    if (isKoComponentBinded) ko.cleanNode(el)
    componentModelRef.current = newModel
    ko.applyBindings({ model: componentModelRef.current }, el)
  }, [meetingDate, meetingId, raceNumber])

  // Capture current race key for react query component deep within KO
  React.useEffect(() => {
    if (meetingDate && meetingId && raceNumber) {
      const key = queryKeys.racePageComplete(meetingId, meetingDate, +raceNumber)
      dispatch(setRacePageReactQueryKey(key))
    }
    return () => {
      dispatch(setRacePageReactQueryKey(null))
    }
  }, [meetingDate, meetingId, raceNumber, dispatch])

  return {
    koElementRef,
  }
}

// =============
// Local Helpers
// =============

/** Remove data from cache because the race page ReactQuery queries are configured with
 Infinite stateTime to mimic the lifetime of KO view model observables */
function invalidateReactQueryAll() {
  ;[
    [queryKeys.racePageFormBase],
    [queryKeys.racePageCompleteBase],
    [queryKeys.racePageStartersForRaces],
  ].forEach(queryKey => queryClient.removeQueries({ queryKey }))
}

function inactiveRacePageQueryPredicate(meetingId: string, meetingDate: Date) {
  return (query: Query) => {
    const queryKey = query.queryKey
    return (
      Array.isArray(queryKey) &&
      queryKey.length === 4 &&
      typeof queryKey[0] === 'string' &&
      queryKey[0].startsWith('race-page-') &&
      queryKey[1] === meetingId.toLowerCase() &&
      queryKey[2] === toISODate(meetingDate) &&
      !query.isActive()
    )
  }
}

function validateParams(params: RouteParametersAssertion): ModelAssertion | null {
  if (
    !params.meetingId ||
    !/^[A-Za-z]{2,3}$/.test(params.meetingId) ||
    !params.meetingDate ||
    !/^\d\d\d\d-\d\d-\d\d$/.test(params.meetingDate) ||
    !params.raceNumber ||
    !/^[0-9]{1,2}$/.test(params.raceNumber)
  )
    return null

  return {
    meetingId: params.meetingId,
    meetingDate: DateTimeProvider.parseIso8601Date(params.meetingDate),
    raceNumber: parseInt(params.raceNumber),
  }
}

const isOnlyRaceNumberChange = (
  oldModel: ModelAssertion | null,
  newModel: ModelAssertion
): boolean =>
  !!oldModel &&
  oldModel.meetingId.toLowerCase() === newModel.meetingId.toLowerCase() &&
  oldModel.meetingDate.toISOString() === newModel.meetingDate.toISOString() &&
  oldModel.raceNumber !== newModel.raceNumber

// =====
// Types
// =====

type ShapeOf<T> = Record<keyof T, unknown>
type AssertKeysEqual<X extends ShapeOf<Y>, Y extends ShapeOf<X>> = X

interface RouteParameters {
  meetingId: string
  meetingDate: string
  raceNumber: string
}
type RouteParametersAssertion = AssertKeysEqual<RouteParameters, Model>

interface Model {
  meetingId: string
  meetingDate: Date
  raceNumber: number
}
type ModelAssertion = AssertKeysEqual<Model, RouteParameters>
