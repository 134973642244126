export function getOrdinalSuffix(input: number | string, isUpperCase = false): string {
  const intNumber = Math.floor(typeof input === 'string' ? parseInt(input, 10) : input)

  if (!isFinite(intNumber)) {
    throw new Error('Input must be a finite number or numeric string')
  }

  let suffix = ''
  // Handling special cases for 11th, 12th, 13th which do not follow the general rule
  if (intNumber % 100 >= 11 && intNumber % 100 <= 13) {
    suffix = 'th'
  } else {
    switch (intNumber % 10) {
      case 1:
        suffix = 'st'
        break
      case 2:
        suffix = 'nd'
        break
      case 3:
        suffix = 'rd'
        break
      default:
        suffix = 'th'
        break
    }
  }

  if (isUpperCase) {
    suffix = suffix.toUpperCase()
  }

  return suffix
}
