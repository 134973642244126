import { CheckboxInput } from '@mobi/component-library/Common/Input'
import React, { ChangeEvent } from 'react'

type Props = {
  isBusy: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}
export const DocumentPermission = ({ isBusy, onChange }: Props) => {
  return (
    <CheckboxInput
      label='I agree to allow the details I provided to be verified by the issuer.'
      id='give-permission'
      disabled={isBusy}
      onChange={e => onChange(e)}
    />
  )
}
