export const queryKeys = {
  depositInitialData: `deposit/initial-data`,
  userContactDetails: 'user-contact-details',
  addCard: 'deposit/add-card',
  /*
   * The betAccount key is used to cache the bet account details outside of the @mobi/account package.
   * It uses the cache from the old state management. This cannot be invalidated wihout access to @core.
   */
  betAccount: 'bet-account',
  /*
   * The depositBetAccount key is used to cache the bet account details inside of the @mobi/account package.
   */
  depositBetAccount: 'deposit/bet-account',
  generatePayId: 'deposit/generate-payid',
  countryList: 'country-list',
  withdrawDetails: 'withdraw-details',
  getBsbDetails: 'get-bsb-details',
  createZeptoContact: 'create-zepto-contact',
  deleteZeptoContact: 'delete-zepto-contact',
}
