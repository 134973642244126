import React from 'react'
import * as QueryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import { useDisableAppHeaderSticky } from '@core/Areas/AppHeader/hooks/useDisableAppHeaderSticky'
import { DockedPlayer as DockedSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/Components/DockedPlayer'
import { isThoroughbredRacePageInWA } from '@core/Areas/Racing/helpers/liveVideoFeedHelpers'
import { RaceNavigation } from '@core/Areas/Racing/Components/Navigation/RaceNavigation'
import { MainContainerStyled } from '@core/Components/Containers/MainContainer.styles'
import { RaceCardMatched } from './RaceCardMatched'
import { useDisableAndroidBackGesture } from './Hooks/useDisableAndroidBackGesture'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'
import { useRacePageData } from '../Racing/Hooks'
import { RaceSwipeContainer } from './Components/RaceSwipeContainer/RaceSwipeContainer'
import { setSelectedRace } from '../Racing/helpers'
import { Direction } from './Components/RaceSwipeContainer/types'
import { SmartBetSlipMultiToastLegacy } from '../Racing/Components/SmartBetSlip/SmartBetSlipMultiToastLegacy'

export const RaceCard = React.memo(() => {
  useDisableAndroidBackGesture()
  useDisableAppHeaderSticky()

  const { meetingId, raceNumber } = useParams<{ meetingId: string; raceNumber: string }>()
  const { date: meetingDate } = QueryString.parse(useLocation().search) as { date: string }

  const { race } = useRacePageData({ meetingDate, meetingId, raceNumber })

  React.useEffect(() => {
    const delay = setTimeout(() => {
      trackOptimoveEvent({ eventName: 'visited_race_page' })
    }, 500)
    return () => clearTimeout(delay)
  }, [])

  const shouldDisplayRacingWA = isThoroughbredRacePageInWA(race)

  const races = race?.MeetingInformation.Races ?? []

  const currentRace = races?.find(race => race.RaceNumber.toString() === raceNumber)
  const lastRaceNumber = races.length > 0 ? races[races.length - 1].RaceNumber : null
  const firstRaceNumber = races.length > 0 ? races[0].RaceNumber : null

  const prevRace = currentRace && races.find(race => race.RaceNumber === currentRace.RaceNumber - 1)
  const nextRace = currentRace && races.find(race => race.RaceNumber === currentRace.RaceNumber + 1)

  return (
    <MainContainerStyled background='white'>
      <DockedSkyVideoPlayer />

      <RaceNavigation
        meetingDate={meetingDate}
        raceNumber={+raceNumber}
        meetingId={meetingId}
        isRacingWA={shouldDisplayRacingWA}
      />

      <RaceSwipeContainer
        onSwipeCallback={(direction: Direction) => {
          const raceToSwipeTo = direction === 'BACKWARD' ? prevRace : nextRace
          if (raceToSwipeTo) {
            setSelectedRace(+raceToSwipeTo.RaceNumber)
          }
        }}
        isLastRace={!currentRace || lastRaceNumber === currentRace.RaceNumber}
        isFirstRace={!currentRace || firstRaceNumber === currentRace.RaceNumber}
      >
        <RaceCardMatched meetingDate={meetingDate} meetingId={meetingId} raceNumber={raceNumber} />
      </RaceSwipeContainer>
      <SmartBetSlipMultiToastLegacy />
    </MainContainerStyled>
  )
})
