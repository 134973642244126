import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ICheckBoxSelectionPipeline } from './ICheckBoxSelectionPipeline'
import { ICheckBoxPipelineProcessor } from './ICheckBoxPipelineProcessor'
import { ISelectionContext } from './ISelectionContext'

export default class CheckBoxSelectionPipeline implements ICheckBoxSelectionPipeline {
  constructor(private processor: ICheckBoxPipelineProcessor) {}

  process(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    context: BettingInformation,
    selectionContext: ISelectionContext,
    count: number
  ): IObservableStarter[] {
    return this.processor.process(current, all, context, selectionContext, count)
  }
}
