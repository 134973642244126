import React from 'react'
import { SearchOverlayStyled, SearchOverlayHeaderStyled } from '@core/Areas/Search/Search.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { OverlayClose } from '@core/Components/Overlay'
import {
  StructuredSearchLocationTrackModalHeaderTextStyled,
  StructuredSearchTrackModalMainContainerStyled,
  StructuredSearchResultComponentStyled,
  CheckboxInResultStyled,
} from './StructuredSearchLocation.styles'

import {
  state$ as structuredSearchState$,
  SelectedTrackType,
  StructuredSearchSetSelectedTracks,
} from '../../Driver/StructuredSearchDriver'
import { useObservableImmutable } from '@core/Utils/hooks'
import { GridRow, GridCell, Grid } from '@mobi/component-library/Common/Grid'
import { getModalTracks, ModalTrack } from './track-utils'

import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { colors } from '@core/Settings'
import { AppHeaderButtonStyled } from '@core/Areas/AppHeader/Components/Buttons/Buttons.styles'
import { CriteriaFixedFooterStyled, PillStyled } from '../../StructuredSearch.styles'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoResultsStyled } from '../../Results/StructuredSearchResults.styles'
import { StructuredSearchTextLabel } from '../../Results/StructuredSearchTextLabel'
import { StructuredSearchTextLabelFontSize } from '../../Results/StructuredSearchTextLabelStyled.styles'

const TRACKS = 'Tracks'
const NOTRACKS = 'No Tracks'

interface IconTypes {
  Races: keyof typeof iconPaths
  Trots: keyof typeof iconPaths
  Dogs: keyof typeof iconPaths
}

export const IconTypes: IconTypes = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export function StructuredSearchTrackModal(): JSX.Element {
  const [modalTracks, setModalTracks] = React.useState<ModalTrack[]>([])
  let tracks: JSX.Element | JSX.Element[] | null = null
  let dataTid: string = NOTRACKS

  const { searchResults, searchCriteria } = useObservableImmutable(structuredSearchState$, [
    'searchResults',
    'searchCriteria',
  ])
  const availableTracks = searchResults?.get('aggregations')?.get('tracks')
  const selectedTracks = searchCriteria?.get('selectedTracks')

  React.useEffect(() => {
    if (availableTracks) {
      setModalTracks(getModalTracks(availableTracks.toJS(), selectedTracks?.toJS() ?? []))
    }
  }, [availableTracks, selectedTracks])

  const handleClearButtonClick = () => {
    // Clear the selections here

    let updatedTracks = modalTracks

    updatedTracks = updatedTracks.map(track => {
      track.selected = false
      return track
    })
    setModalTracks(updatedTracks)
  }

  const handleCloseButtonClick = (doSave: boolean) => {
    if (doSave) {
      // Update the driver

      let selectedTracks: SelectedTrackType = []

      selectedTracks = modalTracks.filter(track => track.selected).map(track => track.id)

      if (selectedTracks.length === 0) {
        selectedTracks = null
      }

      StructuredSearchSetSelectedTracks(selectedTracks)
    }
    // Close the overlay
    OverlayClose()
  }

  const selectCheckBox = (id: string) => {
    // Flip checkbox state here
    // setModalTracks(<new state here>)
    let updatedTracks: ModalTrack[] = modalTracks

    updatedTracks = updatedTracks.map(track => {
      if (track.id === id) {
        track.selected = !track.selected
      }
      return track
    })
    setModalTracks(updatedTracks)
  }

  if (modalTracks) {
    dataTid = TRACKS
    tracks = modalTracks.map(track => {
      const iconType = IconTypes[track.contestType as keyof typeof IconTypes]
      return (
        <StructuredSearchResultComponentStyled key={track.id}>
          <Grid>
            <GridRow>
              <GridCell valign='middle'>{track.name}</GridCell>
              <GridCell width='3.5rem' valign='middle'>
                {track.isDuplicate ? (
                  <Icon
                    size='2.5rem'
                    type={iconType}
                    color={colors.structuredSearchLocationModal.icon.fill}
                    data-tid-structured-search-track-icon={track.id}
                  />
                ) : null}
              </GridCell>
              <GridCell width='5rem' valign='middle'>
                <PillStyled>{track.location}</PillStyled>
              </GridCell>
              <GridCell width='3.6rem' align='center' valign='top'>
                <CheckboxInResultStyled>
                  <CheckboxInput
                    id={track.id}
                    data-tid-structured-search-track-checkbox={track.id}
                    label={''}
                    checked={track.selected}
                    onChange={() => selectCheckBox(track.id)}
                    size={2.2}
                  />
                </CheckboxInResultStyled>
              </GridCell>
            </GridRow>
          </Grid>
        </StructuredSearchResultComponentStyled>
      )
    })
  } else {
    dataTid = NOTRACKS
  }

  return (
    <SearchOverlayStyled data-tid-structured-search-track-overlay=''>
      <SearchOverlayHeaderStyled>
        <AppHeaderButtonStyled
          onClick={() => handleCloseButtonClick(false)}
          data-tid-search-button-back
        >
          <Icon
            type='arrowleft'
            title='Close'
            size='3.5rem'
            color={colors.structuredSearchLocationModal.header.button}
          />
        </AppHeaderButtonStyled>
        <StructuredSearchLocationTrackModalHeaderTextStyled>
          Tracks
        </StructuredSearchLocationTrackModalHeaderTextStyled>

        <AppHeaderButtonStyled
          onClick={() => handleCloseButtonClick(false)}
          data-tid-search-button-close
        >
          <Icon type='cross' title='Close' />
        </AppHeaderButtonStyled>
      </SearchOverlayHeaderStyled>
      <StructuredSearchTrackModalMainContainerStyled
        data-tid-structured-search-track-maincontainer={dataTid}
      >
        <>{modalTracks && modalTracks.length > 0 && tracks} </>
        <>
          {!modalTracks ||
            (modalTracks && modalTracks.length === 0 && (
              <StructuredSearchLocationTrackModalNoTracks />
            ))}{' '}
        </>
      </StructuredSearchTrackModalMainContainerStyled>
      {modalTracks && modalTracks.length > 0 && (
        <CriteriaFixedFooterStyled>
          <Grid maxWidth='40rem'>
            <GridRow padding='0.5rem'>
              <GridCell>
                <ButtonBlock
                  color='secondary'
                  onClick={handleClearButtonClick}
                  data-tid-structred-search-modal-reset-all=''
                >
                  Clear
                </ButtonBlock>
              </GridCell>
              <GridCell>
                <ButtonBlock
                  color='primary'
                  onClick={() => handleCloseButtonClick(true)}
                  testId={'structured-search-submit-button'}
                  data-tid-search-modal-button-close
                >
                  Done
                </ButtonBlock>
              </GridCell>
            </GridRow>
          </Grid>
        </CriteriaFixedFooterStyled>
      )}
    </SearchOverlayStyled>
  )
}

function StructuredSearchLocationTrackModalNoTracks(): JSX.Element {
  return (
    <NoResultsStyled data-tid-structured-search-track-maincontainer='No Results'>
      <Icon type='warningCircled' size='6rem' color={colors.structuredSearch.initialText} />
      <StructuredSearchTextLabel size={StructuredSearchTextLabelFontSize.Large}>
        No Tracks Found
      </StructuredSearchTextLabel>
      <StructuredSearchTextLabel size={StructuredSearchTextLabelFontSize.Small}>
        Try making your criteria less specific
      </StructuredSearchTextLabel>
    </NoResultsStyled>
  )
}
