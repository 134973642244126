import { sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

const enum LocalConstants {
  WaitTimeout = 7_000,
}

export function getFirebaseDynamicLinkFromNative(rawLink: string): Promise<string> {
  return new Promise((resolve, reject) => {
    let timeoutId: number | undefined = undefined

    const subscription = subscribeToNative('RESPONSE_FIREBASE_DYNAMIC_LINK', ({ link }) => {
      clearTimeout(timeoutId)
      subscription.dispose()
      resolve(link)
    })

    sendToNative('REQUEST_FIREBASE_DYNAMIC_LINK', { link: rawLink })

    timeoutId = window.setTimeout(() => {
      subscription.dispose()
      reject()
    }, LocalConstants.WaitTimeout)
  })
}
