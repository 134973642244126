import { BettingInformation } from '../Model/BettingInformation'
import { ForwardingCheckBoxValidatorProcessor } from './ForwardingCheckBoxValidatorProcessor'
import { CheckBoxSelection } from '../Components/Core/UIElements/CheckboxSelection'

export default class AllwaysCheckBoxValidatorProcessor extends ForwardingCheckBoxValidatorProcessor {
  validate(count: number, bettingContext: BettingInformation): boolean {
    if (!bettingContext.isBoxed()) {
      return this.next.validate(count, bettingContext)
    }

    const selections = bettingContext.selections
    const starters = selections.getStartersForRace(bettingContext.raceNumber)()

    if (!starters || starters.length === 0) {
      return false
    }

    const checkboxes = starters
      .filter(starter => typeof starter.selection === 'function')
      .map(starter => starter.selection() as CheckBoxSelection)

    if (checkboxes.some(cb => cb.anySelected() && !cb.onlyFirstSelected())) {
      return false
    }

    const selectedCount = checkboxes.filter(cb => cb.anySelected()).length
    return (
      selectedCount >= count &&
      selectedCount <=
        bettingContext.selections.getTotalNumberOfStartersForRace(bettingContext.raceNumber)
    )
  }
}
