/**
 * In some circumstances it's possible that storage is not supported, such as
 * when it's disabled by the user or, in Safari, all cookies are blocked.
 */
function isLocalStorageSupported() {
  try {
    return (
      'localStorage' in window &&
      window.localStorage !== null &&
      window.localStorage !== undefined &&
      typeof window.localStorage.getItem === 'function' &&
      typeof window.localStorage.setItem === 'function' &&
      typeof window.localStorage.removeItem === 'function'
    )
  } catch (e) {
    if (e instanceof DOMException && e.name === 'SecurityError') {
      // eslint-disable-next-line no-console
      console.error('User has blocked cookies or disabled browser storage', e)
    }

    return false
  }
}

export function getFromLocalStorage(key: string): string | null {
  if (isLocalStorageSupported()) {
    try {
      return window.localStorage.getItem(key)
    } catch (e) {
      swallowQuotaExceededError(e as LocalStorageError)
    }
  }

  return null
}

export function setInLocalStorage(key: string, data: string): void {
  if (isLocalStorageSupported()) {
    try {
      window.localStorage.setItem(key, data)
    } catch (e) {
      swallowQuotaExceededError(e as LocalStorageError)
    }
  }
}

export function removeFromLocalStorage(key: string): void {
  if (isLocalStorageSupported()) {
    try {
      window.localStorage.removeItem(key)
    } catch (e) {
      swallowQuotaExceededError(e as LocalStorageError)
    }
  }
}

function swallowQuotaExceededError(e: LocalStorageError): void {
  // MOBILE-477 Ignore localStorage DOM 22 / "quota exceeded" error (only). Rethrow other errors.
  if (e.code !== 22 && !/quota/i.test(e.name)) {
    throw e
  }
}

type LocalStorageError = { code: number; name: string }
