import { BetSpecialOffer } from '../../Model/BetSpecialOffer'
import { PyosStore } from '../../Store/PyosStore'
import '../../../AppUtils/Framework/CustomBindings/slideVisible'
import { injectable, inject } from 'inversify'
import { CurrencyConverter } from '../../../AppUtils/Utils/CurrencyConverter'

export interface PyosSelectViewModelParams {
  formFieldId: string
}

@injectable()
export class PyosSelectViewModel {
  /* dependencies */
  private _store: PyosStore

  /* properties */
  private _formFieldId: string
  private _currencyConverter: CurrencyConverter

  constructor(
    @inject('$params') params: PyosSelectViewModelParams,
    @inject('PyosStore') store: PyosStore
  ) {
    this._formFieldId = params.formFieldId
    this._store = store
    this._currencyConverter = new CurrencyConverter()
  }

  /* computed state */
  formFieldId() {
    return this._formFieldId
  }

  loading() {
    return this._store.loading()
  }

  specialOffers() {
    const offers = this._store.specialOffers()
    const numberOfRows = Math.ceil(offers.length / 3)
    const numberOfColoumns = numberOfRows * 3
    const tableRows: BetSpecialOffer[][] = []
    for (let i = 0; i < numberOfRows; i++) {
      tableRows.push([])
    }
    let row = 0
    for (let cell = 0; cell < numberOfColoumns; cell++) {
      if (offers.length <= 3 && cell < offers.length) {
        tableRows[row].push(offers[cell])
      } else if (offers.length === 4 && cell < offers.length) {
        tableRows[row].push(offers[cell])
        if (cell === 1) {
          row++
        }
      } else if (offers.length >= 5) {
        tableRows[row].push(cell >= offers.length ? ({} as BetSpecialOffer) : offers[cell])
        if ((cell + 1) % 3 === 0) {
          row++
        }
      }
    }
    return tableRows
  }

  selectedSpecialSeq() {
    return this._store.selectedSpecialSeq()
  }

  isSelectedSpecialSeq = (specialOffer: BetSpecialOffer) => {
    const selectedSpecialSeq = this._store.selectedSpecialSeq()
    return selectedSpecialSeq === specialOffer.specialSeq
  }

  isExpanded() {
    return this._store.isExpanded()
  }

  isVisible() {
    return this._store.hasOffers() && this._store.canShowInducements()
  }

  selectedSpecial() {
    return this._store.selectedSpecial()
  }

  maxReward = (specialOffer: BetSpecialOffer) => {
    if (!specialOffer || !specialOffer.elements) {
      return ''
    }
    const nonBoostElement = specialOffer.elements?.find(element => !element.isBoost)

    return nonBoostElement?.maxReward ? ` up to $${nonBoostElement.maxReward}` : ''
  }

  projectedReward = (specialOffer: BetSpecialOffer, isHeader = false) => {
    if (specialOffer) {
      const reward = this._store.projectedReward(specialOffer)
      if (reward && reward.greaterThan(0)) {
        if (reward.isInt()) {
          return this._currencyConverter.toMoney(Number(reward), 0)
        }
        return this._currencyConverter.toMoney(Number(reward), 2)
      } else {
        return isHeader ? '$0' : ''
      }
    }
    return null
  }

  /* actions */
  primaryAction() {
    this._store.primaryAction()
  }

  setSelectedSpecial = (specialOffer: BetSpecialOffer) => {
    this._store.setSelectedSpecial(specialOffer)
  }

  clearSelectedSpecial() {
    this._store.clearSelectedSpecial()
  }

  isObjectEmptyObject = (specialOffer: BetSpecialOffer) => {
    return Object.getOwnPropertyNames(specialOffer).length === 0
  }

  shouldDisplayReward(specialOffer: BetSpecialOffer) {
    if (specialOffer.isBoostOffer) {
      return false
    }
    const selectedOffer = this._store.selectedSpecial()
    if (!selectedOffer) {
      return true
    }
    return selectedOffer.specialSeq === specialOffer.specialSeq
  }
}
