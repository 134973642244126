import { type ThemeName as BannerThemeName } from '@mobi/component-library/Common/V2/Banner/Banner.styles'

export enum TargetChannel {
  MobileOnly = 'Mobile Only',
  DesktopOnly = 'Desktop Only',
  AllChannels = 'All Channels',
}

export const AlwaysShow = 'Always Show'
export const Inducement = 'Inducement Only'
export const NonInducement = 'Non-Inducement Only'

export interface NotificationWireResource {
  TitleMessage: string
  NavigationLink: string | null
  NavigationLinkText: string | null
  TargetChannel: TargetChannel
  InternalTrackingCode: string | null
  Visibility: string
  Sys: { Id: string }
  Dismissible: boolean
  HideIcon: boolean
  Type: BannerThemeName | null
}

export function mapVisibility(visibility: string): boolean | null {
  return visibility === AlwaysShow ? null : visibility === Inducement
}
