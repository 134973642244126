import { IRaceCodeBuilder } from './IRaceCodeBuilder'
import RaceBuilder from './RaceBuilder'
import DogBuilder from './DogBuilder'
import TrotBuilder from './TrotBuilder'

export default {
  from(starterType: string): IRaceCodeBuilder {
    switch (starterType) {
      case 'Races':
        return new RaceBuilder()
      case 'Dogs':
        return new DogBuilder()
      case 'Trots':
        return new TrotBuilder()
      default:
        throw new Error('invalid source.Type')
    }
  },
}
