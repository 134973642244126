import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import {
  getPendingSortOrder,
  getResultsSortOrder,
} from '@core/Areas/AccountActivity/Store/selectors'
import { colors } from '@mobi/component-library/Theme/Common'
import { fontFamilies, fontFamiliesBold } from '@core/Settings'
import type { SortOrder, Tabs } from '@core/Areas/AccountActivity/types'
import { setPendingSortOrder, setResultsSortOrder } from '@core/Areas/AccountActivity/Store'
import { ACCOUNT_ACTIVITY_ROUTE } from '@core/Areas/AccountActivity/constants'
import { useRebetBetCount } from './hooks/useRebetBetCount'
import { useHeaderScrollEvents } from './hooks/useScrollHeaderEvents'
import { HeaderDate } from './Components/HeaderDate'

const EVENT_START: SortOrder = 'eventStart'
const BET_PLACED: SortOrder = 'betPlaced'

const MONTHS_FOR_HISTORY_SELECT = [...Array(24).keys()]

const enum LocalConstants {
  ActiveNavClass = 'account-activity__nav--active',
  KambiBetHistoryBarHeight = '4.8rem', // 48px on Kambi
  kambiBetHistoryBarColor = '#29193d',
}

export const Header: React.FC<{
  firstDate: string
  setSelectedDate: React.Dispatch<string>
  selectedDate: string
  onRefresh: () => void
}> = ({ firstDate, setSelectedDate, selectedDate, onRefresh }) => {
  const { tab: activeTab } = useParams<{ tab: Tabs }>()

  const dispatch = useDispatch()
  const pendingSortOrder = useSelector(getPendingSortOrder)
  const resultsSortOrder = useSelector(getResultsSortOrder)

  const rebetInsights = useRebetBetCount(activeTab)

  const { fixedHeaderWrapperElRef, fixedHeaderElRef, displayDate } = useHeaderScrollEvents({
    firstDate,
  })

  const isPendingTab = activeTab === 'pending'
  const isResultsTab = activeTab === 'results'
  const isRebetTab = activeTab === 'rebet'
  const isTransactionsTab = activeTab === 'transactions'
  const isOrderByEvent =
    (isPendingTab && pendingSortOrder === 'eventStart') ||
    // TODO: Remove upon confirmation that result sort by event is DESC
    // (isResultsTab && resultsSortOrder === 'eventStart') ||
    isRebetTab

  const handlePendingSortOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setPendingSortOrder(e.target.value as SortOrder))
  }

  const handleResultsSortOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setResultsSortOrder(e.target.value as SortOrder))
  }

  const handlSelectedDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.currentTarget.value)
  }

  const handleRefresh = () => {
    onRefresh()
    rebetInsights.fetchRebetCount()
  }

  return (
    <div ref={fixedHeaderWrapperElRef}>
      <HeaderContainerStyled ref={fixedHeaderElRef}>
        {!isTransactionsTab && (
          <TabNavStyled>
            <Link
              data-testid='account-activity-tab-pending'
              to={`/${ACCOUNT_ACTIVITY_ROUTE}/pending`}
            >
              <span className={activeTab === 'pending' ? LocalConstants.ActiveNavClass : ''}>
                Pending
              </span>
            </Link>

            <Link
              data-testid='account-activity-tab-results'
              to={`/${ACCOUNT_ACTIVITY_ROUTE}/results`}
            >
              <span className={activeTab === 'results' ? LocalConstants.ActiveNavClass : ''}>
                Resulted
              </span>
            </Link>

            {rebetInsights.isReBetFeatureActive && (
              <Link to={`/${ACCOUNT_ACTIVITY_ROUTE}/rebet`} data-tid-tab-rebet>
                <span className={activeTab === 'rebet' ? LocalConstants.ActiveNavClass : ''}>
                  Re-Bet
                  {rebetInsights.hasRebetBadge && (
                    <RebetCountBadgeStyled isMaxCount={rebetInsights.isRebetCountMax}>
                      {rebetInsights.rebetTicketsCount}
                    </RebetCountBadgeStyled>
                  )}
                </span>
              </Link>
            )}
          </TabNavStyled>
        )}

        <SortingBarStyled>
          <div>
            {isPendingTab ? (
              <SortButtonWrapperStyled>
                <select
                  data-testid='pending-sort-select'
                  onChange={handlePendingSortOrderChange}
                  value={pendingSortOrder}
                >
                  <option value={EVENT_START}>Event Start</option>
                  <option value={BET_PLACED}>Bet Placed</option>
                </select>

                <Icon name='SolidChevronDown' color={colors.lavender[700]} size='1.8rem' />
              </SortButtonWrapperStyled>
            ) : isResultsTab ? (
              <SortButtonWrapperStyled>
                <select
                  data-testid='results-sort-select'
                  onChange={handleResultsSortOrderChange}
                  value={resultsSortOrder}
                >
                  <option value={EVENT_START}>Event Start</option>
                  <option value={BET_PLACED}>Bet Placed</option>
                </select>

                <Icon name='SolidChevronDown' color={colors.lavender[700]} size='1.8rem' />
              </SortButtonWrapperStyled>
            ) : (
              <DateLabelStyled>
                {activeTab === 'transactions' && 'Date'}
                {activeTab === 'rebet' && 'Event Start'}
              </DateLabelStyled>
            )}

            <DateWrapperStyled>
              <HeaderDate date={displayDate} isOrderByEvent={isOrderByEvent} />

              {(isResultsTab || isTransactionsTab) && (
                <>
                  <SecondayHeaderIconStyled>
                    <Icon name='DuocolorCalendar' color={colors.lavender[700]} size='1.8rem' />
                  </SecondayHeaderIconStyled>

                  <SecondaryDateSelectStyled
                    data-testid='account-activity-date-picker'
                    value={selectedDate}
                    onChange={handlSelectedDateChange}
                  >
                    {MONTHS_FOR_HISTORY_SELECT.map(monthNumber => {
                      const date = dayjs().subtract(monthNumber, 'months').date(1)
                      return (
                        <option key={monthNumber} value={date.format('YYYY-MM-DD')}>
                          {date.format('MMMM YYYY')}
                        </option>
                      )
                    })}
                  </SecondaryDateSelectStyled>
                </>
              )}
            </DateWrapperStyled>
          </div>

          <RefreshButtonStyled data-testid='account-activity-refresh' onClick={handleRefresh}>
            <Icon name='LineRefreshCw05' color={colors.lavender[700]} size='1.8rem' />
          </RefreshButtonStyled>
        </SortingBarStyled>
      </HeaderContainerStyled>
    </div>
  )
}

// ======
// Styles
// ======

const HeaderContainerStyled = styled.div({
  display: 'block',
  top: 0,
  backfaceVisibility: 'hidden',
  margin: 0,
  width: '100%',
  maxWidth: '80em',
  transform: 'translateZ(0)',
  zIndex: 9,
  position: 'relative',
})

const TabNavStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: LocalConstants.KambiBetHistoryBarHeight,
  padding: '0 0.8rem',
  backgroundColor: LocalConstants.kambiBetHistoryBarColor,

  '& > a': {
    display: 'flex',
    alignItems: 'center',
    height: LocalConstants.KambiBetHistoryBarHeight,
    fontSize: '1.3rem',
    fontFamily: fontFamilies.brand,
    textDecoration: 'none',

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 1rem',
      minWidth: 'auto',
      height: '2.4rem',
      color: 'rgba(255, 255, 255, 0.7)',
      background: 'transparent',
      borderRadius: '1.6rem',
      marginRight: '0',

      '&:hover': {
        background: 'rgba(255, 255, 255, 0.12)',
      },

      [`&.${LocalConstants.ActiveNavClass}`]: {
        fontFamily: fontFamiliesBold.brand,
        color: colors.white,
        background: 'rgba(255, 255, 255, 0.12)',
        pointerEvents: 'none',
      },
    },
  },
})

const SortingBarStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  minHeight: spacing.lg,
  padding: `0 ${spacing.md}`,
  fontSize: '1.2rem',
  background: colors.white,

  '> div:first-of-type': {
    position: 'relative',
    flex: 1,
    display: 'flex',
  },
})

const DateLabelStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '1rem',
  marginRight: '1rem',
  fontSize: '1.4rem',
  color: 'grey',
})

const SortButtonWrapperStyled = styled.div({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  minWidth: '9.5rem',
  border: 0,
  margin: 0,

  '& > select': {
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    width: '100%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    border: 0,
    background: 'none',
    appearance: 'none',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    fontFamily: font.family.primary,
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    fontWeight: font.weight.medium,
    color: colors.lavender[700],
    outline: 'none',
    cursor: 'pointer',
    MozUserSelect: 'none',
  },

  '& > span': {
    position: 'absolute',
    right: `-${spacing.smx2}`,
    width: '2.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    fill: colors.lavender[700],
  },
})

const SecondayHeaderIconStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: spacing.smx1,
})

const SecondaryDateSelectStyled = styled.select({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '50%',
  width: '100%',
  minHeight: '4rem',
  transform: 'translateY(-50%)',
  height: '100%',
  opacity: 0.01,
  fontSize: '1.4rem',
})

const DateWrapperStyled = styled.div({
  position: 'relative',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
})

const RefreshButtonStyled = styled(SecondayHeaderIconStyled)({
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '4rem',
  padding: 0,
  border: 0,
  background: 'none',
  fontSize: 'inherit',
  textDecoration: 'none',
  margin: 0,
}).withComponent('button')

const RebetCountBadgeStyled = styled.span<{ isMaxCount?: boolean }>(
  {
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: 'auto',
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    borderRadius: '50%',
    lineHeight: '2rem',
    textAlign: 'center',
    backgroundColor: colors.error[600],
    color: colors.white,
  },
  ({ isMaxCount = false }) => ({
    fontSize: isMaxCount ? '1rem' : '1.2rem',
    ...(isMaxCount && {
      '&::after': {
        content: '"+"',
        letterSpacing: '-2px',
      },
    }),
  })
)
