import * as ko from 'knockout'
import Guard from '@classic/AppUtils/Framework/Guard'
import type { IObservableStarter } from '../Model/Observables/IObservableStarter'
import type { SortOption } from './types'
import SortFunction from './SortFunction'

export const ModelSorter = {
  sortBy(starters: ko.ObservableArray<IObservableStarter>, sortOption: SortOption) {
    Guard.arrayNotNullOrEmpty(starters())

    const sortOptionProperty = sortOption.property
    const sortOptiondirection = sortOption.direction

    if (!SortFunction.shouldFilter(sortOptionProperty)) {
      starters.sort(SortFunction.for(sortOption))
      return
    }

    const scratchOrSuspended = starters().filter(starter =>
      SortFunction.filter(sortOptionProperty)(starter)
    )
    const sortableStarters = starters().filter(
      starter => !SortFunction.filter(sortOptionProperty)(starter)
    )

    scratchOrSuspended.sort(
      SortFunction.for({ property: 'Number', direction: sortOptiondirection })
    )
    sortableStarters.sort(SortFunction.for(sortOption))
    starters(sortableStarters.concat(scratchOrSuspended))
  },
}
