import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { dayjs } from '@mobi/utils/date'
import {
  state$,
  SelectionType,
  defaultState,
  ToggleSelectionForGame,
  ClearToteSportSelections,
} from '@core/Areas/Tipping/driver'
import { getPool, queryDefaults, ToteSportsGame, ToteSportsPool } from '../../data/toteSports'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Money } from '@core/Components/Text/Money'
import {
  InfoHeaderStyled,
  InfoTitleStyled,
  PoolNameStyled,
  GameHeaderStyled,
  ButtonGroupItemStyled,
  ButtonsContainerStyled,
  ButtonDrawStyled,
  DisclaimerStyled,
  JackpotStyled,
  IconStyled,
  DisclaimerContainerStyled,
  GameGroupStyled,
} from './Games.styles'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { colors } from '@core/Settings'
import { useObservable } from '@core/Utils/hooks'
import { GridCell } from '@mobi/component-library/Common/Grid'

import { ClosedGame } from './ClosedGame'
import { TippingBettingDrawer } from '../BettingDrawer/BettingDrawer'
import { hexColors } from '@mobi/settings'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { LoadingBars } from '../LoadingBars'

interface GamesParams {
  poolKey: string
  sportIconCode: string
}

const DATE_TIME_FORMAT = 'ddd, DD MMM YYYY HH:mm'

export const Games = (): JSX.Element => {
  const { poolKey, sportIconCode } = useParams<GamesParams>()
  const { selections } = useObservable(state$, defaultState)

  React.useEffect(() => {
    ClearToteSportSelections()
  }, [])

  const {
    isLoading,
    isError,
    data: pool,
  } = useQuery<ToteSportsPool>({
    ...queryDefaults,
    queryKey: [queryKeys.toteSportsPool, poolKey],
    queryFn: () => getPool(poolKey),
  })

  if (isLoading) {
    return <LoadingBars />
  }

  if (isError || !pool) {
    return (
      <GridCell padding='1rem'>
        <NoticeBox
          title='Unable to display games at this time. Please try again later.'
          noticeBoxType={NoticeBoxTypes.Info}
          hasBorder={true}
        />
      </GridCell>
    )
  }

  const icon = sportIconCode?.toUpperCase() as keyof typeof iconPaths
  const scheduledCloseTime = dayjs(pool.scheduledCloseTime).format(DATE_TIME_FORMAT)
  const hasDraw = pool.games.some(game => game.isDrawSupported)

  // releaseTime is typed as a date, but sometimes is actually a string.
  // Creating a new date from the value ensures that it's always a date object.
  const releaseTime = pool.releaseTime ? new Date(pool.releaseTime) : null
  const isRefunded = releaseTime != null && pool.isAbandoned
  const formattedReleaseTime = releaseTime ? dayjs(pool.releaseTime).format(DATE_TIME_FORMAT) : ''

  return (
    <>
      <InfoHeaderStyled>
        <PoolNameStyled data-tid-tote-sport-pool-name>
          <IconStyled type={icon === 'FB' ? 'FBL' : icon || 'tab'} />
          {`${pool.name} ${pool.nameSuffix} ${pool.numberOfGames}`}
        </PoolNameStyled>

        <div data-tid-tote-sport-pool-amount>
          <span>Pool:&nbsp;</span>
          <Money amount={Math.floor(pool.total)} decimalPlaces={0} />
          {pool.grossJackpot && (
            <JackpotStyled>
              <Icon type={'jackpot'} data-tid-tote-sport-pool-jackpot-icon='' />
              <Money amount={Math.floor(pool.grossJackpot)} decimalPlaces={0} />
            </JackpotStyled>
          )}
        </div>

        {pool.isOpen && (
          <div data-tid-tote-sport-pool-scheduled-close-time>
            Betting Closes: {scheduledCloseTime}
          </div>
        )}
        {isRefunded && (
          <div data-tid-tote-sport-refund-time>Refunds Official: {formattedReleaseTime}</div>
        )}
        {releaseTime != null && !isRefunded && (
          <>
            <div data-tid-tote-sport-tips-required>
              Tips Required: {pool.dividend?.numberOfTipsRequired || 0}/{pool.numberOfGames}
            </div>
            <div data-tid-tote-sport-dividend>
              Dividend: <Money amount={pool.dividend?.return || 0} />
            </div>
            <div data-tid-tote-sports-official-time>Dividends Official: {formattedReleaseTime}</div>
          </>
        )}
      </InfoHeaderStyled>

      <InfoTitleStyled>
        <div>HOME</div>
        {hasDraw && <div>DRAW</div>}
        <div>AWAY</div>
      </InfoTitleStyled>

      {pool?.isOpen &&
        pool?.games.map((game, i) => {
          const gameSelections = selections[game.key]
          return (
            <GameSelections
              key={game.key}
              game={game}
              gameIndex={i}
              isHomeSelected={gameSelections?.isHome || false}
              isDrawSelected={gameSelections?.isDraw || false}
              isAwaySelected={gameSelections?.isAway || false}
            />
          )
        })}

      {pool?.isOpen === false &&
        pool.games.map((game, i) => {
          return <ClosedGame key={game.key} game={game} gameIndex={i} />
        })}
      <Disclaimers disclaimers={pool.disclaimers} />
      <TippingBettingDrawer poolKey={pool.key} />
    </>
  )
}

interface GameSelectionProps {
  game: ToteSportsGame
  gameIndex: number
  isHomeSelected: boolean
  isDrawSelected: boolean
  isAwaySelected: boolean
}

const GameSelections = ({
  game,
  gameIndex,
  isHomeSelected,
  isDrawSelected,
  isAwaySelected,
}: GameSelectionProps): JSX.Element => {
  return (
    <GameGroupStyled>
      <GameHeaderStyled data-tid-tote-sport-game-header>
        <strong>Game {gameIndex + 1}</strong>{' '}
        {dayjs(game.startTime).format('ddd, DD MMM YYYY HH:mm')}
      </GameHeaderStyled>

      <ButtonsContainerStyled>
        <ButtonGroupItemStyled
          id={`${game.key}_home`}
          selected={isHomeSelected}
          onClick={() =>
            ToggleSelectionForGame({ gameId: game.key, selectionType: SelectionType.Home })
          }
          data-tid-tote-sport-game-home
        >
          {abbreviateName(game.homeTeamName)}
        </ButtonGroupItemStyled>

        {game.isDrawSupported && (
          <ButtonDrawStyled
            id={`${game.key}_draw`}
            selected={isDrawSelected}
            onClick={() =>
              ToggleSelectionForGame({ gameId: game.key, selectionType: SelectionType.Draw })
            }
            data-tid-tote-sport-game-draw
          >
            Draw
          </ButtonDrawStyled>
        )}

        <ButtonGroupItemStyled
          id={`${game.key}_away`}
          selected={isAwaySelected}
          onClick={() =>
            ToggleSelectionForGame({ gameId: game.key, selectionType: SelectionType.Away })
          }
          data-tid-tote-sport-game-away
        >
          {abbreviateName(game.awayTeamName)}
        </ButtonGroupItemStyled>
      </ButtonsContainerStyled>
    </GameGroupStyled>
  )
}

const Disclaimers = ({ disclaimers }: { disclaimers: string[] }): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  if (typeof disclaimers === 'undefined' || disclaimers?.length <= 0) return null

  return (
    <div style={{ marginTop: '1rem', backgroundColor: hexColors.white }}>
      <ExpandablePanel
        scrollIntoViewWhenOpened={true}
        title='Betting Information - Tote'
        rightIcon={<Icon type='infoInvert' color={colors.expandablePanel.dark.informationIcon} />}
        data-tid-tote-sport-pool-disclaimers
        headerClicked={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
      >
        <DisclaimerContainerStyled>
          {disclaimers.map((disclaimer, index) => (
            <DisclaimerStyled key={index}>{disclaimer}</DisclaimerStyled>
          ))}
        </DisclaimerContainerStyled>
      </ExpandablePanel>
    </div>
  )
}

// The name is going to be displayed in one or two lines, for each line the maximum characters is MAX_CHARS_TO_DISPLAY.
// In this function we split the name at spaces, then build up the name, checking the length at each word added.
export function abbreviateName(name: string, MAX_CHARS_TO_DISPLAY = 14): string | null {
  if (name) {
    if (name.length < MAX_CHARS_TO_DISPLAY) {
      return name
    }
    if (!name.includes(' ')) {
      return displayMaxChars(name)
    }
    // Split name into individual words
    const nameSplit = name.split(' ')
    // If first word is longer than MAX_CHARS_TO_DISPLAY, return that shortened
    if (nameSplit[0].length > MAX_CHARS_TO_DISPLAY) {
      return displayMaxChars(nameSplit[0])
    }
    let newNameFirstLine = nameSplit[0]
    let i = 1
    // Add words to the first line, stopping before the first line would be longer than MAX_CHARS_TO_DISPLAY
    while (
      (newNameFirstLine + ' ' + nameSplit[i]).length < MAX_CHARS_TO_DISPLAY &&
      i < nameSplit.length
    ) {
      newNameFirstLine = newNameFirstLine + ' ' + nameSplit[i]
      i++
    }
    let newNameSecondLine = nameSplit[i]
    i++
    // Add words to the second line, stopping after the second line is longer than MAX_CHARS_TO_DISPLAY
    while (newNameSecondLine.length < MAX_CHARS_TO_DISPLAY && i < nameSplit.length) {
      newNameSecondLine = newNameSecondLine + ' ' + nameSplit[i]
      i++
    }
    // If second line is longer than MAX_CHARS_TO_DISPLAY, shorten it
    if (newNameSecondLine.length > MAX_CHARS_TO_DISPLAY) {
      newNameSecondLine = displayMaxChars(newNameSecondLine)
    }
    return `${newNameFirstLine} ${newNameSecondLine}`
  }
  return null
}

function displayMaxChars(chars: string): string {
  const MAX_CHARS_TO_DISPLAY = 14
  if (chars.length > MAX_CHARS_TO_DISPLAY) {
    return `${chars.substring(0, MAX_CHARS_TO_DISPLAY - 2)}..`
  }
  return chars
}
