import { lazy } from 'react'

/** DO NOT modify, contents are automatically generated (see cli-helper) */
export const Icons = {
  BannerError: lazy(() => import('./Banner/error')),
  BannerNeutral: lazy(() => import('./Banner/neutral')),
  BannerPrimary: lazy(() => import('./Banner/primary')),
  BannerSuccess: lazy(() => import('./Banner/success')),
  BannerWarning: lazy(() => import('./Banner/warning')),
  CustomBetSlipWithTabtouchLogo: lazy(() => import('./Custom/bet-slip-with-tabtouch-logo')),
  CustomBetslip: lazy(() => import('./Custom/betslip')),
  CustomScale02: lazy(() => import('./Custom/scale-02')),
  CustomScanMyBet: lazy(() => import('./Custom/scan-my-bet')),
  DuocolorAlertTriangle: lazy(() => import('./Figma/Duocolor/Alerts/alert-triangle')),
  DuocolorArrowCircleRight: lazy(() => import('./Figma/Duocolor/Arrows/arrow-circle-right')),
  DuocolorArrowNarrowRight: lazy(() => import('./Figma/Duocolor/Arrows/arrow-narrow-right')),
  DuocolorArrowsUp: lazy(() => import('./Figma/Duocolor/Arrows/arrows-up')),
  DuocolorBank: lazy(() => import('./Figma/Duocolor/Finance/bank')),
  DuocolorCalendar: lazy(() => import('./Figma/Duocolor/Time/calendar')),
  DuocolorCheckCircle: lazy(() => import('./Figma/Duocolor/General/check-circle')),
  DuocolorCloud01: lazy(() => import('./Figma/Duocolor/Weather/cloud-01')),
  DuocolorCloudRaining05: lazy(() => import('./Figma/Duocolor/Weather/cloud-raining-05')),
  DuocolorCloudSun02: lazy(() => import('./Figma/Duocolor/Weather/cloud-sun-02')),
  DuocolorCopy02: lazy(() => import('./Figma/Duocolor/General/copy-02')),
  DuocolorCurrencyDollarCircle: lazy(
    () => import('./Figma/Duocolor/Finance/currency-dollar-circle')
  ),
  DuocolorDelete: lazy(() => import('./Figma/Duocolor/Editor/delete')),
  DuocolorLoading01: lazy(() => import('./Figma/Duocolor/General/loading-01')),
  DuocolorStar01: lazy(() => import('./Figma/Duocolor/Shapes/star-01')),
  DuocolorSun: lazy(() => import('./Figma/Duocolor/Weather/sun')),
  LineAlertCircle: lazy(() => import('./Figma/Line/Alerts/alert-circle')),
  LineAlertTriangle: lazy(() => import('./Figma/Line/Alerts/alert-triangle')),
  LineBank: lazy(() => import('./Figma/Line/Finance/bank')),
  LineBankNote03: lazy(() => import('./Figma/Line/Finance/bank-note-03')),
  LineBellPlus: lazy(() => import('./Figma/Line/Alerts/bell-plus')),
  LineBookmarkAdd: lazy(() => import('./Figma/Line/General/bookmark-add')),
  LineCheck: lazy(() => import('./Figma/Line/General/check')),
  LineCheckCircle: lazy(() => import('./Figma/Line/General/check-circle')),
  LineCheckCircleBroken: lazy(() => import('./Figma/Line/General/check-circle-broken')),
  LineCreditCard02: lazy(() => import('./Figma/Line/Finance/credit-card-02')),
  LineInfoCircle: lazy(() => import('./Figma/Line/General/info-circle')),
  LineMaximize01: lazy(() => import('./Figma/Line/Layout/maximize-01')),
  LineMaximize02: lazy(() => import('./Figma/Line/Layout/maximize-02')),
  LineMenu01: lazy(() => import('./Figma/Line/General/menu-01')),
  LineMinimize02: lazy(() => import('./Figma/Line/Layout/minimize-02')),
  LinePencil01: lazy(() => import('./Figma/Line/Editor/pencil-01')),
  LinePhoneCall01: lazy(() => import('./Figma/Line/Communication/phone-call-01')),
  LinePlaceholder: lazy(() => import('./Figma/Line/General/placeholder')),
  LinePlusCircle: lazy(() => import('./Figma/Line/General/plus-circle')),
  LineReceipt: lazy(() => import('./Figma/Line/Finance/receipt')),
  LineRefreshCw05: lazy(() => import('./Figma/Line/Arrows/refresh-cw-05')),
  LineRepeat04: lazy(() => import('./Figma/Line/Media/repeat-04')),
  LineSearchSm: lazy(() => import('./Figma/Line/General/search-sm')),
  LineShare02: lazy(() => import('./Figma/Line/General/share-02')),
  LineStar01: lazy(() => import('./Figma/Line/Shapes/star-01')),
  LineTrash01: lazy(() => import('./Figma/Line/General/trash-01')),
  LineTrash04: lazy(() => import('./Figma/Line/General/trash-04')),
  LineVolumeMax: lazy(() => import('./Figma/Line/Media/volume-max')),
  LineVolumeX: lazy(() => import('./Figma/Line/Media/volume-x')),
  LogoFacebook: lazy(() => import('./Logo/facebook')),
  LogoInstagram: lazy(() => import('./Logo/instagram')),
  LogoX: lazy(() => import('./Logo/x')),
  SolidBarChart10: lazy(() => import('./Figma/Solid/Charts/bar-chart-10')),
  SolidBellRinging01: lazy(() => import('./Figma/Solid/Alerts/bell-ringing-01')),
  SolidCheckCircle: lazy(() => import('./Figma/Solid/General/check-circle')),
  SolidChevronDown: lazy(() => import('./Figma/Solid/Arrows/chevron-down')),
  SolidChevronLeft: lazy(() => import('./Figma/Solid/Arrows/chevron-left')),
  SolidChevronRight: lazy(() => import('./Figma/Solid/Arrows/chevron-right')),
  SolidChevronRightDouble: lazy(() => import('./Figma/Solid/Arrows/chevron-right-double')),
  SolidChevronUp: lazy(() => import('./Figma/Solid/Arrows/chevron-up')),
  SolidCircle: lazy(() => import('./Figma/Solid/Shapes/circle')),
  SolidFile06: lazy(() => import('./Figma/Solid/Files/file-06')),
  SolidLayoutGrid01: lazy(() => import('./Figma/Solid/Layout/layout-grid-01')),
  SolidList: lazy(() => import('./Figma/Solid/Layout/list')),
  SolidPauseCircle: lazy(() => import('./Figma/Solid/Media/pause-circle')),
  SolidPlayCircle: lazy(() => import('./Figma/Solid/Media/play-circle')),
  SolidRefreshCw01: lazy(() => import('./Figma/Solid/Arrows/refresh-cw-01')),
  SolidRows02: lazy(() => import('./Figma/Solid/Layout/rows-02')),
  SolidScale02: lazy(() => import('./Figma/Solid/Editor/scale-02')),
  SolidSkipBack15: lazy(() => import('./Figma/Solid/Arrows/skip-back-15')),
  SolidSkipForward15: lazy(() => import('./Figma/Solid/Arrows/skip-forward-15')),
  SolidStar01: lazy(() => import('./Figma/Solid/Shapes/star-01')),
  SolidStopCircle: lazy(() => import('./Figma/Solid/Media/stop-circle')),
  SolidTarget03: lazy(() => import('./Figma/Solid/General/target-03')),
  SolidTrendUp01: lazy(() => import('./Figma/Solid/Charts/trend-up-01')),
  SolidTriangle: lazy(() => import('./Figma/Solid/Shapes/triangle')),
  SolidTrophy01: lazy(() => import('./Figma/Solid/Education/trophy-01')),
  SolidUser01: lazy(() => import('./Figma/Solid/Users/user-01')),
  SolidXCircle: lazy(() => import('./Figma/Solid/General/x-circle')),
  SolidXClose: lazy(() => import('./Figma/Solid/General/x-close')),
} as const
