import Decimal from 'decimal.js'
import type { RacingBetType, BettingType, BetLegType } from '@mobi/betslip/types'
import { KeypadModes, SpecialKeys, KeyPressed } from '@core/Components/Keypad/KeyPress'
import { BetSpecialOffer, IBetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { Stake } from '@classic/Specials/Model/Stake'
import { PyosRewardCalculator } from '@classic/Specials/Store/PyosRewardCalculator'
import { roundEstimateDown } from '@mobi/utils/money'

export function calculateInvestment(
  keyPressed: KeyPressed,
  currentAmount: number,
  lastKeyPressed: KeyPressed,
  secondLastKeyPressed: KeyPressed
): number {
  // When editing an item from Betslip, the investment may be non-zero.
  // If there is no `lastKeyPress`, treat it as if the previous mode was denomination, so that pressing a denomination
  // key results in adding to the initial value (as indicated on the button), not overwriting the initial value.
  if (keyPressed.mode !== (lastKeyPressed.mode || KeypadModes.Denomination)) {
    currentAmount = 0
  }

  switch (keyPressed.mode) {
    case KeypadModes.Denomination: {
      if (keyPressed.value === SpecialKeys.Clear) {
        return 0
      } else {
        return currentAmount + (keyPressed.value as number)
      }
    }

    case KeypadModes.Numeric: {
      switch (keyPressed.value) {
        case SpecialKeys.Backspace: {
          const newValue = parseFloat(currentAmount.toString().slice(0, -1))
          return isNaN(newValue) ? 0 : newValue
        }

        default: {
          const integerPartMaxDigits = 5
          let currentStringAmount = currentAmount.toString()

          if (lastKeyPressed.value === SpecialKeys.Decimal) {
            currentStringAmount += '.' + keyPressed.value.toString()
            return parseFloat(currentStringAmount)
          }

          if (secondLastKeyPressed.value === SpecialKeys.Decimal && lastKeyPressed.value === 0) {
            currentStringAmount += '.0' + keyPressed.value.toString()
            return parseFloat(currentStringAmount)
          }

          currentStringAmount += keyPressed.value.toString()
          const decimalCheck = currentStringAmount.split('.')

          if (decimalCheck[0] && decimalCheck[0].length > integerPartMaxDigits) {
            return currentAmount
          }

          if (decimalCheck[1] && decimalCheck[1].length > 2) {
            return currentAmount
          }

          return parseFloat(currentStringAmount)
        }
      }
    }

    case KeypadModes.Percentage: {
      return 0
    }

    default: {
      return 0
    }
  }
}
export function calculateBetCost(
  bettingType: BettingType | null,
  winInvestment: number,
  placeInvestment: number,
  isEachWay: boolean,
  betType?: RacingBetType,
  numberOfCombinations = 1
): number {
  switch (bettingType) {
    case 'fixed-odds-racing': {
      return isEachWay ? winInvestment * 2 : winInvestment + placeInvestment
    }
    case 'tote-racing': {
      switch (betType) {
        case 'Win & Place': {
          return (winInvestment + placeInvestment) * numberOfCombinations
        }
        case 'Exacta':
        case 'Quinella':
        case 'Trifecta':
        case 'First 4':
        case 'Double':
        case 'Quaddie': {
          return winInvestment
        }
        case 'All Up': {
          return winInvestment * numberOfCombinations
        }
        default: {
          return 0
        }
      }
    }
    case 'mystery-quick-pick':
    case 'tote-sports-tipping': {
      return winInvestment * numberOfCombinations
    }
    case 'favourite-numbers': {
      return 0.5 * numberOfCombinations
    }
    case 'fixed-odds-sports': {
      return winInvestment
    }
    default: {
      return 0
    }
  }
}

export function calculateProjectedReturn(
  bettingType: BettingType | null,
  winPrice: number | undefined,
  placePrice: number | undefined,
  winInvestment: number,
  placeInvestment: number,
  isEachWay: boolean,
  bonusBetValue?: number,
  specialOffer?: BetSpecialOffer | null
): number {
  if (isEachWay) {
    const winEstimate = roundEstimateDown((winPrice ?? 0) * winInvestment, 2)
    const placeEstimate = roundEstimateDown((placePrice ?? 0) * winInvestment, 2)
    const projectedReturn = winEstimate + placeEstimate

    return projectedReturn
  }

  switch (bettingType) {
    case 'fixed-odds-racing': {
      let boostReward = 0
      if (specialOffer) {
        const stakeObj: Stake = Stake.normalise({
          Win: Decimal(winInvestment || 0),
          Place: Decimal(placeInvestment || 0),
        })
        boostReward = new PyosRewardCalculator()
          .calculateProjectedBoostReward(specialOffer as IBetSpecialOffer, stakeObj)
          .toNumber()
      }

      const winEstimate = roundEstimateDown(winInvestment * (winPrice ?? 0), 2)
      const placeEstimate = roundEstimateDown(placeInvestment * (placePrice ?? 0), 2)
      const projectedReturn = winEstimate + placeEstimate + boostReward - (bonusBetValue ?? 0)

      return roundEstimateDown(projectedReturn, 2)
    }
    case 'fixed-odds-sports': {
      return roundEstimateDown(winInvestment * (winPrice ?? 0), 2)
    }
    default: {
      return 0
    }
  }
}

export function calculateFlexiAmount(winInvestment: number, numberOfCombinations: number): number {
  return Math.floor(((winInvestment * 100) / numberOfCombinations) * 100) / 100
}

export function determineLegTypeFromInvestments(
  winInvestment: number,
  placeInvestment: number
): BetLegType {
  if (winInvestment > 0 && placeInvestment === 0) {
    return 'W'
  }
  if (winInvestment === 0 && placeInvestment > 0) {
    return 'P'
  }
  if (winInvestment > 0 && placeInvestment > 0) {
    return 'WP'
  }
  return 'W'
}

export function calculateInvestmentFontSizeFactor({
  amount,
  decimalPlaces,
}: {
  amount: number
  decimalPlaces?: number | undefined
}) {
  // changed to ratio to fit in `$101.00 ▼ and $99,999` on an iPhone5 screen
  const ratioByDigitNum = {
    x4: 0.85,
    x5: 0.75,
    x6: 0.63,
    x7: 0.6,
    x8: 0.6,
    x9: 0.55,
    max: 0.5,
    default: 0.9,
  }

  const numberOfDigits =
    typeof decimalPlaces !== 'undefined' && decimalPlaces === 0
      ? amount.toFixed(2).toString().length - 2
      : amount.toFixed(2).toString().length

  const isBelowMinDigit = numberOfDigits < 4
  const isBetweenDigitRatio = numberOfDigits >= 4 && numberOfDigits <= 9

  const key = (
    isBelowMinDigit ? 'default' : isBetweenDigitRatio ? `x${numberOfDigits}` : 'max'
  ) as keyof typeof ratioByDigitNum

  return ratioByDigitNum[key] || ratioByDigitNum.default
}
