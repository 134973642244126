import dayjs from 'dayjs'
import queryString from 'query-string'
import type { MysteryBetTypes } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { getEventAggregator } from '@core/Utils/events'

interface BuildToteRaceUriProps {
  meetingId: string
  raceNumber: number
  meetingDate: string
  starter?: number
  source?: string
}

interface RaceStarter {
  starterNumber: number
  place?: number
  legNumber?: number
}
export interface RaceHashParameters {
  betType: BetType
  starters: RaceStarter[]
  isValidUri: boolean
  source?: string
}

export function getTypeAndStartersFromRaceUri(uri: string): RaceHashParameters {
  const qs = queryString.parse(uri)

  const hasValidBetType = !!qs.betType && typeof qs.betType === 'string'
  const betType: BetType = hasValidBetType
    ? BetType[qs.betType as MysteryBetTypes] || BetType.WinPlace
    : BetType.WinPlace

  const starters =
    betType === BetType.SameRaceMulti
      ? parseSrmStartersFromToteRaceUri(qs)
      : parseWinAndPlaceStarterFromToteRaceUri(qs)

  const isValidUri = hasValidBetType || (!!starters && starters.length > 0)

  return {
    source: qs.source,
    betType,
    isValidUri,
    starters,
  }
}

function parseWinAndPlaceStarterFromToteRaceUri(parsedQueryString: {
  starter: number
}): RaceStarter[] {
  if (!parsedQueryString.starter || isNaN(Number(parsedQueryString.starter))) return []
  return [
    {
      starterNumber: Number(parsedQueryString.starter),
      place: 1,
    },
  ]
}

function parseSrmStartersFromToteRaceUri(parsedQueryString: { starters?: string }): RaceStarter[] {
  if (!parsedQueryString.starters) return []

  const pairs = parsedQueryString.starters.split(',')
  const pairRegex = /^(?<starter>\d+)-(?<leg>\d+)$/

  return pairs.reduce((existing, newPair) => {
    const splits = newPair.match(pairRegex)
    if (splits && splits.groups) {
      existing.push({
        starterNumber: parseInt(splits.groups['starter']),
        legNumber: parseInt(splits.groups['leg']),
      } as RaceStarter)
    }
    return existing
  }, [] as RaceStarter[])
}

export function buildToteRaceUri({
  meetingId,
  raceNumber,
  meetingDate,
  starter,
  source,
}: BuildToteRaceUriProps) {
  const formattedMeetingDate = dayjs(meetingDate).format('YYYY-MM-DD')
  let url = `/#tote/meetings/${meetingId.toLowerCase()}/${raceNumber}?date=${formattedMeetingDate}`
  if (starter) url += `&betType=WinPlace&starter=${starter}`
  if (source) url += `&source=${source}`
  return url
}

export function buildSameRaceMultiRaceUri({
  meetingId,
  raceNumber,
  meetingDate,
  starters,
  source,
}: {
  meetingId: string
  raceNumber: number
  meetingDate: string
  starters?: string
  source?: string
}): string {
  const formattedMeetingDate = dayjs(meetingDate).format('YYYY-MM-DD')
  let url = `/#tote/meetings/${meetingId.toLowerCase()}/${raceNumber}?date=${formattedMeetingDate}&betType=SameRaceMulti`
  if (starters) url += `&starters=${starters}`
  if (source) url += `&source=${source}`
  return url
}

export function setSelectedRace(raceNumber: number): void {
  getEventAggregator().publish('race-navigate-command', {
    raceNumber,
  })
}

export const getPayIndicatorText = (payIndicator?: number): string | null => {
  if (!payIndicator) return null

  if (payIndicator === 1) return 'Pay Win Only'

  let text = 'Pay '

  for (let i = 1; i <= payIndicator; i++) {
    text += i + ','
  }

  return text.slice(0, -1)
}
