import React from 'react'
import { ButtonGroupItemStyled, ButtonGroupStyled } from '../Buttons/ButtonGroup.styles'

export const Races = 'races'
export const Trots = 'trots'
export const Dogs = 'dogs'

interface CallbackProps {
  field: string
  value: boolean
}
export interface CodeSelectionFilter {
  racesSelected: boolean
  trotsSelected: boolean
  dogsSelected: boolean
  selectionChangedCallback: ({ field, value }: CallbackProps) => void
  testId: string
}

export function CodeSelectionFilter({
  racesSelected,
  trotsSelected,
  dogsSelected,
  selectionChangedCallback,
  testId,
}: CodeSelectionFilter) {
  const toggleButtonHandler = (field: string, value: boolean) => {
    selectionChangedCallback({ field, value })
  }

  return (
    <ButtonGroupStyled>
      <ButtonGroupItemStyled
        color='toggle'
        onClick={() => toggleButtonHandler(Races, !racesSelected)}
        selected={racesSelected}
        aria-label='Races'
        data-tid-races={testId}
      >
        Races
      </ButtonGroupItemStyled>

      <ButtonGroupItemStyled
        color='toggle'
        onClick={() => toggleButtonHandler(Trots, !trotsSelected)}
        selected={trotsSelected}
        aria-label='Trots'
        data-tid-trots={testId}
      >
        Trots
      </ButtonGroupItemStyled>

      <ButtonGroupItemStyled
        color='toggle'
        onClick={() => toggleButtonHandler(Dogs, !dogsSelected)}
        selected={dogsSelected}
        aria-label='Dogs'
        data-tid-dogs={testId}
      >
        Dogs
      </ButtonGroupItemStyled>
    </ButtonGroupStyled>
  )
}
