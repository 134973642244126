import React from 'react'
import { SvgIconStyled } from './Icons.styles'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { hexColors } from '@mobi/settings'
import { Chat, ChevronDown, ChevronUp, SpecialsNew, Tips, Video } from '@core/Components/Icons'

interface DirectionalIconProps {
  direction?: 'up' | 'down' | 'right' | 'left'
  size?: string
  color?: string
  valign?: string
}

export function DirectionalIcon({ size, color, direction, valign }: DirectionalIconProps) {
  let rotations

  switch (direction) {
    case 'up':
      rotations = '90deg'
      break
    case 'down':
      rotations = '-90deg'
      break
    case 'right':
      rotations = '180deg'
      break
    case 'left':
    default:
      rotations = '0deg'
  }

  return (
    <SvgIconStyled
      width='0.8em'
      height='1em'
      size={size || '2.2rem'}
      viewBox='80 0 128 256'
      role='presentation'
      transformation={`rotate(${rotations})`}
      color={color}
      valign={valign}
    >
      <path d={iconPaths.arrowleft} />
    </SvgIconStyled>
  )
}

/** @depcreated Use DirectionalIcon */
export function RightArrowIcon({ size = '2rem' }: { size?: string }) {
  return (
    <SvgIconStyled
      width='0.8em'
      height='1em'
      size={size}
      viewBox='80 0 128 256'
      role='presentation'
      transformation='rotate(180deg)'
      color={hexColors.grey}
    >
      <path d={iconPaths.arrowleft} />
    </SvgIconStyled>
  )
}

export function RefreshIcon() {
  return (
    <SvgIconStyled
      width='1em'
      height='1em'
      size='2rem'
      viewBox='80 0 128 256'
      role='presentation'
      transformation='rotate(180deg)'
      color={hexColors.white}
    >
      <path d={iconPaths.refresh} />
    </SvgIconStyled>
  )
}

type RaceCardIconName = 'chevronUp' | 'chevronDown' | 'tips' | 'chat' | 'specialsNew' | 'video'

type RaceCardIconProps = {
  icon: RaceCardIconName
  size?: number
}

export function RaceCardIcon({ icon, size = 20 }: RaceCardIconProps) {
  return icon == 'chat' ? (
    <Chat size={size} />
  ) : icon === 'chevronUp' ? (
    <ChevronUp size={size} />
  ) : icon === 'chevronDown' ? (
    <ChevronDown size={size} />
  ) : icon === 'specialsNew' ? (
    <SpecialsNew size={size} />
  ) : icon === 'tips' ? (
    <Tips size={size} />
  ) : (
    <Video size={size} />
  )
}
