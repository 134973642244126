import React, { lazy, Suspense } from 'react'
import { useFeature } from '@core/Utils/hooks'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import {
  state$ as userAccountState$,
  UpdateAccountBalance,
} from '@core/State/UserAccount/userAccountDriver'
import { WithdrawPage } from './WithdrawPage'
import { useObservableProperties } from '@core/Utils/hooks'

const App = lazy(() => import('@mobi/account/Areas/Withdraw/Components/App'))

export const Withdraw = (): JSX.Element | null => {
  const { accountNumber } = useObservableProperties(userAccountState$, ['accountNumber'], {
    accountNumber: null,
  })
  const isNewWithdrawFeatureActive = useFeature('WITHDRAW_UPLIFT')

  if (isNewWithdrawFeatureActive && accountNumber !== null) {
    return (
      <Suspense fallback={<Spinner />}>
        <App
          betAccountNumber={accountNumber}
          onWithdrawSuccess={withdrawResponse =>
            UpdateAccountBalance({ balance: withdrawResponse.balance })
          }
        />
      </Suspense>
    )
  }

  return <WithdrawPage />
}
