import type { ToteSelection, FobMatchedSelection, BettingType } from '@mobi/betslip/types'
import type { MeetingInformationDataTransferObject } from '@mobi/api-types'
import type { Acceptor, AcceptorMap } from '@core/Areas/Racing/Hooks/useAcceptors'
import { type PriceSources } from '@core/Data/Betting/selections'
import { toISODate } from '@mobi/utils'
import type { ScratchType } from '@core/Areas/Racing/Types'
import type { Props as TipButtonProps } from '@core/Areas/Racing/Components/BettableTips/TipButton'
import type { Odds } from '@core/Data/Racing'
import type { RawTips } from '@core/Areas/Racing/Components/BettableTips/types'

type FixedOddsAcceptor = SetRequired<Acceptor, 'fixedOdds'>

export type TipPriceAndScratchingsProps = Pick<
  TipButtonProps,
  'isScratched' | 'toteScratchType' | 'isSuspended' | 'price'
> & {
  isFixedOdds: boolean
  priceSource: PriceSources
}

function formatTips(tips: RawTips): number[] {
  if (typeof tips === 'string') {
    return tips.split(',').map(tip => +tip.trim())
  }

  return tips
}

export function getAcceptorsFromTips(tips: RawTips, acceptors: AcceptorMap): Acceptor[] {
  return formatTips(tips)
    .filter(tipNumber => !Number.isNaN(tipNumber) && acceptors.has(tipNumber))
    .map(tipNumber => acceptors.get(tipNumber) as Acceptor)
}

export function makeToteSelection(
  meeting: MeetingInformationDataTransferObject,
  acceptor: Acceptor | number
): ToteSelection {
  return {
    betType: 'Win & Place',
    fixtureDate: toISODate(new Date(meeting.MeetingDate)),
    fixtureId: meeting.MeetingId,
    raceNumber: meeting.SelectedRace.RaceNumber,
    selectionString:
      typeof acceptor === 'number' ? acceptor.toString() : acceptor.number.toString(),
    numberOfCombinations: 1,
    isRovingBanker: false,
    isLegIn: false,
    isAllways: false,
  }
}

function parsePrice(value: string): number {
  const price = parseFloat(value)
  return Number.isNaN(price) ? 0 : price
}

function makeFobMatchedSelection(
  meeting: MeetingInformationDataTransferObject,
  acceptor: FixedOddsAcceptor,
  priceSource: PriceSources
): FobMatchedSelection {
  const winPrice = parsePrice(acceptor.fixedOdds.win)
  const placePrice = parsePrice(acceptor.fixedOdds.place)

  return {
    type: 'fob-matched',
    fixtureDate: toISODate(new Date(meeting.MeetingDate)),
    fixtureId: meeting.MeetingId,
    raceNumber: meeting.SelectedRace.RaceNumber,
    acceptorNumber: acceptor.number,
    priceSource,
    propositionSeq: '',
    winPrice,
    winPriceLastSeen: null,
    placePrice,
    placePriceLastSeen: null,
  }
}

export function makeSelection(
  meeting: MeetingInformationDataTransferObject,
  acceptor: Acceptor,
  isFixedOdds: boolean,
  priceSource: PriceSources
): [BettingType, ToteSelection | FobMatchedSelection] {
  if (isFixedOdds) {
    return [
      'fixed-odds-racing',
      makeFobMatchedSelection(meeting, acceptor as FixedOddsAcceptor, priceSource),
    ]
  }

  return ['tote-racing', makeToteSelection(meeting, acceptor)]
}

function extractPrice(odds: Odds): string {
  return odds.win || odds.place || '0.00'
}

export function getPriceAndScratchings(acceptor: Acceptor): TipPriceAndScratchingsProps {
  if (acceptor.fixedOdds) {
    return {
      isSuspended: acceptor.fixedOdds.isSuspended,
      isScratched: !!acceptor.fixedOdds.isScratched,
      toteScratchType: 'Standard',
      price: extractPrice(acceptor.fixedOdds),
      priceSource: 'api',
      isFixedOdds: true,
    }
  }

  return {
    isScratched: acceptor.isScratched,
    toteScratchType: acceptor.scratchType as ScratchType,
    isSuspended: false,
    price: extractPrice(acceptor.toteOdds),
    priceSource: 'api',
    isFixedOdds: false,
  }
}

export const hasTips = (tips: string, tipster: MaybeDefined<string>): boolean =>
  !['', 'no tips'].includes(tips.toLowerCase()) && !!tipster
