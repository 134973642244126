import React from 'react'
import { useSelector } from 'react-redux'
import { dayjs } from '@mobi/utils/date'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard, LinkInfo } from '@mobi/betslip/Components/Common/BetCard'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import type {
  BetSlipItem,
  FobDetails,
  FobSelection,
  BettingType,
  FobPropositionSelection,
} from '@mobi/betslip/types'
import { ShareBetContext } from '@mobi/betslip/Components/Common/ShareMyBet/context'
import { selectWorkflowStatus } from '@mobi/betslip/Store/Workflow/selectors'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiLegCheckbox'
import { MultiLegTypeToggle } from '@mobi/betslip/Components/Multi/Components/MultiLegTypeToggle'
import type { BetInfoDisplayType } from '../../types'

export const FobBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
  displayMode?: BetInfoDisplayType
}> = ({ item, isMultiItem, displayMode }) => {
  const isShareDisplay = React.useContext(ShareBetContext)

  const workflowStatus = useSelector(selectWorkflowStatus)

  const {
    competitionName,
    eventName,
    handicapName,
    marketName,
    propositionName,
    sportIconCode,
    tournamentName,
    eventStartTime,
    urlPath,
  } = item.selectionDetails as SelectionDetails
  const selection = item.selection as FobSelection

  const selectionName = `${propositionName} ${handicapName ? ` ${handicapName}` : ''}`
  const price = isMultiItem
    ? item.multiLegBetType === 'W'
      ? selection.winPrice
      : selection.placePrice
    : item.isEachWayAvailable
      ? selection.winPrice
      : undefined

  const marketNamePrefix = !item.shouldAllowPlaceInvestment
    ? marketName
    : getMarketTitle(
        isMultiItem ? item.multiLegBetType : getBetTypeOverrideFromInvestment(item.investment)
      )

  const shouldDisplayCountdown =
    !isMultiItem || (isMultiItem && dayjs(eventStartTime).isSameOrBefore(dayjs().add(5, 'minutes')))

  const linkInfo: LinkInfo | undefined =
    !isMultiItem && workflowStatus === 'ready' && urlPath ? { pathname: urlPath } : undefined

  const isFixedDisplay = isMultiItem ? '' : getShouldFixedDisplay(selection, item.bettingType)

  return (
    <>
      {(displayMode === 'full' || displayMode === 'event') && (
        <BetCard.EventDetails
          linkInfoPrimary={linkInfo}
          heading={tournamentName ? `${competitionName} ${tournamentName}` : `${competitionName}`}
          subHeading={eventName && eventName !== marketName ? eventName : ''}
          statusComponent={
            <>
              {isShareDisplay ? (
                <StatusBadge color='gray' badgeSize='S'>
                  {dayjs(eventStartTime).format('D MMM | H:mm')}
                </StatusBadge>
              ) : (
                <>
                  {shouldDisplayCountdown && (
                    <CountdownBadge advertisedStartTime={new Date(eventStartTime)} />
                  )}
                </>
              )}

              {isMultiItem && !item.isEachWayAvailable && (
                <MultiLegTypeToggle id={item.id} multiLegBetType={item.multiLegBetType} />
              )}
            </>
          }
          iconComponent={<Icon size='2rem' type={sportIconCode || 'tab'} />}
        />
      )}

      {(displayMode === 'full' || displayMode === 'selection') && (
        <BetCard.SelectionInfo
          key={`${item.id}${isMultiItem ? item.multiLegBetType : ''}`}
          heading={selectionName}
          subHeading={`${marketNamePrefix || ''} ${isFixedDisplay || ''}`.trim()}
          priceWin={price}
          pricePlace={!isMultiItem && item.isEachWay ? selection.placePrice : undefined}
          rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
          linkInfo={linkInfo}
        />
      )}
    </>
  )
}

// =============
// Local Helpers
// =============

function getMarketTitle(betTypeOverride?: BetSlipItem['multiLegBetType']) {
  if (betTypeOverride === 'W') {
    return 'Win'
  }
  if (betTypeOverride === 'P') {
    return 'Place'
  }
  return 'Win & Place '
}

function getShouldFixedDisplay(selection: FobPropositionSelection, bettingType: BettingType) {
  if (bettingType === 'fixed-odds-racing' && !selection.marketCode) {
    return ' - Fixed'
  }
  return ''
}

function getBetTypeOverrideFromInvestment(
  investment: BetSlipItem['investment']
): BetSlipItem['multiLegBetType'] {
  if (investment.win.value && !investment.place.value) return 'W'
  if (!investment.win.value && investment.place.value) return 'P'
  return undefined
}

// =====
// Types
// =====

type SelectionDetails = Pick<
  FobDetails,
  | 'eventName'
  | 'competitionName'
  | 'sportIconCode'
  | 'tournamentName'
  | 'propositionName'
  | 'marketName'
  | 'handicapName'
  | 'eventStartTime'
  | 'urlPath'
>
