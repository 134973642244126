import React from 'react'
import { OdometerStyled, DigitStyled, DigitContainerStyled } from './Odometer.styles'

/* ****************************************** */
/*

  Usage:
    <Odometer start={12.50} end={26.51} duration={700} />

  Options:
    start: start value
    end: end value
    duration: animation duration

  Future options:
    themes: foreground/background colors
    animation-timing: [linear, ease-in, ease-out, custom]

  Made with ♥ by Racing and Waging Western Australia

*/
/* ****************************************** */

interface NumStringProps {
  start: number
  end: number
  turnCount: number
  index: number
}

const NumString = ({ start, end, turnCount, index }: NumStringProps) => {
  // Generates the number string for rotating number
  // Using maxTurnCount to limit length of string generated
  // When the string is too long the number rotates so fast that it is perceived to rotate backwards

  const maxTurnCount = 10 + index * 2
  let numberStr = ''
  let countRunner = start
  const turns = turnCount > maxTurnCount ? maxTurnCount : turnCount
  const tickAddition = turns !== 0 ? turnCount / turns : 0

  // returns start if there are no turns
  if (turns !== 0) {
    numberStr = start + ' '
    for (let i = 0; i < turns - 1; i++) {
      countRunner += tickAddition
      numberStr += (Math.floor(countRunner) % 10) + ' '
    }
    numberStr += end + ''
  } else {
    numberStr = start + ''
  }

  return numberStr
}

interface GetTurnsProps {
  startDigitsMatched: string[]
  difference: number
}

const GetTurns = ({ startDigitsMatched, difference }: GetTurnsProps) => {
  // Calculates how many turns each digit needs to make to move from start to end number

  const turnsArr: number[] = []
  const newstartDigitsMatched = [...startDigitsMatched]
  const startDigitMatchedCount = startDigitsMatched.length

  startDigitsMatched.map((_digit, index) => {
    newstartDigitsMatched.shift()
    if (newstartDigitsMatched.length === 0) {
      newstartDigitsMatched.push('0')
    }
    // merge array into string then convert to number
    let startJoined = parseInt(newstartDigitsMatched.join(''), 10)

    turnsArr.push(
      Math.floor(
        startJoined / Math.pow(10, startDigitMatchedCount - 1 - index) +
          difference / Math.pow(10, startDigitMatchedCount - 1 - index)
      )
    )

    const startJoinedString = startJoined.toString().substr(1)
    startJoined = startJoinedString !== '' ? parseInt(startJoinedString, 10) : 0
    return null
  })

  return turnsArr
}

interface OdometerProps {
  start: number
  end: number
  duration: number
  startAnim?: boolean
  prefix?: string
  width?: string
  height?: string
}

export const Odometer = ({
  start,
  end,
  duration = 700,
  startAnim = true,
  prefix = '',
  height = '1.7rem',
  width = '0.8rem',
}: OdometerProps) => {
  // Math.floor prevents weird numbers from appearing
  // eg multiplying 17.51 by 100 produces 1751.0000000000002
  const startCopy: number = Math.round(start * 100)
  const endCopy: number = Math.round(end * 100)
  const startDigits: string[] = startCopy.toString().split('')
  const endDigits: string[] = endCopy.toString().split('')
  const difference: number = endCopy - startCopy
  const startCount: number = startDigits.length
  const endCount: number = endDigits.length
  const countDifference: number = endCount - startCount
  let digitCount: number = Math.max(startCount, endCount)
  let startDigitsMatched: string[] = [...startDigits]
  const endDigitsMatched: string[] = [...endDigits]

  if (countDifference > 0) {
    startDigitsMatched = Array(Math.abs(countDifference)).fill(0).concat(startDigits)
  }
  if (countDifference < 0) {
    // remove digits from the start of start number
    startDigitsMatched.splice(0, -countDifference)
    digitCount = endCount
  }

  const turnsArr: number[] = GetTurns({ startDigitsMatched, difference })

  const rows: JSX.Element[] = []

  // Prefix
  if (prefix !== '') {
    rows.push(
      <DigitStyled key='prefix' width={width} height={height}>
        <DigitContainerStyled startAnim={startAnim} height={height}>
          {prefix}
        </DigitContainerStyled>
      </DigitStyled>
    )
  }

  for (let index = 0; index < digitCount; index++) {
    rows.push(
      <DigitStyled key={index} width={width} height={height}>
        <DigitContainerStyled duration={duration} startAnim={startAnim} height={height}>
          {NumString({
            start: parseInt(startDigitsMatched[index], 10),
            end: parseInt(endDigitsMatched[index], 10),
            turnCount: turnsArr[index],
            index,
          })}
        </DigitContainerStyled>
      </DigitStyled>
    )

    const indexToDigitCount = digitCount - index
    // Decimal point added 2 digits from the end
    // 623452.53 => 62345253 => digitCount = 8, so decimal point needs to be after digit at index 5: 6 2 3 4 5 2 . 5 3
    if (indexToDigitCount === 3) {
      rows.push(
        <DigitStyled isDecimalSeparator={true} key='decimal' width={width} height={height}>
          <DigitContainerStyled startAnim={startAnim} height={height}>
            .
          </DigitContainerStyled>
        </DigitStyled>
      )
    }

    // commas supported for millions and thousands separation
    // 98539623452.53 => 9853962345253 => digitCount = 13, so commas after digits at indexes 1, 4, 7: 9 8 , 5 3 9 , 6 2 3 , 4 5 2 . 5 3
    else if (indexToDigitCount % 3 === 0) {
      rows.push(
        <DigitStyled
          isDecimalSeparator={true}
          key={`thousands-${index}`}
          width={width}
          height={height}
        >
          <DigitContainerStyled startAnim={startAnim} height={height}>
            ,
          </DigitContainerStyled>
        </DigitStyled>
      )
    }
  }

  // Fix - Adds an extra empty digit after the last digit to prevent last digit from not rotating when end digit count > start digit count
  // This problem is caused by css transitions not working when react dynamically adds an element to the DOM
  // Because the extra div is added to the page prior to the value changing, transitions work correctly
  // -- Cons ---
  // only one extra digit is applied, if end digit count is greater than start digit count by more than 1, this wont work eg.  start: 8.00, end: 120.00
  rows.push(
    <DigitStyled isExtraDigit={true} key={digitCount} width={width} height={height}>
      <DigitContainerStyled startAnim={startAnim} height={height}>
        &nbsp;
      </DigitContainerStyled>
    </DigitStyled>
  )

  return <OdometerStyled>{rows}</OdometerStyled>
}
