import React from 'react'
import type { Acceptor, BettingType, Race, Selection } from '@mobi/betslip/types'
import { isRaceDetails } from '@mobi/betslip/helpers/typeGuards'
import { observeImmutable } from '@core/Components/HOCs'
import { ErrorMessage } from '@core/Components/Messages'
import { state$ as quickbetState$, QuickbetState } from '@core/Areas/Quickbet/driver'
import { RaceInfo } from '@core/Areas/Quickbet/Components/RaceInfo/RaceInfo'
import { SelectionDisplay } from '@core/Areas/Quickbet/Components/Selection/Selection'
import { NotificationType } from '@core/Areas/Quickbet/Components/Notifications/NotificationTypes'

type RacingHeaderProps = {
  races: Race[]
  acceptors: Acceptor[]
  isRaceClosed?: boolean
} & Required<Pick<QuickbetState, 'selection' | 'bettingType'>>

export function RacingHeaderComponent({
  races,
  acceptors,
  selection,
  bettingType,
  isRaceClosed,
}: RacingHeaderProps): JSX.Element {
  // we only ever show information for one race. for win/place/trifecta, etc. that is the _only_ race
  // for double/quaddie/all-up, we show the first race, as that is when the pool will close
  const race = races[0]

  return (
    <>
      {!!race && (
        <div data-testid='RaceInfo'>
          <RaceInfo
            key={race.key}
            race={race}
            bettingType={bettingType as BettingType}
            selection={selection as Selection}
            isRaceClosed={isRaceClosed}
          />
        </div>
      )}

      <SelectionDisplay
        selection={selection}
        races={races}
        acceptors={acceptors}
        displayPill={true}
      />
    </>
  )
}

export const RacingHeader: React.ComponentClass = observeImmutable(quickbetState$, ({ record }) => {
  const { selection, selectionDetails, bettingType, notificationType } =
    record.toJS() as QuickbetState
  if (!isRaceDetails(selectionDetails)) {
    return !PRODUCTION ? (
      <ErrorMessage>
        Selection details provided to RacingHeader was not compatible with RaceDetails
      </ErrorMessage>
    ) : null
  }
  return (
    <RacingHeaderComponent
      races={selectionDetails.races}
      acceptors={selectionDetails.acceptors}
      selection={selection}
      bettingType={bettingType}
      isRaceClosed={notificationType === NotificationType.RaceClosed}
    />
  )
})
RacingHeader.displayName = 'RacingHeader'
