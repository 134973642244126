import { BetSpecialOffer } from '../Model/BetSpecialOffer'

export type EnquirySelectionDto = object

interface RacingEnquirySelectionDto extends EnquirySelectionDto {
  PropositionCode: string
  FixtureDateTime: string
  FixtureId: string
  RaceNumber: number
  StarterNumber: number
}

interface SportsEnquirySelectionExDto extends EnquirySelectionDto {
  PropositionSeq: number
  VariantSeq: number
}

export interface FobBetPreEnquiryRequestDto {
  SelectedSpecial: BetSpecialOffer
  Racing: RacingEnquirySelectionDto
  SportsEx: SportsEnquirySelectionExDto
}

/* type guards */
export function isRacingEnquirySelectionDto(
  sel: EnquirySelectionDto
): sel is RacingEnquirySelectionDto {
  const r = sel as RacingEnquirySelectionDto
  return r.FixtureId !== undefined && r.FixtureDateTime !== undefined && r.RaceNumber !== undefined
}

export function isSportsEnquirySelectionExDto(
  sel: EnquirySelectionDto
): sel is SportsEnquirySelectionExDto {
  const s = sel as SportsEnquirySelectionExDto
  return s.PropositionSeq !== undefined
}
