import type { PaymentMethodsAll } from '@mobi/component-library/Deposit/types'
import { paymentMethodTypeNameMap } from '../Areas/Deposit/Utils/schema'
import type { DepositAnalyticsKeys } from '../Areas/Deposit/typings/types'
import type { WithdrawAnalyticsKeys } from '../Areas/Withdraw/typings/types'
import type { SignUpAnalyticsKeys } from '../Areas/SignUp/typings/types'

type AnalyticsEventData = {
  accountNumber: number
  paymentMethod?: PaymentMethodsAll
  [key: string]: unknown
}

export type TrackAnalytics = (
  eventKey: DepositAnalyticsKeys | WithdrawAnalyticsKeys | SignUpAnalyticsKeys,
  data: AnalyticsEventData
) => void

export const track: TrackAnalytics = (eventKey, data) => {
  const { accountNumber, paymentMethod, ...other } = data
  window.dataLayer?.push({
    event: eventKey,
    accountNumber,
    ...(paymentMethod && { depositMethod: paymentMethodTypeNameMap[paymentMethod] }),
    ...other,
  })
}
