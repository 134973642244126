import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { colors, font, layering, shadow, spacing } from '@mobi/component-library/Theme/Common'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import { toTitleCase } from '@mobi/utils/string'
import { RaceInfoWrapperWithTextExpand } from '@core/Areas/Racing/Components/Shared/RaceInfoWrapperWithTextExpand'
import { DOCKED_EXPAND_ANIMATION_MS } from '@core/Areas/SkyVideoPlayer/Components/DockedPlayer/constants'
import { useHeaderPinnedState } from '@core/Areas/SkyVideoPlayer/Components/DockedPlayer/Hooks/useHeaderPinnedState'
import type {
  RaceListEntry,
  RaceListResponse,
  RaceStatus,
} from '@core/Areas/Racing/Hooks/useRaceList/types'
import { SkyVideoButton, SkyVideoButtonRace } from './Components/SkyVideoButton'
import { RaceNotificationButton } from '@core/Areas/Racing/Components/RaceNotificationButton'
import { getSimpleRaceStatusText, meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { useRacePageData } from '@core/Areas/Racing/Hooks'
import { ResultsPageDataTransferObject } from '@mobi/api-types'
import dayjs from 'dayjs'
import { useFeature } from '@core/Utils/hooks'

const enum LocalConstants {
  PinnedClassName = 'js-race-info--pinned',
  StatusBadgeClassName = 'js-race-info__status-badge',
}

export const RaceInfoHeader: React.FC<
  { currentRace: RaceListEntry; isRacingWA?: boolean | null } & Pick<
    RaceListResponse,
    'meetingId' | 'meetingDate' | 'courseName'
  >
> = ({ currentRace, meetingDate, meetingId, courseName, isRacingWA }) => {
  const { isHeaderPinned, top, wrapperElRef } = useHeaderPinnedState()

  const isRaceOpenForBetting = currentRace.raceStatus === 'Open'
  const raceStartTime = new Date(currentRace.advertisedStartTime)
  const raceStatusText = useMemo(
    () => getSimpleRaceStatusText(currentRace.raceStatus),
    [currentRace.raceStatus]
  )

  const raceStatusesWithPlacings: RaceStatus[] = ['Interim', 'Results', 'Released']

  const theDate = dayjs(meetingDate).format('YYYY-MM-DD')
  const { race } = useRacePageData({
    meetingDate: theDate,
    meetingId,
    raceNumber: currentRace.raceNumber,
  })

  const hasRaceReplayImprovements = useFeature('RACE_REPLAY_IMPROVEMENTS')
  const videoKey = (race as ResultsPageDataTransferObject)?.RaceReplay?.VideoKey

  const raceCode = meetingIdToContestType(meetingId) ?? 'races'

  const raceProp: SkyVideoButtonRace = {
    ...(currentRace.raceKey && {
      raceReplay: {
        raceNumber: currentRace.raceNumber,
        trackName: courseName,
        raceReplayKey: videoKey,
        raceCode,
        meetingDate,
      },
    }),
    advertisedStartTime: currentRace.advertisedStartTime,
    raceStatus: currentRace.raceStatus,
    skyTvChannelId: currentRace.skyTvChannelId ?? '',
  }

  return (
    <WrapperStyled
      ref={wrapperElRef}
      className={isHeaderPinned ? LocalConstants.PinnedClassName : ''}
      style={{ top }}
    >
      <RaceInfoWrapperWithTextExpand
        titlePrimary={`R${currentRace.raceNumber} ${toTitleCase(courseName)}`}
        titleSecondary={toTitleCase(currentRace.raceName)}
        shouldSwitchTitles={isHeaderPinned}
        subTitle={
          <>
            {currentRace.distance != 0 && `${currentRace.distance}m · `}
            {currentRace.raceType && `${currentRace.raceType}`}
          </>
        }
      >
        <>
          {currentRace.raceKey && (
            <RaceNotificationButton
              key={currentRace.raceKey}
              meetingDate={meetingDate}
              meetingId={meetingId}
              meetingName={courseName}
              raceNumber={currentRace.raceNumber}
              raceTime={raceStartTime}
              raceStatus={currentRace.raceStatus}
              isHeaderPinned={isHeaderPinned}
            />
          )}

          {isRaceOpenForBetting ? (
            <CountdownBadge advertisedStartTime={raceStartTime} badgeSize='L' />
          ) : (
            <StatusBadge color='gray' badgeSize='L'>
              <span className={LocalConstants.StatusBadgeClassName}>
                {raceStatusesWithPlacings.includes(currentRace.raceStatus) ? (
                  <>
                    <span>{raceStatusText}</span>
                    <span>{currentRace.shortResultString}</span>
                  </>
                ) : (
                  raceStatusText
                )}
              </span>
            </StatusBadge>
          )}

          {((hasRaceReplayImprovements && videoKey != null) ||
            (isRacingWA !== null && currentRace.skyTvChannelId != null)) && (
            <SkyVideoButton
              race={raceProp}
              isHeaderPinned={isHeaderPinned}
              isRacingWA={isRacingWA ?? false}
              openLocation='race-card'
            />
          )}
        </>
      </RaceInfoWrapperWithTextExpand>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  position: 'sticky',
  top: '-1px',
  zIndex: layering.stickyPageElements,
  padding: `${spacing.sm} 0`,
  transition: `top ${DOCKED_EXPAND_ANIMATION_MS}ms ease`,

  background: colors.white,
  color: colors.black,
  fontFamily: font.family.primary,

  // Performant drop shadow w/ fade animation
  '&::after': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    bottom: 0,
    width: '100%',
    boxShadow: shadow.md,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.4s ease',
  },

  [`.${LocalConstants.StatusBadgeClassName}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '5.2rem',

    '> span:first-of-type': {
      fontSize: font.size.xs.fontSize,
      letterSpacing: font.size.xs.letterSpacing,
      lineHeight: font.size.xs.lineHeight,

      color: colors.neutral[800],
    },
  },

  // Pinned to show race # + meeting, hide race name
  [`&.${LocalConstants.PinnedClassName}`]: { '&::after': { opacity: 1 } },
})
