import React, { type PropsWithChildren } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button } from '@mobi/component-library/Common/V2/Buttons/Button'
import { Icon as IconLegacy } from '@mobi/component-library/Common/Icon/Icon'
import { Icon } from '@mobi/component-library/Common/V2/Icon/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'
import { trackSideMenuEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { state$ as routeState$ } from '@core/State/Navigation/driver'
import { AppRoutes } from '@core/AppRoutes'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { trackSignUpClick } from '@core/Areas/Account/SignUp/analytics'
import { AppHeaderButtonStyled } from './Buttons.styles'
import { toggleIsHamburgerMenuOpen } from '@core/Areas/AppHeader/Store'

export const HamburgerButton: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <AppHeaderButtonStyled
      style={{ justifyContent: 'flex-end' }}
      data-testid='header-menu-btn'
      role='button'
      aria-label='Open menu'
      onClick={() => {
        dispatch(toggleIsHamburgerMenuOpen(true))
        trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-open-button' })
      }}
    >
      <Icon name='LineMenu01' size='2rem' color={colors.white} />
    </AppHeaderButtonStyled>
  )
}

export const BackButton: React.FC<PropsWithChildren<{ onClick?: VoidFunction }>> = ({
  onClick,
  children,
}) => {
  const handleBackButtonClick = () => {
    routeState$.take(1).subscribe(routeState => {
      window.location.href = `/${routeState.get('backUrl')}`
    })
  }

  return (
    <AppHeaderButtonStyled
      data-testid='header-back-btn'
      onClick={() => {
        onClick?.()
        handleBackButtonClick()
      }}
    >
      {children ?? <Icon name='SolidChevronLeft' size='2rem' color={colors.white} />}
    </AppHeaderButtonStyled>
  )
}

export const AppHeaderLogo: React.FC<{ onClick?: VoidFunction }> = ({ onClick }) => {
  return (
    <LogoButtonStyled id='home-page' to='/' onClick={() => onClick?.()} aria-label='TABtouch Logo'>
      <LogoSVGStyled>
        <SvgLazy width='100%' color={colors.white} name='TABtouchLogo' />
        <IconLegacy type='tabtouchLogoCompact' size='3rem' />
      </LogoSVGStyled>
    </LogoButtonStyled>
  )
}

export const SignUpButton = () => {
  const history = useHistory()
  return (
    <Button
      color='secondary_color'
      data-testid='header-join-btn'
      size='sm'
      onClick={() => {
        history.push(AppRoutes.SignUp)
        trackSignUpClick('header')
      }}
    >
      Sign up
    </Button>
  )
}

export const LoginButton = () => {
  return (
    <Button data-testid='header-login-btn' color='primary' size='sm' onClick={() => showLogin()}>
      Log in
    </Button>
  )
}

// ======
// Styles
// ======

const LogoButtonStyled = styled(Link)({
  display: 'flex',
  color: colors.white,
  alignItems: 'center',
  justifyContent: 'flex-start',
  containerType: 'inline-size',
  minWidth: '3rem',
  maxWidth: '8rem',
  width: '100%',
})

const LogoSVGStyled = styled.span({
  '> svg:nth-of-type(1)': { display: 'none' },
  '> svg:nth-of-type(2)': { display: 'block' },

  '@container (min-width: 7rem)': {
    '> svg:nth-of-type(1)': { display: 'block' },
    '> svg:nth-of-type(2)': { display: 'none' },
  },
})
