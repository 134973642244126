import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ICheckBoxPipelineProcessor } from './ICheckBoxPipelineProcessor'
import { CheckBoxPipelineProcessor } from './CheckBoxPipelineProcessor'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import { ISelectionContext } from './ISelectionContext'
import CheckBoxSelectionContext from './CheckBoxSelectionContext'
import Guard from '../../../../AppUtils/Framework/Guard'
import SameAs from '../../../Model/SameAs'

export default class RegularCheckBoxPipelineProcessor extends CheckBoxPipelineProcessor {
  process(
    toggledStarter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    selectionContext: ISelectionContext,
    count: number
  ): IObservableStarter[] {
    if (bettingContext.isBoxed() || bettingContext.isLegIn() || bettingContext.rovingBanker())
      return (this.next as ICheckBoxPipelineProcessor).process(
        toggledStarter,
        starters,
        bettingContext,
        selectionContext,
        count
      )

    Guard.notNull(toggledStarter)
    Guard.notNull(starters)
    Guard.notNull(bettingContext)

    const results: IObservableStarter[] = []
    RegularCheckBoxPipelineProcessor.clearFieldIndexes(bettingContext, toggledStarter)

    if (bettingContext.sameAsSelectedAtAnyIndex()) {
      const dependenciesOnSelectedIndex = this.sameAsColumnsDependendentOnSelectedColumn(
        bettingContext,
        selectionContext as CheckBoxSelectionContext
      )

      if (toggledStarter && toggledStarter.selection) {
        this.turnOffSameAsButtonForIndex(
          toggledStarter.selection() as CheckBoxSelection,
          selectionContext as CheckBoxSelectionContext,
          bettingContext
        )
      }

      this.updateSameAsColumnsDependentOnSelectedIndex(
        bettingContext,
        selectionContext as CheckBoxSelectionContext,
        toggledStarter,
        dependenciesOnSelectedIndex
      )
    }

    this.maintainExistingSetOfStartersWithCurrent(toggledStarter, starters, results)
    return results
  }

  private static clearFieldIndexes(
    bettingContext: BettingInformation,
    toggledStarter: IObservableStarter
  ) {
    if (!toggledStarter || !toggledStarter.selection) return

    const supplied = toggledStarter.selection() as CheckBoxSelection

    for (let index = 0, count = supplied.count(); index < count; index++) {
      const checked = supplied.selectedAt(index)
      if (
        !checked &&
        !bettingContext.selectedBetType().isQuinella() &&
        !bettingContext.selectedBetType().multiBet()
      ) {
        bettingContext.turnOffFieldAt(index)
      } else if (!checked && bettingContext.selectedBetType().multiBet()) {
        bettingContext.turnOffFieldAt(supplied.raceNumber.leg() - 1)
      } else if (!checked && bettingContext.selectedBetType().isQuinella()) {
        bettingContext.turnOffFieldAt(0)
      }
    }
  }

  private updateSameAsColumnsDependentOnSelectedIndex(
    bettingContext: BettingInformation,
    selectionContext: CheckBoxSelectionContext,
    toggledStarter: IObservableStarter,
    dependencies: SameAs[]
  ) {
    if (!bettingContext.sameAsSelectedAtAnyIndex()) return

    const checkBoxSelection = toggledStarter.selection() as CheckBoxSelection
    const selected = checkBoxSelection.selectedAt(selectionContext.selectedColumn - 1)

    if (!selected) {
      this.cascadeDeselectionToOtherColumns(
        bettingContext,
        selectionContext,
        toggledStarter,
        dependencies
      )
    } else {
      this.cascadeSelectionToOtherColumns(
        bettingContext,
        selectionContext,
        toggledStarter,
        dependencies
      )
    }
  }

  private cascadeDeselectionToOtherColumns(
    bettingContext: BettingInformation,
    selectionContext: CheckBoxSelectionContext,
    toggledStarter: IObservableStarter,
    dependencies: SameAs[]
  ) {
    const checkBoxSelection = toggledStarter.selection() as CheckBoxSelection

    if (dependencies.length === 0) return

    for (const dependency of dependencies) {
      const dependenciesAttachedToDependency = this.sameAsColumnsDependendentOn(
        bettingContext,
        dependency
      )

      if (dependenciesAttachedToDependency.length !== 0) {
        if (
          this.otherDependencyWithCheckBoxSelectionForAMatchingColumn(
            dependency,
            dependenciesAttachedToDependency,
            checkBoxSelection
          )
        ) {
          continue
        }

        checkBoxSelection.clearAt(dependency.column)
        this.cascadeDeselectionToOtherColumns(
          bettingContext,
          selectionContext,
          toggledStarter,
          dependenciesAttachedToDependency
        )
      }

      if (
        !this.otherSameAsColumnSelectedWithSelection(bettingContext, dependency, checkBoxSelection)
      ) {
        checkBoxSelection.clearAt(dependency.column)
      }
    }
  }

  private cascadeSelectionToOtherColumns(
    bettingContext: BettingInformation,
    selectionContext: CheckBoxSelectionContext,
    toggledStarter: IObservableStarter,
    deps: SameAs[]
  ) {
    const checkBoxSelection = toggledStarter.selection() as CheckBoxSelection

    if (deps.length === 0) return

    for (const dep of deps) {
      checkBoxSelection.enableAt(dep.column)
      this.cascadeSelectionToOtherColumns(
        bettingContext,
        selectionContext,
        toggledStarter,
        this.sameAsColumnsDependendentOn(bettingContext, dep)
      )
    }
  }

  private otherSameAsColumnSelectedWithSelection(
    bettingContext: BettingInformation,
    dep: SameAs,
    checkboxes: CheckBoxSelection
  ): boolean {
    const sameAs = bettingContext.sameAs
      .flat()
      .filter(sameAs => sameAs.column === dep.column)
      .filter(sameAs => sameAs.selected())

    for (const value of sameAs) {
      if (checkboxes.selectedAt(value.row - 1)) {
        return true
      }
    }

    return false
  }

  private otherDependencyWithCheckBoxSelectionForAMatchingColumn(
    dependency: SameAs,
    otherDependencies: SameAs[],
    checkBoxSelection: CheckBoxSelection
  ): boolean {
    for (const otherdep of otherDependencies.filter(x => x.column === dependency.column)) {
      if (checkBoxSelection.selectedAt(otherdep.row - 1)) {
        return true
      }
    }
    return false
  }

  private sameAsColumnsDependendentOnSelectedColumn(
    bettingContext: BettingInformation,
    selectionContext: CheckBoxSelectionContext
  ): SameAs[] {
    return bettingContext.sameAs
      .flat()
      .filter(sameAs => sameAs.row === selectionContext.selectedColumn)
      .filter(sameAs => sameAs.selected())
  }

  private sameAsColumnsDependendentOn(
    bettingContext: BettingInformation,
    dependency: SameAs
  ): SameAs[] {
    return bettingContext.sameAs
      .flat()
      .filter(sameAs => sameAs.row === dependency.column)
      .filter(sameAs => sameAs.selected())
  }

  private turnOffSameAsButtonForIndex(
    currentCheckBoxes: CheckBoxSelection,
    selectionContext: CheckBoxSelectionContext,
    context: BettingInformation
  ) {
    if (currentCheckBoxes.selectedAt(selectionContext.selectedColumn - 1)) {
      return
    }

    context.sameAs
      .flat()
      .filter(sameAs => sameAs.column === selectionContext.selectedColumn)
      .filter(sameAs => sameAs.selected())
      .filter(sameAs => currentCheckBoxes.selectedAt(sameAs.row - 1))
      .forEach(sameAs => {
        context.turnOffSameAs(sameAs.row, selectionContext.selectedColumn)
      })
  }
}
