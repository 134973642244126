import * as ko from 'knockout'

ko.bindingHandlers['class'] = {
  update: function (element, valueAccessor) {
    if (element['__ko__previousClassValue__' as keyof typeof element]) {
      ko.utils.toggleDomNodeCssClass(
        element,
        element['__ko__previousClassValue__' as keyof typeof element],
        false
      )
    }
    const value = ko.utils.unwrapObservable(valueAccessor())
    ko.utils.toggleDomNodeCssClass(element, value, true)
    // @ts-expect-error Legacy - typing issue
    element['__ko__previousClassValue__'] = value
  },
}
