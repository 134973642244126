import { UseQueryOptions } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import {
  fetchKambiPendingTicketCount,
  fetchRacePendingTicketsCount,
  type PendingTicketsCountResponse,
  type KambiPendingTicketsCountResponse,
} from './api'

const enum LocalConstants {
  PollingTimeMs = 1_000 * 60 * 15,
}

const pollingQueryOptions: UseQueryOptions = {
  refetchOnWindowFocus: true,
  refetchOnMount: true,
  cacheTime: LocalConstants.PollingTimeMs,
  staleTime: LocalConstants.PollingTimeMs,
  refetchInterval: LocalConstants.PollingTimeMs,
} as const

// ======
// Racing
// ======

export const racingPendingTicketsQuery: UseQueryOptions<PendingTicketsCountResponse> = {
  queryKey: queryKeys.pendingTicketsCount,
  queryFn: fetchRacePendingTicketsCount,
} as const

export const racingPendingTicketsPollingQuery: UseQueryOptions<PendingTicketsCountResponse> = {
  ...racingPendingTicketsQuery,
  ...(pollingQueryOptions as UseQueryOptions<PendingTicketsCountResponse>),
}

// =====
// Kambi
// =====

export const kambiPendingTicketsQuery: UseQueryOptions<KambiPendingTicketsCountResponse> = {
  queryKey: queryKeys.kambiPendingTicketsCount,
  queryFn: fetchKambiPendingTicketCount,
} as const

export const kambiPendingTicketsPollingQuery: UseQueryOptions<KambiPendingTicketsCountResponse> = {
  ...kambiPendingTicketsQuery,
  ...(pollingQueryOptions as UseQueryOptions<KambiPendingTicketsCountResponse>),
} as const
