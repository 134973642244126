import { type MutableRefObject } from 'react'
import { type Client, applePay } from 'braintree-web'
import type { DepositRequest } from '@mobi/api-types'
import { isBraintreeError } from '../../Utils'
import type { DepositRequestWithoutDeviceData } from '../../Hooks'

type InitParams = {
  braintreeClient: MutableRefObject<Client | undefined>
  depositAmount: number
  transactionId: string
  onSuccess: (data: DepositRequestWithoutDeviceData<DepositRequest>) => Promise<void>
  onCancel?: VoidFunction
  onError?: (error: string | Error) => void
}

type ApplePayPaymentAuthorizedEvent = {
  payment: {
    token: unknown
  }
}

export async function tokenize({
  braintreeClient,
  depositAmount,
  transactionId,
  onSuccess,
  onCancel,
  onError,
}: InitParams): Promise<void> {
  const { current: client } = braintreeClient

  if (!client) {
    return Promise.reject(new Error('Attempted to initialize Apple Pay without a Braintree client'))
  }

  const applePayClient = await applePay.create({ client })

  const request = applePayClient.createPaymentRequest({
    merchantCapabilities: ['supports3DS', 'supportsDebit'],
    total: {
      label: 'TABtouch',
      amount: depositAmount.toString(),
    },
  })

  const session = new ApplePaySession(3, request)

  session.onvalidatemerchant = async (event: { validationURL: string }) => {
    try {
      const merchantSession = await applePayClient.performValidation({
        displayName: 'TABtouch',
        validationURL: event.validationURL,
      })
      session.completeMerchantValidation(merchantSession)
    } catch (error) {
      if (isBraintreeError(error)) {
        onError?.('Failed to deposit with Apple Pay')
      }
      throw error
    }
  }

  session.oncancel = () => {
    onCancel?.()
  }

  session.onpaymentauthorized = async (event: ApplePayPaymentAuthorizedEvent) => {
    try {
      const { nonce: paymentMethodNonce } = await applePayClient.tokenize({
        token: event.payment.token,
      })

      await onSuccess({
        amount: depositAmount,
        paymentMethodNonce,
        depositSource: 'ApplePay',
        transactionId,
      })

      session.completePayment(ApplePaySession.STATUS_SUCCESS)
    } catch {
      onError?.('Failed to authorize deposit with Apple Pay')
      session.completeMerchantValidation(ApplePaySession.STATUS_FAILURE)
    }
  }

  session.begin()
}
