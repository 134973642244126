import type { CSSProperties } from 'react'
import {
  type RadioThemeSettings,
  defaultTheme as RadioDefaultTheme,
} from '@mobi/component-library/Common/Input'
import {
  PopupThemeSettings,
  defaultTheme as PopupDefaultTheme,
} from '@core/Components/Popup/Popup.styles'

interface ContainerThemeSettings {
  text: {
    size: CSSProperties['fontSize']
  }
  padding: CSSProperties['padding']
  margin: CSSProperties['margin']
  width: CSSProperties['width']
}

interface InputThemeSettings {
  fontSize: CSSProperties['fontSize']
  padding: CSSProperties['padding']
}

interface ButtonThemeSettings {
  fontSize: CSSProperties['fontSize']
  height: CSSProperties['height']
}

export enum DepositLimitThemes {
  default = 'default',
  signUp = 'signUp',
  desktopSignup = 'desktopSignup',
}

interface DepositLimitComponentThemeSettings {
  container: ContainerThemeSettings
  input: InputThemeSettings
  button: ButtonThemeSettings
}

export type DepositLimitComponentTheme = DepositLimitComponentThemeSettings &
  PopupThemeSettings &
  RadioThemeSettings

// MOBI & DESKTOP default theme (when going through 'Account Preferences')
const defaultTheme: DepositLimitComponentTheme = {
  container: {
    text: {
      size: '1.2rem',
    },
    padding: '0 1rem',
    margin: '1rem 0 2rem',
    width: '100%',
  },
  input: {
    fontSize: '2.4rem',
    padding: '1rem',
  },
  button: {
    fontSize: '1.2rem',
    height: '4rem',
  },
  ...RadioDefaultTheme,
  ...PopupDefaultTheme,
}

// MOBI 'sign up' theme
const signUpTheme: DepositLimitComponentTheme = {
  container: {
    text: {
      size: '1em',
    },
    padding: 0,
    margin: defaultTheme.container.margin,
    width: defaultTheme.container.width,
  },
  radio: {
    width: defaultTheme.radio.width,
    get height() {
      return signUpTheme.radio.width
    },
    paddingLeft: defaultTheme.radio.paddingLeft,
  },
  input: {
    fontSize: defaultTheme.input.fontSize,
    padding: defaultTheme.input.padding,
  },
  button: {
    fontSize: defaultTheme.button.fontSize,
    height: defaultTheme.button.height,
  },
  popup: {
    transform: defaultTheme.popup.transform,
  },
}

// DESKTOP 'sign up' theme
const desktopSignUpTheme: DepositLimitComponentTheme = {
  container: {
    text: {
      size: '1em',
    },
    padding: '',
    margin: '1rem auto',
    width: '50%',
  },
  radio: {
    height: '1.6667em',
    width: '1.6667em',
    get paddingLeft() {
      return `calc(${desktopSignUpTheme.radio.width} * 1.5)`
    },
  },
  input: {
    fontSize: '1.8em',
    padding: '0.5em',
  },
  button: {
    fontSize: '1.16667em',
    height: 'auto',
  },
  popup: {
    // it would be way too complicated to scale all the components within the popup
    // separately. Instead, we are just scaling down the entire popup during sign up.
    // Bear in mind, this particular popup only shows when there is an error during
    // a 'Deposit Limit save' action.
    transform: 'scale(0.7)',
  },
}

export function getDepositLimitTheme(theme?: DepositLimitThemes): DepositLimitComponentTheme {
  switch (theme) {
    case DepositLimitThemes.signUp:
      return signUpTheme
    case DepositLimitThemes.desktopSignup:
      return desktopSignUpTheme
    case DepositLimitThemes.default:
    default:
      return defaultTheme
  }
}
