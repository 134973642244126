import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'
import { SkyTvButton } from '@mobi/component-library/Racing/SkyTvIndicator'
import { trackRaceReplayVideoLaunched } from '@core/Areas/Racing/Components/RaceReplay/analytics'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { setActiveReplayTicketId } from '@core/Areas/AccountActivity/Store'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { logError } from '@core/Utils'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getReplayInfoFromApi } from '@core/Areas/Racing/Components/RaceReplay/api'
import type { ReplayInfo } from '@core/Areas/Racing/Components/RaceReplay/types'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'

export const RaceReplayButton: React.FC<{
  ticket: Ticket
  isPlaying: boolean
  onPlayClick?: (replayInfo: ReplayInfo) => void
  onCloseClick(): void
}> = ({ ticket, onPlayClick, isPlaying, onCloseClick }) => {
  const dispatch = useDispatch()

  const ticketType = getTicketLayoutType(ticket)
  const firstLeg = ticket.legs[0]
  const canReplay =
    (ticketType === 'single-single' || ticketType === 'same-race-multi') &&
    firstLeg &&
    firstLeg.fixtureId &&
    ticket.status === 'Resolved'

  if (!canReplay) {
    return null
  }

  const onClick = (replayInfo: ReplayInfo) => {
    onPlayClick?.(replayInfo)
    dispatch(setActiveReplayTicketId(ticket.ticketSequence))
    trackKey(keys.raceReplayOpened)
  }

  return (
    <ReplayButtonLocal
      ticket={ticket}
      onReady={info => onClick(info)}
      isPlaying={isPlaying}
      onCloseClick={onCloseClick}
    />
  )
}

// ===============
// Local Component
// ===============

const ReplayButtonLocal: React.FC<{
  ticket: Ticket
  isPlaying: boolean
  onCloseClick(): void
  onReady?: (replayInfo: ReplayInfo) => void
}> = ({ ticket, onReady, isPlaying, onCloseClick }) => {
  const [buttonState, setButtonState] = useState<'ready' | 'loading' | 'no_replay'>('ready')

  React.useEffect(() => {
    if (buttonState === 'no_replay') {
      RegisterToast({
        id: buttonState,
        type: 'error',
        message: 'No replay found',
      })
    }
  }, [buttonState])

  const queryClient = useQueryClient()

  const handleClick = useCallback(async () => {
    setButtonState('loading')
    try {
      const { fixtureId, fixtureDate, raceNumber } = ticket.legs[0]
      const data = await queryClient.fetchQuery({
        queryKey: queryKeys.raceReplayRequest(fixtureId, fixtureDate, +raceNumber),
        queryFn: () => getReplayInfoFromApi(fixtureId, fixtureDate, +raceNumber),
        staleTime: Infinity,
      })

      if (data) {
        setButtonState('ready')
        onReady?.(data)
      } else {
        setButtonState('no_replay')
      }

      trackRaceReplayVideoLaunched('resulted-bets', !!data)
    } catch {
      setButtonState('no_replay')
      logError('Could not load race replay')
    }
  }, [ticket, queryClient, onReady, setButtonState])

  if (!ticket.legs[0] || buttonState === 'no_replay') {
    return null
  }

  const isLoading = buttonState === 'loading'

  return (
    <span data-testid='ticket-footer-replay-button'>
      <SkyTvButton
        onClick={isPlaying ? onCloseClick : handleClick}
        channel='Replay'
        isPlaying={isPlaying}
        isLoading={isLoading}
        isLive={false}
      />
    </span>
  )
}
