import { intersection } from '../../AppUtils/Utils/Algorithms'
import { CheckBoxSelection } from '../Components/Core/UIElements/CheckboxSelection'

export function isAllwaysBet(count: number, sets: Set<number>[]): boolean {
  if (sets[0].size >= count && otherSetsButFirstEmpty(sets)) return true

  for (let i = 0; i < count - 1; ++i) {
    if (sets[i].size < count) return false

    if (sets[i].size !== sets[i + 1].size) return false
    if (intersection(sets[i], sets[i + 1]).size !== sets[i].size) return false
  }

  return true
}

function otherSetsButFirstEmpty(sets: Set<number>[]): boolean {
  for (const element of sets.slice(1)) {
    if (element.size !== 0) return false
  }
  return true
}

export function populateSets(checkboxes: CheckBoxSelection[], count: number): Set<number>[] {
  const sets = new Array<Set<number>>()

  for (let i = 0; i < count; ++i) {
    sets[i] = new Set<number>()
  }

  for (const checkbox of checkboxes) {
    for (let i = 0; i < count; ++i) {
      if (checkbox.selectedAt(i)) {
        sets[i].add(checkbox.starter.number())
      }
    }
  }

  return sets
}
