import styled from '@emotion/styled'

export const PaymentSDKButtonContainerStyled = styled.div<{ isDisabled: boolean }>(
  ({ isDisabled }) => ({
    opacity: isDisabled ? '0.6' : '1',
    pointerEvents: isDisabled ? 'none' : 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '750px',
  })
)
