import { BettingInformation } from '../Model/BettingInformation'
import { difference } from '../../AppUtils/Utils/Algorithms'
import { CheckBoxSelection } from '../Components/Core/UIElements/CheckboxSelection'
import { ForwardingCheckBoxValidatorProcessor } from './ForwardingCheckBoxValidatorProcessor'
import { isAllwaysBet, populateSets } from '../Utils/CheckBoxUtils'
import Guard from '../../AppUtils/Framework/Guard'

export default class RegularCheckBoxValidatorProcessor extends ForwardingCheckBoxValidatorProcessor {
  validate(count: number, bettingContext: BettingInformation): boolean {
    Guard.notNull(bettingContext)

    if (bettingContext.rovingBanker() || bettingContext.isBoxed())
      return this.next.validate(count, bettingContext)

    const selections = bettingContext.selections

    const starters = selections.getStartersForRace(bettingContext.raceNumber)()

    if (!starters || starters.length === 0) return false

    const checkboxes = starters
      .filter(starter => typeof starter.selection === 'function')
      .map(starter => starter.selection() as CheckBoxSelection)

    const sets = populateSets(checkboxes, count)
    const sizes = this.populateSizes(count, sets)

    if (isAllwaysBet(count, sets)) return true

    if (!this.guardInvalidSizes(count, sizes)) return false

    if (this.checkSizeConstraintsMet(count, sizes)) return true

    return this.doesValidPathExist(sets)
  }

  private populateSizes(count: number, sets: Set<number>[]): number[] {
    const sizes = new Array<number>()
    for (let i = 0; i < count; ++i) {
      sizes.push(sets[i].size)
    }
    return sizes
  }

  private guardInvalidSizes(count: number, sizes: number[]): boolean {
    for (let i = 0; i < count; ++i) {
      if (sizes[i] === 0) return false
    }
    return true
  }

  private checkSizeConstraintsMet(count: number, sizes: number[]): boolean {
    let pool: number[] = []

    for (let i = 1; i <= count; ++i) {
      pool.push(i)
    }

    pool = pool.reverse()

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < sizes.length; ++i) {
      let index = -1

      for (let j = 0; j < pool.length; ++j) {
        if (pool[j] <= sizes[i]) {
          index = j
          break
        }
      }

      if (index === -1) return false

      pool.splice(index, 1)
    }

    return pool.length === 0
  }

  private doesValidPathExist(sets: Set<number>[]): boolean {
    return this.navigateGrid(sets, 0, sets.length, new Set<number>())
  }

  private navigateGrid(
    sets: Set<number>[],
    index: number,
    max: number,
    excluded: Set<number>
  ): boolean {
    if (index === max) return true

    const startersAtIndex = sets[index]
    const unique = difference(startersAtIndex, excluded)

    for (const starter of unique.values()) {
      const _excluded = new Set<number>() // Clone as this is mutable and shared by all recursive subproblems

      for (const element of excluded.values()) {
        _excluded.add(element)
      }

      _excluded.add(starter)

      const result = this.navigateGrid(sets, index + 1, max, _excluded)
      if (result)
        // Others may have a match
        return true
    }

    return false // Looked at everything and all failed
  }
}
