import { fontFamilies } from '@core/Settings'
import styled from '@emotion/styled'
import React from 'react'

export enum TrendingBetFilterEnum {
  TotalBets = 'count',
  TotalSpend = 'cost',
}

type TrendingBetFilterOptions = Readonly<{
  id: number
  name: string
  filter: TrendingBetFilterEnum
  selectable: boolean
}>

const SelectContainer = styled.div({
  position: 'relative',
  paddingRight: '1rem',

  '&::after': {
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    color: '#cbb5d8',
    content: `'\\76'`,
    display: 'inline-block',
    fontFamily: `'tticons'`,
    fontSize: '11px',
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& select': {
    marginRight: '0.5rem',
  },
})

const Select = styled.select({
  fontFamily: fontFamilies.brand,
  appearance: 'none',
  background: 'none',
  color: '#cbb5d8',
  border: 'none',
  fontWeight: 'normal',
  borderBottom: '1px solid #cbb5d8',
})

export const TrendingBetsFilter: React.FC<{
  initialFilter: TrendingBetFilterEnum
  onChanged: (selectedFilter: TrendingBetFilterEnum) => void
}> = ({ initialFilter, onChanged }) => {
  const FilterSelections: readonly TrendingBetFilterOptions[] = React.useMemo(() => {
    return [
      {
        id: 0,
        name: 'Total Bets',
        filter: TrendingBetFilterEnum.TotalBets,
        selectable: true,
      },
      {
        id: 1,
        name: 'Total Spend',
        filter: TrendingBetFilterEnum.TotalSpend,
        selectable: true,
      },
    ]
  }, [])

  return (
    <SelectContainer>
      <Select
        data-testid={'trending-bets-filter-selection'}
        onChange={e => onChanged(e.target.value as TrendingBetFilterEnum)}
        value={initialFilter}
      >
        {FilterSelections.map(x => (
          <option key={x.id} value={x.filter} disabled={!x.selectable} hidden={!x.selectable}>
            {x.name}
          </option>
        ))}
      </Select>
    </SelectContainer>
  )
}

export const TrendingBetsFilterAsTitle = styled('span')({
  position: 'relative',
  fontFamily: fontFamilies.brand,
  color: '#cbb5d8',
  border: 'none',
  fontWeight: 'normal',
  textAlign: 'right',
  marginRight: '1.5rem',
  marginBottom: '.5rem',
})
