export type CountdownColor = 'green' | 'orange' | 'red'

class TimeSpanColorFormat {
  constructor(
    public text: string,
    public color: CountdownColor
  ) {}
}

export const TimeSpanCalculator = {
  process(ts: countdown.Timespan): TimeSpanColorFormat {
    const passedStartTime = (ts.value || 0) > 0
    const prefix = passedStartTime ? '-' : ''

    if ((ts.days || 0) > 0 && ts.hours === 0) {
      return new TimeSpanColorFormat(`${prefix}${ts.days}d`, passedStartTime ? 'red' : 'green')
    }

    if ((ts.days || 0) > 0 && ts.hours !== 0) {
      return new TimeSpanColorFormat(
        `${prefix}${ts.days}d ${ts.hours}h`,
        passedStartTime ? 'red' : 'green'
      )
    }

    if ((ts.hours || 0) >= 1 && ts.minutes !== 0) {
      return new TimeSpanColorFormat(
        `${prefix}${ts.hours}h ${ts.minutes}m`,
        passedStartTime ? 'red' : 'green'
      )
    }

    if ((ts.hours || 0) >= 1 && ts.minutes === 0) {
      return new TimeSpanColorFormat(`${prefix}${ts.hours}h`, passedStartTime ? 'red' : 'green')
    }

    if ((ts.minutes || 0) >= 10) {
      return new TimeSpanColorFormat(`${prefix}${ts.minutes}m`, passedStartTime ? 'red' : 'green')
    }

    if ((ts.minutes || 0) >= 3 && ts.seconds !== 0) {
      return new TimeSpanColorFormat(
        `${prefix}${ts.minutes}m ${ts.seconds}s`,
        passedStartTime ? 'red' : 'orange'
      )
    }

    if ((ts.minutes || 0) >= 3 && ts.seconds === 0) {
      return new TimeSpanColorFormat(
        `${prefix}${ts.minutes}m ${ts.seconds}s`,
        passedStartTime ? 'red' : 'orange'
      )
    }

    if (ts.minutes !== 0 && ts.seconds !== 0) {
      return new TimeSpanColorFormat(`${prefix}${ts.minutes}m ${ts.seconds}s`, 'red')
    }

    if (ts.minutes !== 0 && ts.seconds === 0) {
      return new TimeSpanColorFormat(`${prefix}${ts.minutes}m`, 'red')
    }

    if (ts.minutes === 0 && ts.seconds !== 0) {
      return new TimeSpanColorFormat(`${prefix}${ts.seconds}s`, 'red')
    }

    return new TimeSpanColorFormat(`0s`, 'red')
  },
} as const
