export const isValidBsb = (bsb: string): boolean => {
  const validBsbRegExp = new RegExp(/^\d{3}-?\d{3}$/)
  return validBsbRegExp.test(bsb)
}

export const formatBsb = (bsb: string): string => {
  if (!isValidBsb(bsb)) {
    return bsb
  }

  const validExactBsbRegExp = new RegExp(/^\d{3}-{1}\d{3}$/)
  return validExactBsbRegExp.test(bsb) ? bsb : bsb.substring(0, 3) + '-' + bsb.substring(3, 6)
}

export const isValidAccountName = (accountName: string): boolean => {
  const validAccountNameRegExp = /^['\-\sa-zA-Z]+$/
  return validAccountNameRegExp.test(accountName)
}

export const isAccountNameIncludingSurname = (
  accountName: string,
  accountHolderSurname: string
): boolean => {
  return accountName.toLowerCase().includes(accountHolderSurname.toLowerCase())
}

export const isValidAccountNumber = (accountNumber: string): boolean => {
  const validAccountNumberRegExp = /^[0-9]{2,9}$/
  return validAccountNumberRegExp.test(accountNumber)
}
