export class Lock {
  _value = 0

  trylock(): boolean {
    if (this._value) {
      return false
    }
    this._value = 1
    return true
  }

  unlock(): void {
    this._value = 0
  }

  count(): number {
    return this._value
  }
}
