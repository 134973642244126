import * as dtos from '../DataTransferObjects/DataTransferObjects'
import ObservableRace from '../Model/Observables/ObservableRace'
import MissingMeetingInformationException from '../Exceptions/MissingMeetingInformationException'
import ObservableFixedOddsRaceInformation from '../Model/Observables/ObservableFixedOddsRaceInformation'
import ObservableMeetingInformation from '../Model/Observables/ObservableMeetingInformation'
import * as DateTimeProvider from '../../Foundation/DateTimeProvider'
import ObservablePool from '../Model/Observables/ObservablePool'

export default {
  map(dto: dtos.PageDataTransferObject): ObservableMeetingInformation {
    const { MeetingInformation: meetingInformation, AvailablePools: availablePools } = dto

    if (!meetingInformation)
      throw new MissingMeetingInformationException('There is no meeting information provided')

    const model: ObservableMeetingInformation = new ObservableMeetingInformation()

    model.meetingId(meetingInformation.MeetingId)
    model.meetingCode(meetingInformation.MeetingCode)
    model.meetingName(meetingInformation.MeetingName)
    model.meetingDate(DateTimeProvider.parseIso8601Date(meetingInformation.MeetingDate))
    model.hasNextRace(meetingInformation.HasNextRace)
    model.hasPreviousRace(meetingInformation.HasPreviousRace)
    model.nextRaceNumber(meetingInformation.NextRaceNumber)
    model.previousRaceNumber(meetingInformation.PreviousRaceNumber)
    model.trackCondition(meetingInformation.TrackCondition)
    model.weatherAtLocation(meetingInformation.WeatherAtLocation)

    if (availablePools) {
      model.availablePools(availablePools.map(pool => ObservablePool.fromDto(pool)))
    }

    return model
  },

  mapRace(
    meetingId: string,
    dto: dtos.RaceDataTransferObject,
    availablePools?: dtos.PoolDataTransferObject[]
  ): ObservableRace {
    const observableRace = new ObservableRace()
    observableRace.meetingId(meetingId)
    observableRace.distance(dto.Distance)
    observableRace.raceName(dto.RaceName)
    observableRace.raceNumber(dto.RaceNumber)
    observableRace.raceTime(DateTimeProvider.parseIso8601DateTime(dto.RaceTime))
    observableRace.raceType(dto.RaceType)
    observableRace.tips(dto.Tips)
    observableRace.tipster(dto.Tipster)
    observableRace.isFixedOddsRace(dto.IsFixedOddsRace)
    observableRace.payoutInfomation(dto.PayoutInfomation || '')
    observableRace.fixedPlacesPaying(dto.FixedPlacesPaying)
    observableRace.hasSpecials(dto.HasSpecials)
    observableRace.hasJackpots(dto.HasJackpots)
    observableRace.hasPlacePool(dto.HasPlacePool)
    observableRace.raceStatus(dto.RaceStatus)
    observableRace.key(dto.Key)

    if (dto.ProvDivUpdateTime) {
      observableRace.provDivUpdateTime(DateTimeProvider.parseIso8601DateTime(dto.ProvDivUpdateTime))
    }

    if (dto.HasJackpots && availablePools != null) {
      for (const pool of availablePools) {
        if (pool.HasJackpot) {
          const jackpotPoolNamesString = observableRace.jackpotPoolNames()
          if (jackpotPoolNamesString.length <= 0) {
            observableRace.jackpotPoolNames(pool.Name)
          } else {
            observableRace.jackpotPoolNames(jackpotPoolNamesString + ', ' + pool.Name)
          }
        }
      }
    }

    if (dto.FixedOddsInfo) observableRace.fixedOddsInfo = mapFixedOdds(dto.FixedOddsInfo)

    return observableRace
  },
}

function mapFixedOdds(
  dto: dtos.FixedOddsRaceInformationDataTransferObject
): ObservableFixedOddsRaceInformation {
  const result = new ObservableFixedOddsRaceInformation()
  result.bettingConditions(dto.BettingConditions)
  result.bettingRules(dto.BettingRules)
  result.bettingTerms(dto.BettingTerms)
  result.isClosed(dto.IsClosed)
  result.isFixedOddsOnly(dto.IsFixedOddsOnly)
  result.isPlaceAvailable(dto.IsPlaceAvailable)
  result.isRaceAvailable(dto.IsRaceAvailable)
  result.isResulted(dto.IsResulted)
  result.isSuspended(dto.IsSuspended)
  result.payoutInformation(dto.PayoutInformation)
  result.marketSequence(dto.MarketSequence)
  return result
}
