import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'

export const PayIDPanelStyled = styled.section({
  fontFamily: font.family.primary,

  p: {
    textAlign: 'left',
    margin: 0,
    ...font.size.md,
  },

  '> header p': {
    color: colors.neutral[800],
  },
})

export const MissingPayIDCardStyled = styled.div({
  alignItems: 'center',
  backgroundColor: colors.lavender[900],
  borderRadius: radius.lgx1,
  columnGap: spacing.big,
  display: 'grid',
  gridTemplateColumns: '7.6rem 1fr',
  marginTop: spacing.md,
  padding: `${spacing.md} ${spacing.bigx1}`,

  p: {
    color: colors.white,
    marginBottom: spacing.sm,
  },

  svg: {
    display: 'inline-block',
  },

  '@keyframes spin': { to: { transform: 'rotate(360deg)' } },

  'button svg': {
    animation: 'spin 1s steps(8) infinite',
  },

  a: {
    color: 'inherit',
  },
})

export const InfoContainerStyled = styled.div({
  alignItems: 'center',
  color: colors.neutral[900],
  columnGap: spacing.sm,
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gridTemplateRows: spacing.lg,
  marginTop: spacing.md,
  ...font.size.lg,

  '> span:first-of-type': {
    fontWeight: font.weight.semibold,
  },
})
