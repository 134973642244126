import React from 'react'
import { AccountHeader } from '../../../../../component-library/src/Account'

/**
 * Display a header for the Deposit screen, with an optional close
 * button
 */
export const DepositHeader = ({ title, onClose }: { title: string; onClose?: VoidFunction }) => {
  return (
    <AccountHeader
      alignItems={'start'}
      title={title}
      onClose={onClose}
    />
  )
}
