import React from 'react'
import { useAppSelector } from '@core/Store/hooks'
import { useLogon } from '@core/Utils/hooks'
import { useNativeInterop } from './Hooks'
import { FloatingPlayer } from './Components/FloatingPlayer/FloatingPlayer'
import { FullscreenPlayer } from './Components/FullscreenPlayer/FullscreenPlayer'

/** SkyVideoPlayer controls:
 *  - Render of Floating + Fullscreen video
 *  - Native comms for all sky video player states (floating | fullscreen | docked)
 *
 *  This component does NOT control render of Docked, see `<DockedPlayer />`
 */
export const SkyVideoPlayer: React.FC = () => {
  const isPlayerOpen = useAppSelector(state => state.skyVideoPlayer.isPlayerOpen)
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)

  const { isLoggedIn } = useLogon()

  const isVideoPlayerRunning = isPlayerOpen && !!isLoggedIn
  useNativeInterop(isVideoPlayerRunning)

  if (!isVideoPlayerRunning) return null

  if (playerStatus === 'floating') return <FloatingPlayer />
  if (playerStatus === 'fullscreen') return <FullscreenPlayer />

  return null
}
