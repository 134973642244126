import dayjs from 'dayjs'
import type { RacingBetType } from '@mobi/betslip/types'
import { PlanSeq } from '@mobi/api-types'
import { FeatureFlags } from '@mobi/settings'
import { isActive } from '@core/State/LaunchDarklyFeatures/helpers/isActive'
import { InvestmentType } from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import { QuickbetSelection, SetActiveInvestment } from '@core/Areas/Quickbet/signals'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { logError } from '@core/Utils'
import {
  buildBaseBetSelection,
  getFOOEventType,
  isFOOBetDetails,
  isSportBetDetails,
} from '../buildBetItem/buildBaseBetSelection'
import { LoadBetDetails, LoadBetErrors } from '../types'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { getToteBetType } from '../buildBetItem/getToteBetType'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'
import { addToBetSlip } from './addToBetSlip'
import { addToLegacyBetslip } from './addToLegacyBetslip'

const SUPPORTED_TOTE_BET_TYPES: RacingBetType[] = [
  'Win & Place',
  'Quinella',
  'Exacta',
  'Trifecta',
  'First 4',
  'Double',
  'Quaddie',
]

export const isLoadBetSupported = (planSeq: number): boolean => {
  if (planSeq === PlanSeq.FOBRacing) return true

  if (planSeq === PlanSeq.FOBSports) return true

  const betType = getToteBetType(planSeq)
  if (!betType) return false

  return SUPPORTED_TOTE_BET_TYPES.includes(betType)
}

export const loadBet = async (
  betPayload: LoadBetDetails[],
  source?: LoadBetSource,
  prebuiltBets?: QuickbetSelection[] | null
): Promise<void> => {
  try {
    if (prebuiltBets && prebuiltBets.length !== betPayload.length) {
      // invalid data. clear it
      prebuiltBets = null
    }
    if (betPayload.length === 1) {
      await addToQuickbet(betPayload[0], { betSource: source }, prebuiltBets?.[0])
      return
    }

    if (betPayload.length > 1) {
      const isNewBetSlipFeatureActive = await isActive(FeatureFlags.NEW_BET_SLIP_DESIGN.key)

      if (isNewBetSlipFeatureActive) {
        await addToBetSlip(betPayload, source, prebuiltBets)
      } else {
        await addToLegacyBetslip(betPayload, source, prebuiltBets)
      }
      return
    }

    throw new Error('No bets provided')
  } catch (error) {
    const message = getErrorMessage(error)

    RegisterToast({
      id: 'failed-bet-creation',
      timeout: 0,
      type: 'error',
      position: 'bottom',
      message,
    })

    if (message !== LoadBetErrors.BettingClosed) logError(error as Error, true)
  }
}

/** Quickbet: build item and open in Quickbet */
export async function addToQuickbet(
  bet: LoadBetDetails,
  opts: Partial<AddToQuickbetOptions> = {},
  prebuiltBet?: QuickbetSelection
): Promise<void> {
  if (opts.shouldNavigateToRace) {
    handleNavigateToBet(bet)
  }
  const selection = prebuiltBet || (await buildBaseBetSelection(bet))
  selection.betSource = opts.betSource

  if (isSportBetDetails(bet)) {
    handleBetSelection({
      betFlow: {
        location: 'BetSlipNew',
      },
      selection: selection,
    })
    return
  }

  handleBetSelection({
    betFlow: {
      location: 'Quickbet',
      options: {
        setUp: () => {
          SetActiveInvestment(
            bet.betType === 'Place - Fixed' ? InvestmentType.Place : InvestmentType.Win
          )
        },
        quickbetProps: {
          // setting this to false will force superpicks off, if true they will show only if
          // available. For now we force it off for starting price
          shouldShowSuperPicks: bet.betType !== FixedOddsMarketDisplayNames.SP,
        },
      },
    },
    selection: selection,
  })
}

export function getErrorMessage(error: unknown): string {
  let errorMessage = LoadBetErrors.Default
  if (isErrorInstance(error) && error.message === LoadBetErrors.BettingClosed) {
    errorMessage = LoadBetErrors.BettingClosed
  }
  return errorMessage
}

// =============
// Local Helpers
// =============

const isErrorInstance = (error: unknown): error is Error => error instanceof Error

async function handleNavigateToBet(bet: LoadBetDetails) {
  if (isSportBetDetails(bet)) {
    // const uri = buildEventPageURL(bet.sportId, bet.tournamentId, bet.eventId)
    // window.location.href = `#${uri.replace(/^\//, '')}` // convert to hash route
    return
  }

  if (isFOOBetDetails(bet)) {
    const { competitionSeq, eventStartDateTime, sportName } = bet
    const event = getFOOEventType(sportName)
    const date = convertToFixtureDate(eventStartDateTime)
    window.location.href = `#fobracing/propositions/${event}/races/${competitionSeq}?selectionDate=${date}`
    return
  }

  const { fixtureId, races, fixtureDate } = bet
  window.location.href = `#tote/meetings/${fixtureId}/${races[0]}?date=${convertToFixtureDate(
    fixtureDate
  )}`
}

const convertToFixtureDate = (date: Date | string) => dayjs(date).format('YYYY-MM-DD')

// =====
// Types
// =====

type LoadBetSource = 'share-bet' | 'rebet' | 'blackbook'

interface AddToQuickbetOptions {
  shouldNavigateToRace: boolean
  betSource: LoadBetSource
}
