import { updateDataLayer } from '@classic/Foundation/Analytics/Analytics'
import {
  getEnhancedBetslipSetting,
  isHapticFeedbackSettingEnabled,
} from '@core/Areas/Settings/Store/selectors'
import type { RootState } from '@core/Store'
import { checkAccountToken } from '@core/Areas/Settings/Components/PayoutNotification/PayoutNotification'
import { getRememberAccountNumber } from '@core/Areas/Login/helpers/api'
import { isReactNativeApp } from '@mobi/web-native-comms/web'

export async function updateUserSettingsOnDataLayer(appState: RootState) {
  const isEnhancedBetslipEnabled = getEnhancedBetslipSetting(appState)
  const isHapticsEnabled = isHapticFeedbackSettingEnabled(appState)

  updateDataLayer({
    user: { isHapticsEnabled, isEnhancedBetslipEnabled },
  })

  if (isReactNativeApp) {
    const samplingRate = PRODUCTION ? 0.01 : 1
    if (Math.random() < samplingRate) {
      const isPayoutNotificationsEnabled = await getPayoutNotificationEnabled()
      if (typeof isPayoutNotificationsEnabled === 'boolean') {
        updateDataLayer({
          user: { isPayoutNotificationsEnabled },
        })
      }
    }
  }
}

export async function getPayoutNotificationEnabled() {
  try {
    const accountNumber = await getRememberAccountNumber()
    if (typeof accountNumber === 'undefined') return null
    const isEnabled = await checkAccountToken(accountNumber)
    if (typeof isEnabled !== 'boolean') return null
    return isEnabled
  } catch {
    return null
  }
}
