import { checkIfIsInIPad } from '@core/Settings/Components/GlobalCss/helpers'

export class SilkImages {
  public small: SilkImage = new SilkImage()
  public large: SilkImage = new SilkImage()
  public larger: SilkImage = new SilkImage()
  public largest: SilkImage = new SilkImage()

  public getSilkImageFor(windowInnerWidth: number): SilkImage {
    const iPadWidth = 768
    if (checkIfIsInIPad() && windowInnerWidth < iPadWidth) {
      return this.large
    }
    return this.large
  }
}

export class SilkImage {
  public url = ''
  public size: SilkImageSize = new SilkImageSize()

  // This is to ensure existing calls, like giddyup, that may not be part of the new method to downloads silks from core work
  // This is fine while giddyup is only available for australian/nz greyhounds and not uk greyhounds
  public getCssBackgroundFor(starterNumber: number, starterType: string): string {
    if (starterType === 'Dogs') return ''
    const isTrot = starterType === 'Trots'
    const leftPosition = this.size.width * (starterNumber - 1)
    const topOffset = isTrot ? -1 : 0
    return `url(${this.url}) no-repeat -${leftPosition}px ${topOffset}px`
  }

  public getCssBackgroundForFinisher(
    finisherNumber: number,
    finisherType: string,
    numberOfFinisherInRace: number
  ): string {
    return this.getCssBackgroundForStarter(finisherNumber, finisherType, numberOfFinisherInRace)
  }

  public getCssBackgroundForStarter(
    starterNumber: number,
    starterType: string,
    numberOfStartersInRace: number
  ): string {
    const imageSizingResult = this.getImageSizingForStarterType(
      starterNumber,
      starterType,
      numberOfStartersInRace
    )
    return `url(${this.url}) ${imageSizingResult} no-repeat`
  }

  private getImageSizingForStarterType(
    starterNumber: number,
    starterType: string,
    numberOfStartersInRace: number
  ): string {
    switch (starterType) {
      case 'Dogs':
        return this.getImageSizingForDogs(starterNumber, numberOfStartersInRace)
      case 'Trots':
        return this.getImageSizingForTrots(starterNumber)
      case 'Races':
        return this.getImageSizingForRaces(starterNumber)
    }
    return ''
  }

  private getImageSizingForDogs(starterNumber: number, numberOfStartersInRace: number): string {
    // Starter numbers start at 1, but the offset has to begin at 0
    const leftOffsetIntoOriginalImage = `${
      ((starterNumber - 1) * 100) / (numberOfStartersInRace - 1)
    }%`

    // Push down from top because there is a gap along the top of the dog sprite
    const topOffsetIntoOriginalImage = '43%'

    // 106% is the percentage amount we cut into the left of the greyhound colour because the border between the greyhound colours are blurry
    // 130% expand the boxes so we can cut off the blurrines between the each greyhound colour
    const horizontalAndVerticalBackgroundSize = `${numberOfStartersInRace * 106}% 130%`

    return `${leftOffsetIntoOriginalImage} ${topOffsetIntoOriginalImage} / ${horizontalAndVerticalBackgroundSize}`
  }

  private getImageSizingForTrots(starterNumber: number): string {
    const leftOffset = this.size.width * (starterNumber - 1)
    const leftOffsetIntoOriginalImage = leftOffset === 0 ? '0' : `-${leftOffset}px`
    const topOffsetIntoOriginalImage = '-1px'
    return `${leftOffsetIntoOriginalImage} ${topOffsetIntoOriginalImage}`
  }

  private getImageSizingForRaces(starterNumber: number): string {
    const leftOffset = this.size.width * (starterNumber - 1)
    const leftOffsetIntoOriginalImage = leftOffset === 0 ? '0' : `-${leftOffset}px`
    const topOffsetIntoOriginalImage = '0'
    return `${leftOffsetIntoOriginalImage} ${topOffsetIntoOriginalImage}`
  }
}

export class SilkImageSize {
  public width = 0
  public height = 0
  public y = 0
}
