import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { InfoBox } from '@mobi/component-library/Common/V2/Display/InfoBox'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import { isFobSportsDetails } from '@mobi/betslip/helpers/typeGuards'
import { BetInfo } from '@mobi/betslip/Components/Common/BetInfo/BetInfo'
import { toggleIsItemInMulti } from '@mobi/betslip/Store/Bets'
import { selectWorkflowStatus } from '@mobi/betslip/Store/Workflow/selectors'
import { ShareBetContext } from '@mobi/betslip/Components/Common/ShareMyBet/context'

const enum LocalConstants {
  VisuallyDisabledClass = 'js-multi-item__grouped-leg--disabled',
}

export const MultiItem: React.FC<{ items: BetSlipItem[] }> = ({ items }) => {
  const dispatch = useDispatch()

  const isShareDisplay = useContext(ShareBetContext)
  const workflow = useSelector(selectWorkflowStatus)

  const hasMultipleInGroup = items.length > 1
  const hasSelectedInGroup = hasMultipleInGroup && items.some(item => item.isInMulti)
  const hasFatalError = isFatalErrorType(items[0].betErrorType)

  const createMultiLegClickHandler: (id: string) => React.MouseEventHandler<HTMLButtonElement> =
    id => e => {
      e.preventDefault()
      if (isShareDisplay || hasFatalError || workflow !== 'ready') return
      dispatch(toggleIsItemInMulti({ id, isInGroup: hasMultipleInGroup }))
    }

  return (
    <WrapperStyled>
      <BetInfo item={items[0]} displayMode='event' isMultiItem />

      {hasMultipleInGroup && !hasFatalError && (
        <InfoBox color='blue' size='sm'>
          Conflict in this {isFobSportsDetails(items[0].selectionDetails) ? 'match' : 'race'}
        </InfoBox>
      )}

      {hasFatalError && (
        <InfoBox color='red' size='sm'>
          This event has closed
        </InfoBox>
      )}

      <ul>
        {items.map(item => (
          <li
            key={item.id}
            className={
              hasSelectedInGroup && !item.isInMulti ? LocalConstants.VisuallyDisabledClass : ''
            }
          >
            <button onClick={createMultiLegClickHandler(item.id)}>
              <BetInfo item={item} isMultiItem displayMode='selection' />
            </button>
          </li>
        ))}
      </ul>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
  padding: spacing.smx1,
  border: `1px solid ${colors.neutral[200]}`,
  borderRadius: radius.lgx1,

  '> ul': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.smx1,
    padding: 0,
    margin: 0,
    listStyle: 'none',

    '> li': {
      display: 'flex',
      flexDirection: 'column',
      transition: 'opacity 0.1s ease',
      opacity: 1,

      '> button': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        background: 0,
        border: 0,
        padding: 0,
        textAlign: 'unset',
      },
    },
  },

  [`li.${LocalConstants.VisuallyDisabledClass}`]: {
    opacity: 0.5,
  },
})
