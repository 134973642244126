export class CurrencyConverter {
  public toMoney(
    value: number,
    decimalNumbers: number,
    currencySymbol?: string,
    decimalSymbol?: string,
    thousandsSymbol?: string
  ) {
    // eslint-disable-next-line no-var
    var n = value,
      c = isNaN((decimalNumbers = Math.abs(decimalNumbers))) ? 2 : decimalNumbers,
      d = decimalSymbol == undefined ? '.' : decimalSymbol,
      t = thousandsSymbol == undefined ? ',' : thousandsSymbol,
      m = currencySymbol == undefined ? '$' : currencySymbol,
      s = n < 0 ? '-' : '',
      nubmerValue = Number(Math.abs(+n || 0).toFixed(c)),
      integerValue = parseInt(nubmerValue.toString()),
      integerString = integerValue.toString(),
      j: number = (j = integerString.length) > 3 ? j % 3 : 0
    const str =
      s +
      m +
      (j ? integerString.substr(0, j) + t : '') +
      integerString.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
        ? d +
          Math.abs(nubmerValue - integerValue)
            .toFixed(c)
            .slice(2)
        : '')
    //console.log('toMoney => str(' + str + ')');
    return str
    //return s + m + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  }
}
