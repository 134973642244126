import React from 'react'
import { TypedRecord } from 'typed-immutable-record'
import { StateMap, PoJoOrMap } from 'typings/immutable'
const { useState, useEffect } = React

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsFromTypedRecord<T> = Exclude<Extract<keyof T, string>, keyof TypedRecord<any>>[]

type PickPoJoOrStateMap<T, K extends keyof T> = {
  [P in K]: PoJoOrMap<T[P]>
}

export function useObservableImmutable<T>(
  // @ts-expect-error Legacy - use redux toolkit
  driver: Rx.Observable<StateMap<T>>,
  property: (keyof T)[]
): PickPoJoOrStateMap<T, (typeof property)[number]>

export function useObservableImmutable<T extends TypedRecord<T>>(
  driver: Rx.Observable<T>,
  property: PropsFromTypedRecord<T>
): Pick<T, (typeof property)[number]>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useObservableImmutable(driver: any, property: any): any {
  const [state, setState] = useState<ReturnType<typeof useObservableImmutable>>({})

  useEffect(() => {
    const subscription = driver
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((val: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return property.reduce((accu: Record<string, unknown>, p: any) => {
          accu[p] = val.get(p)
          return accu
        }, {})
      })
      .distinctUntilChanged()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: React.SetStateAction<Pick<TypedRecord<any>, never>>) => {
        setState(data)
      })
    return () => subscription.dispose()
  }, [])

  return state
}
