import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Modal } from '@mobi/component-library/Overlays/Modal'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { clearAllBetSlipItems } from '@mobi/betslip/Store/Bets'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'

export const ClearBetSlipButton: React.FC = () => {
  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleClearBetSlip = () => {
    setIsModalOpen(false)
    dispatch(clearAllBetSlipItems())
    const hostHelpers = getHostHelpers()
    hostHelpers.toggleBetSlipDrawer?.(false)
  }

  return (
    <>
      <Button
        data-testid='ClearBetSlipButton.ToggleModal'
        size='md'
        isFullWidth
        color='secondary_color'
        onClick={() => setIsModalOpen(true)}
      >
        Clear Bet Slip
      </Button>

      <Modal isOpen={isModalOpen} dismissStatus='disallowed'>
        <WrapperStyled>
          <header>
            <div>
              <Icon name='LineAlertTriangle' color={colors.warning[600]} size='2.4rem' />
            </div>

            <h2>Clear Bet Slip?</h2>

            <p>Are you sure you want to remove all bets from your Bet Slip?</p>
          </header>

          <div>
            <Button
              size='md'
              isFullWidth
              color='secondary_grey'
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>

            <Button
              data-testid='ClearBetSlipButton.Clear'
              size='md'
              isFullWidth
              color='destructive'
              onClick={handleClearBetSlip}
            >
              Clear Bet Slip
            </Button>
          </div>
        </WrapperStyled>
      </Modal>
    </>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '> header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacing.bigx1,
    fontFamily: font.family.primary,

    '> div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '4.8rem',
      height: '4.8rem',
      marginBottom: spacing.md,
      borderRadius: '50%',
      background: colors.warning[50],

      '> span': { transform: 'translateY(-0.1rem)' },
    },

    '> h2': {
      marginBottom: spacing.smx1,

      fontSize: font.size.xl2.fontSize,
      letterSpacing: font.size.xl2.letterSpacing,
      lineHeight: font.size.xl2.lineHeight,
      fontWeight: font.weight.semibold,

      color: colors.neutral[900],
      textShadow: 'none',
      textTransform: 'unset',
    },

    '> p': {
      margin: 0,
      fontSize: font.size.lg.fontSize,
      letterSpacing: font.size.lg.letterSpacing,
      lineHeight: font.size.lg.lineHeight,
      fontWeight: font.weight.regular,

      color: colors.neutral[700],
      textWrap: 'balance',
    },
  },

  '> div:last-of-type': {
    display: 'flex',
    alignSelf: 'stretch',
    gap: spacing.smx1,
  },
})
