import { StructuredSearchTrack } from '@core/Data/StructuredSearch/StructuredSearchResult'
import { SelectedTrackType } from '../../Driver/StructuredSearchDriver'

export interface ModalTrack extends StructuredSearchTrack {
  selected: boolean
}

export function getModalTracks(
  availableTracks: StructuredSearchTrack[] | null,
  selectedTracks: string[] | null
): ModalTrack[] {
  let modalTracks: ModalTrack[] = []

  if (availableTracks) {
    modalTracks = availableTracks.map(track => {
      let selected = false
      if (selectedTracks) {
        selected = selectedTracks.includes(track.id)
      }

      return {
        ...track,
        selected,
      } as ModalTrack
    })
  }

  return modalTracks
}

export function getFilteredTracks(
  availableTracks: StructuredSearchTrack[] | null,
  selectedTracks: string[] | null
): StructuredSearchTrack[] {
  let tracks: StructuredSearchTrack[] = []

  if (selectedTracks && availableTracks) {
    tracks = []
    selectedTracks.forEach(selectedTrack => {
      availableTracks.forEach(availableTrack => {
        if (availableTrack.id === selectedTrack) {
          tracks?.push(availableTrack)
        }
      })
    })
  }

  return tracks
}

export function filterSelectedTracksTrackIds(
  availableTracks: StructuredSearchTrack[] | null,
  selectedTracks: SelectedTrackType
): SelectedTrackType {
  const results = getFilteredTracks(availableTracks, selectedTracks).map(track => track.id)

  return results.length === 0 ? null : results
}
