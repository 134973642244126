import * as ko from 'knockout'

ko.bindingHandlers['sticky'] = {
  init(element: HTMLElement, valueAccessor) {
    const value = ko.utils.unwrapObservable(valueAccessor())
    element.setAttribute('data-isactive', value)

    const onScroll = () => {
      const isActive = element.getAttribute('data-isactive')
      if (isActive === 'true') {
        const scrollY = window.scrollY,
          elemHeight = element.offsetHeight

        const bodyRect = document.body.getBoundingClientRect(),
          elemRect = element.getBoundingClientRect(),
          elemPosY = elemRect.top - bodyRect.top

        if (scrollY > elemPosY && scrollY < elemHeight + elemPosY) {
          element.classList.add('fixed')
        } else {
          element.classList.remove('fixed')
        }
      }
    }

    window.addEventListener('scroll', onScroll)

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      window.removeEventListener('scroll', onScroll)
    })
  },
  update(element, valueAccessor) {
    const value = ko.utils.unwrapObservable(valueAccessor())
    element.setAttribute('data-isactive', value)

    if (!value) {
      element.classList.remove('fixed')
    }
  },
}
