import React from 'react'
import { dayjs } from '@mobi/utils/date'
import styled from '@emotion/styled'
import { useIntervalWhen } from 'rooks'
import { StatusBadge } from '../../Common/StatusBadge/StatusBadge'

/**
 * A badge showing a countdown to a particular time (usually the start of a race) passed as a `Date`.
 * When the time has passed the badge will show a negative duration. The color of the badge
 * changes from green to yellow to red as the time approaches.
 */
export function CountdownBadge({
  badgeSize,
  advertisedStartTime,
}: {
  advertisedStartTime: Date
} & StatusBadgeSize) {
  const previousTextRef = React.useRef<string>('')

  const [state, setState] = React.useState<CountdownBadgeState>(() => {
    const state = getCountdownState(advertisedStartTime)
    previousTextRef.current = state.text
    return state
  })

  useIntervalWhen(
    () => {
      const state = getCountdownState(advertisedStartTime)
      if (state.text !== previousTextRef.current) {
        setState(state)
        previousTextRef.current = state.text
      }
    },
    1_000,
    true
  )

  return (
    <WrapperStyled data-testid='CountdownBadge'>
      <StatusBadge color={state.color} badgeSize={badgeSize}>
        {state.text}
      </StatusBadge>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function getCountdownState(advertisedStartTime: Date): CountdownBadgeState {
  return {
    text: formatCountdownDuration(advertisedStartTime),
    color: badgeColorForCountdown(advertisedStartTime),
  }
}

function badgeColorForCountdown(advertisedStartTime: Date): StatusBadgeColor {
  const secondsToJump = (advertisedStartTime.getTime() - Date.now()) / 1_000

  if (secondsToJump < 0) return 'red'
  if (secondsToJump < 60 * 10) return 'yellow'
  if (secondsToJump < 60 * 60) return 'green'
  return 'gray'
}

function formatCountdownDuration(advertisedStartTime: Date) {
  const now = dayjs()
  const eventTime = dayjs(advertisedStartTime)
  const millisecondsDiff = Math.abs(now.diff(eventTime))

  // Past

  if (eventTime.isBefore(now)) {
    if (eventTime.isAfter(now.subtract(1, 'minute'))) {
      return '-' + dayjs.duration(millisecondsDiff).format('ss[s]')
    }

    if (eventTime.isAfter(now.subtract(1, 'hour'))) {
      return '-' + dayjs.duration(millisecondsDiff).format('m[m] ss[s]')
    }

    return eventTime.format('D MMM YYYY')
  }

  // Upcoming

  if (eventTime.isBefore(now.add(1, 'minute'))) {
    return dayjs.duration(millisecondsDiff).format('s[s]')
  }

  if (eventTime.isBefore(now.add(10, 'minute'))) {
    return dayjs.duration(millisecondsDiff).format('m[m] ss[s]')
  }

  if (eventTime.isBefore(now.add(1, 'hour'))) {
    return dayjs.duration(millisecondsDiff).format('m[m]')
  }

  if (eventTime.isToday()) {
    return eventTime.format('H:mm')
  }

  if (eventTime.isBefore(now.add(7, 'days'))) {
    return eventTime.format('ddd H:mm')
  }

  if (now.isSame(eventTime, 'year')) {
    return eventTime.format('D MMM H:mm')
  }

  return eventTime.format('D MMM YYYY')
}

// ======
// Styles
// ======

const WrapperStyled = styled.span({
  display: 'flex',
  alignItems: 'stretch !important',

  '> *': {
    minWidth: '5.5rem',
  },
})

// =====
// Types
// =====

type CountdownBadgeState = {
  text: string
  color: StatusBadgeColor
}

type StatusBadgeSize = Pick<React.ComponentProps<typeof StatusBadge>, 'badgeSize'>
type StatusBadgeColor = React.ComponentProps<typeof StatusBadge>['color']
