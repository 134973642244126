import React from 'react'
import styled from '@emotion/styled'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import { colors, layering, shadow, measurements } from '@mobi/component-library/Theme/Common'
import { useSwipeClose } from './hooks/useSwipeClose'

export const Drawer: React.FC<{
  isOpen: boolean
  children: React.ReactNode
  maxWidth?: string
  position?: 'left' | 'right'
  onDismiss?: () => void
  shouldUseBodyScrollLock?: boolean
}> = ({
  isOpen,
  children,
  onDismiss,
  maxWidth,
  position = 'right',
  shouldUseBodyScrollLock = true,
}) => {
  const shouldRenderDrawer = useRenderTimeoutControl({
    shouldRender: isOpen,
    timeoutMs: 300,
  })

  if (!shouldRenderDrawer) return null

  return (
    <DrawerContent
      onDismiss={onDismiss}
      maxWidth={maxWidth}
      position={position}
      shouldUseBodyScrollLock={shouldUseBodyScrollLock}
      isUnmounting={!isOpen}
    >
      {children}
    </DrawerContent>
  )
}

// ===============
// Local Component
// ===============

const DrawerContent: React.FC<{
  children: React.ReactNode
  isUnmounting: boolean
  maxWidth?: string
  position?: 'left' | 'right'
  onDismiss?: () => void
  shouldUseBodyScrollLock?: boolean
}> = ({
  isUnmounting,
  children,
  shouldUseBodyScrollLock,
  onDismiss,
  maxWidth = '48rem',
  position = 'right',
}) => {
  const drawerElRef = React.useRef<HTMLElement>(null)

  const scrollContainerRef = useBodyScrollLock(shouldUseBodyScrollLock)
  useSwipeClose({ drawerElRef, onDismiss, position })

  return (
    <WrapperStyled
      aria-modal='true'
      data-testid='Overlay.Drawer'
      data-state={isUnmounting ? 'closing' : 'opened'}
    >
      <article style={{ maxWidth }} ref={drawerElRef} data-position={position}>
        <div ref={scrollContainerRef} data-testid='Overlay.Drawer.Content'>
          {children}
        </div>
      </article>

      <div onClick={onDismiss} />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  position: 'fixed',
  zIndex: layering.overlayHigh,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',

  '> article': {
    boxSizing: 'border-box',
    zIndex: 2,
    position: 'fixed',
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: measurements.mobi.minWidth,
    pointerEvents: 'auto',
    background: colors.white,
    boxShadow: shadow.lg,

    touchAction: 'none',
    willChange: 'transform',
    transition: 'transform 0.4s cubic-bezier(0.32, 0.72, 0, 1)',
    animationDuration: '0.4s',
    animationTimingFunction: 'cubic-bezier(0.32, 0.72, 0, 1)',

    ['&[data-position="left"]']: { left: 0, animationName: 'slideFromLeft' },
    ['&[data-position="right"]']: { right: 0, animationName: 'slideFromRight' },

    // Children container
    '> div': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
    },
  },

  // Background dimmer
  '& > div': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,0,0.5)',
    animation: 'fadeIn 0.4s ease forwards',
  },

  ['&[data-state="closing"]']: {
    '> article': {
      animationDuration: '0.3s',
      animationTimingFunction: 'ease-out',
      animationFillMode: 'forwards',

      ['&[data-position="left"]']: { left: 0, animationName: 'slideToLeft' },
      ['&[data-position="right"]']: { right: 0, animationName: 'slideToRight' },
    },

    '> div': {
      animation: 'fadeOut 0.3s ease forwards',
    },
  },

  '@keyframes fadeIn': { from: { opacity: 0 }, to: { opacity: 1 } },
  '@keyframes fadeOut': { to: { opacity: 0 } },

  '@keyframes slideFromLeft': {
    from: { transform: 'translate3d(-100%, 0, 0)' },
    to: { transform: 'translate3d(0, 0, 0)' },
  },
  '@keyframes slideToLeft': { to: { transform: 'translate3d(-100%, 0, 0)' } },

  '@keyframes slideFromRight': {
    from: { transform: 'translate3d(100%, 0, 0)' },
    to: { transform: 'translate3d(0, 0, 0)' },
  },
  '@keyframes slideToRight': { to: { transform: 'translate3d(100%, 0, 0)' } },
})
