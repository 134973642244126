import React from 'react'
import { useDispatch } from 'react-redux'
import { PromoSelector } from '@mobi/betslip/Components/Common/PromoSelector'
import { PromoBanner } from '@mobi/betslip/Components/Common/PromoBanner'
import type { BetSlipItem, BetSpecialOffer } from '@mobi/betslip/types'
import { calculateProjectedReward } from '@mobi/betslip/helpers/superPicks'
import { setSelectedSpecialOffer } from '@mobi/betslip/Store/Bets'
import { useAppSelector } from '@mobi/betslip/Store/hooks'

export const SuperPicks: React.FC<
  Pick<BetSlipItem, 'specialOffers' | 'id' | 'selectedSuperPickOffer' | 'investment'> & {
    winPrice: number
    placePrice: number
  }
> = ({ specialOffers, id, winPrice, placePrice, selectedSuperPickOffer, investment }) => {
  const dispatch = useDispatch()
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)

  if (workflowStatus !== 'ready') {
    return selectedSuperPickOffer ? (
      <PromoBanner promoType='SuperPick' text={selectedSuperPickOffer.title as string} />
    ) : null
  }

  const selections: Selections = specialOffers.map(specialOffer => {
    const projectedReward = calculateProjectedReward(specialOffer, investment, {
      winPrice,
      placePrice,
    })
    const selectedSuperPickSeq = selectedSuperPickOffer?.specialSeq
    const isSelected = specialOffer.specialSeq === selectedSuperPickSeq
    const isInsuranceOffer = specialOffer.specialTypeCode === 'WBI'
    const maxReward = getMaxReward(specialOffer)

    return {
      isSelected,
      title: specialOffer.title as string,
      onClick: () => dispatch(setSelectedSpecialOffer({ id, specialOffer })),
      descriptionSlots: [
        <>
          <strong key='first'>{`${isInsuranceOffer ? 'Money Back: ' : ''} $${projectedReward}`}</strong>{' '}
          {maxReward}
        </>,
      ],
    }
  })

  return (
    <div>
      <PromoSelector promoType='SuperPick' itemId={id} selections={selections} />
    </div>
  )
}

// =============
// Local Helpers
// =============

const getMaxReward = (specialOffer: BetSpecialOffer): string => {
  if (!specialOffer.elements) return ''

  const nonBoostElement = specialOffer.elements.find(
    (element: NonNullable<BetSpecialOffer['elements']>[0]) => !element.isBoost
  )
  if (!nonBoostElement) return ''

  return nonBoostElement.maxReward ? ` up to $${nonBoostElement.maxReward}` : ''
}

// =====
// Types
// =====

type Selections = React.ComponentProps<typeof PromoSelector>['selections']
