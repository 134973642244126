import moment from 'moment'
import { BettingInformation } from './BettingInformation'
import { BetType } from './Betting/BetType'
import { ILegacyBetModel } from './ILegacyBetModel'
import LegacyTabModel from './LegacyTabModel'
import { ISelectionResult } from '../Components/Core/SelectionResults/ISelectionResult'
import { FobSelectionResult } from '../Components/Core/SelectionResults/FobSelectionResult'
import ObservableMeetingInformation from './Observables/ObservableMeetingInformation'

export class LegacyBetModelBuilder {
  private data: ILegacyBetModel = {} as ILegacyBetModel

  constructor(
    bettingContext: BettingInformation,
    isForEnquiry: boolean,
    shouldBeFormDataCompatible: boolean
  ) {
    this.createModel(bettingContext, shouldBeFormDataCompatible)

    if (isForEnquiry) {
      this.data.HasInvestment = false
      this.data.Investment1 = '0.00'
      this.data.Investment2 = '0.00'
    }

    if (!isForEnquiry && bettingContext.selectedBetType().isAllUp()) {
      this.processAllUpFormulas(bettingContext, shouldBeFormDataCompatible)
    }
  }

  public getFormData() {
    return this.data
  }

  public getModel(): ILegacyBetModel {
    return this.data
  }

  private processAllUpFormulas(
    bettingContext: BettingInformation,
    shouldBeFormDataCompatible: boolean
  ): void {
    if (
      bettingContext.allUpFormulas != undefined &&
      bettingContext.allUpFormulas.formulas().length > 0
    ) {
      this.data.AllUpFormulas = []
      const selectedFormulas = bettingContext.allUpFormulas
        .formulas()
        .filter(formula => formula.isSelected())

      if (shouldBeFormDataCompatible) {
        selectedFormulas.forEach((formula, index) => {
          // @ts-expect-error Types need work
          this.data['AllUpFormulas[' + index + '].Number'] = formula.number()
          // @ts-expect-error Types need work
          this.data['AllUpFormulas[' + index + '].Combinations'] = formula.numberOfCombinations()
        })
      } else {
        selectedFormulas.forEach(formula => {
          this.data.AllUpFormulas.push({
            Number: formula.number(),
            Combinations: formula.numberOfCombinations(),
          })
        })
      }
    }
  }

  private transformSingleLegTabs(
    bettingContext: BettingInformation,
    results: ISelectionResult
  ): LegacyTabModel[] {
    const tabs: LegacyTabModel[] = []

    if (bettingContext.checkForAllwaysBet() && bettingContext.isAllwaysBet()) {
      const tabViewModel = new LegacyTabModel()
      tabViewModel.RaceNo = bettingContext.raceNumber
      tabViewModel.Sel = results.selectionStrings[0]
      tabs.push(tabViewModel)

      results.selectionStrings.slice(1).forEach(() => {
        const tabViewModel = new LegacyTabModel()
        tabViewModel.RaceNo = bettingContext.raceNumber
        tabViewModel.Sel = ''
        tabs.push(tabViewModel)
      })
    } else {
      results.selectionStrings.forEach(selString => {
        const tabViewModel = new LegacyTabModel()
        tabViewModel.RaceNo = bettingContext.raceNumber
        tabViewModel.Sel = selString
        tabs.push(tabViewModel)
      })
    }

    return tabs
  }

  private transformLegTabs(bettingContext: BettingInformation): LegacyTabModel[] {
    const tabs: LegacyTabModel[] = []

    for (const legSelection of bettingContext.getLegsForProcessing()) {
      const tabViewModel = new LegacyTabModel()
      tabViewModel.RaceNo = legSelection.raceKey().raceNumber()
      tabViewModel.Sel = bettingContext.resultsForLeg(
        legSelection.raceKey().leg()
      ).selectionStrings[0]
      if (bettingContext.selectedBetType().betType() === BetType.AllUp) {
        tabViewModel.AllUpBetType = legSelection.raceKey().poolInfo.selectedPool().name()
      }
      tabs.push(tabViewModel)
    }

    return tabs
  }

  private createModel(bettingContext: BettingInformation, shouldBeFormDataCompatible: boolean) {
    this.data.BetType = BetType[bettingContext.selectedBetType().betType()]
    this.data.MeetingDate = moment(bettingContext.meetingDate).format('YYYY-MM-DD')
    this.data.MeetingId = bettingContext.meetingId
    this.data.IsSpecialFobBet = false
    this.data.HasInvestment = true
    this.data.Investment1 = bettingContext.investment1.amount()
    this.data.Investment2 = bettingContext.investment2.amount()

    if (!bettingContext.selectedBetType().multiBet()) {
      this.processSingleLeg(bettingContext, shouldBeFormDataCompatible)
    } else {
      this.processMultiLeg(bettingContext, shouldBeFormDataCompatible)
    }

    this.data.PyosSelectedSpecial = bettingContext.selectedSpecial
    this.data.SpecialOffers = bettingContext.specialOffers
  }

  private processMultiLeg(bettingContext: BettingInformation, shouldBeFormDataCompatible: boolean) {
    this.data.IsRovingBanker = false
    this.data.IsAllways = false
    const tabs = this.transformLegTabs(bettingContext)
    this.data.FixedPlacesPaying = bettingContext.fixedPlacesPaying as number
    this.data.IsFlexi = bettingContext.selectedBetType().supportsFlexi

    if (bettingContext.selectedBetType().isAllUp()) {
      this.data.RaceSelections = tabs.map(tab => tab.RaceNo).join(',')
    }

    if (shouldBeFormDataCompatible) {
      this.configureMultiLegTabsAsFormDataFormat(bettingContext, tabs)
    } else {
      this.configureTabs(bettingContext, tabs)
    }
  }

  private processSingleLeg(
    bettingContext: BettingInformation,
    shouldBeFormDataCompatible: boolean
  ) {
    const results = bettingContext.results()
    if (bettingContext.isFixed()) {
      this.data.PropositionNumber = (results as FobSelectionResult).propositionNumber
      this.data.FixedWinPrice = Number((results as FobSelectionResult).winPrice)
      if (
        (
          bettingContext.meetingInformation as ObservableMeetingInformation
        ).selectedRace.fixedOddsInfo.isPlaceAvailable()
      ) {
        this.data.FixedPlacePrice = Number((results as FobSelectionResult).placePrice)
      }
    }

    this.data.IsRovingBanker = bettingContext.rovingBanker()
    this.data.IsAllways = bettingContext.isAllwaysBet()

    const tabs = this.transformSingleLegTabs(bettingContext, results)
    if (bettingContext.checkForAllwaysBet()) {
      this.data.IsAllways = bettingContext.isAllwaysBet()
    }

    if (bettingContext.selectedBetType().isQuinella() && !this.data.IsAllways) {
      this.data.IsQuinellaLegIn = true
    }

    this.data.FixedPlacesPaying = bettingContext.fixedPlacesPaying as number
    this.data.IsFlexi = bettingContext.selectedBetType().supportsFlexi

    if (shouldBeFormDataCompatible) {
      this.configureSingleLegTabsAsFormDataFormat(bettingContext, results, tabs)
    } else {
      this.configureTabs(bettingContext, tabs)
    }
  }

  private configureTabs(bettingContext: BettingInformation, tabs: LegacyTabModel[]) {
    this.data.BetIdentifier = bettingContext.betIdentifier as string
    this.data.Tabs = tabs
  }

  private configureSingleLegTabsAsFormDataFormat(
    bettingContext: BettingInformation,
    selectionResult: ISelectionResult,
    tabs: LegacyTabModel[]
  ) {
    if (
      bettingContext.isFixed() &&
      (bettingContext.meetingInformation as ObservableMeetingInformation).selectedRace
        .fixedOddsInfo != null
    ) {
      // @ts-expect-error Type issues
      this.data['FixedBettingConditions'] = (
        bettingContext.meetingInformation as ObservableMeetingInformation
      ).selectedRace.fixedOddsInfo.bettingConditions()

      // @ts-expect-error Type issues
      this.data['FixedBettingRules'] = (
        bettingContext.meetingInformation as ObservableMeetingInformation
      ).selectedRace.fixedOddsInfo.bettingRules()

      // @ts-expect-error Type issues
      this.data['FixedBettingTerms'] = (
        bettingContext.meetingInformation as ObservableMeetingInformation
      ).selectedRace.fixedOddsInfo.bettingTerms()
    }

    if (tabs != undefined && tabs.length > 0) {
      tabs.forEach((tab, index) => {
        // @ts-expect-error Type issues
        this.data['Tabs[' + index + '].RaceNo'] = tab.RaceNo
        // @ts-expect-error Type issues
        this.data['Tabs[' + index + '].Sel'] = tab.Sel
      })
    }
  }

  private configureMultiLegTabsAsFormDataFormat(
    bettingContext: BettingInformation,
    tabs: LegacyTabModel[]
  ) {
    if (tabs != undefined && tabs.length > 0) {
      tabs.forEach((tab, index) => {
        // @ts-expect-error Type issues
        this.data['Tabs[' + index + '].RaceNo'] = tab.RaceNo
        // @ts-expect-error Type issues
        this.data['Tabs[' + index + '].Sel'] = tab.Sel
        if (bettingContext.selectedBetType().isAllUp()) {
          // @ts-expect-error Type issues
          this.data['Tabs[' + index + '].AllUpBetType'] = tab.AllUpBetType
        }
      })
    }
  }
}
