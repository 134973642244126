import React from 'react'
import styled from '@emotion/styled'
import { hexColors, Constants } from '@mobi/settings'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { PrimaryButtonStyled, SecondaryButtonStyled } from '@mobi/component-library/Common/Buttons'
import { font } from '@mobi/component-library/Theme/Common'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as navigationState$ } from '@core/State/Navigation/driver'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackSideMenuEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useGetAccountDetails } from '@core/Areas/Account/helpers/useGetAccountDetails'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { useLoginState } from '@core/Areas/Login/hooks'
import { Greeting } from '../Greeting/Greeting'
import { BalanceSection } from './Components/BalanceSection/BalanceSection'
import { usePendingTicketsCount } from '@core/Data/Account/PendingTicketsCount/usePendingTicketsCount'
import { Badge } from '@core/Components/Badge/Badge'
import { openDeposit } from '@core/Areas/Deposit'
import { store } from '@core/Store'
import { useFeature, useObservableImmutable } from '@core/Utils/hooks'
import { HamburgerMenuExternalLink, HamburgerMenuLink } from './HamburgerMenuLink'
import {
  AccountDetailsSectionStyled,
  AccountNameLabelStyled,
  AccountNumberLabelStyled,
  CloseButtonStyled,
  DepositWithdrawSectionStyled,
  HamburgerMenuSectionHeaderStyled,
  JustifiedLinkText,
  LoginSignUpSectionStyled,
  SeparatorStyled,
} from './HamburgerMenu.styles'
import { trackSignUpClick } from '@core/Areas/Account/SignUp/analytics'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'
import { toggleIsDropDownOpen } from '../../Store'

const enum LocalConstants {
  LoadingText = 'Loading...',
}

export const HamburgerMenu: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const isNewWithdrawFeatureActive = useFeature('WITHDRAW_UPLIFT')
  const containerRef = React.useRef<HTMLDivElement>(null)

  const { isLoggedIn } = useObservableImmutable(userAccountState$, ['isLoggedIn'])

  const {
    accountName = LocalConstants.LoadingText,
    accountNumber = LocalConstants.LoadingText,
    activeCampaignsCount = 0,
    blackBookAlertCount = 0,
  } = useGetAccountDetails(true)?.toJS() || {}

  const { handlers } = useLoginState()

  const { doPendingBetsExceedMaxCount, pendingBetsCount } = usePendingTicketsCount({
    enabled: !!isLoggedIn,
  })

  React.useEffect(() => {
    const subscription = navigationState$
      .map(navState => navState.toJS().currentUrl)
      .skip(1)
      .distinctUntilChanged()
      .subscribe(onClose)
    return () => subscription?.dispose()
  }, [onClose])

  React.useEffect(() => {
    store.dispatch(toggleIsDropDownOpen(false))
  }, [])

  return (
    <HamburgerMenuStyled
      ref={containerRef}
      id='hamburger-menu-container'
      className='hamburger-menu'
      data-tid-hamburger-menu=''
      role='dialog'
    >
      <CloseButtonStyled
        role='button'
        id='hamburger-close'
        onClick={() => {
          trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-close-button' })
          onClose()
        }}
      >
        <Icon type='cross' color={hexColors.studio} size='1rem' title='Close menu' />
      </CloseButtonStyled>

      {isLoggedIn && (
        <AccountDetailsSectionStyled>
          <Greeting />
          <AccountNameLabelStyled>{accountName}</AccountNameLabelStyled>
          <AccountNumberLabelStyled>Account # {accountNumber}</AccountNumberLabelStyled>
        </AccountDetailsSectionStyled>
      )}

      {!isLoggedIn && (
        <LoginSignUpSectionStyled>
          <SecondaryButtonStyled
            id='hamburger-sign-up'
            onClick={() => {
              trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-sign-up' })
              onClose()
              handlers.signUp()
              trackSignUpClick('hamburger')
            }}
          >
            Sign up
          </SecondaryButtonStyled>
          <PrimaryButtonStyled
            id='hamburger-login'
            onClick={() => {
              trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-login' })
              onClose()
              showLogin()
            }}
          >
            Log in
          </PrimaryButtonStyled>
        </LoginSignUpSectionStyled>
      )}

      {isLoggedIn && (
        <>
          <SeparatorStyled />
          <BalanceSection />
        </>
      )}

      {isLoggedIn && (
        <>
          <SeparatorStyled />
          <DepositWithdrawSectionStyled>
            <SecondaryButtonStyled
              id='hamburger-deposit'
              onClick={() => {
                trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-deposit' })
                onClose()
                openDeposit()
              }}
            >
              <Icon type='arrowdownwithtail' size='1.5rem' color={hexColors.studio} />
              Deposit
            </SecondaryButtonStyled>

            <SecondaryButtonStyled
              id='hamburger-withdraw'
              href='/#account/withdrawal'
              onClick={() => {
                trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-withdraw' })
                onClose()
              }}
            >
              <Icon type='arrowupwithtail' size='1.5rem' color={hexColors.studio} />
              Withdraw
            </SecondaryButtonStyled>
          </DepositWithdrawSectionStyled>
        </>
      )}

      {(isLoggedIn || isReactNativeApp) && (
        <>
          <SeparatorStyled />
          <HamburgerMenuSectionHeaderStyled>My Bets</HamburgerMenuSectionHeaderStyled>
        </>
      )}

      {isLoggedIn && (
        <>
          <HamburgerMenuLink to='/account/activity/pending' id='hamburger-pending-bets'>
            <JustifiedLinkText>
              Pending bets
              {pendingBetsCount > 0 && (
                <Badge
                  count={pendingBetsCount}
                  showPlusIcon={doPendingBetsExceedMaxCount}
                  data-testid='hamburger-menu-pending-bets-badge'
                />
              )}
            </JustifiedLinkText>
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/activity/results' id='hamburger-resulted-bets'>
            Resulted bets
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/blackbook' id='hamburger-blackbook'>
            <JustifiedLinkText>
              Blackbook
              {blackBookAlertCount > 0 && (
                <Badge count={blackBookAlertCount} data-testid='hamburger-blackbook-badge' />
              )}
            </JustifiedLinkText>
          </HamburgerMenuLink>
        </>
      )}

      {isReactNativeApp && (
        <HamburgerMenuLink
          to='/'
          id='hamburger-scan-my-bet'
          onClick={e => {
            trackSideMenuEvent(keys.hamburgerMenuClicked, {
              item: 'hamburger-scan-my-bet',
            })
            e.preventDefault()
            sendToNative('SCAN_RETAIL_TICKET')
            onClose()
          }}
        >
          Scan my bet
        </HamburgerMenuLink>
      )}

      {isLoggedIn && (
        <HamburgerMenuLink to='/account/activity/transactions' id='hamburger-debit-credit'>
          Debit/credit
        </HamburgerMenuLink>
      )}

      <SeparatorStyled />

      <HamburgerMenuSectionHeaderStyled>Racing</HamburgerMenuSectionHeaderStyled>

      <HamburgerMenuLink to='/next-events' id='hamburger-next-races'>
        Next races
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/tote?code=races' id='hamburger-races'>
        Races
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/tote?code=trots' id='hamburger-trots'>
        Trots
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/tote?code=dogs' id='hamburger-dogs'>
        Dogs
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/favourite-numbers' id='hamburger-favourite-numbers'>
        Favourite numbers
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/tote/mystery' id='hamburger-mystery-bet'>
        Mystery bet
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/jackpots' id='hamburger-jackpots'>
        Jackpots
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/goodthings' id='hamburger-good-things'>
        Good things
      </HamburgerMenuLink>

      <SeparatorStyled />

      <HamburgerMenuSectionHeaderStyled>Sport</HamburgerMenuSectionHeaderStyled>

      <HamburgerMenuLink to='/tabtouch-sports/starting-soon' id='hamburger-next-sport'>
        Next sport
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/tabtouch-sports/in-play' id='hamburger-live-sport'>
        Live sport
      </HamburgerMenuLink>

      <HamburgerMenuLink to='/tabtouch-sports/home' id='hamburger-all-sport'>
        All sport
      </HamburgerMenuLink>

      <SeparatorStyled />

      <HamburgerMenuSectionHeaderStyled>Insider Tips</HamburgerMenuSectionHeaderStyled>

      <HamburgerMenuExternalLink
        href='https://blog.tabtouch.com.au/'
        id='hamburger-blog'
        onClick={e => {
          trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-blog' })
          if (!isReactNativeApp) {
            return
          }
          e.preventDefault()
          sendToNative('REQUEST_NATIVE_NAVIGATION', { location: 'Blog' })
        }}
      >
        Blog
      </HamburgerMenuExternalLink>

      <SeparatorStyled />

      {isLoggedIn && (
        <>
          <HamburgerMenuSectionHeaderStyled tabIndex={undefined}>
            Profile
          </HamburgerMenuSectionHeaderStyled>

          {activeCampaignsCount > 0 && (
            <HamburgerMenuLink to='/account/offer' id='hamburger-bonus-offers'>
              Bonus offers
            </HamburgerMenuLink>
          )}

          <HamburgerMenuLink to='/account/settings' id='hamburger-settings'>
            Settings <em>New</em>
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/contactdetails' id='hamburger-personal-details'>
            My contact details
          </HamburgerMenuLink>

          {!isNewWithdrawFeatureActive && (
            <HamburgerMenuLink to='/account/bankaccount' id='hamburger-bank-account'>
              Bank account
            </HamburgerMenuLink>
          )}

          <HamburgerMenuLink to='/account/marketingcommunications' id='hamburger-subscription'>
            Subscription
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/change-password' id='hamburger-change-password'>
            Change password
          </HamburgerMenuLink>
          <SeparatorStyled />
        </>
      )}

      <HamburgerMenuSectionHeaderStyled tabIndex={undefined}>
        Support
      </HamburgerMenuSectionHeaderStyled>

      <HamburgerMenuLink to='/contactus' id='hamburger-contact-us'>
        Contact us
      </HamburgerMenuLink>

      <HamburgerMenuExternalLink
        href='https://help.tabtouch.com.au'
        id='hamburger-help-and-support'
        onClick={() => {
          trackOptimoveEvent({
            eventName: 'set_page_visit',
            data: {
              customURL: 'https://help.tabtouch.com.au',
              pageTtile: 'HELP',
            },
          })
        }}
      >
        Help & support
      </HamburgerMenuExternalLink>

      <HamburgerMenuExternalLink
        href='https://www.rwwa.com.au/privacy-policy/'
        id='hamburger-privacy-policy'
      >
        Privacy policy
      </HamburgerMenuExternalLink>

      <HamburgerMenuLink
        to='/tablocator'
        id='hamburger-find-a-tab'
        onClick={e => {
          trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-find-a-tab' })
          if (isReactNativeApp) {
            e.preventDefault()
            sendToNative('REQUEST_NATIVE_NAVIGATION', { location: 'TAB Locator' })
            onClose()
          }
        }}
      >
        Find my TAB
      </HamburgerMenuLink>

      {isReactNativeApp && (
        <HamburgerMenuLink
          to='/'
          id='hamburger-what-is-new'
          onClick={e => {
            trackSideMenuEvent(keys.hamburgerMenuClicked, { item: 'hamburger-what-is-new' })
            e.preventDefault()
            sendToNative('REQUEST_NATIVE_NAVIGATION', { location: "What's New" })
            onClose()
          }}
        >
          What's new
        </HamburgerMenuLink>
      )}

      <SeparatorStyled />

      <HamburgerMenuSectionHeaderStyled tabIndex={undefined}>
        Responsible wagering
      </HamburgerMenuSectionHeaderStyled>

      {isLoggedIn && (
        <>
          <HamburgerMenuLink to='/account/deposit-limits' id='hamburger-deposit-limits'>
            Deposit limits
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/precommitment' id='hamburger-bet-limits'>
            Bet limits
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/take-a-break' id='hamburger-take-a-break'>
            Take a break
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/close' id='hamburger-close-account'>
            Close account
          </HamburgerMenuLink>

          <HamburgerMenuLink to='/account/activity-statements' id='hamburger-activity-statement'>
            Activity statement
          </HamburgerMenuLink>
        </>
      )}

      <HamburgerMenuExternalLink
        href={Constants.ResponsibleWageringURL}
        id='hamburger-responsible-wagering'
      >
        Responsible wagering
      </HamburgerMenuExternalLink>
    </HamburgerMenuStyled>
  )
}

// ======
// Styles
// ======

const HamburgerMenuStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  backgroundColor: hexColors.white,
  fontFamily: font.family.secondary,
})
