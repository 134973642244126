import Decimal from 'decimal.js'
import { injectable } from 'inversify'
import {
  BetSpecialOffer,
  IBetSpecialOffer,
  IBetSpecialOfferElement,
} from '../Model/BetSpecialOffer'
import { Stake } from '../Model/Stake'
import { PriceDetails } from '../Model/PriceDetails'
import { LegTypeCode } from '../Model/LegTypeCode'

export interface IPyosRewardCalculator {
  checkEligible(specialOffer: BetSpecialOffer, stake: Stake): boolean
  calculateProjectedReward(
    specialOffer: BetSpecialOffer,
    stake: Stake,
    priceDetails: PriceDetails
  ): decimal.Decimal
  calculateProjectedBoostReward(specialOffer: BetSpecialOffer, stake: Stake): decimal.Decimal
}

@injectable()
export class PyosRewardCalculator {
  legTypeIsEligible(offerlegTypeCode: string, legTypeCode: string): boolean {
    return legTypeIsEligible(offerlegTypeCode, legTypeCode)
  }

  validateStake(
    specialOfferElement: IBetSpecialOfferElement,
    individualStake: decimal.Decimal
  ): decimal.Decimal {
    return validateStake(specialOfferElement, individualStake)
  }

  calculateLegReward(
    specialOfferElement: IBetSpecialOfferElement,
    individualStake: decimal.Decimal,
    dollarReturn: decimal.Decimal,
    offerLegTypeCode: string,
    calculationLegTypeCode: string
  ): decimal.Decimal {
    return calculateLegReward(
      specialOfferElement,
      individualStake,
      dollarReturn,
      offerLegTypeCode,
      calculationLegTypeCode
    )
  }

  calculateLegBoostReward(
    specialOfferElement: IBetSpecialOfferElement,
    individualStake: decimal.Decimal,
    offerLegTypeCode: string,
    calculationLegTypeCode: string
  ): decimal.Decimal {
    if (legTypeIsEligible(offerLegTypeCode, calculationLegTypeCode)) {
      const stake = validateStake(specialOfferElement, individualStake)

      const priceIncreased = specialOfferElement.priceIncrease
        ? calculationLegTypeCode === LegTypeCode.Place
          ? new Decimal(specialOfferElement.priceIncrease.place || 0)
          : new Decimal(specialOfferElement.priceIncrease.win || 0)
        : new Decimal(0)

      return stake.times(priceIncreased)
    }

    return new Decimal(0)
  }

  checkEligible(specialOffer: IBetSpecialOffer, stake: Stake) {
    return checkSuperPickEligible(specialOffer, stake)
  }

  calculateProjectedReward(
    specialOffer: IBetSpecialOffer,
    stake: Stake,
    priceDetails: PriceDetails
  ): decimal.Decimal {
    return calculateProjectedReward(specialOffer, stake, priceDetails)
  }

  calculateProjectedBoostReward(specialOffer: IBetSpecialOffer, stake: Stake): decimal.Decimal {
    stake = Stake.normalise(stake)

    if (this.checkEligible(specialOffer, stake) && specialOffer.elements) {
      const boostElement = specialOffer.elements?.find(element => element.isBoost)
      if (boostElement) {
        const winReward = this.calculateLegBoostReward(
          boostElement,
          stake.Win,
          specialOffer.legTypeCode as string,
          LegTypeCode.Win
        )
        const placeReward = this.calculateLegBoostReward(
          boostElement,
          stake.Place,
          specialOffer.legTypeCode as string,
          LegTypeCode.Place
        )
        let totalReward = Decimal(winReward).plus(placeReward)

        if (boostElement.maxReward && new Decimal(boostElement.maxReward).greaterThan(0)) {
          totalReward = Decimal.min(boostElement.maxReward, totalReward)
        }

        return new Decimal(totalReward.toFixed(2, Decimal.ROUND_FLOOR))
      }
    }

    return Decimal(0)
  }
}

// ====================
// Composable Functions
// ====================

function validateStake(
  specialOfferElement: IBetSpecialOfferElement,
  individualStake: decimal.Decimal
): decimal.Decimal {
  let stake = new Decimal(individualStake)
  const maxStake = new Decimal(specialOfferElement.maxStake || 0)
  if (maxStake.greaterThan(0)) {
    stake = Decimal.min(maxStake, stake)
  }
  return stake
}

function legTypeIsEligible(offerlegTypeCode: string, legTypeCode: string): boolean {
  return offerlegTypeCode === LegTypeCode.WinPlace || offerlegTypeCode === legTypeCode
}

export function checkSuperPickEligible(specialOffer: IBetSpecialOffer, stake: Stake): boolean {
  stake = Stake.normalise(stake)
  if (specialOffer.isExclusiveLegType) {
    return (
      (specialOffer.legTypeCode === LegTypeCode.Win && stake.Place.isZero()) ||
      (specialOffer.legTypeCode === LegTypeCode.Place && stake.Win.isZero()) ||
      (specialOffer.legTypeCode === LegTypeCode.WinPlace &&
        (stake.Win.isZero() || stake.Place.isZero()))
    )
  } else {
    return (
      (specialOffer.legTypeCode === LegTypeCode.Win &&
        (!stake.Win.isZero() || stake.Place.isZero())) ||
      (specialOffer.legTypeCode === LegTypeCode.Place &&
        (stake.Win.isZero() || !stake.Place.isZero())) ||
      specialOffer.legTypeCode === LegTypeCode.WinPlace
    )
  }
}

function calculateLegReward(
  specialOfferElement: IBetSpecialOfferElement,
  individualStake: decimal.Decimal,
  dollarReturn: decimal.Decimal,
  offerLegTypeCode: string,
  calculationLegTypeCode: string
): decimal.Decimal {
  if (legTypeIsEligible(offerLegTypeCode, calculationLegTypeCode)) {
    const stake = validateStake(specialOfferElement, individualStake)

    const profit = new Decimal(dollarReturn).plus(-1.0)
    const actualProfitMultiplier = new Decimal(profit).times(
      specialOfferElement.profitMultiplier || 0
    )
    const combinedMultiplier = new Decimal(actualProfitMultiplier).plus(
      specialOfferElement.stakeMultiplier || 0
    )

    return stake.times(combinedMultiplier)
  }

  return new Decimal(0)
}

export function calculateProjectedReward(
  specialOffer: IBetSpecialOffer,
  stake: Stake,
  priceDetails: PriceDetails
): decimal.Decimal {
  stake = Stake.normalise(stake)
  priceDetails = PriceDetails.normalise(priceDetails)

  if (checkSuperPickEligible(specialOffer, stake) && specialOffer.elements) {
    const nonBoostElement = specialOffer.elements?.find(element => !element.isBoost)
    if (nonBoostElement) {
      const winReward = calculateLegReward(
        nonBoostElement,
        stake.Win,
        priceDetails.Win.DollarReturn,
        specialOffer.legTypeCode as string,
        LegTypeCode.Win
      )
      const placeReward = calculateLegReward(
        nonBoostElement,
        stake.Place,
        priceDetails.Place.DollarReturn,
        specialOffer.legTypeCode as string,
        LegTypeCode.Place
      )
      let totalReward = Decimal(winReward).plus(placeReward)

      if (nonBoostElement.maxReward && new Decimal(nonBoostElement.maxReward).greaterThan(0)) {
        totalReward = Decimal.min(nonBoostElement.maxReward, totalReward)
      }

      return new Decimal(totalReward.toFixed(2, Decimal.ROUND_FLOOR))
    }
  }

  return Decimal(0)
}
