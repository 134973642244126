import * as ko from 'knockout'
import { IAppWindow } from '../../../AppUtils/Framework/WindowManagement/IAppWindow'
import { ISelection } from '../../Components/Core/UIElements/ISelection'
import Guard from '../../../AppUtils/Framework/Guard'
import { RaceTypes } from '../../Model/RaceTypes'
import ObservableProvDivPool from '../../Model/Observables/ObservableProvDivPool'
import { SilkImages, SilkImage } from '../SilkImages'
import ObservableFixedOddsStarterInfo from './ObservableFixedOddsStarterInfo'
import { IObservableStarter } from './IObservableStarter'
import { MarketMover } from '../MarketMovers'

export abstract class ObservableStarter implements IObservableStarter {
  constructor() {
    // @ts-expect-error Type issue
    this.tag = ko.observable()
    // @ts-expect-error Type issue
    this.template = ko.observable()
    // @ts-expect-error Type issue
    this.number = ko.observable()
    // @ts-expect-error Type issue
    this.type = ko.observable()
    // @ts-expect-error Type issue
    this.name = ko.observable()
    // @ts-expect-error Type issue
    this.silkImages = ko.observable()
    // @ts-expect-error Type issue
    this.isScratched = ko.observable()
    // @ts-expect-error Type issue
    this.hasFixedOdds = ko.observable()
    // @ts-expect-error Type issue
    this.isScratchedToteAndFob = ko.observable()
    // @ts-expect-error Type issue
    this.isFixedOddsStarter = ko.observable()
    this.isFixedScratchedOrSuspended = ko.pureComputed(
      () =>
        this.fixedOddsStarterInfo &&
        (this.fixedOddsStarterInfo.isScratched() || this.fixedOddsStarterInfo.isSuspended())
    )
    // @ts-expect-error Type issue
    this.isFavourite = ko.observable()
    // @ts-expect-error Type issue
    this.starterPersonName = ko.observable()
    // @ts-expect-error Type issue
    this.trainerName = ko.observable()
    // @ts-expect-error Type issue
    this.isEmergency = ko.observable()
    // @ts-expect-error Type issue
    this.emergencyNum = ko.observable()
    // @ts-expect-error Type issue
    this.emres = ko.observable()
    // @ts-expect-error Type issue
    this.isToteEnabled = ko.observable()
    // @ts-expect-error Type issue
    this.hasPlacePool = ko.observable()
    // @ts-expect-error Type issue
    this.scratchType = ko.observable()
    // @ts-expect-error Type issue
    this.displayWinDividend = ko.observable()
    // @ts-expect-error Type issue
    this.displayPlaceDividend = ko.observable()
    // @ts-expect-error Type issue
    this.lastFour = ko.observable()
    this.fixedOddsStarterInfo = new ObservableFixedOddsStarterInfo()
    this.isSameRaceMultiSuspended = ko.pureComputed(() =>
      this.fixedOddsStarterInfo.sameRaceMultiPrices.isSuspended()
    )
    this.selection = ko.observable() as ko.Observable<ISelection>
    this.provDivPools = ko.observableArray<ObservableProvDivPool>()
    // @ts-expect-error Type issue
    this.rating = ko.observable()
    // @ts-expect-error Type issue
    this.isRiderChanged = ko.observable()
    this.isFormAvailable = ko.observable<boolean>(false)
    this.marketMovers = ko.observableArray<MarketMover>()
    this.hasMarketMovers = ko.observable<boolean>(false)
    // @ts-expect-error Type issue
    this.propositionSequence = ko.observable()
  }

  merge(other: ObservableStarter) {
    Guard.notNull(other)

    this.tag(other.tag())
    this.template(other.template())
    this.number(other.number())
    this.type(other.type())
    this.name(other.name())
    this.silkImages(other.silkImages())
    this.isScratched(other.isScratched())
    this.hasFixedOdds(other.hasFixedOdds())
    this.isScratchedToteAndFob(other.isScratchedToteAndFob())
    this.isFixedOddsStarter(other.isFixedOddsStarter())
    this.isFavourite(other.isFavourite())
    this.starterPersonName(other.starterPersonName())
    this.trainerName(other.trainerName())
    this.isEmergency(other.isEmergency())
    this.emergencyNum(other.emergencyNum())
    this.isToteEnabled(other.isToteEnabled())
    this.hasPlacePool(other.hasPlacePool())
    this.scratchType(other.scratchType())
    this.displayWinDividend(other.displayWinDividend())
    this.displayPlaceDividend(other.displayPlaceDividend())
    this.lastFour(other.lastFour())
    this.fixedOddsStarterInfo.merge(other.fixedOddsStarterInfo)
    this.provDivPools(other.provDivPools())
    this.rating(other.rating())
    this.isRiderChanged(other.isRiderChanged())
    this.marketMovers(other.marketMovers())
    this.hasMarketMovers(other.hasMarketMovers())
    this.propositionSequence(other.propositionSequence())

    this.computeEmRes()
  }

  public untypedMerge(other: IObservableStarter) {
    this.merge(other as ObservableStarter)
  }

  public getSilkImage(appWindow: IAppWindow): SilkImage {
    if (this.silkImages()) {
      return (this.silkImages() as SilkImages).getSilkImageFor(appWindow.innerWidth())
    }
    return new SilkImage()
  }

  public computeEmRes() {
    let emres = ''

    if (this.emergencyNum()) {
      switch (this.type()) {
        case RaceTypes[RaceTypes.Races]:
        case RaceTypes[RaceTypes.Trots]:
          emres = '(EM' + this.emergencyNum() + ')'
          break

        case RaceTypes[RaceTypes.Dogs]:
          emres = '(RES' + this.emergencyNum() + ')'
          break

        default:
          emres = '(UNKNOWN)'
      }
    }

    this.emres(emres)
  }

  public disposeSelection(): void {
    if (this.selection != null && this.selection() != null) {
      this.selection().dispose()
    }
    this.selection = null as unknown as ko.Observable<ISelection>
  }

  public displayPlaceDividend: ko.Observable<string>
  public displayWinDividend: ko.Observable<string>
  public emergencyNum: ko.Observable<number>
  public emres: ko.Observable<string>
  public fixedOddsStarterInfo: ObservableFixedOddsStarterInfo
  public hasFixedOdds: ko.Observable<boolean>
  public hasMarketMovers: ko.Observable<boolean>
  public hasPlacePool: ko.Observable<boolean>
  public isEmergency: ko.Observable<boolean>
  public isFavourite: ko.Observable<boolean>
  public isFixedOddsStarter: ko.Observable<boolean>
  public isFixedScratchedOrSuspended: ko.PureComputed<boolean>
  public isFormAvailable: ko.Observable<boolean>
  public isRiderChanged: ko.Observable<boolean>
  public isSameRaceMultiSuspended: ko.PureComputed<boolean | undefined>
  public isScratched: ko.Observable<boolean>
  public isScratchedToteAndFob: ko.Observable<boolean>
  public isToteEnabled: ko.Observable<boolean>
  public lastFour: ko.Observable<string>
  public marketMovers: ko.ObservableArray<MarketMover>
  public name: ko.Observable<string>
  public number: ko.Observable<number>
  public propositionSequence: ko.Observable<number>
  public provDivPools: ko.ObservableArray<ObservableProvDivPool>
  public rating: ko.Observable<number>
  public scratchType: ko.Observable<string>
  public selection: ko.Observable<ISelection>
  public silkImages: ko.Observable<SilkImages>
  public starterPersonName: ko.Observable<string>
  public tag: ko.Observable<string>
  public template: ko.Observable<string>
  public trainerName: ko.Observable<string>
  public type: ko.Observable<string>
}
