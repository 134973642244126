import React from 'react'
import { fromJS } from 'immutable'
import type { MysteryBetTypes } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'
import { MysteryQuickPickOptions } from '@core/Areas/Racing/Components/Mystery/MysteryTypes'
import { createMysterySelection } from '@core/Areas/Racing/Components/Mystery/utils'
import { MysteryQuickPicks } from '@core/Areas/Racing/Components/Mystery/Components/MysteryQuickPick'
import { BettingDrawer } from '@core/Components/BettingDrawer'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { createRaceObjectFromResponse } from '@core/Data/Racing'
import { useObservableImmutable } from '@core/Utils/hooks'
import { NumberOfBets } from './NumberOfBets'
import { ReviewMysteryDetails } from './ReviewMysteryDetails'
import { SetBetError, SetSelectedQuickPick, state$ } from '../driver'
import {
  MysteryHeadingStyled,
  MysteryBetContainerStyled,
  BettingDrawerSpacerStyled,
} from '../MysteryBetPage.styles'

export const QuickPick = (): JSX.Element => {
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false)
  const mysteryData = useObservableImmutable(state$, ['currentData']).currentData?.toJS()
  const selectedQuickPick = useObservableImmutable(state$, ['selectedQuickPick']).selectedQuickPick
  const selectedBetCount = useObservableImmutable(state$, [
    'selectedQuickPickBetCount',
  ]).selectedQuickPickBetCount
  const isRaceClosed = useObservableImmutable(state$, ['isRaceClosed']).isRaceClosed
  const hasAnyDisabledOptions = !!mysteryData?.DisabledQuickPickOptions?.length
  const shouldBettingDrawerBeOpen = !isRaceClosed && !!selectedQuickPick && !!selectedBetCount

  const selectedMysteryOption = MysteryQuickPickOptions.find(x => x.uniqueKey === selectedQuickPick)
  const selectedDisplayPrice = selectedMysteryOption?.displayPrice
  const mysterySelection =
    selectedMysteryOption && mysteryData
      ? createMysterySelection({
          betType: 'mystery-quick-pick',
          betTypeName: selectedMysteryOption.uniqueKey,
          investmentWin: selectedMysteryOption.investment,
          investmentPlace: 0,
          isAlways: selectedMysteryOption.isAlways,
          mysteryOption: selectedMysteryOption.mysteryOption,
          mysteryType: selectedMysteryOption.mysteryType,
          productCode: selectedMysteryOption.productCode,
          raceInformation: fromJS(createRaceObjectFromResponse(mysteryData.Meeting)),
        })
      : undefined

  return (
    <MysteryBetContainerStyled>
      <MysteryHeadingStyled>Bet Type</MysteryHeadingStyled>
      <MysteryQuickPicks
        selectedKey={isRaceClosed ? null : selectedQuickPick}
        disabledQuickPicks={
          isRaceClosed
            ? MysteryQuickPickOptions.map(x => x.uniqueKey)
            : mysteryData?.DisabledQuickPickOptions
        }
        optionsPerRow={2}
        selectionCallback={SetSelectedQuickPick}
      />
      {hasAnyDisabledOptions && (
        <NoticeBox
          title='Not all mystery options are available for this race.'
          noticeBoxType={NoticeBoxTypes.Info}
          hasBorder={true}
        />
      )}
      <br />
      <NumberOfBets isDisabled={isRaceClosed || !selectedQuickPick} type='QuickPick' />
      <BettingDrawerSpacerStyled isOpen={shouldBettingDrawerBeOpen} />
      <BettingDrawer
        open={shouldBettingDrawerBeOpen}
        dataTId='mystery-buttons'
        handleQuickbetClick={() => {
          SetBetError(null)
          if (selectedDisplayPrice && mysterySelection) setIsDetailsOpen(true)
        }}
      />
      {isDetailsOpen &&
        mysteryData &&
        mysterySelection &&
        selectedMysteryOption &&
        selectedDisplayPrice && (
          <ReviewMysteryDetails
            open={isDetailsOpen}
            mysteryData={mysteryData}
            betType={selectedMysteryOption.productCode as MysteryBetTypes}
            optionNumber={selectedMysteryOption.mysteryOption}
            winStake={selectedDisplayPrice}
            placeStake={0}
            investmentWin={selectedMysteryOption.investment}
            numberOfBets={selectedBetCount || 1}
            isRaceClosed={isRaceClosed}
            onClosed={() => setIsDetailsOpen(false)}
          />
        )}
    </MysteryBetContainerStyled>
  )
}
