import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import React from 'react'
import {
  EventInfoContainerStyled,
  EventInfoDescriptionSectionStyled,
  EventInfoIconSectionStyled,
  EventInfoIconStyled,
  EventInfoStatusStyled,
  EventInfoTimeStyled,
  PillStyled,
  RaceDateHeaderStyled,
  HeaderSpecialsIconStyled,
  EventInfoNumberStyled,
  EventInfoStatusTextStyled,
  EventInfoStatusFieldStyled,
  EventInfoPayIndicatorTextStyled,
} from '../RaceCardFixedOnly.styles'
import { hexColors } from '@mobi/settings'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import dayjs from 'dayjs'
import { RaceProduct, RaceStatus } from '../constants'

interface EventInfoProps {
  icon: keyof typeof iconPaths | null
  eventNumber: number
  eventLocation: string
  eventDate: Date
  eventName: string
  eventStatus: RaceStatus
  priceLastUpdateTime: Date
  raceProduct: RaceProduct
  raceCode: 'races' | 'dogs' | 'trots'
  hasSpecials: boolean
  payIndicator?: number
  fieldState: boolean
  onFieldClicked: () => void
}

export const EventInfo = (input: EventInfoProps): JSX.Element | null => {
  return (
    <>
      <RaceDateHeaderStyled level={2}>
        <Grid>
          <GridCell valign='middle' align='center'>
            {dayjs(input.eventDate).format('ddd D MMM YYYY')}
          </GridCell>
        </Grid>
      </RaceDateHeaderStyled>
      <EventInfoContainerStyled>
        <EventInfoIconSectionStyled>
          {input.icon && (
            <EventInfoIconStyled
              raceStatus={input.eventStatus}
              type={input.icon as keyof typeof iconPaths}
              size='3.75em'
              color={hexColors.white}
            />
          )}
          {!input.icon && (
            <EventInfoNumberStyled raceStatus={input.eventStatus} color={hexColors.white}>
              {input.eventNumber}
            </EventInfoNumberStyled>
          )}
          <EventInfoTimeStyled>{dayjs(input.eventDate).format('HH:mm')}</EventInfoTimeStyled>
        </EventInfoIconSectionStyled>
        <EventInfoDescriptionSectionStyled>
          {input.eventLocation && <div>{input.eventLocation}</div>}
          <div>
            {`${input.eventName} `}
            {input.hasSpecials && <HeaderSpecialsIconStyled width='1.8rem' height='1.8rem' />}
          </div>
          <EventInfoStatusStyled>
            <EventInfoStatusTextStyled>
              {getRacingStatusText(input.eventStatus, input.priceLastUpdateTime)}
              {input.raceProduct == RaceProduct.FixedOddsOnly &&
                input.eventStatus != RaceStatus.Abandoned &&
                input.eventStatus != RaceStatus.Suspended &&
                !!input.payIndicator && (
                  <EventInfoPayIndicatorTextStyled>
                    {' '}
                    {` ${getPayIndicatorText(input.payIndicator)}`}
                  </EventInfoPayIndicatorTextStyled>
                )}
            </EventInfoStatusTextStyled>
            <EventInfoStatusFieldStyled>
              {input.raceProduct == RaceProduct.FixedOddsOnly &&
                (input.eventStatus == RaceStatus.Resulted ||
                  input.eventStatus == RaceStatus.Settled) && (
                  <PillStyled color={hexColors.sushi} onClick={input.onFieldClicked}>
                    {input.fieldState ? 'Results' : 'Field'}
                  </PillStyled>
                )}
            </EventInfoStatusFieldStyled>
          </EventInfoStatusStyled>
        </EventInfoDescriptionSectionStyled>
      </EventInfoContainerStyled>
    </>
  )
}

const getRacingStatusText = (raceStatus: RaceStatus, priceLastUpdateTime: Date) => {
  const comparisonFormat = 'MMDDYYYY'
  const displayFormatToday = 'HH:mm:ss'
  const displayFormatNotToday = 'DD MMM HH:mm:ss'
  const raceStatusValue = RaceStatus[raceStatus]

  const lastUpdatedToday =
    dayjs().format(comparisonFormat) == dayjs(priceLastUpdateTime).format(comparisonFormat)

  const lastUpdatedDisplay = dayjs(priceLastUpdateTime).format(
    lastUpdatedToday ? displayFormatToday : displayFormatNotToday
  )

  if (raceStatus == RaceStatus.Open) {
    return priceLastUpdateTime ? `Updated ${lastUpdatedDisplay}` : raceStatusValue
  }

  if (raceStatus == RaceStatus.Settled) {
    return RaceStatus[RaceStatus.Resulted]
  }

  return raceStatusValue
}

const getPayIndicatorText = (payIndicator: number): string | null => {
  if (!payIndicator) {
    return null
  }

  let text = 'Pay '

  for (let i = 1; i <= payIndicator; i++) {
    text += i + ', '
  }

  return text.slice(0, -2)
}
