import Rx from 'rx'
import { filterObject } from './helpers'
import * as apiService from '@classic/Foundation/Services/ApiService'
import { IKeyMap } from 'rwwa-data-access'

// IIS has a 260 char limit for all requested URLs
// Without resource type or keys, the URL is 50 chars: https://tabtouch-mobi.local.rwwaq.com.au/api/data/
const MAX_PATH_LENGTH = 260 - 50

interface DataFetcher<TResource> {
  keys: string[]
  result?: IKeyMap<IKeyMap<TResource>>
  error?: Error
}

// Nullable resources are usually fetched from /api/data/resourceType/resourceKeys and
// can be "null" which means they do not exist
// It is different to other fetches which expect non-existant resources to not be null
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchNullableResourcesWithKeys<Map extends Record<string, any>, Resource>(
  keys: string[],
  resourceType: string
): Rx.Observable<DataFetcher<Resource>> {
  if (!keys || !keys.length || !resourceType) {
    return Rx.Observable.just<DataFetcher<Resource>>({
      keys: keys || [],
      error: new Error('Resource type or keys are empty'),
    })
  }

  // 99% of the time keys will be the same length, so assume all keys are as long as the longest key
  const maxKeyLength = keys.reduce((prevKey, key) => Math.max(prevKey, key.length), 0)
  const urlPath = `/api/data/${resourceType}/`
  const availableLengthForKeys = MAX_PATH_LENGTH - urlPath.length
  const keySeparator = ','
  const numToBuffer = Math.floor(availableLengthForKeys / (maxKeyLength + keySeparator.length))

  return (
    Rx.Observable.fromArray(keys)
      .bufferWithCount(numToBuffer)
      .select(bufferedKeys =>
        apiService.get<Map>({
          url: `${urlPath}${bufferedKeys.join(keySeparator)}`,
          opts: { credentials: 'include' },
        })
      )
      .select(promise => Rx.Observable.fromPromise(promise))
      .mergeAll() as unknown as Rx.Observable<Map>
  ).selectMany(result => {
    const success = filterObject<Resource>(result[resourceType], resource => resource !== null)
    const success$ = Rx.Observable.just<DataFetcher<Resource>>({
      keys: Object.keys(success),
      result: { [resourceType]: success },
    })

    const failed = filterObject<Resource>(result[resourceType], resource => resource === null)
    const failedKeys = Object.keys(failed)
    if (failedKeys.length > 0) {
      const failed$ = Rx.Observable.just<DataFetcher<Resource>>({
        keys: failedKeys,
        error: new Error(
          `Failed to fetch resource ${resourceType} keys ${Object.keys(failed).join(',')}`
        ),
      })
      return Rx.Observable.concat(success$, failed$)
    }

    return success$
  })
}
