import { IWinPlaceProcessor } from './IWinPlaceProcessor'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ButtonSelectionType } from '../UIElements/ButtonSelectionType'
import { ButtonsSelection } from '../UIElements/ButtonsSelection'
import Guard from '../../../../AppUtils/Framework/Guard'

export default class WinPlaceFobProcessor implements IWinPlaceProcessor {
  public process(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation
  ): IObservableStarter[] {
    Guard.notNull(starter)
    Guard.notNull(starters)
    Guard.notNull(bettingContext)

    const results: IObservableStarter[] = []
    bettingContext.turnOffFieldAt(starter.selection().raceNumber.leg() - 1)
    bettingContext.isFixed(true)

    const starterSelection = starter.selection() as ButtonsSelection

    starters()
      .filter(
        (x: IObservableStarter) => x.number() !== starter.number() && !x.isScratchedToteAndFob()
      )
      .forEach(element => {
        if (!element.selection) return

        const current = element.selection() as ButtonsSelection

        if (!current) return

        current.value(ButtonSelectionType.None)
      })

    if (starter.isScratchedToteAndFob() || starter.isFixedScratchedOrSuspended()) {
      if (starterSelection) {
        starterSelection.value(ButtonSelectionType.None)
      }
    } else {
      if (starterSelection.hasAny()) {
        results.push(starter)
      }
    }

    // only contains non-scratched selected starter
    return results
  }
}
