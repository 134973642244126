import React from 'react'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'
import { BET_SLIP_BUTTON_ID } from '../../BetSlipButton/constants'
import { LoadingSpinner } from '@mobi/component-library/Feedback/LoadingSpinner'
import { DisplayLocation } from '../../../types'

const enum LocalConstants {
  CountAnimationUpClass = 'js-count-animation--up',
  CountAnimationDownClass = 'js-count-animation--down',
}

export const Button: React.FC<{
  onClick(): void
  type: 'betslip' | 'pending'
  count: number | null
  displayLocation?: DisplayLocation
}> = ({ onClick, type, count, displayLocation = 'global' }) => {
  const prevCountRef = React.useRef<number | null>(count)

  React.useEffect(() => {
    prevCountRef.current = count
  }, [count])

  const isBetSlip = type === 'betslip'
  const ariaLabel = isBetSlip ? 'Bet slip item count' : 'Pending bets count'
  const testId = `${isBetSlip ? 'betslip' : 'pending-bets'}-item-count`

  const animation = count !== null ? getBadgeAnimation(count, prevCountRef.current) : null

  let countAnimationClass = ''
  if (animation) {
    countAnimationClass =
      animation === 'up'
        ? LocalConstants.CountAnimationUpClass
        : LocalConstants.CountAnimationDownClass
  }

  return (
    <ButtonStyled
      data-testid={isBetSlip ? 'header-betslip-btn' : 'header-pending-btn'}
      onClick={onClick}
      id={isBetSlip ? BET_SLIP_BUTTON_ID : ''}
      data-mybets-btn={type}
      data-mybets-display={displayLocation}
    >
      <span className={countAnimationClass} key={count}>
        {count !== null ? (
          <>
            <span aria-label={ariaLabel} data-testid={testId}>
              {count}
            </span>

            {animation && <span aria-hidden>{count + (animation === 'up' ? -1 : 1)}</span>}
          </>
        ) : (
          <LoadingSpinner size='1.6rem' color={isBetSlip ? 'purple' : 'white'} />
        )}
      </span>

      <span>{isBetSlip ? 'Bet Slip' : 'My Bets'}</span>
    </ButtonStyled>
  )
}

// =============
// Local Helpers
// =============

function getBadgeAnimation(count: number, prevCount: number | null): 'up' | 'down' | null {
  if (prevCount === null || prevCount === count) return null
  return count > prevCount ? 'up' : 'down'
}

// ======
// Styles
// ======

const ButtonStyled = styled.button({
  flexShrink: 0,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '4.8rem',
  border: 0,
  touchAction: 'manipulation',
  cursor: 'pointer',

  fontFamily: font.family.primary,
  fontWeight: font.weight.medium,

  // Count
  '> span:nth-of-type(1)': {
    display: 'grid',
    gridTemplateAreas: 'stack',
    height: font.size.lg.lineHeight,
    marginBottom: '-0.2rem',
    overflow: 'hidden',
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,

    '> span': { gridArea: 'stack', animationDirection: 'forwards' },

    '@keyframes animatePrevOutUp': { to: { transform: 'translateY(100%)' } },
    '@keyframes animatePrevOutDown': { to: { transform: 'translateY(-100%)' } },

    [`&.${LocalConstants.CountAnimationUpClass}`]: {
      '> span:nth-of-type(1)': {
        animationName: 'animateCountInBounceDown',
        animationDuration: '0.8s',
      },
      '> span:nth-of-type(2)': {
        animation: 'animatePrevOutDown 0.2s ease forwards',
      },
    },

    [`&.${LocalConstants.CountAnimationDownClass}`]: {
      '> span:nth-of-type(1)': {
        animation: 'animateCountInBounceUp 0.8s ease forwards',
      },
      '> span:nth-of-type(2)': {
        animation: 'animatePrevOutUp 0.2s ease forwards',
      },
    },
  },

  // Label
  '> span:nth-of-type(2)': {
    whiteSpace: 'nowrap',
    fontSize: font.size.xs.fontSize,
    letterSpacing: font.size.xs.letterSpacing,
    lineHeight: font.size.xs.lineHeight,
  },

  backgroundColor: colors.studio[600],
  color: colors.white,

  ['&[data-mybets-btn="betslip"]']: {
    backgroundColor: colors.lavender[100],
    color: colors.studio[600],
  },

  ['&[data-mybets-btn="pending"][data-mybets-display="betslip"]']: {
    color: colors.lavender[700],
    backgroundColor: colors.lavender[50],
  },

  '@keyframes animateCountInBounceDown': {
    '0%': { transform: 'translateY(100%)' },
    '15%': { transform: 'translateY(0.81%)' },
    '16%': { transform: 'translateY(-3.77%)' },
    '17%': { transform: 'translateY(-7.74%)' },
    '18%': { transform: 'translateY(-11.08%)' },
    '19%': { transform: 'translateY(-13.83%)' },
    '20%': { transform: 'translateY(-16%)' },
    '21%': { transform: 'translateY(-17.62%)' },
    '22%': { transform: 'translateY(-18.73%)' },
    '23%': { transform: 'translateY(-19.37%)' },
    '24%': { transform: 'translateY(-20%)' },
    '41%': { transform: 'translateY(0%)' },
    '55%': { transform: 'translateY(3%)' },
    '56%': { transform: 'translateY(2.73%)' },
    '57%': { transform: 'translateY(2.44%)' },
    '58%': { transform: 'translateY(2.14%)' },
    '59%': { transform: 'translateY(1.84%)' },
    '60%': { transform: 'translateY(1.54%)' },
    '61%': { transform: 'translateY(1.25%)' },
    '62%': { transform: 'translateY(0.98%)' },
    '63%': { transform: 'translateY(0.71%)' },
    '64%': { transform: 'translateY(0.47%)' },
    '65%': { transform: 'translateY(0.25%)' },
    '100%': { transform: 'translateY(0%)' },
  },

  '@keyframes animateCountInBounceUp': {
    '0%': { transform: 'translateY(-100%)' },
    '15%': { transform: 'translateY(-0.81%)' },
    '16%': { transform: 'translateY(3.77%)' },
    '17%': { transform: 'translateY(7.74%)' },
    '18%': { transform: 'translateY(11.08%)' },
    '19%': { transform: 'translateY(13.83%)' },
    '20%': { transform: 'translateY(16%)' },
    '24%': { transform: 'translateY(20%)' },
    '41%': { transform: 'translateY(0%)' },
    '55%': { transform: 'translateY(-3%)' },
    '56%': { transform: 'translateY(-2.73%)' },
    '57%': { transform: 'translateY(-2.44%)' },
    '58%': { transform: 'translateY(-2.14%)' },
    '59%': { transform: 'translateY(-1.84%)' },
    '60%': { transform: 'translateY(-1.54%)' },
    '61%': { transform: 'translateY(-1.25%)' },
    '62%': { transform: 'translateY(-0.98%)' },
    '63%': { transform: 'translateY(-0.71%)' },
    '64%': { transform: 'translateY(-0.47%)' },
    '65%': { transform: 'translateY(-0.25%)' },
    '100%': { transform: 'translateY(0%)' },
  },
})
