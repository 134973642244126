import { useMutation } from 'react-query'
import { AppRoutes } from '@core/AppRoutes'
import { navChange } from '@classic/Foundation/Navigation/Nav'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as useAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { showLogin } from '@core/Areas/Login/helpers'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { postAddRunnerToBlackbook } from '@core/Areas/Blackbook/helpers/api'
import {
  trackBlackbookAddRunner,
  trackBlackbookGoToBlackbook,
  trackBlackbookUnableToAdd,
} from '@core/Areas/Blackbook/analytics'
import { useBlackbookRunners } from '@core/Areas/Blackbook/Hooks/useBlackbookRunners'
import { getMatchedRunnerInBlackbook } from '@core/Areas/Blackbook/helpers/getMatchedRunnerInBlackbook'
import type { BlackbookControlProps } from '@core/Areas/Blackbook/types'
import { triggerHapticFeedback } from '@core/Utils/hapticFeedback/hapticFeedback'

export const useAddToBlackbook = ({ starterName, starterType, source }: BlackbookControlProps) => {
  const { isLoggedIn } = useObservableImmutable(useAccountState$, ['isLoggedIn'])
  const { data: blackbookRunners, isLoading: isLoadingRunners } = useBlackbookRunners()

  const matchedRunnerInBlackbook = isLoggedIn
    ? getMatchedRunnerInBlackbook(blackbookRunners, starterName, starterType)
    : undefined

  const { mutate, isLoading } = useMutation<PostRes, void, PostArgs>(
    ({ starterName, starterType }) => postAddRunnerToBlackbook({ starterName, starterType }),
    {
      onSuccess: res => {
        if (!res.success)
          return handleUnableToAdd({
            source,
            starterName,
            starterType,
            errorMessage: res.errorMessage,
          })
        queryClient.invalidateQueries(queryKeys.blackbookRunnersBase, { refetchActive: true })
        queryClient.invalidateQueries({ queryKey: queryKeys.blackbookDetails, refetchActive: true })
        if (source) trackBlackbookAddRunner({ source, name: starterName, code: starterType })
        triggerHapticFeedback('impact-light')
      },
      onError: () => {
        handleUnableToAdd({ source, starterName, starterType })
      },
    }
  )

  const handleAddToBlackbook = () =>
    isLoggedIn
      ? mutate({ starterName, starterType })
      : showLogin({ onLoginSuccessCallback: () => mutate({ starterName, starterType }) })

  const handleNavigateToBlackbook = () => {
    navChange(`#${AppRoutes.Blackbook.slice(1)}?incoming=${matchedRunnerInBlackbook?.Id}`)
    if (source) trackBlackbookGoToBlackbook({ source, name: starterName, code: starterType })
  }

  return {
    isLoading,
    isLoadingRunners,
    matchedRunnerInBlackbook,
    clickHandler: matchedRunnerInBlackbook ? handleNavigateToBlackbook : handleAddToBlackbook,
  }
}

// Local Helpers

function handleUnableToAdd({
  source,
  starterName,
  starterType,
  errorMessage,
}: BlackbookControlProps & { errorMessage?: string }) {
  RegisterToast({
    id: 'blackbook-control-add',
    message: 'Unable to Add to Blackbook',
    type: 'error',
  })
  const reason: Parameters<typeof trackBlackbookUnableToAdd>[0]['reason'] =
    !errorMessage || typeof errorMessage !== 'string'
      ? 'other'
      : errorMessage.toLowerCase().includes('unable to resolve starter')
        ? 'not-matched'
        : errorMessage.toLowerCase().includes('maximum number')
          ? 'limit-exceeded'
          : 'other'
  trackBlackbookUnableToAdd({ source, name: starterName, code: starterType, reason })
}

// Types

type PostArgs = Parameters<typeof postAddRunnerToBlackbook>[0]
type PostRes = Awaited<ReturnType<typeof postAddRunnerToBlackbook>>
