import React from 'react'
import { type BetslipItem, state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { calculateMultiReturn } from '@core/Areas/Betslip/helpers/calculator'
import { getMultiBetslipItems } from '@core/Areas/Racing/Components/SmartBetSlip/Components/MultiToast/helpers/getMultiBetslipItems'
import { MultiToast } from '@core/Areas/Racing/Components/SmartBetSlip/Components/MultiToast/MultiToast'

export const SmartBetslipToast: React.FC = React.memo(() => {
  const multiReturnRef = React.useRef<number>()
  const hasRunFirstSubscriptionCBRef = React.useRef(false)

  const [state, setState] = React.useState<SmartBetslipToastState>({
    shouldShow: false,
    multiBetslipItems: [],
  })

  React.useEffect(() => {
    const subscription = betslipState$.subscribe(state => {
      if (state.isOpen) return

      const { isValidMulti, multiBetslipItems } = getMultiBetslipItems(state.items.toArray())

      if (!isValidMulti) {
        multiReturnRef.current = 0
        setState({ shouldShow: false, multiBetslipItems: [] })
        return
      }

      const newMultiReturn = calculateMultiReturn(state.items, { shouldRound: true })

      if (hasRunFirstSubscriptionCBRef.current && multiReturnRef.current !== newMultiReturn) {
        setState({ shouldShow: true, multiBetslipItems })
      }
      multiReturnRef.current = newMultiReturn
    })

    hasRunFirstSubscriptionCBRef.current = true

    return () => subscription.dispose()
  }, [])

  return <MultiToast show={state.shouldShow} betslipItems={state.multiBetslipItems} />
})

// Types

type SmartBetslipToastState = {
  shouldShow: boolean
  multiBetslipItems: BetslipItem[]
}
