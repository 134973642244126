import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import BetTypeInformation from '@classic/Betting-v2/Model/Betting/BetTypeInformation'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import Guard from '@classic/AppUtils/Framework/Guard'

export default {
  labelFor(
    betType: ko.Observable<BetTypeInformation>,
    bettingContext: BettingInformation,
    count: number
  ): string {
    Guard.notNull(bettingContext)
    Guard.greaterThanZero(count)

    //Double & Quaddie
    if (
      betType().betType() === BetType.Double ||
      betType().betType() === BetType.Quaddie ||
      betType().betType() === BetType.AllUp
    )
      return "<span class='tick-select tticon tticon-tick'/>"

    // Quinella
    if (count === 1 && betType().betType() === BetType.Quinella && bettingContext.isLegIn())
      return ''

    if (count === 2 && betType().betType() === BetType.Quinella && bettingContext.isLegIn())
      return "<span class='tick-select tticon tticon-tick'/>"

    if (count === 1 && betType().betType() === BetType.Quinella)
      return "<span class='tick-select tticon tticon-tick'/>"

    // Allways
    if (count === 1 && bettingContext.isBoxed())
      return "<span class='tick-select tticon tticon-tick'/>"

    // Roving Banker
    if (count === 1 && bettingContext.rovingBanker()) return 'RB'

    // With
    if (count === 2 && bettingContext.rovingBanker()) return 'With'

    // Standard Columns
    if (count === 1) return '1st'

    if (count === 2) return '2nd'

    if (count === 3) return '3rd'

    if (count === 4) return '4th'

    return ''
  },
}
