import React, { CSSProperties, ReactNode } from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '../Theme/Common'
import { Button, Icon } from '../Common/V2'

export type AccountHeaderProps = {
  alignItems: 'start' | 'center' | 'end'
  title: string | ReactNode
  description?: string | ReactNode
  onClose?: ()=>void
  style?: CSSProperties
}

export const AccountHeader = ({ alignItems, title, description, onClose, style }: AccountHeaderProps) => {
  return (
    <AccountHeaderWrapper alignItems={alignItems} onClose={onClose} style={style}>
        {onClose && <ButtonWrapper>
          <Button
            aria-label='Back button'
            color='secondary_grey'
            isIconOnlyButton={true}
            onClick={onClose}
            size='xs'
          >
            <Icon name='SolidChevronLeft' />
          </Button>
        </ButtonWrapper>}
        <TextWrapper alignItems={alignItems}>
          <MainTitle>
            <b>{title}</b>
          </MainTitle>
          {description && <Description>{description}</Description>}
        </TextWrapper>
    </AccountHeaderWrapper>
  )
}

const AccountHeaderWrapper = styled.header<Pick<AccountHeaderProps, 'alignItems' | 'onClose'>>(
  ({ onClose, alignItems }) => ({
    flex: '0 0 10vh',
    display: onClose ? 'grid' : 'flex',
    gridTemplateColumns: onClose ? '1fr 10fr 1fr' : 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: alignItems,
    textAlign: getTextAlign(alignItems),
    margin: `0 0 ${spacing.md}`,
    a: {
      color: colors.studio[500],
    },
  })
)

const TextWrapper = styled.div<Pick<AccountHeaderProps, 'alignItems'>>(
  ({ alignItems }) =>({
  display:'flex',
  flexDirection: 'column',
  alignItems: alignItems,
  textAlign: getTextAlign(alignItems),
  width: '100%',
}))

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: spacing.sm
})

export const MainTitle = styled.div({
  fontFamily: font.family.primary,
  color: colors.studio[900],
  ...font.size.xl4,
})

export const Description = styled.div({
  fontFamily: font.family.primary,
  color: colors.neutral[800],
  marginTop: spacing.sm,
  ...font.size.lg,
})

const getTextAlign = (alignItems: 'start' | 'center' | 'end'): 'left' | 'center' | 'right' => {
  if (alignItems === 'start') return 'left'
  if (alignItems === 'end') return 'right'
  return 'center'
}
