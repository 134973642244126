import styled from '@emotion/styled'
import type { CSSProperties } from 'react'
import { hexColors } from '@mobi/settings'

export type RadioThemeSettings = {
  radio: {
    height: CSSProperties['height']
    width: CSSProperties['width']
    paddingLeft: CSSProperties['paddingLeft']
  }
}

export const defaultTheme: RadioThemeSettings = {
  radio: {
    width: '2rem',
    get height() {
      return this.width
    },
    get paddingLeft(): string {
      return `calc(${defaultTheme.radio.width} * 1.5)`
    },
  },
}

type Props = {
  theme?: RadioThemeSettings
}

export const RadioInputStyled = styled.input<Props>(
  {
    position: 'absolute',
    visibility: 'hidden',

    '&:checked + label::after': {
      backgroundImage: `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTQxLjcgMTQxLjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE0MS43IDE0MS43OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe3N0cm9rZTojMDAwMDAwO3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxjaXJjbGUgY2xhc3M9InN0MCIgY3g9IjcwLjkiIGN5PSI3MC45IiByPSI2My45Ii8+PC9zdmc+)`,
      backgroundRepeat: 'no-repeat',
      transform: 'scale(0.8)',
      backgroundPosition: '50%',
      border: `2px solid transparent`,
    },

    '&[disabled] + label::before': {
      backgroundColor: hexColors.gainsboro,
    },
  },

  ({ theme }) => {
    const { height, width } = theme && theme.radio ? theme.radio : defaultTheme.radio
    return {
      '&:checked + label::after': {
        height,
        width,
      },
    }
  }
)

export const RadioLabelStyled = styled.label<Props>(
  {
    fontSize: '1.2rem',
    color: hexColors.black,
    cursor: 'pointer',
    display: 'inline-block',
    padding: '0.2rem 0 0.2rem 3rem',
    position: 'relative',
    margin: '0.3rem 0 0 0',
    fontWeight: 'bold',

    '&::after, &::before': {
      position: 'absolute',
      content: '" "',
      display: 'inline-block',
      left: 0,
      top: 0,
    },

    '&::before': {
      border: `2px solid ${hexColors.darkGrey}`,
      borderRadius: '50%',
    },
  },

  ({ theme }) => {
    const { height, width, paddingLeft } = theme && theme.radio ? theme.radio : defaultTheme.radio
    return {
      lineHeight: height,
      paddingLeft,

      '&::before': {
        height,
        width,
      },
    }
  }
)
