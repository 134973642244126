import ko from 'knockout'
import { inject, injectable } from 'inversify'
import Guard from '@classic/AppUtils/Framework/Guard'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import type { IResultsFieldViewModel } from './IResultsFieldViewModel'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import {
  FinishersColumnHeader,
  type FinisherSortOptions,
  type SortLabels,
  type SortDirection,
} from '@core/Areas/Racing/Components/RunnersColumnHeader/FinishersColumnHeader'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
@injectable()
export class ResultsFieldViewModel extends Disposable implements IResultsFieldViewModel {
  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  public raceInformation!: ObservableResultsPage
  public shouldDisplayFixed!: ko.Observable<boolean>
  public sortedFinishers!: ko.Computed<IObservableFinisher[]>
  public FinishersColumnHeader!: typeof FinishersColumnHeader
  private currentSortOption = ko.observable<FinisherSortOptions>('Number.Asc')
  private raceStatus: string | undefined

  public init(params: { raceInformation: ObservableResultsPage }) {
    Guard.notNull(params)
    Guard.notNull(params.raceInformation)

    this.FinishersColumnHeader = FinishersColumnHeader
    this.shouldDisplayFixed = params.raceInformation.meetingInformation.selectedRace.isFixedOddsRace
    this.raceInformation = params.raceInformation
    this.raceStatus = params.raceInformation.raceStatus()

    this.sortedFinishers = ko.pureComputed<IObservableFinisher[]>(() => {
      const sortOption = this.currentSortOption()
      return params.raceInformation.raceFinishers.finishers().sort(getSortFunction(sortOption))
    })

    this.subscribeToSortChanges(this.raceStatus)
  }
  protected subscribeToSortChanges(raceStatus: string | undefined): void {
    this.safeSubscribe('sort-finishers', ({ newSort }: { newSort: FinisherSortOptions }) => {
      this.currentSortOption(newSort)
      trackRaceCardSorting(newSort, raceStatus)
    })
  }
}

// =============
// Local Helpers
// =============

const mappedSortTypes: Record<SortLabels, string> = {
  Number: 'runner',
  Tote: 'tote',
  Fixed: 'fixed',
}

function trackRaceCardSorting(
  finisherSortOption: FinisherSortOptions,
  raceStatus: string | undefined
) {
  const [currentSortItem, currentSortOrder] = finisherSortOption.split('.') as [
    SortLabels,
    SortDirection,
  ]
  const sortOrder = currentSortOrder === 'Asc' ? 'ascending' : 'descending'
  const currentRaceStatus = raceStatus ? raceStatus.toLowerCase() : ''

  trackEvent(analyticsKeys.raceCardSorting, {
    sortItem: mappedSortTypes[currentSortItem],
    sortOrder,
    raceStatus: currentRaceStatus,
  })
}

function getSortFunction(sortOption: FinisherSortOptions) {
  switch (sortOption) {
    case 'Fixed.Asc':
    case 'Fixed.Desc': {
      const direction = sortOption === 'Fixed.Asc' ? 1 : -1
      return (a: IObservableFinisher, b: IObservableFinisher) => {
        if (a.isScratched() && !b.isScratched()) return 1
        if (!a.isScratched() && b.isScratched()) return -1
        if (a.fixedOddsFinisherInfo.winDiv() > b.fixedOddsFinisherInfo.winDiv())
          return 1 * direction
        if (a.fixedOddsFinisherInfo.winDiv() < b.fixedOddsFinisherInfo.winDiv())
          return -1 * direction
        return 0
      }
    }
    case 'Number.Asc':
    case 'Number.Desc': {
      const direction = sortOption === 'Number.Asc' ? 1 : -1
      return (a: IObservableFinisher, b: IObservableFinisher) => {
        if (a.number() > b.number()) return 1 * direction
        if (a.number() < b.number()) return -1 * direction
        return 0
      }
    }
    case 'Tote.Asc':
    case 'Tote.Desc': {
      const direction = sortOption === 'Tote.Asc' ? 1 : -1
      return (a: IObservableFinisher, b: IObservableFinisher) => {
        if (a.isScratched() && !b.isScratched()) return 1
        if (!a.isScratched() && b.isScratched()) return -1
        if (a.toteWinProvDiv() > b.toteWinProvDiv()) return 1 * direction
        if (a.toteWinProvDiv() < b.toteWinProvDiv()) return -1 * direction
        return 0
      }
    }
  }
}
