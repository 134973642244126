import { queryClient } from '@core/Data/ReactQuery'
import { kambiPendingTicketsPollingQuery, racingPendingTicketsPollingQuery } from './queries'

export function invalidateRacingPendingTicketsQueryData() {
  queryClient.invalidateQueries(racingPendingTicketsPollingQuery)
}

export function invalidateKambiPendingTicketsQueryData() {
  queryClient.invalidateQueries(kambiPendingTicketsPollingQuery)
}
