import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { store } from '@core/Store'
import {
  ACCOUNT_ACTIVITY_PENDING_ROUTE,
  ACCOUNT_ACTIVITY_RESULTS_ROUTE,
} from '@core/Areas/AccountActivity/constants'
import { KAMBI_PENDING_HASH_ROUTE, KAMBI_RESULTED_HASH_ROUTE } from '@core/Areas/Kambi/constants'
import {
  setBetSlipNewIsOpen,
  setIsPreviousPendingBetsSportsOnly,
} from '@core/Areas/AppHeader/Store'
import { selectIsPreviousPendingBetsSportsOnly } from '@core/Areas/AppHeader/Store/selectors'
import { usePendingTicketsCount } from '@core/Data/Account/PendingTicketsCount/usePendingTicketsCount'
import { Button } from '../Common/Button'
import type { DisplayLocation } from '../../types'
import { useInvalidatePendingCountOnEvents } from './hooks'

export const PendingBetsButton = React.memo<{ displayLocation: DisplayLocation }>(
  ({ displayLocation }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { pendingBetsCount, isSportsOnlyPending } = usePendingTicketsCount({ enabled: true })
    useInvalidatePendingCountOnEvents()

    const handleClick = () => {
      const myBetsRoute = getMyBetsRoute({ pendingBetsCount, isSportsOnlyPending })
      history.push(myBetsRoute)
      if (displayLocation === 'betslip') dispatch(setBetSlipNewIsOpen(false))
    }

    return (
      <Button
        type='pending'
        displayLocation={displayLocation}
        count={pendingBetsCount}
        onClick={handleClick}
      />
    )
  }
)

// =============
// Local Helpers
// =============

function getMyBetsRoute({
  pendingBetsCount,
  isSportsOnlyPending,
}: Omit<ReturnType<typeof usePendingTicketsCount>, 'doPendingBetsExceedMaxCount'>): `/${string}` {
  const isPreviousPendingSportsOnly = selectIsPreviousPendingBetsSportsOnly(store.getState())

  if (pendingBetsCount === 0) {
    return `/${
      isPreviousPendingSportsOnly
        ? KAMBI_RESULTED_HASH_ROUTE.replace('#', '')
        : ACCOUNT_ACTIVITY_RESULTS_ROUTE
    }`
  }

  if (isSportsOnlyPending) {
    store.dispatch(setIsPreviousPendingBetsSportsOnly(true))
    return `/${KAMBI_PENDING_HASH_ROUTE.replace('#', '')}`
  }

  store.dispatch(setIsPreviousPendingBetsSportsOnly(false))
  return `/${ACCOUNT_ACTIVITY_PENDING_ROUTE}`
}
