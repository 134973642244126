import '../assets/scss/mobi.scss'
import './include-polyfill'

import * as ko from 'knockout'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import * as analyticsService from '@classic/Foundation/Analytics/AnalyticsService'
import * as modal from '@classic/Foundation/Modal/modal'
import * as analyticsDataLayer from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import * as rwwaRxStateMachineDevTools from '@core/Utils/rwwa-rx-state-machine-dev-tools'

import { queryClient } from '@core/Data/ReactQuery'
import { serverConfigQuery } from '@core/Data/ServerConfig/query'
import {
  startDeepLinkingListeners,
  startUserLoginListeners,
  startLocationServiceListeners,
  startPushNotificationsAuthStatus,
  startMetadataStatusUpdate,
  startRaceKeyNotification,
  startWebviewIsAliveListener,
} from '@core/NativeServices'
import '@classic/AppUtils/Framework/CustomBindings/React'
import { registerUserAccountSideEffects } from '@core/State/UserAccount/side-effects'
import { registerRaceBettingPageCommandSideEffects } from '@classic/Betting-v2/Components/Commands/driver-side-effects'
import { DepositLimitsAnniversaryPopup } from '@core/Areas/DepositLimits/Components/DepositLimitsAnniversaryPopup/DepositLimitsAnniversaryPopup'
import { initializeLaunchDarklyServiceWithConfig } from '@core/State/LaunchDarklyFeatures/LaunchDarklyService'
import '@core/State/PushData'
import { iotSubscribeTopics } from '@mobi/utils/awsiot'
import { getGlobalPushSubscriptions } from '@mobi/utils/awsiot/topics'
import App from '@core/App'
import { init as initWebNativeComms } from '@mobi/web-native-comms/web'
import { refreshLogonState } from '@core/Areas/Login/helpers'
import { initializeSentry } from '@core/Services/Sentry'
import { defer } from '@mobi/utils'

ko.options.deferUpdates = true
// @ts-expect-error Missing type
ko.onError = error => {
  // eslint-disable-next-line no-console
  console.error('Knockout Error: \n' + error.message)
  throw error
}

window.AnalyticsDataLayer = analyticsDataLayer

queryClient
  .fetchQuery(serverConfigQuery)
  .then(config => {
    if (config.enableSentryIo) {
      initializeSentry(config.sentryIoUrl, config.serverEnvironment)
    }
    return initializeLaunchDarklyServiceWithConfig(config)
  })
  .then(() => {
    const container = document.getElementById('deposit-limit-anniversary-popup')
    if (container) {
      const root = createRoot(container)
      root.render(React.createElement(DepositLimitsAnniversaryPopup, null))
    }
  })

defer(() => {
  initWebNativeComms()

  refreshLogonState()
  analyticsService.init()
  modal.init()

  startDeepLinkingListeners()
  startUserLoginListeners()
  startLocationServiceListeners()
  startPushNotificationsAuthStatus()
  startMetadataStatusUpdate()
  startRaceKeyNotification()
  startWebviewIsAliveListener()

  // Register driver side effects here
  registerUserAccountSideEffects()
  registerRaceBettingPageCommandSideEffects()

  // Render <App />
  const container = document.getElementById('js-react-app')
  if (container) {
    const root = createRoot(container)
    root.render(React.createElement(App, null))
  }

  // Do global push subscriptions
  iotSubscribeTopics(getGlobalPushSubscriptions())
})

// Set up DEV tools
if (!PRODUCTION) {
  rwwaRxStateMachineDevTools.start()
  // @ts-expect-error DEV only global
  if (module.hot) module.hot.accept()
}
