import styled from '@emotion/styled'
import type { CSSProperties } from 'react'
import { hexColors } from '@mobi/settings'

export interface PopupThemeSettings {
  popup: {
    transform?: CSSProperties['transform']
  }
}

export const defaultTheme: PopupThemeSettings = {
  popup: {
    transform: undefined,
  },
}

export const PopupStyled = styled('div')<{ theme?: PopupThemeSettings }>(
  {
    backgroundColor: hexColors.white,
    boxShadow: `0 0 10px rgba(0,0,0,0.5)`,
    left: '50%',
    margin: '2rem auto 2rem -14rem',
    padding: '1rem',
    position: 'absolute',
    textAlign: 'center',
    top: '2rem',
    width: '26em',
    fontSize: '1rem',
  },
  ({ theme }) => {
    const { transform } = theme && theme.popup ? theme.popup : defaultTheme.popup
    return {
      transform,
    }
  }
)
PopupStyled.displayName = 'Popup'

export const PopupContainerStyled = styled('div')({
  backgroundColor: 'rgba(0,0,0,0.4)',
  left: '0',
  margin: 0,
  position: 'absolute',
  textAlign: 'center',
  top: '0',
  width: '100%',
  height: '100%',
  zIndex: 201,
})
PopupContainerStyled.displayName = 'PopupContainer'

export const PopupCenteredStyled = styled('div')({
  backgroundColor: hexColors.white,
  boxShadow: `0 0 10px rgba(0,0,0,0.5)`,
  position: 'fixed',
  width: '30rem',
  top: '50%',
  transform: 'translate(0, -50%)',
  left: '0',
  right: '0',
  margin: 'auto',
})

export const PopupContainerCenteredStyled = styled('div')({
  backgroundColor: 'rgba(0,0,0,0.4)',
  top: '0',
  width: '100%',
  height: '100%',
  zIndex: 201,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export interface PopupTitleStyledProps {
  isDesktop?: boolean
}

export const PopupTitleStyled = styled('strong')<PopupTitleStyledProps>(({ isDesktop }) => ({
  textAlign: 'center',
  fontSize: isDesktop ? '1.7rem' : '1.6rem',
  lineHeight: '2.4rem',
}))
PopupTitleStyled.displayName = 'PopupTitle'
