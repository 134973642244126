import React from 'react'
import QueryString from 'query-string'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { H3 } from '@core/Components/Text/Heading.styles'
import type {
  BlackbookEntry,
  BlackbookEntryNotRacing,
  BlackbookEntryRacingToday,
} from '@core/Areas/Blackbook/types'
import { trackBlackbookChangeView } from '@core/Areas/Blackbook/analytics'
import { JockeysRunningSoon, RunnersRunningSoon } from './Components'
import { SlimEntryExpandable } from '../../../RunnerItem/Components/SlimEntryExpandable'
import { useIsNewBetSlipFeatureActive } from '@core/Areas/BetSlipNew/hooks'
import { useSmartBetSlip } from '@core/Areas/Racing/Components/SmartBetSlip/hooks/useSmartBetSlip'
import { SmartBetSlipMultiToastLegacy } from '@core/Areas/Racing/Components/SmartBetSlip/SmartBetSlipMultiToastLegacy'

export const RunnerListWithData: React.FC<{ entries: BlackbookEntry[] }> = ({ entries }) => {
  const isNewBetslipFeatureActive = useIsNewBetSlipFeatureActive()
  const smartBetslipState = useSmartBetSlip(isNewBetslipFeatureActive)

  const { incoming, view } = QueryString.parse(useLocation().search) as SearchParams
  const incomingIdRef = React.useRef(incoming)

  const [currentFilterType, setCurrentFilterType] = React.useState<FilterTypes>(() =>
    getEntryTabType({ entries, incoming, view })
  )

  const filteredEntries = entries.filter(entry =>
    currentFilterType === 'Jockeys' ? entry.Code === 'Jockeys' : entry.Code !== 'Jockeys'
  )

  const racingToday = getEntriesRacingToday(filteredEntries)
  const allOther = getEntriesNotRacing(filteredEntries)

  const handleFilterClick = (type: FilterTypes) => () => {
    if (currentFilterType === type) return
    setCurrentFilterType(type)
    trackBlackbookChangeView(type)
  }

  return (
    <WrapperStyled data-testid='blackbook-with-data'>
      {smartBetslipState.shouldAutoAddToBetslip && <SmartBetSlipMultiToastLegacy />}

      <div data-js-bb-entry-filter>
        <button
          type='button'
          data-testid='blackbook-filter-runners'
          data-js-bb-entry-filter-active={currentFilterType === 'Runners'}
          onClick={handleFilterClick('Runners')}
        >
          Runners
        </button>
        <button
          type='button'
          data-testid='blackbook-filter-jockeys'
          data-js-bb-entry-filter-active={currentFilterType === 'Jockeys'}
          onClick={handleFilterClick('Jockeys')}
        >
          Jockeys
        </button>
      </div>

      {currentFilterType === 'Jockeys' ? (
        <JockeysRunningSoon
          entries={racingToday}
          smartBetslipState={smartBetslipState}
          incomingIdRef={incomingIdRef}
        />
      ) : (
        <RunnersRunningSoon
          entries={racingToday}
          smartBetslipState={smartBetslipState}
          incomingIdRef={incomingIdRef}
        />
      )}

      {allOther.length > 0 && (
        <>
          <H3 data-testid='blackbook-list-heading-all-other'>All Other {currentFilterType}</H3>
          {allOther.map(runner => (
            <SlimEntryExpandable
              key={`${runner.Id}`}
              id={runner.Id}
              name={runner.Code === 'Jockeys' ? runner.RiderName : runner.StarterName}
              code={runner.Code}
              hasComments={!!runner.LatestNoteSeq}
              incomingIdRef={incomingIdRef}
            />
          ))}
        </>
      )}
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function getEntriesRacingToday(entries: BlackbookEntry[]): BlackbookEntryRacingToday[] {
  const runningSoonEntries = entries.filter(entry => entry.RacingToday)
  if (!hasRunnersRacingToday(runningSoonEntries)) return []
  return runningSoonEntries
}

function getEntriesNotRacing(entries: BlackbookEntry[]): BlackbookEntryNotRacing[] {
  return entries.filter(entry => !entry.RacingToday) as BlackbookEntryNotRacing[]
}

function hasRunnersRacingToday(runners: BlackbookEntry[]): runners is BlackbookEntryRacingToday[] {
  return !!runners.length
}

function getEntryTabType({
  entries,
  incoming,
  view,
}: {
  entries: BlackbookEntry[]
  incoming?: string
  view?: string
}): FilterTypes {
  if (incoming && Number.isInteger(+incoming)) {
    return entries.some(entry => entry.Id === +incoming && entry.Code === 'Jockeys')
      ? 'Jockeys'
      : 'Runners'
  }

  if (view && typeof view === 'string') {
    window.history.replaceState('', document.title, '/#account/blackbook')
    return view.toLowerCase() === 'jockeys' ? 'Jockeys' : 'Runners'
  }

  return 'Runners'
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  '[data-js-bb-entry-filter]': {
    display: 'flex',
    padding: '0.5rem 1rem',

    button: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '4rem',
      fontSize: '1.2rem',
      background: hexColors.white,
      border: 0,
      color: hexColors.black,

      '&[data-js-bb-entry-filter-active="true"]': {
        background: hexColors.vividViolet,
        color: hexColors.white,
      },

      span: { position: 'relative' },
    },
  },

  // List titles
  '& > h3': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '3rem',
    margin: '1rem 0 0.5rem',
    fontSize: '1.4rem',
    fontWeight: 'bold',

    // Select Holder
    '& > div': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '3rem',

      'svg, span': {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        color: hexColors.studio,
      },

      svg: { right: 0 },

      span: {
        width: 'auto',
        right: '1.5rem',
        fontSize: '1.2rem',
        fontWeight: 'normal',
        lineHeight: 1,
        textAlign: 'right',
      },

      select: {
        opacity: 0,
        position: 'relative',
        zIndex: 1,
        boxSizing: 'border-box',
        height: '3rem',
        margin: 0,
        padding: 0,
        paddingRight: '1.5rem',
        background: 0,
        fontSize: '1.2rem',
        appearance: 'none',
        MozAppearance: 'none',
        MozUserSelect: 'none',
        WebkitAppearance: 'none',
        cursor: 'pointer',
      },
    },
  },
})

// =====
// Types
// =====

type SearchParams = {
  incoming?: string
  view?: Lowercase<FilterTypes>
}
type FilterTypes = 'Jockeys' | 'Runners'
