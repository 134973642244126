import React from 'react'
import { useDispatch } from 'react-redux'
import { Transition, TransitionGroup, TransitionStatus } from 'react-transition-group'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useAppSelector } from '@core/Store/hooks'
import {
  ANIMATION_DURATION_MS,
  ModalScreenCoverStyled as ScreenCover,
  ModalPositionStyled as Position,
  ModalAnimationContainerStyled as AnimationContainer,
  ModalContentContainerStyled as ContentContainer,
  ModalBackgroundStyled as Background,
  ModalCloseButtonStyled as CloseButton,
} from './Modal.styles'
import type { ModalItem } from './types'
import { closeModal } from './store'

/** Handles rendering of open modal instances  */
/** @deprecated - Use Overlays/Modal/Modal.tsx instead */
export const ModalContainer: React.FC = () => {
  const items = useAppSelector(rootState => rootState.modal.items)

  return (
    <TransitionGroup>
      {items.map(item => (
        <Transition key={item.id} timeout={ANIMATION_DURATION_MS}>
          {status => <Modal status={status} item={item} />}
        </Transition>
      ))}
    </TransitionGroup>
  )
}

// Single modal instance
const Modal = ({
  status,
  item: {
    id,
    modalComponent,
    modalComponentProps,
    color,
    exitAnimation,
    isDismissable = true,
    onCloseCallback,
    position,
    cornerStyle,
  },
}: {
  status: TransitionStatus
  item: ModalItem
}) => {
  const modalContainerRef = useBodyScrollLock()
  const dispatch = useDispatch()

  const handleClose = () => {
    if (status !== 'entering' && isDismissable) dispatch(closeModal({ id }))
  }

  React.useEffect(() => {
    return onCloseCallback
  }, [onCloseCallback])

  return (
    <ScreenCover data-testid={`modal-item-${id}`}>
      <Position position={position}>
        <AnimationContainer
          id={id}
          status={status}
          ref={modalContainerRef}
          exitAnimation={exitAnimation}
        >
          <ContentContainer cornerStyle={cornerStyle}>
            {isDismissable && (
              <CloseButton onClick={handleClose} color={color} data-testid='modal-close-button'>
                <Icon type='cross' size='inherit' />
              </CloseButton>
            )}

            <Content content={modalComponent} contentProps={modalComponentProps} />
          </ContentContainer>
        </AnimationContainer>
      </Position>

      <Background onClick={isDismissable ? handleClose : undefined} status={status} />
    </ScreenCover>
  )
}

// React.memo w/ areEqual = true ensures no further renders of modal content after mount
const Content = React.memo(
  ({
    content,
    contentProps = {},
  }: {
    content: React.ElementType
    contentProps?: Record<string, unknown>
  }) => {
    const Content = content
    return <Content {...contentProps} />
  },
  () => true
)

Content.displayName = 'Modal.Content'
