import React from 'react'
import throttle from 'lodash.throttle'
import { createUniqueSkyRaceId, getLatestRace } from '@core/Areas/RaceCardSky/helpers'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

export const useNextToJumpReset = ({
  races,
  currentRace,
  setCurrentRace,
}: {
  races: NextSkyRace[]
  currentRace: NextSkyRace
  setCurrentRace: React.Dispatch<React.SetStateAction<NextSkyRace>>
}) => {
  const horizontalScrollerRef = React.useRef<HTMLUListElement>(null)

  const [isScrolling, setIsScrolling] = React.useState(false)
  const [nextToJumpEl, setNextToJumpEl] = React.useState<React.RefObject<HTMLLIElement | null>>()
  const [nextToJumpPosition, setNextToJumpPosition] = React.useState<ResetButtonPosition>('visible')

  const currectRaceId = createUniqueSkyRaceId(currentRace)
  const currentRaceIndex = races.findIndex(race => createUniqueSkyRaceId(race) === currectRaceId)

  const nextToJumpRace = races.find(race => race.RaceStatus === 'Open')
  const nextToJumpIndex = races.findIndex(race => race === nextToJumpRace)
  const nextToJumpRaceId = nextToJumpRace && createUniqueSkyRaceId(nextToJumpRace)

  const shouldRenderResetButtons =
    nextToJumpIndex !== -1 && (isScrolling || Math.abs(currentRaceIndex - nextToJumpIndex) >= 5)

  const onResetButtonClick = React.useCallback(() => {
    nextToJumpEl?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
    setCurrentRace(getLatestRace(races))
    trackKey(analyticsKeys.resetSkyScrollView)
  }, [races, setCurrentRace, nextToJumpEl])

  // ==========================================
  // Display reset button while scrolling races
  // ==========================================
  React.useEffect(() => {
    if (!horizontalScrollerRef.current) return
    const el = horizontalScrollerRef.current
    let timerId: number

    const handleScroll = throttle(() => {
      setIsScrolling(true)
      window.clearTimeout(timerId)
      timerId = window.setTimeout(() => setIsScrolling(false), 3_000)
    }, 1_000)

    el.addEventListener('scroll', handleScroll)
    return () => {
      if (timerId) clearTimeout(timerId)
      el.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // ===================================================
  // Update reset btn position based on next to jump btn
  // ===================================================
  React.useEffect(() => {
    const containerEl = horizontalScrollerRef.current
    if (!containerEl || !nextToJumpEl?.current || !nextToJumpRaceId) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        setNextToJumpPosition(() => {
          if (entry.isIntersecting) return 'visible'
          const containerBoundingRect = containerEl.getBoundingClientRect()
          return entry.boundingClientRect.left < containerBoundingRect.left ? 'left' : 'right'
        })
      },
      { threshold: 0.2 }
    )
    observer.observe(nextToJumpEl.current)
    return () => observer.disconnect()
  }, [nextToJumpEl, nextToJumpRaceId])

  return {
    horizontalScrollerRef,
    nextToJumpPosition,
    nextToJumpRaceId,
    onResetButtonClick,
    setNextToJumpEl,
    shouldRenderResetButtons,
  }
}

// =====
// Types
// =====

type ResetButtonPosition = 'visible' | 'left' | 'right'
