import { useQuery } from 'react-query'
import { useFeature } from '@core/Utils/hooks'
import {
  racingPendingTicketsPollingQuery,
  racingPendingTicketsQuery,
} from '@core/Data/Account/PendingTicketsCount/queries'

export const usePendingTicketsCount = (isEnabled: boolean) => {
  const isAppHeaderPendingBetsFeatureActive = useFeature('PENDING_BETS_BUTTON_IN_APP_HEADER')

  const { data } = useQuery({
    ...(isAppHeaderPendingBetsFeatureActive
      ? racingPendingTicketsPollingQuery
      : racingPendingTicketsQuery),
    enabled: isEnabled,
  })

  const pendingBets = data?.pendingTicketsCount ?? 0

  return {
    pendingBetsCount: Math.min(pendingBets, 99),
  }
}
