import React from 'react'
import styled from '@emotion/styled'
import { lessThan } from '@mobi/component-library/Utils/media'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import { getImageUrlWithSizes } from '@core/Components/SilkImage/utils'

const StructuredSearchSilkContainer = styled('span')({
  width: '2.5rem',
  verticalAlign: 'middle',
  display: 'block',
  padding: '0 0.5rem 0 0',
  marginTop: '0.5rem',
  marginRight: '0.25rem',

  [lessThan('px350')]: {
    display: 'none',
  },
})

interface StructuredSearchSilkImageProps {
  meetingId: string
  meetingDate: string
  raceNo: number
  isDogsSilk: boolean
  isTrots: boolean
  imageSize?: string
  acceptorNumber: number
}

export function StructuredSearchSilkImage({
  meetingId,
  meetingDate,
  raceNo,
  isDogsSilk,
  isTrots,
  acceptorNumber,
}: StructuredSearchSilkImageProps) {
  const imageUrlWithSizes = getImageUrlWithSizes({ meetingId, meetingDate, isTrots, raceNo })

  const xOffset = acceptorNumber - 1

  return (
    <StructuredSearchSilkContainer>
      <SilkImage
        url={imageUrlWithSizes.large.url}
        height={imageUrlWithSizes.large.size.height}
        width={imageUrlWithSizes.large.size.width}
        isDogsSilk={isDogsSilk}
        xOffset={xOffset}
      />
    </StructuredSearchSilkContainer>
  )
}
