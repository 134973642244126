import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'

let hidden = 'hidden'
let visibilityChange = 'visibilitychange'
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

interface DeviceState {
  hidden: boolean
  hiddenLastChange: number
  onLine: boolean
  fcmToken?: string
  fcmTokenStatus?: string
}

export const defaultDeviceState: DeviceState = {
  hidden: false,
  hiddenLastChange: Date.now(),
  onLine: true,
  fcmToken: '',
  fcmTokenStatus: '',
}

document.addEventListener(
  visibilityChange,
  () => {
    StateChanged({
      hidden: document[hidden as keyof typeof document] as boolean,
      hiddenLastChange: Date.now(),
    })
  },
  false
)

// native app doesn't trigger visiblitychange event consistently
// this is workaround
let lastFired = new Date().getTime()
window.setInterval(() => {
  const now = new Date().getTime()
  if (now - lastFired > 5000) {
    StateChanged({ hidden: false, hiddenLastChange: Date.now() })
  }
  lastFired = now
}, 1000)

window.addEventListener('offline', () => StateChanged({ onLine: navigator.onLine }))
window.addEventListener('online', () => StateChanged({ onLine: navigator.onLine }))

export const FcmTokenUpdated = createSignal<{ fcmTokenStatus: string; fcmToken: string }>(
  'FcmTokenUpdated'
)
const StateChanged = createSignal<Partial<DeviceState>>('DeviceStateChanged')

interface DeviceStateRecord extends TypedRecord<DeviceStateRecord>, DeviceState {}
const DeviceStateFactory = makeTypedFactory<DeviceState, DeviceStateRecord>(defaultDeviceState)

export function deviceStateDriver(state = DeviceStateFactory(), signal: Signal): DeviceStateRecord {
  switch (signal.tag) {
    case StateChanged:
      return state.mergeDeep(signal.data)
    case FcmTokenUpdated:
      return state.merge({
        fcmToken: signal.data.fcmToken,
        fcmTokenStatus: signal.data.fcmTokenStatus,
      })
    default:
      return state
  }
}

export const state$ = attachDriver<DeviceStateRecord>({
  path: 'deviceState',
  driver: deviceStateDriver,
}).debounce(500)
