export default class NoveltyDividend {
  public poolName: string
  public raceNumbers: number[]
  public hasRaceNumbers: boolean
  public raceNumbersString: string
  public dividends: Dividend[]

  constructor() {
    this.poolName = ''
    this.raceNumbers = []
    this.hasRaceNumbers = false
    this.raceNumbersString = ''
    this.dividends = []
  }
}

export class Dividend {
  public name: string
  public dividend: string

  constructor(name: string, dividend: string) {
    this.name = name
    this.dividend = dividend
  }
}
