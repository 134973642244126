import React from 'react'

export const useSwipeClose = ({
  drawerElRef,
  onDismiss,
  position,
}: {
  drawerElRef: React.RefObject<HTMLElement>
  position: 'left' | 'right'
  onDismiss?: () => void
}) => {
  React.useEffect(() => {
    const drawer = drawerElRef.current
    if (!onDismiss || !drawer) return

    const controller = new AbortController()

    let startX = 0
    let startY = 0
    let moveX = 0
    let moveY = 0

    let isScrolling: boolean | null = null

    // TOUCH START
    const handleTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX
      startY = e.touches[0].clientY

      moveX = 0
      moveY = 0
      isScrolling = null
    }

    // TOUCH MOVE
    const handleTouchMove = (e: TouchEvent) => {
      const { clientX, clientY } = e.touches[0]
      if (position === 'left' && startX < clientX) return
      if (position === 'right' && startX > clientX) return

      moveX = clientX - startX
      moveY = clientY - startY

      isScrolling ??= !!(Math.abs(moveX) < Math.abs(moveY))

      if (isScrolling) return

      e.preventDefault()

      drawer.style.transform = `translateX(${moveX}px)`
      drawer.style.transition = 'transform linear 0s'
    }

    // TOUCH END
    const handleTouchEnd = () => {
      const cancel = () => {
        drawer.style.transition = 'transform 0.3s ease-out'
        requestAnimationFrame(() => {
          drawer.style.transform = 'translateX(0)'
        })
      }

      if (isScrolling) {
        isScrolling = false
        cancel()
        return
      }

      const hasMovedEnoughToTriggerSwipe = Math.abs(moveX) > 50
      if (!hasMovedEnoughToTriggerSwipe) {
        cancel()
        return
      }

      requestAnimationFrame(onDismiss)
    }

    // KEY PRESS
    const keyPressHandler = (e: KeyboardEvent) => e.key === 'Escape' && onDismiss()

    document.addEventListener('keydown', keyPressHandler, { signal: controller.signal })
    document.addEventListener('touchstart', handleTouchStart, { signal: controller.signal })
    document.addEventListener('touchmove', handleTouchMove, { signal: controller.signal })
    document.addEventListener('touchend', handleTouchEnd, { signal: controller.signal })

    return () => controller.abort()
  }, [drawerElRef, onDismiss, position])
}
