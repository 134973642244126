import React from 'react'
import { useParams } from 'react-router-dom'
import { FooterButtons } from '@mobi/betslip/Components/Common/BetCard'
import { useFeature } from '@core/Utils/hooks'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { SharePreview } from './Components/SharePreview/SharePreview'
import { OverlayOpen } from '@core/Components/Overlay'
import { doesClientSupportShare } from './helpers/doesClientSupportShare'

export const ShareBet: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const isShareBetFeatureActive = useFeature('SHARE_BET')
  const { tab: activeTab } = useParams<{ tab: Tabs }>()

  if (!isShareBetFeatureActive || !doesClientSupportShare()) return null

  return (
    <FooterButtons.ShareButton
      onClick={() => launchSharePreview(ticket, activeTab)}
      testId='share-bet-button'
    />
  )
}

// =============
// Local Helpers
// =============

function launchSharePreview(ticket: Ticket, activeTab: Tabs) {
  OverlayOpen(() => <SharePreview ticket={ticket} activeTab={activeTab} />)
}
