import React from 'react'
import { useSelector } from 'react-redux'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RootState } from '@core/Store'

/** ONLY to be used within App.tsx */
export const DevTools: React.FC = () => {
  const isReactQueryDevToolsEnabled = useSelector(
    (state: RootState) => state.toolbox.isReactQueryDevToolsEnabled
  )

  if (PRODUCTION) return null

  return <>{isReactQueryDevToolsEnabled && <ReactQueryDevtools initialIsOpen={false} />}</>
}
