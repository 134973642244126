import React from 'react'
import { fetchFromApi } from '@classic/Foundation/Services/ApiService'
import { GridRow } from '@mobi/component-library/Common/Grid'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { SameRaceMultiQuickbet } from './SameRaceMultiQuickbet'
import { SameRaceMultiSelectionPriceDetail } from './SameRaceMultiPriceDetail'
import { SameRaceMultiBettingDrawerStyled } from './SameRaceMultiBettingDrawer.styles'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export interface SameRaceMultiPriceResponse {
  price: number // price can not be null since an invalid price will result in an error returned by the mobi API
}

export async function getSelectionPrice(
  meetingDate: Date,
  meetingId: string,
  raceNumber: number,
  selections: string
): Promise<SameRaceMultiPriceResponse> {
  const results = await fetchFromApi(
    `/api/meetings/${meetingId}/${dayjs(meetingDate).format(
      'YYYY-MM-DD'
    )}/race/${raceNumber}/srmSelectionPrice?${selections}`
  )
  return await results.json()
}

const queryDefaults = {
  cacheTime: 0,
  retry: 1,
  refetchOnWindowFocus: false,
}

export interface SelectionPriceProps {
  isValid: boolean
  meetingDate: Date
  meetingId: string
  raceNumber: number
  starterSelections: boolean[][]
  handleQuickbetSelected(price?: SameRaceMultiPriceResponse): void
}

export function SameRaceMultiPrice({
  isValid,
  meetingDate,
  meetingId,
  raceNumber,
  starterSelections,
  handleQuickbetSelected,
}: SelectionPriceProps) {
  // generate the required query selections format
  // - e.g. selection=10-2&selection=11-1 means RunnerNumber: 10 is Win and RunnerNumber 11 is Top2
  // - selections array MUST be in ascending starter number order
  const selectionsWithStarters = starterSelections
    .map((x, i) => ({ starter: i, legs: x }))
    .filter(x => x.legs.some(s => s))
  const selectionsQuery = selectionsWithStarters
    .map(s => `selection=${s.starter + 1}-${s.legs.indexOf(true) + 1}`)
    .join('&')

  const { isLoading, isError, data } = useQuery<SameRaceMultiPriceResponse>({
    ...queryDefaults,
    enabled: isValid,
    queryKey: [
      queryKeys.sameRaceMultiGetSelectionPrice,
      meetingDate,
      meetingId,
      raceNumber,
      selectionsQuery,
    ],
    queryFn: () => getSelectionPrice(meetingDate, meetingId, raceNumber, selectionsQuery),
  })

  return (
    <SameRaceMultiBettingDrawerStyled>
      <GridRow>
        <SameRaceMultiSelectionPriceDetail
          isError={isError}
          isLoading={isLoading}
          data={data}
          numberOfLegs={selectionsWithStarters.length}
        />
      </GridRow>

      <GridRow>
        <SameRaceMultiQuickbet
          isValid={!isError && !isLoading && data != undefined && !!data?.price}
          handleQuickbetSelected={() => handleQuickbetSelected(data)}
        />
      </GridRow>
    </SameRaceMultiBettingDrawerStyled>
  )
}
