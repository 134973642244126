import type { BetSlipItem, BetSelection } from '../types'
import { isSelectionAllowedInMulti } from './state'

/** Used by client wrapper to create bet slip item */
export const createBetSlipItem = (
  {
    bettingType,
    isEachWayAvailable,
    selection,
    selectionDetails,
    shouldAllowPlaceInvestment,
    betSource,
  }: BetSelection,
  presetData: PresetData = {}
): BetSlipItem => {
  const isAllowedInMulti = isSelectionAllowedInMulti(selection)

  return {
    id: Date.now().toString(),
    bettingType,
    isEachWayAvailable,
    isEachWay: presetData.isEachWay || false,
    investment: {
      win: { value: presetData.investmentWin || 0 },
      place: { value: presetData.investmentPlace || 0 },
      bonusBet: undefined,
    },
    isSuperPickAvailable: false,
    selection,
    selectionDetails,
    shouldAllowPlaceInvestment,

    errorMessage: '',
    selectedSuperPickOffer: null,
    isInMulti: isAllowedInMulti,
    multiLegBetType: isAllowedInMulti ? 'W' : undefined,
    multiBetLegError: null,
    specialOffers: [],
    tags: [],
    betSource,
  }
}

// ========
// Types
// ========

type PresetData = {
  investmentWin?: number
  investmentPlace?: number
  isEachWay?: boolean
}
