import * as dtos from '../DataTransferObjects/DataTransferObjects'
import ObservableRacePage from '../Model/Observables/ObservableRacePage'

export default {
  map(dto: dtos.RacePageDataTransferObject, model: ObservableRacePage) {
    for (const element of dto.AvailableBetTypes) {
      model.addBetType(element)
    }
  },
}
