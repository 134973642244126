import { attachDriver, createSignal, Signal } from 'rwwa-rx-state-machine'
import { setInLocalStorage, getFromLocalStorage, removeFromLocalStorage } from '@mobi/utils/storage'
import { StateMap } from 'typings/immutable'
import { fromJS } from 'immutable'

const LOCAL_STOREAGE_KEY = 'savedFavouriteNumbers'

interface SaveFavouriteNumbersProps {
  numbers: number[]
  game: number
}

export const SaveFavouriteNumbers = createSignal<SaveFavouriteNumbersProps>('SaveFavouriteNumbers')

interface SavedFavouriteNumbers {
  gameOne: number[]
  gameTwo: number[]
}

const defaultState: SavedFavouriteNumbers = {
  gameOne: [],
  gameTwo: [],
}

type SavedFavouriteNumbersStateMap = StateMap<SavedFavouriteNumbers>

function driver(
  state = fromJS(FromLocalStorage()) || fromJS(defaultState),
  signal: Signal
): SavedFavouriteNumbersStateMap {
  switch (signal.tag) {
    case SaveFavouriteNumbers: {
      const theState =
        signal.data.game == 1
          ? state.merge({ gameOne: signal.data.numbers })
          : state.merge({ gameTwo: signal.data.numbers })
      toLocalStorage(theState)
      return theState
    }
  }
  return state
}

export const state$ = attachDriver<SavedFavouriteNumbersStateMap>({
  driver,
  path: `saved-favourite-numbers`,
}).map(s => s.toJS())

function toLocalStorage(state: SavedFavouriteNumbers): void {
  //any errors are caught from stream-driver.js - compositeDriver() during driver reduce
  setInLocalStorage(LOCAL_STOREAGE_KEY, JSON.stringify(state))
}

function FromLocalStorage(): SavedFavouriteNumbers | null {
  //any errors are caught from stream-driver.js - compositeDriver() during driver reduce
  const payloadString = getFromLocalStorage(LOCAL_STOREAGE_KEY)
  if (!payloadString) return null
  try {
    const payloadObj = JSON.parse(payloadString)
    if (!payloadObj) return null
    return payloadObj
  } catch {
    removeFromLocalStorage(LOCAL_STOREAGE_KEY)
    return null
  }
}
