import { ImageUrlWithSizes, ImageUrlWithSize, ImageSize } from '@core/Data/Racing'
import { formatDateStringWithoutChangingTimezone } from '@mobi/utils'

type Sizes = 'small' | 'large' | 'larger' | 'largest'

export interface BuildImageUrlWithSizesProps {
  meetingId: string
  meetingDate: string
  raceNo: number
  isTrots: boolean
}

export interface BuildImageUrlWithSizeProps extends BuildImageUrlWithSizesProps {
  imageSize: Sizes
}

export const RaceSmall: ImageSize = { width: 25, height: 30, y: 0 }
export const RaceLarge: ImageSize = { width: 35, height: 42, y: 0 }
export const RaceLarger: ImageSize = { width: 45, height: 54, y: 0 }
export const RaceLargest: ImageSize = { width: 55, height: 77, y: 0 }
export const TrotSmall: ImageSize = { width: 24, height: 18, y: -1 }
export const TrotLarge: ImageSize = { width: 36, height: 36, y: -1 }
export const TrotLarger: ImageSize = { width: 48, height: 48, y: -1 }
export const TrotLargest: ImageSize = { width: 60, height: 60, y: -1 }

export function getSize(isTrots: boolean, imageSize: Sizes): ImageSize {
  const sizes: Record<string, ImageSize> = {
    RaceSmall,
    RaceLarge,
    RaceLarger,
    RaceLargest,
    TrotSmall,
    TrotLarge,
    TrotLarger,
    TrotLargest,
  }

  return (
    sizes[
      `${isTrots ? 'Trot' : 'Race'}${imageSize.charAt(0).toUpperCase() + imageSize.slice(1)}`
    ] || RaceSmall
  )
}

export function getImageUrlWithSize({
  meetingId,
  meetingDate,
  raceNo,
  isTrots,
  imageSize,
}: BuildImageUrlWithSizeProps): ImageUrlWithSize {
  return {
    url: getImageSizeUrl({ meetingId, meetingDate, raceNo, isTrots, imageSize }),
    size: getSize(isTrots, imageSize),
  }
}

export function getImageUrlWithSizes({
  meetingId,
  meetingDate,
  raceNo,
  isTrots,
}: BuildImageUrlWithSizesProps): ImageUrlWithSizes {
  return {
    small: getImageUrlWithSize({ meetingId, meetingDate, raceNo, isTrots, imageSize: 'small' }),
    large: getImageUrlWithSize({ meetingId, meetingDate, raceNo, isTrots, imageSize: 'large' }),
    larger: getImageUrlWithSize({ meetingId, meetingDate, raceNo, isTrots, imageSize: 'larger' }),
    largest: getImageUrlWithSize({ meetingId, meetingDate, raceNo, isTrots, imageSize: 'largest' }),
  }
}

// /Image/GetSilkSpriteWithSizes?meetingId=CR&meetingDate=03%2F30%2F2020&raceNo=5&isTrots=False&size=large

export function getImageSizeUrl({
  meetingId,
  meetingDate,
  raceNo,
  isTrots,
  imageSize,
}: BuildImageUrlWithSizeProps): string {
  const urlDate = encodeURIComponent(
    formatDateStringWithoutChangingTimezone(meetingDate, 'MM/DD/YYYY')
  )

  return `/Image/GetSilkSpriteWithSizes?meetingId=${meetingId}&meetingDate=${urlDate}&raceNo=${raceNo}&isTrots=${isTrots}&size=${imageSize}`
}
