import Rx from 'rx'
import { recordify, TypedRecord } from 'typed-immutable-record'
import * as apiService from '@classic/Foundation/Services/ApiService'
import { BetAccount } from '@mobi/api-types'
import { declareResourceType } from 'rwwa-data-access'

import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'

const BetAccountDetailsKey = 'betAccountDetails'
export const BetAccountKey = 'betAccountKey'

export interface BetAccountRecord extends TypedRecord<BetAccountRecord>, BetAccount {}

/**
 * @deprecated Use useBetAccount React Query hook instead
 */
export const BetAccountRepo = declareResourceType<BetAccountRecord, BetAccount>({
  typeKey: BetAccountDetailsKey,
  // @ts-expect-error Legacy - use better data management solution
  fetchMultipleNoPromise,
  hardTimeToLive: 2 * 60 * 60 * 1000, // 2 hours
  map: betAccount => recordify<BetAccount, BetAccountRecord>(betAccount),
})

function fetchMultipleNoPromise(keys: string[]) {
  // need to check if user is logged in because otherwise a race condition can
  // cause this call to get stuck in an infinite loop
  const isLoggedInCheck = new Promise((resolve, reject) => {
    userAccountState$?.take(1).subscribe(state => {
      const isLoggedIn = state.isLoggedIn ?? false

      if (isLoggedIn) {
        resolve(isLoggedIn)
      } else {
        reject()
      }
    })
  })

  return Rx.Observable.fromPromise(
    isLoggedInCheck
      .then(() => {
        return apiService.get<BetAccount>({ url: '/$_/api/account/getBetAccountDetails' })
      })
      .then(response => ({
        [BetAccountDetailsKey]: {
          [BetAccountKey]: response,
        },
      }))
      .then(result => ({ keys, result }))
      .catch(error => ({ keys, error }))
  )
}
