export const FeatureFlags = {
  RACING_HOMEPAGE_FEATURED: {
    key: 'homepage-feature-items',
    defaultValue: true,
  },
  HOMEPAGE_SUPERPICKS: {
    key: 'home-page-super-boosts',
    defaultValue: true,
  },
  HOMEPAGE_SUPERPICKS_DATA: {
    key: 'home-page-super-boost-data',
    defaultValue: {},
  },
  RACING_SMART_FORM_FIXED_ODDS: {
    key: 'smart-form-fixed-odds',
    defaultValue: false,
  },
  POC_API_GATEWAY: {
    key: 'poc-api-gateway',
    defaultValue: false,
  },
  PUSHDATA_PRICECHANGE: {
    key: 'pushdata-pricechange',
    defaultValue: true,
  },
  PUSHDATA_RACECLOSE: {
    key: 'pushdata-raceclose',
    defaultValue: true,
  },
  PUSHDATA_USERINFO: {
    key: 'pushdata-userinfo',
    defaultValue: false,
  },
  NATIVE_DEPOSITS: {
    key: 'native-deposits',
    defaultValue: false,
  },
  CONFIG_BALANCE_POLL_INTERVAL: {
    key: 'config-balance-poll-interval',
    defaultValue: 30000,
  },
  VISION_PLAYER_MIGRATION: {
    key: 'vision-player-migration',
    defaultValue: false,
  },

  MYSTERY_ON_RACE_BETTING_PAGE: {
    key: 'mystery-race-betting-page',
    defaultValue: false,
  },
  KAMBI_MAINTENANCE: {
    key: 'kambi-maintenance',
    defaultValue: {},
  },
  REBET_PENDING_TICKET: {
    key: 're-bet-pending-ticket',
    defaultValue: false,
  },
  REBET_TAB: {
    key: 're-bet-tab',
    defaultValue: false,
  },
  SHARE_BET: {
    key: 'share-bet',
    defaultValue: false,
  },
  SHARE_BET_LINK: {
    key: 'share-bet-phase-2',
    defaultValue: false,
  },
  RATE_MY_APP: {
    key: 'rate-my-app-trigger',
    defaultValue: false,
  },
  POPULAR_BETS_DISPLAY: {
    key: 'popular-bets-display',
    defaultValue: false,
  },
  TRENDING_SAME_RACE_MULTI: {
    key: 'trending-same-race-multi',
    defaultValue: false,
  },
  TAKE_A_BREAK: {
    key: 'take-a-break',
    defaultValue: false,
  },
  SAME_RACE_MULTI: {
    key: 'same-race-multi',
    defaultValue: false,
  },
  NCPF_ACTIVITY_STATEMENTS: {
    key: 'ncpf-activity-statements-button',
    defaultValue: false,
  },
  RACE_PAGE_VIDEO_CONTENT: {
    key: 'race-page-video-content',
    defaultValue: false,
  },
  QUICKBET_KEEP_SELECTIONS: {
    key: 'quickbet-keep-selections',
    defaultValue: false,
  },
  CENTS_INCREMENTS_BET: {
    key: '50-c-work-increments',
    defaultValue: false,
  },
  BONUS_CASH_IMPROVEMENTS: {
    key: 'bonus-cash-improvements',
    defaultValue: false,
  },
  SEARCH_UNMATCHED_RACING: {
    key: 'search-unmatched-racing',
    defaultValue: false,
  },
  ENHANCED_BETSLIP: {
    key: 'enhanced-betslip',
    defaultValue: true,
  },
  INTELLIGENT_FORM_DISPLAY: {
    key: 'intelligent-form-display',
    defaultValue: true,
  },
  SHOW_SKY_CHANNEL: {
    key: 'show-sky-channel',
    defaultValue: false,
  },
  SHARE_BLACKBOOK_RUNNER: {
    key: 'share-blackbook-runner',
    defaultValue: false,
  },
  WITHDRAW_UPLIFT: {
    key: 'withdraw-uplift',
    defaultValue: false,
  },
  WITHDRAW_REALTIME: {
    key: 'withdraw-realtime',
    defaultValue: false,
  },
  SWIPE_TO_CHANGE_RACE: {
    key: 'swipe-to-change-race',
    defaultValue: false,
  },
  HAPTIC_FEEDBACK: {
    key: 'haptic-feedback',
    defaultValue: false,
  },
  PENDING_BETS_FOR_RACE: {
    key: 'pending-bets-for-race',
    defaultValue: false,
  },
  FOO_FORM_SUMMARY: {
    key: 'FooFormSummary',
    defaultValue: false,
  },
  FOO_FORM_ENABLED: {
    key: 'FooFormEnabled',
    defaultValue: false,
  },
  RESULTED_TICKET_REPLAYS: {
    key: 'resulted-ticket-replays',
    defaultValue: false,
  },
  MULTIPLE_RACE_ALERTS_ENABLED: {
    key: 'multiple-race-alerts-enabled',
    defaultValue: false,
  },
  SIGNUP_DESIGN: {
    key: 'signup-design',
    defaultValue: 'unknown', // 'old' | 'new'
  },
  SIGNUP_OPT_IN_MARKING: {
    key: 'signup-opt-in-marketing',
    defaultValue: false,
  },
  FIXED_ODDS_SPORTS: {
    key: 'fixed-odds-sports',
    defaultValue: false,
  },
  SPORTS_API_DATA_SOURCE: {
    key: 'sports-api-data-source',
    defaultValue: 'rwwa', //rwwa | kambi | theoddsapi (deprecated)
  },
  FIELD_SUMMARY_V2: {
    key: 'field-summary-v2',
    defaultValue: false,
  },
  SEND_GA_EVENTS_TO_FIREBASE: {
    key: 'send-ga-events-to-firebase',
    defaultValue: false,
  },
  NEW_BET_SLIP_DESIGN: {
    key: 'new-bet-slip-design',
    defaultValue: false,
  },
  UNBUNDLE_IOS_JS: {
    key: 'unbundle-ios-js',
    defaultValue: true,
  },
  RACING_WA_VISION_CHANNEL: {
    key: 'racing-wa-vision-channel',
    defaultValue: false,
  },
  RACE_REPLAY_IMPROVEMENTS: {
    key: 'race-replay-improvements',
    defaultValue: false,
  },
  PENDING_BETS_BUTTON_IN_APP_HEADER: {
    key: 'pending-bets-button-in-app-header',
    defaultValue: false,
  },
} as const

export type FeatureFlagValue = string | number | boolean | Record<string, unknown> | undefined

export type FeatureFlagType = {
  key: FeatureFlagKeyName
  defaultValue: FeatureFlagValue
}

type FeatureFlagsKeys = keyof typeof FeatureFlags

export type FeatureFlagKeyName = (typeof FeatureFlags)[FeatureFlagsKeys]['key']

type FeatureFlagDefaults = Record<string, FeatureFlagValue>

export function getFlagDefaults(): FeatureFlagDefaults {
  return (Object.keys(FeatureFlags) as FeatureFlagsKeys[]).reduce((accumulator, flag) => {
    const { key, defaultValue } = FeatureFlags[flag]
    accumulator[key] = defaultValue

    return accumulator
  }, {} as FeatureFlagDefaults)
}
