import * as ko from 'knockout'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import SameAsUIElement from './SameAsUIElement'
import SameAs from '@classic/Betting-v2/Model/SameAs'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import Index from './Index'
import FieldUIElement from './FieldUIElement'
import Guard from '@classic/AppUtils/Framework/Guard'
import { injectable, inject } from 'inversify'
import { IMultiFieldViewModel } from './IMultiFieldViewModel'
import { range } from '@mobi/utils'

@injectable()
export class MultiFieldViewModel extends Disposable implements IMultiFieldViewModel {
  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  init(params: { raceKey: ObservableRaceKey; bettingContext: BettingInformation }) {
    Guard.notNull(params.bettingContext)
    Guard.notNull(params.raceKey)

    this.bettingContext = params.bettingContext
    this.raceKey = params.raceKey
    this.columns = ko.observableArray<string>([])
    this.fieldElements = ko.observableArray<FieldUIElement>([])
    this.sameAsElements = []

    this.count = ko.pureComputed<number>(() => {
      if (
        this.bettingContext.rovingBanker() ||
        this.bettingContext.isBoxed() ||
        this.bettingContext.isLegIn()
      ) {
        return 1
      }
      return this.bettingContext.selectedBetType().checkBoxCount()
    })

    this.setUpColumns(this.count())
    this.setUpField(this.count())
    this.setUpSameAs()

    const countSub = this.count.subscribe(value => {
      this.setUpColumns(value)
      this.setUpField(value)
    })

    this.visible = ko.pureComputed<boolean>(
      () =>
        !(
          this.bettingContext.isBoxed() ||
          this.bettingContext.rovingBanker() ||
          this.bettingContext.isLegIn()
        ) && this.fieldElements().length > 1
    )
    this.showSameAs = ko.pureComputed<boolean>(
      () =>
        !(
          this.bettingContext.isBoxed() ||
          this.bettingContext.rovingBanker() ||
          this.bettingContext.isLegIn()
        ) && this.sameAsElements.length > 0
    )

    this.registerDisposals(() => {
      this.count.dispose()
      this.showSameAs.dispose()
      countSub.dispose()
    })
  }

  private setUpColumns(count: number) {
    this.columns(range(1, count + 1).map(index => Index.toString(index)))
  }

  private setUpField(count: number) {
    this.fieldElements(
      range(1, count + 1)
        .map(index => new FieldUIElement(index - 1, this.bettingContext.getFieldAtIndex(index - 1)))
        .reverse()
    )
  }

  private setUpSameAs() {
    let rowCounter = 1
    this.sameAsElements = this.bettingContext.sameAs.map(
      row => new SameAsUIElement(rowCounter++, row)
    )
  }

  public fieldSelected(option: FieldUIElement, event: Event) {
    event.preventDefault()
    this.evtAggregator.publish('field-selected-command', {
      raceNumber: this.raceKey.raceNumber(),
      position: option.index,
      selected: !option.selected(),
    })
  }

  public sameAsSelected(option: SameAs, event: Event) {
    event.preventDefault()
    this.evtAggregator.publish('sameas-selected-command', {
      raceNumber: this.raceKey.raceNumber(),
      row: option.row,
      column: option.column,
      selected: !option.selected(),
    })
  }

  public bettingContext!: BettingInformation
  public raceKey!: ObservableRaceKey
  public fieldElements!: ko.ObservableArray<FieldUIElement>
  public sameAsElements!: SameAsUIElement[]
  public columns!: ko.ObservableArray<string>
  public count!: ko.PureComputed<number>
  public visible!: ko.PureComputed<boolean>
  public showSameAs!: ko.PureComputed<boolean>
}
