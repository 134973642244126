import { useQueries } from 'react-query'
import { getFromRepo } from '@core/Utils/repository'
import { AccountHolders, AccountHoldersKey } from '@core/Data/Account/accountHolder'
import { BetAccountKey, BetAccountRepo } from '@core/Data/Account/betAccount'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export const useActivityStatementQueries = (
  accountNumber: number | undefined | null,
  isFeatureActive = true
) => {
  return useQueries([
    {
      queryKey: [queryKeys.activityStatementsAccountHolders, accountNumber],
      queryFn: () => getFromRepo(AccountHolders, AccountHoldersKey),
      enabled: isFeatureActive && !!accountNumber,
      cacheTime: 0, // fallback on rwwa-data-access cache
    },
    {
      queryKey: [queryKeys.activityStatementsAccountDetails, accountNumber],
      queryFn: () => getFromRepo(BetAccountRepo, BetAccountKey),
      enabled: isFeatureActive && !!accountNumber,
      cacheTime: 0, // fallback on rwwa-data-access cache
    },
  ])
}
