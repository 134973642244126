import React, { useEffect, useState } from 'react'
import { DocumentNextButton } from '@mobi/component-library/Verification/Documents'
import { FormLayout } from '@mobi/component-library/Verification/Common/Layouts'
import { DocumentBodyStyled } from '@mobi/component-library/Verification/Documents/DocumentScreen.styled'
import { InputField, ValidationErrorStyled } from '@mobi/component-library/Common/Input'
import { SelectField, SelectOption } from '@mobi/component-library/Common/Select'
import { GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { DocumentPermission } from './DocumentPermission'
import { daysInMonth, months } from '@mobi/utils/date'
import { range } from '@mobi/utils/array'

export const medicareCardColours = [
  { value: '', label: 'Card Colour' },
  { value: 'Green', label: 'Green' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Yellow', label: 'Yellow' },
]

export const medicareExpiryNext10Years = () => {
  const thisYear = new Date().getFullYear()
  return range(thisYear, thisYear + 10)
}

export const Medicare = () => {
  const [number, setNumber] = useState('')
  const [cardColour, setCardColour] = useState('')
  const [reference, setReference] = useState('')
  const [middleNameOnCard, setMiddleNameOnCard] = useState<string | null>(null)
  const [expiryDay, setExpiryDay] = useState<number | undefined>(undefined)
  const [expiryMonth, setExpiryMonth] = useState(0) //not 0 based like js Date month value
  const [expiryYear, setExpiryYear] = useState(0)
  const [givePermission, setGivePermission] = useState(false)
  const [cardNumberError, setCardNumberError] = useState(false)
  const [referenceNumberError, setReferenceNumberError] = useState(false)
  const [cardExpiryError, setCardExpiryError] = useState('')

  const isMedicareCardExpiryValid =
    (cardColour === 'Green' && !!expiryMonth && !!expiryYear) ||
    (cardColour !== 'Green' && !!expiryDay && !!expiryMonth && !!expiryYear)
  const isCardNumberValid = !!number && number.length === 10 && +number
  const isReferenceNumberValid = !!reference && reference.length === 1 && +reference
  const isGreenMedicareCardSelected = !!cardColour && cardColour === 'Green'
  const EVERYTHING_EXCEPT_NUMBERS = /[^0-9]/g

  const allowToProceed =
    givePermission &&
    !!isCardNumberValid &&
    !!cardColour &&
    !!isReferenceNumberValid &&
    isMedicareCardExpiryValid &&
    !cardExpiryError

  useEffect(() => {
    if (expiryDay && expiryDay > daysInMonth(expiryYear, expiryMonth)) {
      //reset default if day selection is invalid
      setExpiryDay(undefined)
    }

    if (!isMedicareCardExpiryValid) {
      //user has not entered all necessary fields for selected card colour
      setCardExpiryError('')
    } else {
      const tempDay = isGreenMedicareCardSelected ? 31 : expiryDay //green colour does not require day
      const monthIndex = expiryMonth - 1 //monthIndex is 0 based

      const expiryDate = new Date(expiryYear, monthIndex, tempDay)
      const todayTemp = new Date()
      const today = new Date(todayTemp.getFullYear(), todayTemp.getMonth(), todayTemp.getDate())

      setCardExpiryError(expiryDate < today ? 'Enter a valid expiry date.' : '')
    }
  }, [expiryDay, expiryMonth, expiryYear, isGreenMedicareCardSelected, isMedicareCardExpiryValid])

  const days: SelectOption[] = !isGreenMedicareCardSelected
    ? [...Array(daysInMonth(expiryYear, expiryMonth))].map((_, i) => {
        const day = i + 1
        return { value: day.toString(), label: day.toString() }
      })
    : []

  return (
    <FormLayout>
      <header>
        <h1>Enter your medicare details</h1>
        <p>We need these details to be able to verify your identity</p>
      </header>
      <DocumentBodyStyled>
        <InputField
          name='Medicare card number'
          required
          inputName='medicare-card-number'
          placeholder='Enter card number'
          disabled={false} //verify.isLoading
          onChange={e => {
            setNumber(e.currentTarget.value.replace(EVERYTHING_EXCEPT_NUMBERS, ''))
            setCardNumberError(false)
          }}
          value={number}
          type='text'
          maxLength={10}
          inputMode='numeric'
          pattern='[0-9]*'
          onBlur={() => {
            setCardNumberError(isCardNumberValid ? false : true)
          }}
          errorMessage={cardNumberError ? 'Enter 10 digits only.' : ''}
        />

        <InputField
          name='Medicare reference number'
          required
          inputName='medicare-reference-number'
          placeholder='Enter reference number'
          disabled={false} //verify.isLoading
          onChange={e => {
            setReference(e.currentTarget.value.replace(EVERYTHING_EXCEPT_NUMBERS, ''))
            setReferenceNumberError(false)
          }}
          value={reference}
          type='text'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          onBlur={() => {
            setReferenceNumberError(isReferenceNumberValid ? false : true)
          }}
          errorMessage={referenceNumberError ? 'Enter 1 digit only.' : ''}
        />

        <InputField
          name='Middle name (optional)'
          inputName='medicare-middle-name'
          placeholder='Enter your middle name'
          disabled={false} //verify.isLoading
          onChange={e => setMiddleNameOnCard(e.currentTarget.value)}
        />

        <SelectField
          name='Card colour'
          required
          disabled={false} //verify.isLoading
          disablePlaceholder={true}
          placeholder='Select colour'
          defaultValue=''
          options={medicareCardColours.filter(colour => colour.value !== '')}
          onChange={e =>
            setCardColour(prevState => {
              if (prevState === 'Green') {
                // green medicare does not require day
                setExpiryDay(undefined)
              }
              return e.target.value
            })
          }
        />

        <div>
          <GridRow>
            <GridCell valign='bottom' flexGrow={1} padding='0 1rem 0 0'>
              <SelectField
                name='Card expiry'
                required
                disabled={false} //{isBusy || isGreenMedicareCardSelected}
                disablePlaceholder={true}
                placeholder='Day'
                value={expiryDay ?? ''}
                options={days}
                onChange={e => setExpiryDay(+e.target.value)}
                data-testid='expiryDay'
              />
            </GridCell>
            <GridCell valign='bottom' flexGrow={2} padding='0 1rem 0 0'>
              <SelectField
                name='Card expiry month'
                required
                hideLabel={true}
                disabled={false} //verify.isLoading
                disablePlaceholder={true}
                placeholder='Month'
                value={expiryMonth === 0 ? '' : expiryMonth}
                options={months.map(({ number, name }) => ({
                  value: number.toString(),
                  label: name,
                }))}
                onChange={e => setExpiryMonth(+e.target.value)}
                data-testid='expiryMonth'
              />
            </GridCell>
            <GridCell valign='bottom' flexGrow={1}>
              <SelectField
                name='Card expiry year'
                required
                hideLabel={true}
                disabled={false} //verify.isLoading
                disablePlaceholder={true}
                placeholder='Year'
                value={expiryYear === 0 ? '' : expiryYear}
                options={medicareExpiryNext10Years().map(year => ({
                  value: year.toString(),
                  label: year.toString(),
                }))}
                onChange={e => setExpiryYear(+e.target.value)}
                data-testid='expiryYear'
              />
            </GridCell>
          </GridRow>
          {cardExpiryError && (
            <ValidationErrorStyled data-testid='expiryError'>
              {cardExpiryError}
            </ValidationErrorStyled>
          )}
        </div>

        <DocumentPermission
          isBusy={false} //verify.isLoading
          onChange={e => setGivePermission(e.target.checked)}
        />
      </DocumentBodyStyled>
      <footer>
        <DocumentNextButton
          isBusy={false} //verify.isLoading
          allowToProceed={allowToProceed}
          onSubmit={
            () =>
              // eslint-disable-next-line no-console
              console.log({
                number,
                cardColour,
                reference,
                middleNameOnCard,
                expiryDay: expiryDay === undefined ? null : expiryDay,
                expiryMonth,
                expiryYear,
              })
            // onSubmit({
            //   number,
            //   cardColour,
            //   reference,
            //   middleNameOnCard,
            //   expiryDay: expiryDay === undefined ? null : expiryDay,
            //   expiryMonth,
            //   expiryYear,
            // })
          }
        />
      </footer>
    </FormLayout>
  )
}
