import InvariantFailureException from './Exceptions/InvariantFailureException'

export default {
  stringNotNullOrEmpty(value: string) {
    if (value == null || value.length === 0)
      throw new InvariantFailureException('Invariant: String not empty not satisfied.')
  },

  notNull<T>(value: T) {
    if (value == null)
      throw new InvariantFailureException('Invariant: Value not null not satisfied.')
  },

  arrayNotNullOrEmpty<T>(value: T[]) {
    if (value == null || value.length == 0)
      throw new InvariantFailureException('Invariant: Array not null or empty not satisfied.')
  },

  greaterThanZero(value: number) {
    if (value <= 0) throw new InvariantFailureException('Invariant: Value > 0 not satisfied.')
  },

  greaterThanOrEqualZero(value: number) {
    if (value < 0) throw new InvariantFailureException('Invariant: Value >= 0 not satisfied.')
  },

  greaterOrEqual(min: number, value: number): void {
    if (value < min) throw new InvariantFailureException('Invariant: Value < Min not satisfied.')
  },
}
