import { SearchResult, RawSearchResults, SearchResultType } from '@core/Data/Search/Search'
import { trackSearchQuery } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export const SearchService = {
  async performSearch(searchTerm: string, includeUnmatched?: boolean): Promise<SearchResultType> {
    const url = `/api/search/query?q=${searchTerm}${includeUnmatched ? '&includeUnmatched' : ''}`

    trackSearchQuery(searchTerm)

    const response: Response = await fetch(url, {
      method: 'GET',
    })

    if (!response.ok) {
      return {
        message: 'An error has occurred retrieving the data',
      }
    }

    const data: RawSearchResults = await response.json()

    const results: SearchResult[] = data.results

    return results
  },
}

export const isResultAnError = (result: SearchResultType): result is Error => {
  if ((result as Error).message) {
    return true
  } else {
    return false
  }
}
