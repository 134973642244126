import { IWinPlaceProcessor } from './IWinPlaceProcessor'
import { ButtonSelectionType } from '../UIElements/ButtonSelectionType'
import WinPlaceToteProcessor from './WinPlaceToteProcessor'
import WinPlaceFobProcessor from './WinPlaceFobProcessor'

export default class WinPlaceProcessorFactory {
  constructor(private selectionType: ButtonSelectionType) {}

  processor(): IWinPlaceProcessor | null {
    if (this.selectionType === ButtonSelectionType.Tote) {
      return new WinPlaceToteProcessor()
    }

    if (this.selectionType === ButtonSelectionType.Fob) {
      return new WinPlaceFobProcessor()
    }

    return null
  }
}
