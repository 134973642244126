import * as ko from 'knockout'
import { injectable } from 'inversify'
import { FeatureFlags } from '@mobi/settings'
import Guard from '@classic/AppUtils/Framework/Guard'
import { IDisposable } from '@classic/AppUtils/Framework/Disposable/IDisposable'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { IMultiLegViewModel } from './IMultiLegViewModel'
import { IProvDiv } from '../BaseBetType/IBaseBetType'
import {
  iotSubscribeTopics as subscribeRaceEvent,
  iotUnsubscribeTopics as unsubscribeRaceEvent,
  getFobPriceChangeTopic,
  getToteRaceTopic,
} from '@core/State/PushData'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'
import StartersMapper from '@classic/Betting-v2/Mapping/StartersMapper'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { isRacePageDto } from '@mobi/api-types'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRacePageDataFromApi } from '@core/Areas/Racing/Hooks/useRacePageData'

@injectable()
export class MultiLegViewModel implements IMultiLegViewModel, IDisposable, IProvDiv {
  private legVisibleSubscriptions!: ko.Subscription
  private subscribedTopics: string[] = []
  private ldSubscription!: Rx.Disposable
  private isPriceChangeFeatureActive = false

  public data!: ObservableRacePage
  public bettingContext!: BettingInformation
  public selections!: ko.ObservableArray<IObservableStarter>
  public legVisible!: ko.Observable<number | null>
  public doubleProvDivAllowed!: ko.PureComputed<boolean>
  public quaddieProvDivAllowed!: ko.PureComputed<boolean>
  public useGiddyUp?: IUseGiddyUp

  public init(params: {
    data: ObservableRacePage
    selections: ko.ObservableArray<IObservableStarter>
    context: { bettingContext: BettingInformation }
    useGiddyUp: IUseGiddyUp
  }) {
    Guard.notNull(params.data)
    Guard.notNull(params.context.bettingContext)

    this.data = params.data
    this.useGiddyUp = params.useGiddyUp

    this.bettingContext = params.context.bettingContext
    this.selections = params.selections

    this.legVisible = ko.observable<number>(null)
    this.bettingContext.legVisible = this.legVisible

    this.doubleProvDivAllowed = ko.pureComputed(() => {
      return (
        this.bettingContext.selectedBetType().betType() === BetType.WinPlace &&
        this.data.hasDoubleProvDivs()
      )
    })

    this.quaddieProvDivAllowed = ko.pureComputed(() => {
      return (
        this.bettingContext.selectedBetType().betType() === BetType.WinPlace &&
        this.data.hasQuaddieProvDivs()
      )
    })

    this.registerHandlers()
  }

  public isValidMultiLeg(raceKey: ObservableRaceKey): boolean {
    if (this.bettingContext.selectedBetType().betType() === BetType.Double) {
      return raceKey.isDoubleLeg()
    } else if (this.bettingContext.selectedBetType().betType() === BetType.Quaddie) {
      return raceKey.isQuaddieLeg()
    }
    return false
  }

  public dispose() {
    this.unsubscribeFromPushData()
    this.legVisibleSubscriptions.dispose()
    if (this.ldSubscription) this.ldSubscription.dispose()
  }

  private registerHandlers() {
    this.legVisibleSubscriptions = this.legVisible.subscribe(() => {
      this.loadStartersForLeg()
    })

    this.ldSubscription = launchDarkState$.subscribe(record => {
      const isPriceChangeFeatureActive = record.features.get(FeatureFlags.PUSHDATA_PRICECHANGE.key)

      if (this.isPriceChangeFeatureActive != isPriceChangeFeatureActive) {
        this.isPriceChangeFeatureActive = isPriceChangeFeatureActive
        if (this.isPriceChangeFeatureActive) this.subscribeToPushData()
        else this.unsubscribeFromPushData()
      }
    })
  }

  private loadStartersForLeg(): void {
    //Only load starters for leg if not loaded previously
    const legVisible = this.legVisible()
    if (legVisible && legVisible >= 0) {
      const startersForLeg = this.data.getStartersForLeg(legVisible)
      if (startersForLeg().length <= 0) {
        const raceKeyForLeg = this.data.getRaceKeyForLeg(legVisible)

        if (raceKeyForLeg) {
          this.loadStartersForRace(raceKeyForLeg)
        }
      } else {
        if (this.isPriceChangeFeatureActive) this.subscribeToPushData()
      }
    }
  }

  private subscribeToPushData() {
    try {
      const legVisible = this.legVisible()
      if (legVisible) {
        const newTopics: string[] = []
        const raceKeyForLeg = this.data.getRaceKeyForLeg(legVisible)

        if (!raceKeyForLeg) {
          return
        }

        const currLegRaceNumber = raceKeyForLeg.raceNumber()
        const currLegRace = this.data.meetingInformation
          .races()
          .filter(race => race.raceNumber() === currLegRaceNumber)[0]

        if (
          !!currLegRace &&
          currLegRace.fixedOddsInfo &&
          currLegRace.fixedOddsInfo.marketSequence &&
          currLegRace.fixedOddsInfo.marketSequence()
        ) {
          const marketId = currLegRace.fixedOddsInfo.marketSequence().toString()
          // FOB Price Change
          newTopics.push(getFobPriceChangeTopic(marketId))
        }
        if (!!currLegRace && currLegRace.key) {
          // TOTE Price Change
          newTopics.push(getToteRaceTopic(currLegRace.key(), { type: 'priceChanged' }))
        }
        subscribeRaceEvent(newTopics)
        this.subscribedTopics = this.subscribedTopics.concat(newTopics)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error Subscribing', e)
    }
  }

  private unsubscribeFromPushData() {
    unsubscribeRaceEvent(this.subscribedTopics)
    this.subscribedTopics.length = 0
  }

  private async loadStartersForRace(raceKey: ObservableRaceKey) {
    if (raceKey == null) return

    const raceNumber = raceKey.raceNumber()
    const { meetingId, meetingDate } = this.bettingContext

    try {
      const pageDto = await getRacePageDataFromApi(meetingId, meetingDate, raceNumber)

      queryClient.setQueryData(
        queryKeys.racePageComplete(meetingId, meetingDate, raceNumber),
        pageDto
      )

      if (isRacePageDto(pageDto)) {
        const raceStarters = pageDto.RaceStarters.find(s => s.RaceKey.RaceNumber === raceNumber)
        const starters = raceStarters?.Starters ?? []

        this.data.addStartersForRace(raceKey, StartersMapper.mapStarters(starters))

        if (this.isPriceChangeFeatureActive) {
          this.subscribeToPushData()
        }
      }
    } catch {
      this.data.addStartersForRace(raceKey, [])
    }
  }
}
