import * as ko from 'knockout'
import jQuery from 'jquery'

ko.bindingHandlers['slideVisible'] = {
  init(element, valueAccessor) {
    const value = valueAccessor()
    jQuery(element).toggle(ko.unwrap(value))
  },
  update(element, valueAccessor) {
    const value = valueAccessor()
    if (ko.unwrap(value)) jQuery(element).slideDown('fast')
    else jQuery(element).slideUp('fast')
  },
}
