import { IConfigureBetType } from '../Configuration/IConfigureBetType'
import WinPlaceConfigurator from '../Configuration/WinPlaceConfigurator'
import TrifectaConfigurator from '../Configuration/TrifectaConfigurator'
import MysteryConfigurator from '../Configuration/MysteryConfigurator'
import AllUpConfigurator from '../Configuration/AllUpConfigurator'
import QuaddieConfigurator from '../Configuration/QuaddieConfigurator'
import DoubleConfigurator from '../Configuration/DoubleConfigurator'
import FirstFourConfigurator from '../Configuration/FirstFourConfigurator'
import SameRaceMultiConfigurator from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiConfigurator'
import ExactaConfigurator from '../Configuration/ExactaConfigurator'
import QuinellaConfigurator from '../Configuration/QuinellaConfigurator'
import Guard from '../../../AppUtils/Framework/Guard'

export default {
  fromUnTyped(betType: string): IConfigureBetType {
    Guard.stringNotNullOrEmpty(betType)

    switch (betType) {
      case 'WinPlace':
        return new WinPlaceConfigurator()
      case 'Trifecta':
        return new TrifectaConfigurator()
      case 'Mystery':
        return new MysteryConfigurator()
      case 'AllUp':
        return new AllUpConfigurator()
      case 'Quaddie':
        return new QuaddieConfigurator()
      case 'Double':
        return new DoubleConfigurator()
      case 'First4':
        return new FirstFourConfigurator()
      case 'SameRaceMulti':
        return new SameRaceMultiConfigurator()
      case 'Exacta':
        return new ExactaConfigurator()
      case 'Quinella':
        return new QuinellaConfigurator()
      default:
        throw {}
    }
  },
}
