import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '@core/AppRoutes'
import {
  fetchAccountBalanceAsync,
  fetchCampaignsAsync,
} from '@core/State/UserAccount/async-signals'
import { useObservableImmutable } from '@core/Utils/hooks/useObservableImmutable'
import { state$ as accountState$ } from '@core/Areas/Account/driver'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as featuresState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { FeatureFlags } from '@mobi/settings'
import { Currency } from '@mobi/utils/money'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Icon as IconV2 } from '@mobi/component-library/Common/V2/Icon'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { setBetSlipNewIsOpen, toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import { FobSpecialToken } from '../AccountDropDown/Components/helpers/useGetSpecialTokens'
import { useGetSpecialTokens } from '../AccountDropDown/Components/helpers/useGetSpecialTokens'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { getIsBalanceVisible, getIsDropDownOpen } from '../../Store/selectors'
import styled from '@emotion/styled'
import { Money } from '@core/Components/Text/Money'
import { useRefreshBalanceOnForeground } from '@core/Utils/hooks'

type RenderContext = 'app-header' | 'betslip'

const enum LocalConstants {
  betslipHeaderClassname = 'js-balance-pill--betslip',
}

export const BalancePill: React.FC<{ renderContext: RenderContext }> = ({ renderContext }) => {
  const dispatch = useAppDispatch()

  const {
    accountBalance: balance = 0,
    accountBalanceUpdatedTime: accountBalanceLastUpdated = 0,
    bonusBetBalance = 0,
    bonusCashBalance = 0,
    activeCampaigns,
  } = useObservableImmutable(userAccountState$, [
    'accountBalance',
    'accountBalanceUpdatedTime',
    'bonusBetBalance',
    'bonusCashBalance',
    'activeCampaigns',
  ])

  useRefreshBalanceOnForeground()
  const { accountDetails } = useObservableImmutable(accountState$, ['accountDetails'])
  const { features } = useObservableImmutable(featuresState$, ['features'])

  const isDropDownOpen = useAppSelector(getIsDropDownOpen)
  const isBalanceVisible = useAppSelector(getIsBalanceVisible)

  const isAccountPushFeatureEnabled = features?.get(FeatureFlags.PUSHDATA_USERINFO.key)
  const accountBalanceTTL = features?.get(FeatureFlags.CONFIG_BALANCE_POLL_INTERVAL.key)

  const superPicksLastUpdated = React.useRef<number | null>(null)
  const standardSuperPickCount = React.useRef(0)
  const bonusSuperPickCount = React.useRef(0)
  const [canShowBonusCount, setCanShowBonusCount] = React.useState(false)
  const {
    tokens,
    errorMessage,
    isLoading: isLoadingSuperPicks,
  } = useGetSpecialTokens(canShowBonusCount)

  React.useEffect(() => {
    if (accountDetails?.get('activeCampaignsCount') != (activeCampaigns?.count() ?? -1)) {
      fetchCampaignsAsync()
    }
  }, [accountDetails, activeCampaigns])

  React.useEffect(() => {
    const bonusCountTimeoutId = window.setTimeout(() => {
      setCanShowBonusCount(true)
    }, 500)
    return () => {
      window.clearTimeout(bonusCountTimeoutId)
    }
  }, [])

  // Timer Setup: Handle Balance Polling Based on Interval
  React.useEffect(() => {
    let balanceTimerId: number

    if (accountBalanceTTL && isBalanceVisible && !isAccountPushFeatureEnabled) {
      if (
        !!accountBalanceLastUpdated &&
        Date.now() - accountBalanceLastUpdated > accountBalanceTTL
      ) {
        fetchAccountBalanceAsync()
      }
      balanceTimerId = window.setInterval(() => {
        if (
          !!accountBalanceLastUpdated &&
          Date.now() - accountBalanceLastUpdated > accountBalanceTTL
        ) {
          fetchAccountBalanceAsync()
        }
      }, accountBalanceTTL)
    }

    return () => {
      window.clearInterval(balanceTimerId)
    }
  }, [isBalanceVisible, accountBalanceTTL, accountBalanceLastUpdated, isAccountPushFeatureEnabled])

  const handleDropDownToggle = () => {
    const accountNumber = accountDetails?.toJS()?.accountNumber
    if (!isDropDownOpen && accountNumber) {
      trackBonusBetEvent(keys.accountDropDownOpened, {
        accountNumber,
        accountBalance: balance,
        bonusBetBalance,
        bonusCashBalance,
      })
    }
    dispatch(toggleIsDropDownOpen())
  }

  const bonusBalance = (bonusBetBalance ?? 0) + (bonusCashBalance ?? 0)
  const campaignsCount = activeCampaigns?.count() || 0

  const standardSuperPicks = tokens.filter(token => !token.IsBonusToken)
  const bonusSuperPicks = tokens.filter(token => token.IsBonusToken)

  const isStandardSuperPickAvailable =
    !errorMessage && standardSuperPicks && standardSuperPicks.length > 0
  const isBonusSuperPicksAvailable = !errorMessage && bonusSuperPicks && bonusSuperPicks.length > 0

  if (!isLoadingSuperPicks) {
    standardSuperPickCount.current = isStandardSuperPickAvailable
      ? standardSuperPicks[0].BetsAvailable
      : 0
    bonusSuperPickCount.current = isBonusSuperPicksAvailable
      ? calculateTotalBonusSuperPicks(bonusSuperPicks)
      : 0
  }

  const totalOffersCount =
    campaignsCount + standardSuperPickCount.current + bonusSuperPickCount.current
  superPicksLastUpdated.current = Date.now()

  return (
    <BalancePillComponent
      renderContext={renderContext}
      cashBalance={balance || 0}
      bonusBalance={bonusBalance}
      bonusesCount={totalOffersCount}
      isVisible={isBalanceVisible}
      onWalletToggle={handleDropDownToggle}
    />
  )
}

// ===============
// Local Component
// ===============

// NOT main export, see above for <BalancePill />
export const BalancePillComponent: React.FC<{
  cashBalance: number
  bonusBalance: number
  bonusesCount: number
  isVisible: boolean
  onWalletToggle: () => void
  renderContext: RenderContext
}> = ({ cashBalance, bonusBalance, bonusesCount, isVisible, onWalletToggle, renderContext }) => {
  const history = useHistory()

  const dispatch = useAppDispatch()

  const hideDecimalsInCashBalance = cashBalance >= 1000
  const hideDecimalsInBonusBalance = bonusBalance >= 1000

  const balanceToDisplay = hideDecimalsInCashBalance ? Math.floor(cashBalance) : cashBalance
  const bonusBalanceToDisplay = hideDecimalsInBonusBalance ? Math.floor(bonusBalance) : bonusBalance

  const handleDepositNavFromBetSlip = () => {
    dispatch(setBetSlipNewIsOpen(false))

    history.push(AppRoutes.Deposit)
  }

  return (
    <BalanceContainerStyled
      onClick={renderContext === 'app-header' ? onWalletToggle : handleDepositNavFromBetSlip}
      className={renderContext === 'betslip' ? LocalConstants.betslipHeaderClassname : ''}
      data-testid='app-header-balance'
    >
      {renderContext === 'app-header' && (
        <BalanceSectionDefaultAppHeaderStyled>
          {isVisible ? (
            <>
              <div>
                <BalanceMoneyStyled
                  amount={balanceToDisplay as number}
                  decimalPlaces={hideDecimalsInCashBalance ? 0 : 2}
                />

                {!!bonusBalanceToDisplay && (
                  <BonusBalanceMoneyWrapperStyled>
                    <IconV2
                      name='DuocolorCurrencyDollarCircle'
                      size='1.2rem'
                      color={colors.success[500]}
                    />

                    <BonusBalanceMoneyStyled>
                      {new Currency(bonusBalanceToDisplay).format({ pattern: '#' })}
                    </BonusBalanceMoneyStyled>
                  </BonusBalanceMoneyWrapperStyled>
                )}
              </div>

              {!!bonusesCount && <BonusSectionStyled>{bonusesCount}</BonusSectionStyled>}
            </>
          ) : (
            <Icon type='personMinimal' size='2.5rem' data-testid='user-icon' />
          )}
        </BalanceSectionDefaultAppHeaderStyled>
      )}

      {renderContext !== 'app-header' && (
        <BalanceSectionForBetSlipStyled>
          <BalanceMoneyStyled
            amount={balanceToDisplay as number}
            decimalPlaces={hideDecimalsInCashBalance ? 0 : 2}
          />

          <IconV2 name='LinePlusCircle' size='1.6rem' />
        </BalanceSectionForBetSlipStyled>
      )}
    </BalanceContainerStyled>
  )
}

// =============
// Local Helpers
// =============

function calculateTotalBonusSuperPicks(array: FobSpecialToken[]): number {
  return array.reduce((count, { BetsAvailable = 0 }) => BetsAvailable + count, 0)
}

// ======
// Styles
// ======

const BalanceContainerStyled = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  cursor: 'pointer',
})

const BalanceSectionDefaultAppHeaderStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing.smx2,

  backgroundColor: colors.studio[600],
  color: colors.white,
  borderRadius: radius.lg,
  padding: `${spacing.smx2} ${spacing.smx1}`,
  fontFamily: font.family.primary,
  fontWeight: font.weight.medium,
  fontSize: font.size.md.fontSize,
  lineHeight: font.size.md.lineHeight,
})

const BalanceSectionForBetSlipStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing.smx2,
  height: '4rem',

  backgroundColor: colors.surface[200],
  color: colors.neutral[900],
  padding: `0 ${spacing.smx1}`,
  border: 0,
  borderRadius: radius.md,

  fontFamily: font.family.primary,
  fontSize: font.size.md.fontSize,
  fontWeight: font.weight.medium,
  lineHeight: font.size.md.lineHeight,
})

const BonusSectionStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2.2rem',
  height: '2.2rem',
  fontFamily: font.family.primary,
  fontWeight: font.weight.semibold,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,

  borderRadius: radius.full,
  backgroundColor: colors.orange[500],
  color: '#5C4000',
})

const BalanceMoneyStyled = styled
  .div({
    margin: 'unset',
    display: 'block',
  })
  .withComponent(Money)

const BonusBalanceMoneyWrapperStyled = styled.span({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '.2rem',

  background: colors.success[50],
  borderRadius: radius.sm,
  padding: '0 .2rem',
})

const BonusBalanceMoneyStyled = styled.span({
  color: colors.celery[500],
  margin: 'unset',
  display: 'block',
  whiteSpace: 'nowrap',
})
