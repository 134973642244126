import React from 'react'
import styled from '@emotion/styled'
import { isRacePageDto } from '@mobi/api-types'
import { BettingInfo } from '@core/Areas/RaceCard/Components/BettingInfo'
import { MoreBettingOptions } from '@core/Areas/RaceCard/Components/MoreBettingOptions'
import { PoolTotals } from '@core/Areas/RaceCard/Components/PoolTotals'
import { SpecialsInfo } from '@core/Areas/RaceCard/Components/SpecialsInfo'
import { useRacePageData } from '@core/Areas/Racing/Hooks/useRacePageData'
import { useLegacyRaceCardIntegration } from '@core/Areas/RaceCard/Hooks/useLegacyRaceCardIntegration'
import { AccountTicketsForRace } from './Components/AccountTicketsForRace'
import { BetTypeSelector } from './Components/BetTypeSelector'
import { SkeletonLoader } from './Components/SkeletonLoader'

export const RaceCardMatched: React.FC<{
  /** Expect format YYYY-MM-DD */
  meetingDate: string
  meetingId: string
  raceNumber: string
  shouldForceLoading?: boolean
}> = ({ meetingDate, meetingId, raceNumber, shouldForceLoading = false }) => {
  const { race, isLoading, isFetching } = useRacePageData({ meetingDate, meetingId, raceNumber })
  const { koElementRef } = useLegacyRaceCardIntegration({ meetingId, meetingDate, raceNumber })

  const raceData = isLoading ? undefined : race

  const raceStatus = raceData?.MeetingInformation.SelectedRace.RaceStatus
  const isPending = raceStatus === 'Open' || raceStatus === 'Closed' || raceStatus === 'Interim'
  const hasFixedResulted = !!raceData?.MeetingInformation.SelectedRace.FixedOddsInfo?.IsResulted

  return (
    <WrapperStyled ref={koElementRef}>
      {raceStatus === 'Open' && raceData && isRacePageDto(raceData) && (
        <BetTypeSelector raceData={raceData} />
      )}

      {(isPending || raceStatus === 'Released') && (
        <AccountTicketsForRace
          isPending={isPending}
          date={meetingDate}
          id={meetingId}
          raceNumber={raceNumber}
          raceKey={raceData?.MeetingInformation.SelectedRace.Key as string}
          hasFixedResulted={hasFixedResulted}
        />
      )}

      {(shouldForceLoading || isLoading || isFetching) && (
        <SkeletonLoaderContainerStyled>
          <SkeletonLoader />
        </SkeletonLoaderContainerStyled>
      )}

      <div key='knockout-race-card'>
        {/* @ts-expect-error KNOCKOUT element */}
        <race-display-state params='meetingId: model.meetingId, raceNumber: model.raceNumber, meetingDate: model.meetingDate' />
      </div>

      <MoreBettingOptions raceData={raceData} />
      <PoolTotals raceData={raceData} />
      <BettingInfo raceData={raceData} />
      <SpecialsInfo raceData={raceData} />
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  position: 'relative',
  minHeight: '100vh',
  overflow: 'hidden',
})

const SkeletonLoaderContainerStyled = styled.div({
  background: 'white',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 99,
})
