import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record'
import { OnFeatureChange } from './signals'
import * as immutable from 'immutable'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FeatureFlags = Record<string, any | null>

export interface FeatureFlagState {
  /** Specifies whether feature flags are based on anonymous or ID */
  isUserAnonymous: boolean
  /** set to true when ld client event 'ready' is dispatched */
  isLdInitialized?: boolean
  features: immutable.Map<keyof FeatureFlags, FeatureFlags[keyof FeatureFlags]>
}

const defaultState: Readonly<FeatureFlagState> = {
  isUserAnonymous: true,
  isLdInitialized: false,
  features: immutable.fromJS({}),
}

export interface FeatureFlagDriverRecord
  extends TypedRecord<FeatureFlagDriverRecord>,
    FeatureFlagState {}

const Factory = makeTypedFactory<FeatureFlagState, FeatureFlagDriverRecord>(defaultState)

export function featureFlagDriver(state = Factory(), signal: Signal): FeatureFlagDriverRecord {
  switch (signal.tag) {
    case OnFeatureChange: {
      if (!signal.data.features) {
        return state
      }
      return state.merge({
        features: { ...state.features.toJS(), ...signal.data.features },
        isLdInitialized: state.isLdInitialized || signal.data.isLdInitialized,
        isUserAnonymous:
          signal.data.isUserAnonymous === undefined
            ? state.isUserAnonymous
            : signal.data.isUserAnonymous,
      })
    }

    default:
      return state
  }
}

export const state$ = attachDriver<FeatureFlagDriverRecord>({
  path: 'featureFlags',
  driver: featureFlagDriver,
})
