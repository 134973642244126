import * as dtos from '../DataTransferObjects/DataTransferObjects'
import NoveltyDividend from '../Model/NoveltyDividend'
import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import { Dividend } from '../Model/NoveltyDividend'

export default {
  map(dto: dtos.ResultsPageDataTransferObject, model: ObservableResultsPage) {
    const noveltyDividends = []
    for (const noveltyDividend of dto.NoveltyDividends) {
      noveltyDividends.push(noveltyDividendModel(noveltyDividend))
    }
    model.addNoveltyDividends(noveltyDividends)
  },
}

function noveltyDividendModel(
  noveltyDividendDto: dtos.NoveltyDividendDataTransferObject
): NoveltyDividend {
  const noveltyDividend = new NoveltyDividend()
  noveltyDividend.poolName = noveltyDividendDto.PoolName

  noveltyDividendDto.RaceNumbers.forEach(raceNumber => {
    noveltyDividend.raceNumbers.push(raceNumber)
  })

  const raceNumbers = noveltyDividend.raceNumbers
  if (raceNumbers != undefined && raceNumbers.length > 0) {
    noveltyDividend.hasRaceNumbers = true
    const numbersString = '(' + raceNumbers.join(', ') + ')'
    noveltyDividend.raceNumbersString = numbersString
  }

  noveltyDividendDto.Dividends.forEach(dividendDto => {
    noveltyDividend.dividends.push(new Dividend(dividendDto.Name, dividendDto.Dividend))
  })

  return noveltyDividend
}
