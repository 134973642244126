import { get } from '@classic/Foundation/Services/ApiService'
import { kambiPlayerApi } from '@core/Areas/Kambi/helpers/kambiPlayerApi'

// ======
// Racing
// ======

export type PendingTicketsCountResponse = { pendingTicketsCount?: number }

export const fetchRacePendingTicketsCount = () =>
  get<PendingTicketsCountResponse>({
    url: '/$_/accountActivity/tickets/pending/count',
  })

// =====
// Kambi
// =====

export type KambiPendingTicketsCountResponse = number | undefined

export const fetchKambiPendingTicketCount = async () => {
  try {
    const response = await kambiPlayerApi().PendingBetHistory()

    return response?.range.size
  } catch {
    return undefined
  }
}
