import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import React from 'react'
import { SameRaceMultiPriceResponse } from './SameRaceMultiPrice'
import {
  SameRaceMultiPriceDetailContainerStyled,
  SameRaceMultiPriceDetailStyled,
  SameRaceMultiKeyStyled,
  SameRaceMultiValueStyled,
  SameRaceMultiErrorMessageStyled,
  SameRaceMultiPriceTitleStyled,
} from './SameRaceMultiPriceDetail.styles'

interface SelectionPriceDetail {
  isError: boolean
  isLoading: boolean
  data: SameRaceMultiPriceResponse | undefined
  numberOfLegs: number
}

export function SameRaceMultiSelectionPriceDetail({
  isError,
  isLoading,
  data,
  numberOfLegs,
}: SelectionPriceDetail) {
  if (isError) {
    return (
      <SameRaceMultiErrorMessageStyled>Unable to retrieve price.</SameRaceMultiErrorMessageStyled>
    )
  }

  const price = !data?.price
    ? '-'
    : data.price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

  return (
    <SameRaceMultiPriceDetailContainerStyled>
      <SameRaceMultiPriceTitleStyled>Same Race Multi</SameRaceMultiPriceTitleStyled>

      <SameRaceMultiPriceDetailStyled>
        <div role='legs'>
          <SameRaceMultiKeyStyled>Legs:</SameRaceMultiKeyStyled>
          <SameRaceMultiValueStyled>{numberOfLegs}</SameRaceMultiValueStyled>
        </div>

        <div>
          {isLoading ? (
            <LoadingPlaceholder width='100px' height='1.4rem' testId='shimmer' />
          ) : (
            <div role='price'>
              <SameRaceMultiKeyStyled>Odds:</SameRaceMultiKeyStyled>
              <SameRaceMultiValueStyled>{price}</SameRaceMultiValueStyled>
            </div>
          )}
        </div>
      </SameRaceMultiPriceDetailStyled>
    </SameRaceMultiPriceDetailContainerStyled>
  )
}
