export function filterObject<T>(
  object: Record<string, T>,
  predicate: (value: T, key: string) => boolean
): Record<string, T> {
  return Object.keys(object)
    .filter(key => predicate(object[key], key))
    .reduce(
      (acc, key) => {
        acc[key] = object[key]
        return acc
      },
      {} as Record<string, T>
    )
}

export function mapObject<
  SourceObject extends Record<string, unknown>,
  NewObject extends Record<string, unknown>,
>(object: SourceObject, iterator: (value: unknown, key: string) => unknown): NewObject {
  const keys = Object.keys(object as object)

  const newObject = {} as NewObject

  keys.forEach(key => {
    const value = object[key]

    const newValue = iterator(value, key)

    // @ts-expect-error Typing issues
    newObject[key] = newValue
  })

  return newObject
}
