import React from 'react'
import styled from '@emotion/styled'
import { radius } from '@mobi/component-library/Theme/Common'
import { BetSlipButton } from './Components/BetSlipButton'
import { PendingBetsButton } from './Components/PendingBetsButton'
import { DisplayLocation } from './types'
import { useFeature } from '@core/Utils/hooks'

export const MyBetsButtons: React.FC<{
  shouldDisplayBetSlip: boolean
  shouldDisplayPendingBets: boolean
  displayLocation?: DisplayLocation
}> = ({ shouldDisplayBetSlip, shouldDisplayPendingBets, displayLocation = 'global' }) => {
  const isPendingBetsFeatureActive = useFeature('PENDING_BETS_BUTTON_IN_APP_HEADER')

  return (
    <WrapperStyled>
      {shouldDisplayPendingBets && isPendingBetsFeatureActive && (
        <PendingBetsButton displayLocation={displayLocation} />
      )}

      {shouldDisplayBetSlip && <BetSlipButton />}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'stretch',

  ':empty': { display: 'none' },

  '> button:only-of-type': {
    borderRadius: radius.lg,
  },

  '> button:nth-of-type(1)': {
    borderTopLeftRadius: radius.lg,
    borderBottomLeftRadius: radius.lg,
  },

  '> button:nth-of-type(2)': {
    borderTopRightRadius: radius.lg,
    borderBottomRightRadius: radius.lg,
  },
})
