import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { hexColors } from '@mobi/settings'
import { gapY } from '../../../Utils/gap'

const headerStyling = css({
  fontSize: '2.2rem',
})

const bodyTextStyling = css({
  fontSize: '1.3rem',
})

const layoutBaseStyling = css({
  boxSizing: 'border-box',
  display: 'grid',
  fontFamily: '"PF Square Sans Pro", Arial, sans-serif',
  minHeight: '100%',
  padding: '2.2rem 2rem 2rem',

  '> footer': {
    marginTop: '2rem',
    minHeight: '8rem',
  },
})

/**
 * Used for landing page and retry page (white background)
 */
export const InfoLayoutStyled = styled.section(
  {
    backgroundColor: hexColors.white,
    color: hexColors.nightRider,
    gridTemplateRows: '1fr min-content min-content',
    gridRow: 'pre-main / main',
    textAlign: 'center',

    h1: [
      {
        color: hexColors.blueDiamond,
      },
      headerStyling,
    ],

    p: [
      {
        margin: 0,
      },
      bodyTextStyling,
    ],

    '> div': {
      alignSelf: 'center',
    },
  },
  layoutBaseStyling
)

/**
 * Used for verification success/fail messages (purple background)
 */
export const MessageLayoutStyled = styled.section(
  {
    backgroundColor: hexColors.blueDiamond,
    color: hexColors.white,
    gridTemplateRows: '47% 1fr min-content',
    gridRow: 'pre-main / main',
    textAlign: 'center',

    '> header': [
      {
        alignSelf: 'flex-end',
        padding: '1.6rem 1.4rem 4.5rem',

        '> h1': [
          {
            fontStyle: 'italic',
          },
          headerStyling,
        ],

        '> p': [
          {
            margin: 0,
          },
          bodyTextStyling,
        ],
      },
      gapY('2rem'),
    ],
  },
  layoutBaseStyling
)

/**
 * Used for Contact Details, Driver Licence, Medicare, Passport Forms, etc
 */
export const FormLayoutStyled = styled.section(
  {
    backgroundColor: hexColors.white,
    color: hexColors.nightRider,
    gridTemplateRows: 'min-content 1fr min-content',

    '> header': {
      marginBottom: '1.5rem',
      textAlign: 'center',

      h1: [
        {
          color: hexColors.blueDiamond,
        },
        headerStyling,
      ],

      p: [
        {
          margin: 0,
        },
        bodyTextStyling,
      ],
    },

    h2: {
      color: hexColors.blueDiamond,
      fontSize: '1.2rem',
      margin: '1.5rem 0 0rem',
      textAlign: 'left',
      textShadow: 'none',
    },

    '> footer': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  layoutBaseStyling
)
