import dayjs from 'dayjs'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import { MIN_LEGS_IN_MULTI, MAX_LEGS_IN_RACE_MULTI } from '@mobi/betslip/helpers/constants'
import { isRaceDetails, isFobDetails } from '@mobi/betslip/helpers/typeGuards'
import { groupBy } from '@mobi/utils/functions'

export function getMultiBetslipItems(betslipItems: BetslipItem[]) {
  // item must be in multi, i.e. fixed odds and checked
  const multiBetslipItems = betslipItems.filter(item => item.isInMulti)

  // every betslip item must be on a unique race (matched) or event (unmatched, challenges, etc)
  const betslipItemsGroupedByRaceOrEvent = groupBy(multiBetslipItems, item => {
    if (isRaceDetails(item.selectionDetails)) return item.selectionDetails.races[0].key
    else if (isFobDetails(item.selectionDetails)) return item.selectionDetails.event

    // should not be possible
    return ''
  })

  const groupedRaces = Object.entries(betslipItemsGroupedByRaceOrEvent)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasNoSameRaceBetslipItems = groupedRaces.every(([_, races]) => races.length == 1)

  // min 2 and max 10 legs allowed
  const hasSufficientBetslipItems =
    multiBetslipItems.length >= MIN_LEGS_IN_MULTI &&
    multiBetslipItems.length <= MAX_LEGS_IN_RACE_MULTI

  const isEveryRaceOpen = multiBetslipItems.every(item => {
    if (isRaceDetails(item.selectionDetails)) {
      // matched race
      return isRaceOpen(item.selectionDetails.races[0].raceTime)
    } else if (isFobDetails(item.selectionDetails)) {
      // unmatched race
      return isRaceOpen(item.selectionDetails.eventStartTime)
    }
    return false
  })

  return {
    isValidMulti: hasSufficientBetslipItems && hasNoSameRaceBetslipItems && isEveryRaceOpen,
    multiBetslipItems,
  }
}

function isRaceOpen(raceTime: string | Date): boolean {
  // an open race is considered to be any which has an AST that is NOT more than 15minutes in the past
  // - ideally we would use the race status (open, close, abandoned, etc), but unfortunately the betslip race info
  //   isn't automatically updated (e.g. via tabtouch-push)
  const raceTimeDayjs = dayjs(raceTime)
  return raceTimeDayjs.diff(dayjs(), 'minute') >= -15
}
