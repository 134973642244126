import React, { useRef, useState } from 'react'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { StructuredSearchCriteria } from './Criteria/StructuredSearchCriteria'
import { StructuredSearchResults } from './Results/StructuredSearchResults'
import {
  TabLabelResultsStyled,
  StructuredSearchTabListContainerStyled,
} from './StructuredSearch.styles'
import { Tabs, TabPanel, Tab, TabList } from '@core/Components/Tabs'
import {
  defaultState,
  state$,
  StructuredSearchSetState,
  observableSearchCriteria,
} from './Driver/StructuredSearchDriver'
import { useObservableImmutable } from '@core/Utils/hooks'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'
import { RangeBoundaries } from './RangeBoundaries'
import { logError } from '@core/Utils/logging/logging'
import { performStructuredSearch } from './Service/StructuredSearchService'

export const StructuredSearchStorageKey = 'structured-search-criteria'

export function StructuredSearchPage(): JSX.Element {
  const testId = { 'data-tid-structured-search-container': '' }
  const { searchResults } = useObservableImmutable(state$, ['searchResults'])
  const totalResultCount = searchResults?.get('totalResultCount')
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const componentDidMount = useRef(true)

  React.useEffect(() => {
    const initialiseSearchCriteriaFromLocalStorage = () => {
      let savedSearchCriteria: Record<string, string | number> | null = null
      try {
        const savedStorageFilter: string | null = getFromLocalStorage(StructuredSearchStorageKey)
        if (savedStorageFilter) {
          savedSearchCriteria = JSON.parse(savedStorageFilter)
          if (savedSearchCriteria) {
            const rangeValues: Record<string, number> = RangeBoundaries
            // Need this code to handle the fact that we originally stored data as string for these fields when they were textboxes
            // Using a string causes the UI not to render, hence forcing to be a number (in this case, the default value)
            Object.keys(rangeValues).forEach((key: string) => {
              if (
                savedSearchCriteria &&
                Object.prototype.hasOwnProperty.call(savedSearchCriteria, key) &&
                typeof savedSearchCriteria[key] !== 'number'
              ) {
                savedSearchCriteria[key] = rangeValues[key]
              }
            })
          }
        }
      } catch (e) {
        logError(`Error parsing local storage state data: ${e}`)
      } finally {
        const newState = { ...defaultState.searchCriteria, ...savedSearchCriteria }
        StructuredSearchSetState(newState)
      }
    }

    initialiseSearchCriteriaFromLocalStorage()

    const observer = observableSearchCriteria().subscribe(newState => {
      performStructuredSearch(newState)
      if (!componentDidMount.current) {
        // read from LS so no need to set in LS on component did mount
        setInLocalStorage(
          StructuredSearchStorageKey,
          JSON.stringify(newState, Object.keys(newState).sort())
        )
      }
      componentDidMount.current = false
    })

    return () => {
      observer.dispose()
    }
  }, [])

  return (
    <MainContainer {...testId}>
      <ContentHeader title='Smart Form' />

      <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
        <StructuredSearchTabListContainerStyled>
          <TabList>
            <Tab data-tid-structured-search-tab='criteria'>Criteria</Tab>
            <Tab data-tid-structured-search-tab='results'>
              Results{' '}
              <TabLabelResultsStyled data-tid-total-result-count={totalResultCount}>
                {totalResultCount}
              </TabLabelResultsStyled>
            </Tab>
          </TabList>
        </StructuredSearchTabListContainerStyled>
        <TabPanel>
          <StructuredSearchCriteria selectTabIndex={x => setSelectedTabIndex(x)} />
        </TabPanel>
        <TabPanel>
          <StructuredSearchResults />
        </TabPanel>
      </Tabs>
    </MainContainer>
  )
}
