import React from 'react'
import { useDispatch } from 'react-redux'
import { selectBetSlipItemCount } from '@mobi/betslip/Store/Bets/selectors'
import { useAppSelector } from '@core/Store/hooks'
import { Betslip } from '@core/Areas/Betslip/Betslip'
import { OverlayOpen } from '@core/Components/Overlay/signals'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { hasNotBeenPlaced } from '@core/Areas/Betslip/helpers/state'
import { setBetSlipNewIsOpen, toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import { useIsNewBetSlipFeatureActive } from '@core/Areas/BetSlipNew/hooks/useIsNewBetSlipFeatureActive'
import { Button } from '../Common/Button'

export const BetSlipButton: React.FC = () => {
  const dispatch = useDispatch()

  const newBetSlipCount = useAppSelector(selectBetSlipItemCount)
  const [count, setCount] = React.useState<number | null>(null)

  const prevCountRef = React.useRef(count)
  React.useEffect(() => {
    prevCountRef.current = count
  }, [count])

  const isNewBetSlipFeatureActive = useIsNewBetSlipFeatureActive()

  // Legacy Bet Slip Count + Toast
  React.useEffect(() => {
    if (isNewBetSlipFeatureActive) return
    const betslipSubscription = betslipState$
      .map(state => state.items.filter(hasNotBeenPlaced).count())
      .distinctUntilChanged()
      .subscribe(count => {
        if (prevCountRef.current !== null) {
          const hasCountIncreased = count > prevCountRef.current
          const toastText = hasCountIncreased ? 'Added to' : 'Removed from'

          RegisterToast({
            message: `${toastText} Betslip`,
            position: 'top',
            id: `Betslip Button Event - ${toastText} Betslip`,
          })
        }

        setCount(count)
      })
    return () => betslipSubscription.dispose()
  }, [isNewBetSlipFeatureActive])

  const handleClick = React.useCallback(() => {
    if (isNewBetSlipFeatureActive) {
      dispatch(setBetSlipNewIsOpen(true))
    } else {
      OverlayOpen(Betslip)
    }
    dispatch(toggleIsDropDownOpen(false))
  }, [isNewBetSlipFeatureActive, dispatch])

  return (
    <Button
      type='betslip'
      onClick={handleClick}
      count={isNewBetSlipFeatureActive ? newBetSlipCount : count}
    />
  )
}
