import { HasLoggedIn, HasLoggedOut } from '@core/State/UserAccount/userAccountDriver'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { getIsAuthenticated } from './api'
import { hideLogin, showLogin } from './showLogin'

export async function refreshLogonState(forceLogon?: boolean) {
  try {
    const data = await getIsAuthenticated()

    // #anti-forgery: Required for the current version of the app, the api breaks without it
    const antiforgeryEl = document.getElementById('anti-forgery')
    if (antiforgeryEl) antiforgeryEl.innerHTML = data.token

    // #AccountNumber: Used in LaunchDarklyService on init (remove if login state changes)
    const serverRenderedLoginEl = document.getElementById('AccountNumber') as HTMLInputElement
    const shouldRemoveServerRenderedLoginEl =
      serverRenderedLoginEl &&
      serverRenderedLoginEl.value &&
      (!data.accountNumber || serverRenderedLoginEl.value !== data.accountNumber.toString())
    if (shouldRemoveServerRenderedLoginEl) serverRenderedLoginEl.remove()

    if (!data.accountNumber) {
      HasLoggedOut()
      // If we're in a React Native context and the loggedIn state is false, we
      // will notify the native side so it can be reflected there as well
      // so we can prevent the native app from getting out of sync.
      if (isReactNativeApp) sendToNative('USER_DID_LOGOUT')
      if (forceLogon) showLogin()
      return
    }

    HasLoggedIn({ accountNumber: data.accountNumber })
    if (forceLogon) hideLogin()
  } catch {
    hideLogin()
  }
}
