import * as dtos from '../DataTransferObjects/DataTransferObjects'
import ObservablePool from '../Model/Observables/ObservablePool'
import ObservableRaceKey from '../Model/Observables/ObservableRaceKey'
import ObservablePage from '../Model/Observables/ObservablePage'

export default {
  map(dto: dtos.RacePageDataTransferObject, model: ObservablePage) {
    for (const pool of dto.AvailablePools) {
      const poolTotal = this.createObservablePool(pool)
      model.addPoolTotal(poolTotal)
    }
  },

  mapAllUpPools(dto: dtos.RaceKeyDataTransferObject, model: ObservableRaceKey) {
    for (const pool of dto.AllUpPools) {
      const poolTotal = this.createObservablePool(pool)
      model.addAllUpPool(poolTotal)
    }
  },

  createObservablePool(poolDto: dtos.PoolDataTransferObject): ObservablePool {
    const poolTotal = new ObservablePool()
    poolTotal.hasJackpot(poolDto.HasJackpot)
    poolTotal.isAbandoned(poolDto.IsAbandoned)
    poolTotal.jackpotTotal(poolDto.JackpotTotal)
    poolTotal.name(poolDto.Name)
    poolTotal.total(poolDto.Total)
    poolTotal.poolingIcon(poolDto.PoolingIcon)
    poolTotal.jurisdictionDescription(poolDto.JurisdictionDescription)

    poolDto.RaceNumbers.forEach(raceNumber => {
      poolTotal.raceNumbers.push(raceNumber)
    })

    poolTotal.hasPoolingIcon(
      poolTotal.poolingIcon() != undefined && poolTotal.poolingIcon().length > 0
    )
    poolTotal.isInternationalPool(
      poolTotal.poolingIcon() != undefined && poolTotal.poolingIcon() === 'INT'
    )

    return poolTotal
  },
}
