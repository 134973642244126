import React, { type CSSProperties } from 'react'
import {
  PriceInnerStyled,
  PriceChangeContainerStyled,
  PriceChangeIndicatorInsideStyled,
  PriceWrapperStyled,
  PriceLableStyled,
  PriceChangeIndicatorAboveStyled,
} from './PriceChangeDisplay.styles'
import { usePriceChangeAnimation, PriceChangeType } from '@core/Utils/hooks/usePriceChangeAnimation'

export const PriceChangeDisplay: React.FC<{
  priceWin: string
  pricePlace?: string
  isVisible?: boolean
  isSelected?: boolean
  priceWinPrefix?: React.ReactNode
  pricePlacePrefix?: React.ReactNode
  /** Prices can be stacked (default) or inline */
  isInline?: boolean
  /** Show w/p label in price display */
  isWidthDynamic?: boolean
  /** set the width of price change indicator to be Dynamic */
  isLabelShown?: boolean
  /** Arrow can be above container 'OUT' (default) or inline with price 'IN' */
  arrowLocation?: 'IN' | 'OUT'
  /** Custom styles for container */
  containerStyle?: CSSProperties
  /** Centre the content */
  isContentCentred?: boolean
}> = ({
  priceWin,
  pricePlace,
  priceWinPrefix,
  pricePlacePrefix,
  isVisible = true,
  isSelected = false,
  isInline = false,
  isWidthDynamic = false,
  isLabelShown = false,
  arrowLocation = 'OUT',
  containerStyle,
  isContentCentred = false,
}) => {
  const [state] = usePriceChangeAnimation(priceWin, pricePlace)

  if (!isVisible) {
    return null
  }

  return (
    <PriceChangeContainerStyled isInline={isInline} containerStyle={containerStyle}>
      {!!priceWin && (
        <PriceWrapperStyled
          isInline={isInline}
          isWidthDynamic={isWidthDynamic}
          isSelected={isSelected}
          isPriceChanging={!!state.wpChangeTypeState.win}
          data-tid-starters-win-price=''
        >
          {arrowLocation === 'IN' && (
            <PriceChangeIndicatorInsideStyled
              arrowDirection={state.wpChangeTypeState.win}
              light={isSelected}
              isWidthDynamic={isWidthDynamic}
            />
          )}

          <PriceInnerStyled shouldForceColor={isLabelShown} centerContent={isContentCentred}>
            {priceWinPrefix && priceWinPrefix}
            {state.priceDisplayState.win}
            {isLabelShown && <PriceLableStyled>w</PriceLableStyled>}
          </PriceInnerStyled>
        </PriceWrapperStyled>
      )}

      {!!pricePlace && (
        <PriceWrapperStyled
          isInline={isInline}
          isSelected={isSelected}
          isPriceChanging={!!state.wpChangeTypeState.place}
          data-tid-starters-place-price=''
        >
          {arrowLocation === 'IN' && (
            <PriceChangeIndicatorInsideStyled
              arrowDirection={state.wpChangeTypeState.place}
              light={isSelected}
            />
          )}

          <PriceInnerStyled shouldForceColor={isLabelShown} centerContent={isContentCentred}>
            {pricePlacePrefix && pricePlacePrefix}
            {state.priceDisplayState.place}
            {isLabelShown && <PriceLableStyled>p</PriceLableStyled>}
          </PriceInnerStyled>
        </PriceWrapperStyled>
      )}

      {arrowLocation === 'OUT' && (
        <PriceChangeIndicatorAboveStyled
          data-testid={`price-change-w${state.wpChangeTypeState.win || ''}-p${
            state.wpChangeTypeState.place || ''
          }`}
          arrowDirection={getArrowDirection(
            state.wpChangeTypeState.win,
            state.wpChangeTypeState.place
          )}
        />
      )}
    </PriceChangeContainerStyled>
  )
}

// =======
// Helpers
// =======
export type ArrowDirection = PriceChangeType | 'BOTH'

function getArrowDirection(winType: PriceChangeType, placeType: PriceChangeType): ArrowDirection {
  if (!!winType && !!placeType && winType !== placeType) {
    return 'BOTH'
  }
  return winType || placeType
}
