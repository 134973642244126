import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { PaymentMethods, PaymentMethodsAll } from '@mobi/component-library/Deposit/types'
import { isSelfServePaymentMethod } from '@mobi/component-library/Deposit/helpers'
import { OnFailureParams } from '../Components/types'
import type { DepositFlow } from '../typings/types'

export type DepositStatus =
  | 'idle'
  | 'verifying' // performing verification
  | 'depositing' // depositing funds to account
  | 'success' // funds deposited
  | 'unsuccessful' // funds not deposited

type DepositInput = { inputValue: string } & (
  | { isValid: false }
  | { isValid: true; amount: number }
)

export type State = {
  depositFlow: DepositFlow
  depositStatus: DepositStatus
  depositInput: DepositInput
  selectedPaymentMethod?: PaymentMethodsAll
  lastSelectedPaymentMethod: Record<number, PaymentMethodsAll>
  lastUsedPaymentMethod: Record<number, PaymentMethods>
  depositFailure?: OnFailureParams
}

type DepositState = {
  deposit: State
}

const initialState: State = {
  depositFlow: 'account-deposit',
  depositStatus: 'idle',
  depositInput: { inputValue: '', isValid: false },
  lastSelectedPaymentMethod: {},
  lastUsedPaymentMethod: {},
}

type SelectPaymentMethodPayload = { accountNumber: number; paymentMethod: PaymentMethodsAll }

type LastUsedPaymentMethodPayload = { accountNumber: number; paymentMethod: PaymentMethods }

type DepositSuccessPayload = { accountNumber: number }

const slice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    setDepositInput(state, { payload }: PayloadAction<DepositInput>) {
      state.depositInput = payload
    },

    selectPaymentMethod(state, { payload }: PayloadAction<SelectPaymentMethodPayload>) {
      const { accountNumber, paymentMethod } = payload
      state.selectedPaymentMethod = paymentMethod
      state.lastSelectedPaymentMethod[accountNumber] = paymentMethod
    },
    setLastUsedPaymentMethod(state, { payload }: PayloadAction<LastUsedPaymentMethodPayload>) {
      const { accountNumber, paymentMethod } = payload
      state.lastUsedPaymentMethod[accountNumber] = paymentMethod
    },
    verify(state) {
      if (state.depositStatus !== 'idle') {
        throw new Error('Invalid state transition')
      }
      if (!state.selectedPaymentMethod) {
        throw new Error('Missing payment method')
      }
      if (!state.depositInput.isValid || state.depositInput.amount === 0) {
        throw new Error('Zero deposit amount')
      }
      state.depositStatus = 'verifying'
      state.depositFailure = undefined
    },
    cancel(state) {
      if (state.depositStatus !== 'verifying') {
        throw new Error('Invalid state transition')
      }
      state.depositStatus = 'idle'
    },
    deposit(state) {
      if (state.depositStatus !== 'verifying') {
        throw new Error('Invalid state transition')
      }
      state.depositStatus = 'depositing'
    },
    depositSucceeded(state, { payload }: PayloadAction<DepositSuccessPayload>) {
      if (state.depositStatus !== 'depositing') {
        throw new Error('Invalid state transition')
      }
      state.depositStatus = 'success'
      if (state.selectedPaymentMethod && !isSelfServePaymentMethod(state.selectedPaymentMethod)) {
        state.lastUsedPaymentMethod[payload.accountNumber] = state.selectedPaymentMethod
      }
    },
    depositFailed(state, { payload }: PayloadAction<OnFailureParams>) {
      if (state.depositStatus !== 'depositing' && state.depositStatus !== 'verifying') {
        throw new Error('Invalid state transition')
      }
      state.depositStatus = 'unsuccessful'
      state.depositFailure = payload
    },
    finished(state) {
      if (state.depositStatus !== 'success' && state.depositStatus !== 'unsuccessful') {
        throw new Error('Invalid state transition')
      }
      if (state.depositStatus === 'success') {
        state.depositInput = { inputValue: '', isValid: false }
      }
      state.depositStatus = 'idle'
      state.depositFailure = undefined
    },
    /** Used by Toolbox only */
    resetLastUsedPaymentMethod(state, { payload }: PayloadAction<number>) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.lastUsedPaymentMethod[payload]
    },
    reset({ lastSelectedPaymentMethod, lastUsedPaymentMethod }) {
      return {
        ...initialState,
        lastSelectedPaymentMethod,
        lastUsedPaymentMethod,
      }
    },
    setDepositFlow(state, { payload }: PayloadAction<DepositFlow>) {
      state.depositFlow = payload
    },
  },
})

export default persistReducer(
  {
    key: 'deposit',
    whitelist: ['lastSelectedPaymentMethod', 'lastUsedPaymentMethod'],
    storage,
    debug: !PRODUCTION,
  },
  slice.reducer
)

export const {
  setDepositInput,
  setLastUsedPaymentMethod,
  selectPaymentMethod,
  verify,
  cancel,
  deposit,
  depositSucceeded,
  depositFailed,
  finished,
  resetLastUsedPaymentMethod,
  reset,
  setDepositFlow,
} = slice.actions

export const selectDepositState = (state: DepositState) => state.deposit

export const selectDepositInput = (state: DepositState) => state.deposit.depositInput

export const selectDepositStatus = (state: DepositState) => state.deposit.depositStatus

export const selectLastSelectedPaymentMethod =
  (accountNumber: number | null) => (state: DepositState) => {
    return accountNumber ? state.deposit.lastSelectedPaymentMethod[accountNumber] : undefined
  }

export const selectLastUsedPaymentMethod =
  (accountNumber: number | null) => (state: DepositState) => {
    return accountNumber ? state.deposit.lastUsedPaymentMethod[accountNumber] : undefined
  }

export const selectIsBusy = (state: DepositState) => state.deposit.depositStatus !== 'idle'

export const selectIsDepositing = (state: DepositState) =>
  state.deposit.depositStatus === 'depositing'

export const selectDepositFlow = (state: DepositState) => state.deposit.depositFlow
