import * as ko from 'knockout'
import ObservableRaceKey from './ObservableRaceKey'
import { IObservableStarter } from './IObservableStarter'
import ObservableRaceStarter from './ObservableRaceStarter'
import ObservableDogStarter from './ObservableDogStarter'
import ObservableTrotStarter from './ObservableTrotStarter'
import EventAggregator from '../../../AppUtils/Framework/Messaging/EventAggregator'
import { IEventAggregator } from '../../../AppUtils/Framework/Messaging/IEventAggregator'

export default class ObservableRaceStarters {
  public starters: ko.ObservableArray<StartersKeyValue>

  private eventAggregator: IEventAggregator

  constructor() {
    this.starters = ko.observableArray<StartersKeyValue>([])
    this.eventAggregator = new EventAggregator()
  }

  public getStartersByPropSeq(propositionSeq: number) {
    for (const starterKv of this.starters()) {
      for (const starter of starterKv.Value()) {
        if (
          starter.fixedOddsStarterInfo &&
          starter.fixedOddsStarterInfo.propositionSequence() === propositionSeq
        ) {
          return starter
        }
      }
    }
    return null
  }

  public getStarter(raceNumber: number, starterNumber: number): IObservableStarter | null {
    const raceStarters = this.getStartersForRace(raceNumber)

    if (!raceStarters) {
      return null
    }

    return raceStarters.Value().filter(starter => starter.number() === starterNumber)[0] ?? null
  }

  public getStartersForRace(raceNumber: number): StartersKeyValue | null {
    return this.starters().filter(race => race.Key.raceNumber() === raceNumber)[0] ?? null
  }

  public clearOutStarterSelections(): void {
    this.starters()
      .map(starter => starter.Value())
      .flat()
      .forEach(starter => {
        starter.disposeSelection()
      })
  }

  public addStartersForRace(key: ObservableRaceKey, starters: IObservableStarter[]) {
    const raceStarters = this.getStartersForRace(key.raceNumber())

    if (!raceStarters) {
      this.starters.push({ Key: key, Value: ko.observableArray(starters) })
      return
    }

    raceStarters.Value(starters)
  }

  public mergeStartersForRace(key: ObservableRaceKey, starters: IObservableStarter[]) {
    const raceStarters = this.getStartersForRace(key.raceNumber())

    if (raceStarters == null) {
      this.starters.push({ Key: key, Value: ko.observableArray(starters) })
      return
    }

    if (raceStarters.Value().length <= 0) {
      raceStarters.Value(starters)
      return
    }

    const otherLookUp = new Map<number, IObservableStarter>()
    starters.forEach(x => {
      otherLookUp.set(x.number(), x)
    })

    for (const starter of raceStarters.Value()) {
      this.mergeStarterForRace(key.raceNumber(), otherLookUp, starter)
    }
  }

  public getRaceNumbers(): ObservableRaceKey[] {
    const results = this.starters().map(x => x.Key)

    if (results === null) return []

    return results
  }

  public merge(other: ObservableRaceStarters, forceClear = true) {
    if (forceClear) {
      this.starters(other.starters())
      return
    }

    for (const raceStarters of this.starters()) {
      const otherLookUp = this.otherStartersToMap(other, raceStarters.Key.raceNumber())
      if (otherLookUp.size === 0) {
        raceStarters.Value([])
      } else if (raceStarters.Value().length === 0) {
        raceStarters.Value(Array.from(otherLookUp.values()))
      } else {
        for (const starter of raceStarters.Value()) {
          this.mergeStarterForRace(raceStarters.Key.raceNumber(), otherLookUp, starter)
        }
      }
    }
  }

  private mergeStarterForRace(
    raceNumber: number,
    otherLookUp: Map<number, IObservableStarter>,
    starter: IObservableStarter
  ) {
    // merge in the new starter info (e.g. from a data refresh) into the starter that is being rendered (e.g. setup during SelectionViewModel ctor)
    const match = otherLookUp.get(starter.number())
    if (match) StartersMerger.from(starter).merge(starter, match)

    // a pseudo/phantom selection is being made in an attempt to clear the starter selection if it is no longer selectable
    if (
      starter.isScratched() ||
      starter.isScratchedToteAndFob() ||
      starter.isFixedScratchedOrSuspended() ||
      starter.isSameRaceMultiSuspended()
    ) {
      this.eventAggregator.publish('selection-made-command', { raceNumber, starter, context: null })
    }
  }

  private otherStartersToMap(
    other: ObservableRaceStarters,
    raceNumber: number
  ): Map<number, IObservableStarter> {
    return other
      .starters()
      .filter(x => x.Key.raceNumber() === raceNumber)
      .map(x => x.Value())
      .flat()
      .reduce((map, starter) => {
        return map.set(starter.number(), starter)
      }, new Map<number, IObservableStarter>())
  }

  public removeAll() {
    this.starters([])
  }
}

interface IObservableRaceMerger {
  merge(current: IObservableStarter, other: IObservableStarter): void
}

class Merger<T extends IObservableStarter> implements IObservableRaceMerger {
  merge(current: IObservableStarter, other: IObservableStarter) {
    const source = other as T
    const dest = current as T

    this._merge(dest, source)
  }

  private _merge(current: T, other: T) {
    current.untypedMerge(other)
  }
}

export type StartersKeyValue = {
  Key: ObservableRaceKey
  Value: ko.ObservableArray<IObservableStarter>
}

const StartersMerger = {
  from(source: IObservableStarter): IObservableRaceMerger {
    switch (source.tag()) {
      case 'Race':
        return new Merger<ObservableRaceStarter>()
      case 'Dog':
        return new Merger<ObservableDogStarter>()
      case 'Trot':
        return new Merger<ObservableTrotStarter>()
      default:
        throw new Error('invalid source.Type')
    }
  },
}
