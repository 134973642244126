import React from 'react'
import { TicketResponseData, TransactionsResponseData } from '@core/Areas/AccountActivity/types'
import { Transaction } from '@core/Areas/AccountActivity/Components/Transaction'
import { Ticket } from '@core/Areas/AccountActivity/Components/Ticket'
import {
  getIdFromSortedString,
  isFauxTicketTransaction,
  isTransactionResponse,
} from '@core/Areas/AccountActivity/helpers'
import { CenterContentStyled as Center } from '@core/Areas/AccountActivity/Components/Main/AccountActivityMain.styles'
import { DateHeader } from '@core/Areas/AccountActivity/Components/PagesDateHeader'

// Main export to render collections of pages
export const Pages: React.FC<{
  pages: (TicketResponseData | TransactionsResponseData)[]
  isOrderByEvent: boolean
  selectedDate: string
}> = ({ pages, isOrderByEvent, selectedDate }) => {
  const latestDateRef = React.useRef<string | null>(null)

  return (
    <>
      {pages.map((page, i) => {
        return (
          <Page
            key={page.pageNumber}
            page={page}
            isOrderByEvent={isOrderByEvent}
            latestDateRef={latestDateRef}
            isFirstPage={i === 0}
            selectedDate={selectedDate}
          />
        )
      })}
    </>
  )
}

// Single page to ensure best performance
const Page = React.memo<{
  page: TicketResponseData | TransactionsResponseData
  isOrderByEvent: boolean
  latestDateRef: React.MutableRefObject<string | null>
  isFirstPage: boolean
  selectedDate: string
}>(({ page, isOrderByEvent, latestDateRef, isFirstPage, selectedDate }) => {
  return (
    <>
      {isTransactionResponse(page)
        ? page.sortedIds.map((transactionId, i) => {
            const item = page.transactionsById[getIdFromSortedString(transactionId)]
            if (isFauxTicketTransaction(item))
              return (
                <Center key={transactionId}>
                  <strong data-testid='transactions-faux-item'>{item.message}</strong>
                </Center>
              )

            return [
              <DateHeader
                key={'date.' + transactionId}
                timestamp={item.timestamp}
                latestDateRef={latestDateRef}
                isFirstDate={isFirstPage && i === 0}
              />,
              <Transaction key={transactionId} transaction={item} selectedDate={selectedDate} />,
            ]
          })
        : page.sortedIds.map((ticketId, i) => {
            const item = page.ticketsById[getIdFromSortedString(ticketId)]
            if (isFauxTicketTransaction(item))
              return (
                <Center key={ticketId}>
                  <strong data-testid='transactions-faux-item'>{item.message}</strong>
                </Center>
              )

            return [
              <DateHeader
                key={'date.' + ticketId}
                isOrderByEvent={isOrderByEvent}
                eventStartDateTime={item.eventStartDateTime}
                timestamp={item.timestamp}
                latestDateRef={latestDateRef}
                isFirstDate={isFirstPage && i === 0}
              />,
              <Ticket key={ticketId} ticket={item} />,
            ]
          })}
    </>
  )
})

Page.displayName = 'Page'
