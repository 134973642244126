import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { measurements, layering } from '@mobi/component-library/Theme/Common'
import { Heading } from '@core/Components/Text/Heading'
import { MysteryToggleButtonStyled } from '../Racing/Components/Mystery/Mystery.styles'
import { transparentize } from 'polished'

export const MeetingInformationContainerStyled = styled.div({
  backgroundColor: hexColors.nero,
  color: hexColors.white,
  padding: '1rem',
  fontWeight: 'bold',
})

export const MeetingInformationSectionStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: hexColors.sushi,
  padding: '0.5rem 1rem',
  textAlign: 'center',
  lineHeight: '1em',
})

export const RaceNumberStyled = styled.div({
  fontSize: '2rem',
  textAlign: 'center',
  lineHeight: '1em',
})

export const RaceTimeStyled = styled.div({
  fontSize: '1.2rem',
  textAlign: 'center',
})

export const MeetingNameStyled = styled.div({
  fontSize: '1.6rem',
  lineHeight: '2rem',
})

export const RaceNameStyled = styled.div({
  fontSize: '1.2rem',
  lineHeight: '2rem',
  color: hexColors.grey,
})

export const MysteryBetContainerStyled = styled.div({
  padding: '1rem',
})

export const MysteryHeadingStyled = styled(Heading)({
  margin: '0 0 1rem 0',
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
  color: hexColors.nightRider,
  textTransform: 'uppercase',
})

export const CustomBetButtonContainerStyled = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '0.5rem',
  margin: '0 0 1rem 0',
})

export const MysteryComboButtonStyled = styled(MysteryToggleButtonStyled)({
  ['> :first-of-type']: {
    fontWeight: 'bold',
    marginBottom: '0.33em',
  },
})

export const BetNumberButtonStyled = styled(MysteryToggleButtonStyled)(
  {
    width: '100%',
    borderRadius: 0,
  },
  ({ num }: { num: number }) => ({
    borderRightWidth: num === 5 ? '1px' : 0,
  })
)

export const BettingDrawerSpacerStyled = styled.div(({ isOpen }: { isOpen: boolean }) => ({
  height: isOpen ? '7rem' : 0,
}))

export const FullScreenOverlayContainerStyled = styled.div({
  position: 'fixed',
  display: 'flex',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: layering.appHeader + 1,
  overflowY: 'auto',
  overscrollBehaviorY: 'contain',
  backgroundColor: hexColors.whiteSmoke,
})

export const FullScreenContentsStyled = styled.div({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: measurements.mobi.maxWidth,
  width: `min(100vw, ${measurements.mobi.maxWidth})`,
})

export const FullScreenTopSectionStyled = styled.div({
  backgroundColor: hexColors.blueDiamond,
  color: hexColors.white,
  padding: '1rem',
  '#mystery-meeting-name-and-race-number': {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  '#mystery-bet-type, #mystery-bet-betting-closed': {
    backgroundColor: hexColors.darkPurple,
    padding: '0 1em',
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  '#mystery-bet-countdown': {
    backgroundColor: hexColors.darkPurple,
    padding: '0 1em',
  },
})

export const CustomizeSpendInputContainerStyled = styled.div<{
  infoText: string
  isSelected: boolean
}>(({ infoText, isSelected }) => ({
  position: 'relative',
  color: isSelected ? hexColors.blueDiamond : hexColors.white,
  '&:before': {
    content: infoText ? `"${infoText}"` : undefined,
    position: 'absolute',
    display: 'inline-block',
    left: '1rem',
    top: '33.33%',
    bottom: 0,
    pointerEvents: 'none',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    verticalAlign: 'middle',
    zIndex: 1,
  },
}))

interface CustomizeSpendInputStyledProps {
  isSelected: boolean
}

export const CustomizeSpendInputStyled = styled.button<CustomizeSpendInputStyledProps>(
  {
    backgroundColor: transparentize(0.88, hexColors.white),
    color: 'inherit',
    border: 'none',
    fontSize: '2.2rem',
    fontWeight: 'bold',
    padding: '1.6rem 1rem 1.6rem 5rem',
    textAlign: 'right',
    width: '100%',
  },
  ({ isSelected }) => ({
    backgroundColor: isSelected ? hexColors.white : transparentize(0.88, hexColors.white),
    color: isSelected ? hexColors.blueDiamond : hexColors.white,
    outline: 'none',
  })
)

export const BiggerTextStyled = styled.div({
  fontSize: '1.4rem',
})

export const BetAmountButtonsContainerStyled = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: '0.5rem',
  margin: '1.5rem 1rem',
})

export const StudioHeadingStyled = styled(Heading)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: hexColors.studio,
  color: hexColors.white,
  fontSize: '1.2rem',
  fontWeight: 'bold',
  padding: '0.5rem 1rem 0.5rem 1rem',
  margin: 0,
  '> :first-of-type': {
    textTransform: 'uppercase',
  },
})

export const BetContainerStyled = styled.table({
  borderCollapse: 'collapse',
  border: 0,
  fontSize: '1.2rem',
  width: '100%',
})

export const BetLineContainerStyled = styled.tr({
  borderTop: '1px solid ' + hexColors.gainsboro,
  borderBottom: '1px solid ' + hexColors.gainsboro,
  backgroundColor: hexColors.white,
  ['> td']: {
    padding: '0.5rem 1rem',
    ['&:nth-of-type(2)']: {
      fontWeight: 'bold',
      width: '50%',
    },
  },
})

export const MysteryBetCloseButtonStyled = styled.button({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '5.5rem',
  height: '4rem',
  padding: 0,
  border: 0,
  backgroundColor: 'transparent',
  fontSize: '1.6rem',
  color: hexColors.white,
})
