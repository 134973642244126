import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { IBetTypeValidator } from '@classic/Betting-v2/Validation/IBetTypeValidator'
import Guard from '@classic/AppUtils/Framework/Guard'
import { ButtonSelectionType } from '@classic/Betting-v2/Components/Core/UIElements/ButtonSelectionType'
import { ButtonsSelection } from '@classic/Betting-v2/Components/Core/UIElements/ButtonsSelection'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'

export declare type Bit = 0 | 1

export default class SameRaceMultiValidator implements IBetTypeValidator {
  // invoked by KO computed property 'StartersPageViewModel.isValid'.. used to render the react 'quickbet/betslip toast' (refer BettingDrawer.tsx)
  isValid(bettingContext: BettingInformation): boolean {
    Guard.notNull(bettingContext)

    // retrieves only starters that have selections, i.e. starters[].selection must be initialised in order for a selection to exist
    const selectedStarters = bettingContext.selections.getStartersForRace(
      bettingContext.raceNumber
    )()

    // determine isValid based purely on starters that have selections
    const { isValid } = SameRaceMultiValidator.validate(
      SameRaceMultiValidator.getSelectionsFromStarters(selectedStarters)
    )
    return isValid
  }

  public static getSelectionsFromStarters(starters: IObservableStarter[]): Bit[][] {
    // starters MUST be fully initialised with the KO selection property assigned!
    return starters
      .map(starter => starter.selection() as ButtonsSelection)
      .map(starterButton =>
        starterButton.values().map(value => (value() === ButtonSelectionType.Fob ? 1 : 0))
      )
  }

  public static validate(
    allStarters: Bit[][], // may of may not have leg selections
    starterIndex: number | undefined = undefined,
    legNumber: number | undefined = undefined
  ) {
    // 1. starter can only have 1 leg selected - toggle starter leg selection if already selected...
    // eslint-disable-next-line prefer-const
    let [noStarterHasMultipleLegs, singleStarterWithTwoSelectionsIndex] =
      checkStartersDoNotHaveMultipleLegs(allStarters)
    const hasSingleStarterWithTwoSelections =
      singleStarterWithTwoSelectionsIndex !== undefined &&
      starterIndex !== undefined &&
      starterIndex === singleStarterWithTwoSelectionsIndex

    // ... if one starter has 2 selections, we might be in the middle of changing selection
    // so to try to see what the selections would look like with this change in place
    // we un-select the old selection (if we know the new one by starterNumber and legNumber)
    if (hasSingleStarterWithTwoSelections) {
      allStarters = getSelectionsAfterLegNumberChange(
        allStarters,
        singleStarterWithTwoSelectionsIndex,
        legNumber
      )
      noStarterHasMultipleLegs = true
    }

    // 2. number of starters with leg selections must be within range, i.e. 2, 3, or 4
    const isNumberOfStartersWithLegSelectionsInRange =
      checkNumberOfStartersWithLegWithinRange(allStarters)

    // 3. number of starters with leg selections can't create multiple winning combinations
    // - e.g. x1 WIN and x2 TOP2 is invalid
    // - e.g. x3 TOP2 is invalid
    const isSingleCombination = checkSingleCombinationExists(allStarters)

    const isValid =
      noStarterHasMultipleLegs && isNumberOfStartersWithLegSelectionsInRange && isSingleCombination

    const sumLegSelectionsCount = getSumLegSelectionCount(allStarters)

    return { isValid, sumLegSelectionsCount, hasSingleStarterWithTwoSelections }
  }
}

export function checkStartersDoNotHaveMultipleLegs(
  allStarters: Bit[][]
): [boolean, number | undefined] {
  // catering for scenario whereby a starter already has a leg selection and user has requested a different leg selection, i.e. akin to a radio button
  const starterSelectionsWithMultipleLegCounts: { starterIndex: number; legCount: number }[] = []
  allStarters.forEach((starterWithLegSelections, starterIndex) => {
    const legCount = starterWithLegSelections.filter(isLegSelected => isLegSelected).length
    if (legCount > 1) starterSelectionsWithMultipleLegCounts.push({ starterIndex, legCount }) // store starter index and leg selection count
  })

  // process any starters with multiple legs selected
  if (starterSelectionsWithMultipleLegCounts.length > 0) {
    if (
      starterSelectionsWithMultipleLegCounts.length == 1 &&
      starterSelectionsWithMultipleLegCounts[0].legCount == 2
    ) {
      // 1 starter has 2 leg selections is valid, but selection needs adjustment
      return [false, starterSelectionsWithMultipleLegCounts[0].starterIndex]
    }

    // this should not be possible due to the nature of our 'psuedo radio button'.. 1 starter with >= 3 legs OR multiple starters with >= 2 legs
    return [false, undefined]
  }

  // all starters have a single leg selection
  return [true, undefined]
}

export function getSelectionsAfterLegNumberChange(
  allStarters: Bit[][],
  starterNumberIndex: number | undefined,
  legNumber: number | undefined
) {
  // project a new 'all selections' collection with the new previous leg removed
  return allStarters.map((starterWithLegSelections, starterIndex) =>
    starterWithLegSelections.map((legSelection, legSelectionIndex) => {
      if (starterNumberIndex === undefined || legNumber === undefined) return legSelection
      return starterNumberIndex === starterIndex && legNumber !== legSelectionIndex && legSelection
        ? 0
        : legSelection
    })
  )
}

export function checkNumberOfStartersWithLegWithinRange(allStarters: Bit[][]) {
  const startersWithLegSelections = allStarters.filter(starter =>
    starter.some(legSelectionIndex => legSelectionIndex)
  )
  const count = startersWithLegSelections.length
  return count > 1 && count < 5
}

export function checkSingleCombinationExists(allStarters: Bit[][]) {
  const legCounts = [0, 0, 0, 0]
  allStarters.forEach(starter => {
    for (let leg = 0; leg < 4; leg++) {
      legCounts[leg] += starter[leg]
    }
  })

  // legCounts should contain something like [1, 2, 0, 1]
  // we now add all smaller leg counts to current leg
  const aggregatedLegCounts = [0, 0, 0, 0]
  // => we should get [1, 3, 3, 4], which shows us that leg 2 has too many selections
  for (let i = 0; i < legCounts.length; i++) {
    aggregatedLegCounts[i] = legCounts.reduce(
      (prev, crt, index) => prev + (index <= i ? crt : 0),
      0
    )
  }
  // for a leg number (index + 1), return invalid if sum of selections up to then is greater than said leg number
  const isInvalid = aggregatedLegCounts.some((legCount, legIndex) => legCount > legIndex + 1)
  return !isInvalid
}

export function getSumLegSelectionCount(allStarters: Bit[][]) {
  const sumLegSelectionsCount = allStarters.reduce(
    (total, starter) => total + starter.filter(leg => leg == 1).length,
    0
  )
  return sumLegSelectionsCount
}
