import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { MaterialIcon } from '@core/Components/Icons'

export const FieldToggle = ({
  enabled,
  toggleFieldState,
}: {
  enabled: boolean
  toggleFieldState: () => void
}): JSX.Element => {
  const isFieldToggled = enabled
  const isResultsToggled = !enabled

  const resultsClicked = () => {
    if (!isResultsToggled) toggleFieldState()
  }
  const fieldClicked = () => {
    if (!isFieldToggled) toggleFieldState()
  }

  return (
    <FieldToggleContainerStyled>
      <ToggleButtonStyled enabled={isResultsToggled} onClick={resultsClicked}>
        {isResultsToggled && <MaterialIcon type='tick' />}
        Results
      </ToggleButtonStyled>
      <ToggleButtonStyled enabled={isFieldToggled} onClick={fieldClicked}>
        {isFieldToggled && <MaterialIcon type='tick' />}
        Field
      </ToggleButtonStyled>
    </FieldToggleContainerStyled>
  )
}

const FieldToggleContainerStyled = styled.div({
  alignItems: 'center',
  backgroundColor: hexColors.white,
  boxSizing: 'border-box',
  display: 'flex',
  maxWidth: '100vw',
  overflowX: 'auto',
  padding: '1rem 1.2rem 1rem 1.2rem',
  whiteSpace: 'nowrap',
})

const ToggleButtonStyled = styled.label<{ enabled: boolean }>(({ enabled = false }) => ({
  alignItems: 'center',
  border: 'none',
  borderRadius: '5px',
  boxSizing: 'border-box',
  display: 'inline-flex',
  height: '2.6rem',
  justifyContent: 'center',
  padding: '0 1rem',
  backgroundColor: enabled ? hexColors.lavenderBlue : hexColors.gainsboro,
  color: enabled ? hexColors.blueDiamond : hexColors.matterhorn,
  marginRight: '1rem',
  fontSize: '1.2rem',

  '> svg': {
    marginRight: '0.4rem',
  },
}))
