import { ISelectionResultProcessor } from './ISelectionResultProcessor'
import { BettingInformation } from '../../../Model/BettingInformation'
import ObservableSelectedRaceStarters from '../../../Model/Observables/ObservableSelectedRaceStarters'
import { ISelectionResult } from './ISelectionResult'
import Guard from '../../../../AppUtils/Framework/Guard'
import MultiLegSelectionResult from './MultiLegSelectionResult'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { sortNumberAscending } from '@core/Utils'
import { range } from '@mobi/utils'

class CheckBoxProjection {
  constructor(
    public starter: number,
    public selection: CheckBoxSelection
  ) {}
}

class CheckBoxValuesProjection {
  constructor(
    public starter: number,
    public selections: { checked: boolean; value: number }[]
  ) {}
}

export default class MultiLegSelectionResultProcessor implements ISelectionResultProcessor {
  public selections(
    bettingContext: BettingInformation,
    raceNumber: number,
    selections: ObservableSelectedRaceStarters
  ): ISelectionResult {
    Guard.notNull(bettingContext)
    Guard.notNull(selections)

    const starters = selections.getStartersForRace(raceNumber)
    const leg = selections.getLegForRaceNumber(raceNumber)

    if (!starters || starters().length === 0) return new MultiLegSelectionResult(leg, [''])

    if (starters().length === 0 || starters().some(starter => !starter.selection))
      return new MultiLegSelectionResult(leg, [''])

    if (!bettingContext.fieldSelectedAtIndex(leg - 1)) {
      return new MultiLegSelectionResult(leg, [this.dotSeperate(starters)])
    } else if (bettingContext.fieldSelectedAtIndex(leg - 1)) {
      return new MultiLegSelectionResult(leg, ['FD'])
    } else {
      return new MultiLegSelectionResult(leg, [])
    }
  }

  private dotSeperate(stream: ko.ObservableArray<IObservableStarter>): string {
    return stream()
      .map(
        starter =>
          new CheckBoxProjection(starter.number(), starter.selection() as CheckBoxSelection)
      )
      .map(
        checkboxProjection =>
          new CheckBoxValuesProjection(
            checkboxProjection.starter,
            range(checkboxProjection.selection.count()).map(i => ({
              value: i,
              checked: checkboxProjection.selection.selectedAt(i),
            }))
          )
      )
      .filter(checkboxValuesProjection =>
        checkboxValuesProjection.selections.some(
          selection => selection.checked && selection.value === 0
        )
      )
      .map(checkboxValuesProjection => checkboxValuesProjection.starter)
      .sort(sortNumberAscending)
      .join('.')
  }

  public getSelectionsResult(
    bettingContext: BettingInformation,
    raceNumber: number
  ): ISelectionResult {
    return this.selections(bettingContext, raceNumber, bettingContext.selections)
  }
}
