import React from 'react'
import { useQuery } from 'react-query'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { SlimEntryExpandable } from '@core/Areas/Blackbook/Components/RunnerItem/Components/SlimEntryExpandable'
import { RunningSoonHeader } from '@core/Areas/Blackbook/Components/RunnerItem/Components/Common'
import { getAllJockeyRacesRunningSoon } from '@core/Areas/Blackbook/helpers/api'
import { BlackbookEntryRacingToday, SmartBetslipState } from '@core/Areas/Blackbook/types'
import { sortByStartTime } from '../../../helpers'
import { isBettingAvailable } from '@core/Areas/Blackbook/helpers'

export const JockeyRunningSoonEntries: React.FC<{
  id: number
  name: string
  initialRunners: BlackbookEntryRacingToday[]
  smartBetslipState: SmartBetslipState
  incomingIdRef: React.MutableRefObject<string | undefined>
}> = ({ id, name, initialRunners, smartBetslipState, incomingIdRef }) => {
  const [hasExpanded, setHasExpanded] = React.useState(false)

  const { data: runners = [] } = useQuery({
    queryFn: async () => {
      const data = await getAllJockeyRacesRunningSoon(name)
      // Initial data only returns runners for current day, jockeys api has all upcoming fixtures
      // Can't replace initial data with jockeys api, if < 4 races for today data can be deleted
      // Merge the two sets of data, using the jockeyname.ast as a key
      const runners = [...data]
      const keys = new Set(data.map(runner => keyForBlackbookEntry(runner)))
      initialRunners.forEach(runner => {
        if (keys.has(keyForBlackbookEntry(runner))) return
        runners.push(runner)
      })
      return runners.sort(sortByStartTime)
    },
    queryKey: queryKeys.blackbookRunnersForJockey(name),
    placeholderData: initialRunners,
    enabled: hasExpanded,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1_000 * 60 * 5, // 5 minutes
  })

  const firstRunner = runners[0]

  const rideCountText = React.useMemo(() => {
    if (runners.length === 1) {
      return '1 Ride'
    } else if (runners.length === 4 && !hasExpanded) {
      return `${runners.length}+ Rides`
    } else {
      return `${runners.length} Rides`
    }
  }, [runners.length, hasExpanded])

  if (!firstRunner) {
    return null
  }

  const ast =
    (isBettingAvailable(firstRunner.RacingTodayDetails) &&
      firstRunner.RacingTodayDetails.AdvertisedStartTime) ||
    undefined

  return (
    <SlimEntryExpandable
      id={id}
      name={name}
      ast={ast}
      subheading={rideCountText}
      code={firstRunner.Code}
      incomingIdRef={incomingIdRef}
      hasComments={!!initialRunners[0].LatestNoteSeq}
      onExpand={() => setHasExpanded(true)}
    >
      <RunnersWrapperStyled>
        {runners.map(runner => (
          <RunningSoonHeader
            key={`${name}.${runner.RacingTodayDetails.AdvertisedStartTime || 'not-running'}`}
            runner={runner}
            isSelected={
              smartBetslipState.shouldAutoAddToBetslip &&
              smartBetslipState.betslipFobPropSeqs.includes(
                runner.RacingTodayDetails.PropositionSeq
              )
            }
            shouldAutoAddToBetslip={smartBetslipState.shouldAutoAddToBetslip}
          />
        ))}
      </RunnersWrapperStyled>
    </SlimEntryExpandable>
  )
}

// =============
// Local Helpers
// =============

function keyForBlackbookEntry(entry: BlackbookEntryRacingToday) {
  const ast = entry.RacingTodayDetails?.AdvertisedStartTime || 'not-running'
  return `${entry.StarterName}:${entry.RiderName ?? 'unknown'}:${ast}`
}

// ======
// Styles
// ======

const RunnersWrapperStyled = styled.div({
  margin: '0.5rem 0',

  '> div': {
    padding: '1rem 0',
    borderTop: '0.1rem solid ' + hexColors.gainsboro,

    ':last-of-type': {
      borderBottom: '0.1rem solid ' + hexColors.gainsboro,
    },
  },
})
