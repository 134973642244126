import React from 'react'
import styled from '@emotion/styled'
import { dayjs } from '@mobi/utils/date'
import { Flex } from '@mobi/component-library/Common/Flex'
import { colors, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import { useFeature } from '@core/Utils/hooks'
import { useAppSelector } from '@core/Store/hooks'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useDisableAppHeaderSticky } from '@core/Areas/AppHeader/hooks/useDisableAppHeaderSticky'
import { DockedPlayer as DockedSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/Components/DockedPlayer'
import { MainContainer } from '@core/Components/Containers'
import { NoticeBox } from '@core/Components/NoticeBox'
import { RaceCardMatched, RaceCardSkeletonLoader } from '@core/Areas/RaceCard'
import { RaceInfoSlim, RaceInfoSkeletonLoader } from './Components/RaceInfoSlim'
import { RaceFilter, RaceFilterSkeletonLoader } from './Components/RaceFilter'
import { RaceScrollView, RaceScrollViewSkeletonLoader } from './Components/RaceScrollView'
import { NoRacesMessage } from './Components/NoRacesMessage'
import { RaceListView } from './Components/RaceListView'
import { getResetTimestamp, getSkyRaceFilters } from './Store/selectors'
import type { FilterOptions } from './Store'
import type { NextSkyRace } from './types'
import { getLatestRace } from './helpers'
import { useSwipeable, useNextSkyRaces } from './Hooks'
import { sortRaceStatusOpenToTheRight } from '@core/Areas/RaceCardSky/helpers'

const enum LocalConstants {
  SwipeBackIndicatorsClass = 'js-sky-race__swipe--back',
  SwipeForwardIndicatorsClass = 'js-sky-race__swipe--forward',
  IsSwipingClass = 'js-sky-race__swipe--active',
}

export const RaceCardSky: React.FC = () => {
  useDisableAppHeaderSticky()
  const { isLoading, isError, nextRaces, refetch, resetLimit } = useNextSkyRaces()
  const raceFilters = useAppSelector(getSkyRaceFilters)

  React.useEffect(() => {
    return resetLimit
  }, [])

  if (isError) {
    return (
      <MainContainer background='grey'>
        <NoticeBox
          title='Unable to retreive data'
          subtitle='Please try again later'
          buttonText='Try again'
          buttonClick={refetch}
          hasBorder
          testId='sky-page-error'
        />
      </MainContainer>
    )
  }

  if (isLoading)
    return (
      <MainContainer>
        <div style={{ overflow: 'hidden' }}>
          <Flex testId='loading' flexDirection='column'>
            <RaceFilterSkeletonLoader />
            <RaceScrollViewSkeletonLoader />
            <RaceInfoSkeletonLoader />
            <RaceCardSkeletonLoader />
          </Flex>
        </div>
      </MainContainer>
    )

  const filteredSkyRaces = nextRaces.filter(filterRaces(raceFilters))
  const filteredAndSortedRaces = sortRaceStatusOpenToTheRight(filteredSkyRaces)

  return <RaceCardSkyWithData data={filteredAndSortedRaces} />
}

// =======================
// Local Component w/ Data
// =======================

const RaceCardSkyWithData: React.FC<{ data: NextSkyRace[] }> = ({ data }) => {
  const isSwipeActive = useFeature('SWIPE_TO_CHANGE_RACE')

  const swipeableElRef = React.useRef<HTMLDivElement>(null)

  const [currentRace, setCurrentRace] = React.useState<NextSkyRace>(() => getLatestRace(data))

  const resetTimestamp = useAppSelector(getResetTimestamp)
  const resetTimestampPrevRef = React.useRef(resetTimestamp)

  // Set latest if data is missing current race
  React.useEffect(() => {
    if (!data.length || data.some(isCurrentRaceAvailableInData(currentRace))) return
    setCurrentRace(getLatestRace(data))
  }, [currentRace, data])

  // Set latest if reset timestamp changes
  React.useEffect(() => {
    if (resetTimestamp === resetTimestampPrevRef.current) return
    setCurrentRace(getLatestRace(data))
  }, [data, resetTimestamp])

  // Swipe derived vars
  const currentRaceIdx = data.findIndex(r => r === currentRace)
  const isForwardDisabled = data.length === currentRaceIdx + 1
  const isBackDisabled = currentRaceIdx === 0

  const onSwipeBack = React.useCallback(() => {
    const prevRace = data.find((_, idx) => idx === currentRaceIdx - 1)
    if (!prevRace) return
    setCurrentRace(prevRace)
    trackEvent('race-card-swiped', { direction: 'BACKWARD' })
  }, [currentRaceIdx, data])

  const onSwipeForward = React.useCallback(() => {
    const nextRace = data.find((_, idx) => idx === currentRaceIdx + 1)
    if (!nextRace) return
    setCurrentRace(nextRace)
    trackEvent('race-card-swiped', { direction: 'FORWARD' })
  }, [currentRaceIdx, data])

  const { isSwiping, isChangingRace } = useSwipeable({
    onSwipeBack,
    onSwipeForward,
    swipeableElRef,
    isBackDisabled,
    isForwardDisabled,
    isSwipeActive,
  })

  const swipeClassNames = []
  if (!isBackDisabled) swipeClassNames.push(LocalConstants.SwipeBackIndicatorsClass)
  if (!isForwardDisabled) swipeClassNames.push(LocalConstants.SwipeForwardIndicatorsClass)
  if (isSwiping) swipeClassNames.push(LocalConstants.IsSwipingClass)

  return (
    <MainContainer background='surface50'>
      <DockedSkyVideoPlayer isOnSkyRacePage />

      <WrapperStyled>
        <RaceFilter races={data} />
        <RaceListView races={data} setCurrentRace={setCurrentRace} />

        {!currentRace || data.length === 0 ? (
          <NoRacesMessage />
        ) : (
          <>
            <RaceScrollView
              races={data}
              currentRace={currentRace}
              setCurrentRace={setCurrentRace}
            />

            <section className={swipeClassNames.join(' ')}>
              <div>
                <div ref={swipeableElRef}>
                  <div key={`${currentRace.AdvertisedStartTime}-${currentRace.MeetingID}`}>
                    <RaceInfoSlim
                      shouldForceLoading={isChangingRace}
                      meetingDate={currentRace.MeetingDate}
                      meetingId={currentRace.MeetingID}
                      raceNumber={currentRace.RaceNumber}
                      skyTvChannelId={currentRace.SkyTvChannelId}
                    />

                    <RaceCardMatched
                      shouldForceLoading={isChangingRace}
                      meetingDate={dayjs(currentRace.MeetingDate).format('YYYY-MM-DD')}
                      meetingId={currentRace.MeetingID}
                      raceNumber={currentRace.RaceNumber.toString()}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </WrapperStyled>
    </MainContainer>
  )
}

// =============
// Local Helpers
// =============

function isLocalRace(isoCountryCode: string) {
  return ['AU', 'NZ'].includes(isoCountryCode)
}

const isCurrentRaceAvailableInData = (currentRace: NextSkyRace) => (race: NextSkyRace) =>
  race.AdvertisedStartTime === currentRace.AdvertisedStartTime &&
  race.MeetingID === currentRace.MeetingID

const filterRaces = (filters: FilterOptions) => (race: NextSkyRace) => {
  if (!filters[race.RaceType.toLowerCase() as 'races' | 'trots' | 'dogs']) return false
  if (!filters['international'] && !isLocalRace(race.IsoCountryCode)) return false
  if (!filters['local'] && isLocalRace(race.IsoCountryCode)) return false
  return true
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: 0,
  paddingBottom: spacing.md,

  '> section': {
    overflow: 'hidden',
    position: 'relative',

    // Swipe Wrapper
    '> div': {
      overflow: 'hidden',

      // Swipe container
      '> div': {
        overflow: 'hidden',
        padding: '0 ' + spacing.md,

        // Main race card
        '> div ': {
          position: 'relative',
          minHeight: '70vh',
          padding: 0,
          paddingBottom: spacing.md,
          borderRadius: radius.lgx1,
          border: '0.1rem solid ' + colors.neutral[200],
          boxShadow: shadow.xs,
          backgroundColor: colors.white,
        },
      },
    },

    // Swipe Indicators
    '&::before, &::after': {
      content: "''",
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      pointerEvents: 'none',
      transition: 'transform 0.4s ease 1s, opacity 0s',
      borderRadius: radius.lgx1,
      border: '0.1rem solid ' + colors.neutral[200],
      boxShadow: shadow.xs,
      backgroundColor: colors.white,
      opacity: 0,
    },

    [`&.${LocalConstants.SwipeBackIndicatorsClass}::before`]: {
      right: '100%',
      opacity: 1,
      transform: `translateX(calc((${spacing.md} / 2)))`,
    },
    [`&.${LocalConstants.SwipeForwardIndicatorsClass}::after`]: {
      left: '100%',
      opacity: 1,
      transform: `translateX(calc((${spacing.md} / 2) * -1))`,
    },

    // Swipe is active, hide indicators
    [`&.${LocalConstants.IsSwipingClass}::before`]: {
      transform: `translateX(calc(${spacing.md} * 2 * -1))`,
      opacity: 0,
      transitionDelay: '0s',
    },
    [`&.${LocalConstants.IsSwipingClass}::after`]: {
      transform: `translateX(calc(${spacing.md} * 2))`,
      opacity: 0,
      transitionDelay: '0s',
    },
  },
})
