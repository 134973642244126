import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import {
  setBetSlipUserAccountBalanceSync,
  setBetSlipIsUserLoggedInSync,
} from '@mobi/betslip/Store/Workflow'

export const useAccountBalanceSync = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const subscription = userAccountState$
      .distinctUntilChanged(state => `${state.isLoggedIn}.${state.accountBalance}`)
      .subscribe(state => {
        dispatch(setBetSlipUserAccountBalanceSync(state.accountBalance))
        dispatch(setBetSlipIsUserLoggedInSync(!!state.isLoggedIn))
      })
    return () => {
      subscription.dispose()
    }
  }, [dispatch])
}
