import React from 'react'
import styled from '@emotion/styled'
import { PlanSeq } from '@mobi/api-types'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'
import {
  isSameRaceMulti,
  isTippingTicket,
  isRefundedStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { sortSameRaceMultiTicketLegs } from '@core/Areas/AccountActivity/helpers'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { LegStatusSilks } from './LegStatusSilks/LegStatusSilks'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SelectionStatusSilks } from './SelectionStatusSilks/SelectionStatusSilks'

export const StatusSilks: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  let legs = ticket.legs
  if (isSameRaceMulti(ticket)) {
    legs = sortSameRaceMultiTicketLegs(ticket)
  }

  const isMulti = !isTippingTicket(ticket) && ticket.legs.length > 1
  const isDoubleOrQuaddie = [PlanSeq.Double, PlanSeq.Quaddie].includes(ticket.planSeq)

  // don't display status silks for SRM if the bet is refunded (including the derived refunded states)
  const isRefundedSameRaceMulti = isSameRaceMulti(ticket) && isRefundedStatus(ticket)

  if ((!isMulti && !isDoubleOrQuaddie) || isRefundedSameRaceMulti) return null

  return (
    <SummaryLine
      testId='StatusSilks'
      left='Legs'
      right={
        <StatusSilksContainerStyled>
          {isMulti ? (
            <LegStatusSilks legs={legs} planSeq={ticket.planSeq} />
          ) : (
            <SelectionStatusSilks
              selections={legs[0].selections}
              contestType={meetingIdToContestType(legs[0].fixtureId)}
            />
          )}
        </StatusSilksContainerStyled>
      }
    />
  )
}

const StatusSilksContainerStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    marginLeft: '0.2rem',
  },
})
