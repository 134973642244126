import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { RaceStatus } from '@mobi/api-types'
import { toTitleCase } from '@mobi/utils/string'
import { Button } from '@mobi/component-library/Common/V2'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { Icon, IconType } from '@mobi/component-library/Common/Icon'
import { triggerHapticFeedback } from '@core/Utils/hapticFeedback/hapticFeedback'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import { RaceNotificationButton } from '@core/Areas/Racing/Components/RaceNotificationButton'
import { getSimpleRaceStatusText } from '@core/Areas/Racing/helpers'
import { useIsBlackbookInRace } from '../../Hooks/useIsBlackbookInRace'
import { mapCodeToIcon } from '../../helpers'

const enum LocalConstants {
  ListViewDisplayClass = 'js-sky-race-list-view__button',
}

export const SharedRaceSelection: React.FC<{
  skyRace: NextSkyRace
  onClick: () => void
  isSelected?: boolean
  isListViewDisplay?: boolean
}> = ({ skyRace, onClick, isSelected, isListViewDisplay }) => {
  const raceStatusText = useMemo(
    () => getSimpleRaceStatusText(skyRace.RaceStatus),
    [skyRace.RaceStatus]
  )

  const codeIconSize = isListViewDisplay ? '2.4rem' : '1.8rem'
  const wrapperClassName = isListViewDisplay ? LocalConstants.ListViewDisplayClass : ''
  const raceNumberText = `R${skyRace.RaceNumber}`

  const handleButtonClick = () => {
    if (!isSelected) triggerHapticFeedback('impact-light')
    onClick()
  }

  const isInBlackbook = useIsBlackbookInRace(skyRace)

  return (
    <Button data-testid='SkyRaceSelection' color='secondary_outline' onClick={handleButtonClick}>
      <WrapperStyled className={wrapperClassName}>
        <Icon type={mapCodeToIcon[skyRace.RaceType]} size={codeIconSize} />
        <div>
          <span data-testid='SkyRaceSelection.RaceNumber'>
            {isListViewDisplay ? (
              <StatusBadge color='gray'>{raceNumberText}</StatusBadge>
            ) : (
              <strong>{raceNumberText}</strong>
            )}
          </span>

          <span data-testid='SkyRaceSelection.MeetingName'>{toTitleCase(skyRace.MeetingName)}</span>
        </div>
        <div>
          {skyRace.RaceStatus == RaceStatus.Open ? (
            <>
              {isListViewDisplay && isInBlackbook ? <IconStatusBadge iconType='blackbook' /> : null}

              <span data-testid='SkyRaceSelection.StartTime'>
                <CountdownBadge advertisedStartTime={new Date(skyRace.AdvertisedStartTime)} />
              </span>

              {skyRace.RaceKey && isListViewDisplay && (
                <RaceNotificationButton
                  raceStatus='Open'
                  raceTime={new Date(skyRace.AdvertisedStartTime)}
                  meetingId={skyRace.MeetingID}
                  raceNumber={skyRace.RaceNumber}
                  meetingName={skyRace.MeetingName}
                  meetingDate={skyRace.MeetingDate}
                />
              )}
            </>
          ) : (
            <span data-testid='SkyRaceSelection.Status'>
              <StatusBadge color='gray'>{raceStatusText}</StatusBadge>
            </span>
          )}
        </div>
        {!isListViewDisplay && isInBlackbook ? <IconStatusBadge iconType='blackbook' /> : null}
      </WrapperStyled>
    </Button>
  )
}

const IconStatusBadge: React.FC<{ iconType: IconType }> = ({ iconType }) => (
  <StatusBadge badgeSize='S' color='gray'>
    <Icon type={iconType} size='1.2rem' />
  </StatusBadge>
)
// Styles

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing.smx1,
  flex: 1,

  fontFamily: font.family.primary,
  fontWeight: font.weight.regular,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
  color: colors.black,
  cursor: 'pointer',

  strong: { fontWeight: font.weight.semibold },

  [`&.${LocalConstants.ListViewDisplayClass}`]: {
    justifyContent: 'flex-start',
    gap: spacing.sm,

    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,

    '> div:last-of-type': { marginLeft: 'auto' },
  },

  '> div': { display: 'flex', alignItems: 'center', gap: spacing.smx2 },
})
