import jQuery from 'jquery'
import cookies from 'js-cookie'
import * as common from './Common'

// Used in TabLocatorViewModel
export function initTabLocatorNotification(callback: () => void) {
  attachCloseHandlers('tabtouch-notification')
  const introToTabLocatorNotification = new Notification(
    'tablocator-intro-notification',
    'tabtouch.dont_show_tablocator_notification',
    callback
  )

  return introToTabLocatorNotification
}

class Notification {
  private hasBeenDisabled = false
  private cookieName: string
  private notificationPopup: JQuery
  private callback: () => void

  constructor(wrapperId: string, cookieName: string, callback: () => void) {
    attachCloseHandlers(wrapperId, callback)

    this.notificationPopup = jQuery('#' + wrapperId)
    this.cookieName = cookieName
    this.callback = callback
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisClass = this

    jQuery('#' + wrapperId + ' .continue').off('click')
    jQuery('#' + wrapperId + ' .continue').click(function (this: HTMLElement) {
      if (jQuery('#dontShowNotification').is(':checked')) {
        if (cookieName) {
          cookies.set(cookieName, 'true')
        }
        thisClass.hasBeenDisabled = true
      }

      if ($(this).hasClass('close')) {
        thisClass.notificationPopup.fadeOut('fast', callback)
      } else if (callback && typeof callback === 'function') {
        callback()
      }
    })
  }

  public show(e: Event) {
    if ((!this.cookieName || cookies.get(this.cookieName) !== 'true') && !this.hasBeenDisabled) {
      if (e && e.preventDefault) e.preventDefault()

      common.showPopup(this.notificationPopup)
      jQuery('#dontShowNotification').removeAttr('checked')
    } else {
      if (typeof this.callback === 'function') {
        this.callback()
      }
    }
  }
}

function attachCloseHandlers(wrapperId: string, callback?: () => void) {
  const notificationPopup = jQuery('#' + wrapperId)
  jQuery('#' + wrapperId + ' .close').click(() => {
    notificationPopup.fadeOut('fast', callback)
  })
}
