import React from 'react'
import {
  SearchFixedOddsLabelStyled,
  SearchResultInfoHeaderStyled,
  SearchResultsStyled,
} from './SearchResults.styles'
import { SearchTextLabel } from '../SearchTextLabel/SearchTextLabel'
import { SearchTextLabelFontSize } from '../SearchTextLabel/SearchTextLabelStyled.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { SearchMessageDisplayStyled } from './InitialDisplay.styles'
import { colors } from '@core/Settings'
import * as DateTimeProvider from '@classic/Foundation/DateTimeProvider'
import { SearchResultComponent } from './SearchResult'
import { SearchResult } from '@core/Data/Search/Search'
import { SearchResultComponentStyled } from './SearchResultStyled.styles'
import { getRedirectUrl } from './utils'
import { OverlayClose } from '@core/Components/Overlay'
interface SearchResultProps {
  results: SearchResult[]
}

export function SearchResults({ results }: SearchResultProps): JSX.Element {
  const currentDateMilli = DateTimeProvider.now()
  const searchResultsComponents = SearchResultsComponent({
    searchResults: results,
    currentDateMilli,
  })
  return (
    <SearchResultsStyled>
      {searchResultsComponents === null ? (
        <NoResults />
      ) : (
        <>
          <SearchResultInfoHeaderStyled>
            <SearchFixedOddsLabelStyled>Fixed</SearchFixedOddsLabelStyled>
          </SearchResultInfoHeaderStyled>
          <SearchResultsComponent searchResults={results} currentDateMilli={currentDateMilli} />
        </>
      )}
    </SearchResultsStyled>
  )
}

export function NoResults() {
  return (
    <SearchMessageDisplayStyled>
      <Icon type='warningCircled' size='6rem' color={colors.search.initialText} />
      <SearchTextLabel size={SearchTextLabelFontSize.Large}>No Results Found</SearchTextLabel>
      <SearchTextLabel size={SearchTextLabelFontSize.Small}>
        Make sure all words are spelled correctly
      </SearchTextLabel>
    </SearchMessageDisplayStyled>
  )
}

function handleClick(searchResult: SearchResult) {
  const url = getRedirectUrl(searchResult)
  window.location.href = url
  OverlayClose()
}

interface SearchResultsComponentProps {
  searchResults: SearchResult[]
  currentDateMilli: number
}

export function SearchResultsComponent({
  searchResults,
  currentDateMilli,
}: SearchResultsComponentProps): JSX.Element | null {
  const elements: JSX.Element[] = []
  searchResults.forEach(searchResult => {
    // can be null, if type of search result unknown
    const component = SearchResultComponent({
      searchResult,
      currentDateMilli,
      highlights: searchResult.metadata.highlights,
    })
    if (component === null) return

    elements.push(
      <SearchResultComponentStyled
        key={searchResult.id}
        onClick={() => handleClick(searchResult)}
        data-tid-search-result={`${searchResult.kind.toLowerCase()}-${searchResult.id}`}
      >
        {component}
      </SearchResultComponentStyled>
    )
  })

  if (!elements.length) {
    return null
  }
  return <>{elements}</>
}
