import React from 'react'
import { InputField } from '@mobi/component-library/Common/Input'
import { SelectField } from '@mobi/component-library/Common/Select'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { ValidationErrors, ChangeValue, ValidateField } from '../../../ContactDetails/driver'
import { DateSelection } from '../../../../Components/DateSelection/DateSelection'
import { MedicareSvg } from './MedicareSvg'
import { medicareCardColours } from '@mobi/account/Areas/SignUp/Components/Verification/Medicare'

interface MedicareFields {
  medicareBlueYellowExpiryDate: Date | null
  medicareGreenExpiry: string
  medicareCardColour: string
  medicareMiddleNameOnCard: string
  medicareNumber: string
  medicareReference: string
}

export interface MedicareProps extends MedicareFields {
  validationErrors: ValidationErrors
}

export function validateMedicareFields() {
  const validationFields: (keyof MedicareFields)[] = [
    'medicareBlueYellowExpiryDate',
    'medicareCardColour',
    'medicareGreenExpiry',
    'medicareNumber',
    'medicareReference',
  ]
  validationFields.forEach(field => ValidateField({ field }))
}

export function Medicare({
  medicareBlueYellowExpiryDate,
  medicareGreenExpiry,
  medicareCardColour,
  medicareNumber,
  medicareReference,
  medicareMiddleNameOnCard,
  validationErrors,
}: MedicareProps) {
  const changeMedicareNumber = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    const numberOfDigitsAllowed = 10
    if (value.length > numberOfDigitsAllowed) {
      return
    }
    ChangeValue({ field: 'medicareNumber', value })
  }
  const changeMedicareReference = (event: React.FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value
    const numberOfDigitsAllowed = 1
    if (value.length > numberOfDigitsAllowed) {
      value = value.split('')[0]
    }
    // 0 is not allowed as reference
    if (value === '0') {
      return
    }
    ChangeValue({ field: 'medicareReference', value })
  }
  const changeMedicareMiddleNameOnCard = (event: React.FormEvent<HTMLInputElement>) =>
    ChangeValue({ field: 'medicareMiddleNameOnCard', value: event.currentTarget.value })
  const changeMedicareCardColor = (event: React.FormEvent<HTMLSelectElement>) =>
    ChangeValue({ field: 'medicareCardColour', value: event.currentTarget.value })
  const changeMedicareGreenExpiry = (selectedDate: Date | null) => {
    if (!selectedDate) {
      ChangeValue({ field: 'medicareGreenExpiry', value: '' })
      return
    }
    const month = selectedDate.getMonth() + 1
    const monthFormatted = month < 10 ? `0${month}` : `${month}`
    const year = selectedDate.getFullYear()
    ChangeValue({ field: 'medicareGreenExpiry', value: `${monthFormatted}/${year}` })
  }
  const changeMedicareBlueYellowExpiry = (selectedDate: Date | null) => {
    ChangeValue({ field: 'medicareBlueYellowExpiryDate', value: selectedDate || '' })
  }
  const isMedicareGreenCard =
    medicareCardColour && medicareCardColour.trim().toLocaleLowerCase() === 'green'
  const isMedicareBlueOrYellowCard =
    medicareCardColour &&
    (medicareCardColour.trim().toLocaleLowerCase() === 'blue' ||
      medicareCardColour.trim().toLocaleLowerCase() === 'yellow')

  return (
    <div>
      <Grid padding='0.5rem'>
        <GridRow>
          <GridCell>
            <InputField
              type='number'
              name='Medicare Card Number'
              value={medicareNumber || ''}
              onChange={changeMedicareNumber}
              onBlur={validateMedicareFields}
              errorMessage={validationErrors.medicareNumber}
            />
          </GridCell>
          <GridCell width={'15%'}>
            <InputField
              type='number'
              name='IRN'
              value={medicareReference || ''}
              onChange={changeMedicareReference}
              onBlur={validateMedicareFields}
              errorMessage={validationErrors.medicareReference}
            />
          </GridCell>
          <GridCell width='10rem'>
            <MedicareSvg />
          </GridCell>
        </GridRow>
      </Grid>
      <InputField
        type='text'
        name='Middle Name on Card'
        value={medicareMiddleNameOnCard || ''}
        onChange={changeMedicareMiddleNameOnCard}
      />
      <SelectField
        name='Card Colour'
        options={medicareCardColours}
        value={medicareCardColour}
        onChange={changeMedicareCardColor}
        onBlur={validateMedicareFields}
        errorMessage={validationErrors.medicareCardColour}
      />
      {isMedicareGreenCard && (
        <GreenCardExpiryDate
          medicareGreenExpiry={medicareGreenExpiry}
          onChange={changeMedicareGreenExpiry}
          onBlur={validateMedicareFields}
          errorMessage={validationErrors.medicareGreenExpiry}
        />
      )}
      {isMedicareBlueOrYellowCard && (
        <BlueOrYellowCardDate
          medicareBlueYellowExpiry={medicareBlueYellowExpiryDate}
          onChange={changeMedicareBlueYellowExpiry}
          onBlur={validateMedicareFields}
          errorMessage={validationErrors.medicareBlueYellowExpiryDate}
        />
      )}
    </div>
  )
}

interface GreeCardExpiryDateProps {
  medicareGreenExpiry: string
  onChange: (selectedDate: Date | null) => void
  onBlur: () => void
  errorMessage: string | boolean
}

function GreenCardExpiryDate({
  medicareGreenExpiry,
  onChange,
  onBlur,
  errorMessage,
}: GreeCardExpiryDateProps) {
  const greenExpiry = medicareGreenExpiry && medicareGreenExpiry.split('/')

  if (!greenExpiry) {
    return (
      <DateSelection
        name={'Valid To'}
        day={null}
        onChange={onChange}
        onBlur={onBlur}
        errorMessage={errorMessage}
      />
    )
  }
  const month = greenExpiry[0]
  const year = greenExpiry[1]
  return (
    <DateSelection
      name={'Valid To'}
      day={null}
      month={month}
      year={year}
      onChange={onChange}
      onBlur={onBlur}
      errorMessage={errorMessage}
    />
  )
}

interface BlueOrYellowCardDateProps {
  medicareBlueYellowExpiry: Date | null
  onChange: (selectedDate: Date | null) => void
  onBlur: () => void
  errorMessage: string | boolean
}

function BlueOrYellowCardDate({
  medicareBlueYellowExpiry,
  onChange,
  onBlur,
  errorMessage,
}: BlueOrYellowCardDateProps) {
  if (!medicareBlueYellowExpiry) {
    return (
      <DateSelection
        name={'Valid To'}
        onChange={onChange}
        onBlur={onBlur}
        errorMessage={errorMessage}
      />
    )
  }

  medicareBlueYellowExpiry =
    typeof medicareBlueYellowExpiry === 'string'
      ? new Date(medicareBlueYellowExpiry)
      : medicareBlueYellowExpiry
  const day = medicareBlueYellowExpiry.getDate()
  const month = medicareBlueYellowExpiry.getMonth() + 1
  const monthFormatted = month < 10 ? `0${month}` : `${month}`
  const year = medicareBlueYellowExpiry.getFullYear()
  return (
    <DateSelection
      name={'Valid To'}
      day={`${day}`}
      month={monthFormatted}
      year={`${year}`}
      onChange={onChange}
      onBlur={onBlur}
      errorMessage={errorMessage}
    />
  )
}
