import { BaseCheckBoxSelectionProcessor } from './BaseCheckBoxSelectionProcessor'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ISelectionContext } from './ISelectionContext'
import CheckBoxPipelineService from './CheckBoxPipelineService'
import CheckBoxSameAsProcessor from './CheckBoxSameAsProcessor'
import CheckBoxFieldProcessor from './CheckBoxFieldProcessor'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'

export default class CheckboxSelectionProcessor extends BaseCheckBoxSelectionProcessor {
  public constructor(count: number) {
    super(count)
  }

  public tag(): string {
    return 'CheckboxSelectionProcessor'
  }

  public generateSelections(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    context: ISelectionContext
  ): IObservableStarter[] {
    return CheckBoxPipelineService.process(starter, starters, bettingContext, context, this.count)
  }

  public processFieldSelection(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    index: number,
    selected: boolean
  ) {
    const processor = new CheckBoxFieldProcessor()
    processor.process(starters, selections, bettingContext, index, selected)
  }

  public processSameAsSelection(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    row: number,
    column: number,
    selected: boolean
  ) {
    const processor = new CheckBoxSameAsProcessor()
    processor.process(starters, selections, bettingContext, row, column, selected)
  }

  public clearAllSelections(
    starters: ko.ObservableArray<IObservableStarter>[],
    selections: ko.ObservableArray<IObservableStarter>[]
  ) {
    selections.forEach(starters => {
      starters().forEach(starter => (starter.selection() as CheckBoxSelection).clearAll())
    })
  }
}
