import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import CheckBoxSelectionPipeline from './CheckBoxSelectionPipeline'
import AllwaysCheckBoxPipelineProcessor from './AllwaysCheckBoxPipelineProcessor'
import RovingBankerCheckBoxPipelineProcessor from './RovingBankerCheckBoxPipelineProcessor'
import RegularCheckBoxPipelineProcessor from './RegularCheckBoxPipelineProcessor'
import LegInCheckBoxPipelineProcessor from './LegInCheckBoxPipelineProcessor'
import { ISelectionContext } from './ISelectionContext'

export default {
  process(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    context: BettingInformation,
    selectionContext: ISelectionContext,
    count: number
  ): IObservableStarter[] {
    return new CheckBoxSelectionPipeline(
      new RegularCheckBoxPipelineProcessor(
        new RovingBankerCheckBoxPipelineProcessor(
          new AllwaysCheckBoxPipelineProcessor(new LegInCheckBoxPipelineProcessor(null))
        )
      )
    ).process(current, all, context, selectionContext, count)
  },
}
