import SortConverter from '@classic/Betting-v2/Sorting/SortConverter'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import type { SelectedBetType } from '../types'

export function shouldDisplayColumn(
  sortOption: SortOption,
  selectedBetType: SelectedBetType,
  isFixedOddsOn: boolean,
  hasFixedOdds: boolean
): boolean {
  switch (sortOption.property) {
    case 'Price':
      return selectedBetType.isWinPlace || !isFixedOddsOn
    case 'FixedPrice':
      return hasFixedOdds && (selectedBetType.isWinPlace || isFixedOddsOn)
    case 'Number':
    case 'Name':
      return true
    case 'SameRaceMultiWinPrice':
      return selectedBetType.isSameRaceMulti
    default:
      return false
  }
}

export function shouldResetTotePriceSort(
  selectedBetType: SelectedBetType,
  selectedSortOption: SortOption,
  isFixedOddsOn: boolean
): boolean {
  return !selectedBetType.isWinPlace && selectedSortOption.property === 'Price' && isFixedOddsOn
}

export function shouldResetFixedPriceSort(
  sortOptions: SortOption[],
  selectedBetType: SelectedBetType,
  selectedSortOption: SortOption,
  isFixedOddsOn: boolean
): boolean {
  const hasFixedOddsHeader = sortOptions.some((so: SortOption) => {
    return so.property === 'FixedPrice'
  })
  return (
    (!hasFixedOddsHeader && selectedSortOption.property === 'FixedPrice') ||
    (!selectedBetType.isWinPlace && selectedSortOption.property === 'FixedPrice' && !isFixedOddsOn)
  )
}

export function cssClass(sortOption: SortOption): string {
  let result = SortConverter.for(sortOption.property)
  // first time needed for Same Race Multi: replace ' ' with '-'
  result = result.replace(/\s/g, '-')
  const cssClass = `${result.toLowerCase()}-col ${result.toLowerCase()}-color`

  return cssClass
}
