import React from 'react'
import { useMutation } from 'react-query'
import { isReactNativeAndroid } from '@mobi/web-native-comms/web'
import { get } from '@classic/Foundation/Services/ApiService'
import { useLogon } from '@core/Utils/hooks'
import { SettingItemWithCheckbox } from '../Common/SettingItemWithCheckbox'
import { checkNotificationStatusAsync, ShowNotificationAuthPopup } from './PayoutNotificationPopup'

// DEV testing, use this status response:
// const pushAuthStatus = {
//   fcmRegistrationToken: 'FAKE_TOKEN_1',
//   status: 'Authorized',
// }

export const PayoutNotification: React.FC = () => {
  const { accountNumber } = useLogon()
  const [isEnabled, setIsEnabled] = React.useState<boolean | null>(null)

  React.useEffect(() => {
    checkAccountToken(accountNumber)
      .then(isEnabled => {
        if (typeof isEnabled !== 'boolean') return
        setIsEnabled(isEnabled)
      })
      .catch()
  }, [accountNumber])

  const { mutate, isLoading } = useMutation<void, void, OnToggleParams>(
    ({ accountNumber, isEnabled }) => onToggleCreditAlerts({ accountNumber, isEnabled }),
    { onSuccess: () => setIsEnabled(curr => !curr) }
  )

  if (isEnabled === null) return null

  return (
    <SettingItemWithCheckbox
      isEnabled={isEnabled}
      label='Payout Notification'
      desciption='Enables an alert when you get a payout'
      onToggle={() => mutate({ accountNumber, isEnabled })}
      shouldDisable={isLoading}
      isLoading={isLoading}
    />
  )
}

// =============
// Local Helpers
// =============

export async function checkAccountToken(accountNumber: number | null) {
  if (!accountNumber) return

  const pushAuthStatus = await checkNotificationStatusAsync()
  if (!pushAuthStatus) return

  const response = await get<{ enabled: boolean }>({
    url: `/api/credit-alerts/registrationStatus?accountNumber=${accountNumber}&fcmToken=${pushAuthStatus.fcmRegistrationToken}`,
    opts: { credentials: 'include' },
  })

  return response.enabled
}

async function onToggleCreditAlerts({
  accountNumber,
  isEnabled,
}: {
  accountNumber: number | null
  isEnabled: boolean
}) {
  const pushAuthStatus = await checkNotificationStatusAsync()

  if (pushAuthStatus.status !== 'Authorized' && !isEnabled) {
    ShowNotificationAuthPopup({
      reason:
        'Notifications must be enabled on TABtouch to receive payout alerts. Enable notifications then try again.',
      notificationAuthStatus: pushAuthStatus.status,
    })
    return
  }

  // populate JSON payload,
  // http://gitlab.rwwa.com.au/rwwa-aws/tabtouch-credit-alerts/-/blob/master/TabTouch.CreditAlerts.Api/README.md
  const body = JSON.stringify({
    accountNumber: accountNumber,
    fcmToken: pushAuthStatus.fcmRegistrationToken,
    enable: !isEnabled,
    deepLink: `${window.location.origin}/#account/activity/results`,
    isAndroid: isReactNativeAndroid,
  })

  await fetch('/api/credit-alerts/register', {
    method: 'post',
    credentials: 'include',
    body,
  })
}

// =====
// Types
// =====

type OnToggleParams = Parameters<typeof onToggleCreditAlerts>[0]
