import { ScratchingDataTransferObject } from '@mobi/api-types'
import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import ObservableScratching from '../Model/Observables/ObservableScratching'

export default {
  map(dto: ScratchingDataTransferObject[], model: ObservableResultsPage, meetingCode: string) {
    dto.forEach(dtoScratching => {
      //Create new observable
      const scratching = new ObservableScratching()

      scratching.starterNumber = dtoScratching.StarterNumber
      scratching.starterName = dtoScratching.StarterName

      scratching.isFixedOdds = dtoScratching.IsFixedOdds
      scratching.scratchedDateTime = dtoScratching.ScratchedDateTime

      if (dtoScratching.WinDivDeduction !== null) {
        scratching.winDivDeduction = parseFloat(
          dtoScratching.WinDivDeduction as unknown as string
        ).toFixed(2)
      } else {
        scratching.winDivDeduction = null
      }
      if (dtoScratching.PlaceDivDeduction !== null) {
        scratching.placeDivDeduction = parseFloat(
          dtoScratching.PlaceDivDeduction as unknown as string
        ).toFixed(2)
      } else {
        scratching.placeDivDeduction = null
      }

      // @ts-expect-error Missing type
      scratching.isEmergency = dtoScratching.IsEmergency
      scratching.meetingCode = meetingCode
      // @ts-expect-error Missing type
      scratching.emergencyNum = dtoScratching.EmergencyNumber

      //Finally add it to model for template
      model.addScratching(scratching)
    })
  },
}
