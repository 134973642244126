import { injectable } from 'inversify'
import { ILoginHelper } from './ILoginHelper'
import { HasLoggedIn, HasLoggedOut, state$ } from '@core/State/UserAccount/userAccountDriver'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'

@injectable()
export class LogInHelper implements ILoginHelper {
  public isUserLoggedIn(): boolean {
    let loggedOn = false
    state$.take(1).subscribe(userAccountState => {
      loggedOn = !!userAccountState.isLoggedIn
    })
    return loggedOn
  }

  public presentLogIn(): void {
    showLogin()
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  public setupHasLoggedInSubscription(callback: () => void): Rx.IDisposable {
    return HasLoggedIn.signal$.subscribe(callback)
  }

  public setupHasLoggedOutSubscription(callback: () => void): Rx.IDisposable {
    return HasLoggedOut.signal$.subscribe(callback)
  }
}
