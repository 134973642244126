import { BettingInformation } from '../../../Model/BettingInformation'
import SameAs from '../../../Model/SameAs'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'

export function sameAsColumnsDependendentOn(
  bettingContext: BettingInformation,
  dependency: SameAs
): SameAs[] {
  return bettingContext.sameAs
    .flat()
    .filter(x => x.row === dependency.column)
    .filter(x => x.selected())
}

function otherSameAsColumnsDependendentOn(
  bettingContext: BettingInformation,
  dependency: SameAs
): SameAs[] {
  return bettingContext.sameAs
    .flat()
    .filter(x => x.column === dependency.column)
    .filter(x => x.row !== dependency.row)
    .filter(x => x.selected())
}

export function sameAsColumnsDependendentOnSelectedFieldIndex(
  bettingContext: BettingInformation,
  fieldIndex: number
): SameAs[] {
  return bettingContext.sameAs
    .flat()
    .filter(x => x.row === fieldIndex + 1)
    .filter(x => x.selected())
}

export function cascadeSameAsSelectedThroughColumns(
  bettingContext: BettingInformation,
  starters: IObservableStarter[],
  dependencies: SameAs[]
) {
  if (dependencies.length === 0) return

  for (const dependency of dependencies) {
    notifyDependentColumnOfSelection(starters, dependency.row, dependency.column)
    const dependencies = sameAsColumnsDependendentOn(
      bettingContext,
      bettingContext.sameAsAt(dependency.row, dependency.column) as SameAs
    )
    cascadeSameAsSelectedThroughColumns(bettingContext, starters, dependencies)
  }
}

export function cascadeSameAsDeselectedThroughColumns(
  bettingContext: BettingInformation,
  affected: CheckBoxSelection[],
  dependencies: SameAs[]
) {
  if (dependencies.length === 0) return

  for (const dependency of dependencies) {
    notifyDependentColumnOfDeselection(bettingContext, affected, dependency)
    const dependencies = sameAsColumnsDependendentOn(
      bettingContext,
      bettingContext.sameAsAt(dependency.row, dependency.column) as SameAs
    )
    cascadeSameAsDeselectedThroughColumns(bettingContext, affected, dependencies)
  }
}

export function notifyDependentColumnOfSelection(
  starters: IObservableStarter[],
  row: number,
  column: number
) {
  starters
    .filter(starter => !starter.isScratched())
    .map(starter => starter.selection() as CheckBoxSelection)
    .forEach(element => {
      element.enableSiblingIfSelected(row, column)
    })
}

function notifyDependentColumnOfDeselection(
  bettingContext: BettingInformation,
  affected: CheckBoxSelection[],
  dependency: SameAs
) {
  const columnOtherDependencies = otherSameAsColumnsDependendentOn(bettingContext, dependency)

  affected
    .filter(
      selection => !selection.anySelectedAtIndicies(columnOtherDependencies.map(x => x.row - 1))
    )
    .forEach(selection => {
      selection.clearAt(dependency.column)
    })
}

export function updateColumnMatchingSameAs(
  bettingContext: BettingInformation,
  starters: IObservableStarter[],
  sameAsNode: SameAs
): CheckBoxSelection[] {
  const columnOtherDependencies = otherSameAsColumnsDependendentOn(bettingContext, sameAsNode)

  const affectStarters = starters
    .filter(x => !x.isScratched())
    .map(x => x.selection() as CheckBoxSelection)
    .filter(selection => selection.selectedAt(sameAsNode.row - 1))
    .filter(
      selection => !selection.anySelectedAtIndicies(columnOtherDependencies.map(x => x.row - 1))
    )

  affectStarters.forEach(selection => {
    selection.clearAt(sameAsNode.column)
  })

  return affectStarters
}
