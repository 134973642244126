import { FixedOddsMarketCodes, type MarketCodesType } from '@mobi/api-types'

export const MORE_BETTING_OPTIONS_ID = 'js-MoreBettingOptions'

export const CHANGE_BET_TYPE_EVENT = 'change-bettype-to'

export const FixedOddsMarketDisplayNames = {
  [FixedOddsMarketCodes.HeadToHead]: 'Head to Head',
  [FixedOddsMarketCodes.RunnerVsField]: 'Runner vs Field',
  [FixedOddsMarketCodes.StartingPrice]: 'SP - Fixed',
} as const

/** Provides filter and sort order for MoreBettingOptions display */
export const FixedOddsMarketsInMoreBettingOptions: readonly MarketCodesType[] = [
  'EO',
  'IO',
  'HVH',
  'H2H',
  'RVF',
  'FAV',
]

export const BettingInformationOverrides = {
  StartingPrice: [
    {
      title: 'Betting Conditions',
      detail: [
        'Win Only. Starting Price bets will receive the official on-course bookmaker’s price at the time betting closes. Where an official price cannot be sourced, the TABtouch closing Fixed Odds price shall serve as the SP.',
      ],
    },
    {
      title: 'Betting Rules',
      detail: ['Bet is subject to ‘Refunds and Deductions’ betting rules.'],
    },
  ],
}
