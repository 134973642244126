import type { BlackbookRunner } from '@core/Areas/Blackbook/types'

const regexInternationalSuffix = new RegExp('\\s\\([A-Z]{2,3}\\)$')
const regexApprenticeSuffix = new RegExp('\\s\\(.*?\\)')

export const getMatchedRunnerInBlackbook = (
  allBlackbookRunners: BlackbookRunner[],
  starterNameToMatch: string,
  starterCodeToMatch: BlackbookRunner['Code']
): BlackbookRunner | undefined => {
  if (!allBlackbookRunners.length) return undefined

  const cleanedName = starterNameToMatch
    .replace(regexInternationalSuffix, '')
    .replace(regexApprenticeSuffix, '')
    .toUpperCase()

  return allBlackbookRunners.find(
    ({ StarterName, Code }) =>
      StarterName.toUpperCase() === cleanedName && Code === starterCodeToMatch
  )
}
