import { getServerVersion } from './ServerConfig'
import {
  getLastKnownLocationAsString,
  getLastKnownVenueAsString,
} from '@core/NativeServices/Location/LocationService'
import { HasLoggedOut } from '@core/State/UserAccount/userAccountDriver'
import { isReactNativeApp } from '@mobi/web-native-comms/web'

const ERROR_CODE_APP_VERSION_MISMATCH = 551
const ERROR_CODE_UNAUTHORIZED = 401

export class ApiServiceError extends Error {
  response: Response

  constructor(response: Response) {
    super(`Fetch failed. Error code ${response.status}`)
    this.response = response
  }
}

export function fetchFromApi(url: string, opts?: RequestInit): Promise<Response> {
  const headers = new Headers()

  const { headers: optionalHeaders, ...options } = opts || { headers: null }

  if (optionalHeaders) {
    let key: keyof typeof optionalHeaders
    for (key in optionalHeaders) {
      if (typeof key !== 'string') continue
      const value = optionalHeaders[key]
      if (typeof value !== 'string') continue
      headers.append(`${key}`, value)
    }
  }

  // Custom headers can cause CORS check to fail, so only send if URL is relative (same-origin)
  if (isRelativeUrl(url)) {
    addCustomHeaders(headers)
  }

  return window
    .fetch(url, { credentials: 'same-origin', headers: headers, ...options })
    .then(response => {
      if (!response.ok) {
        switch (response.status) {
          case ERROR_CODE_APP_VERSION_MISMATCH:
            window.location.reload()
            break
          case ERROR_CODE_UNAUTHORIZED:
            HasLoggedOut()
            break
          default:
            throw new ApiServiceError(response)
        }
      }
      return response
    })
}

export async function get<T>({ url, opts }: { url: string; opts?: RequestInit }): Promise<T> {
  const response = await fetchFromApi(url, opts)
  return response.status === 204 ? (response.text() as unknown as T) : response.json()
}

export async function post<T, TBody = unknown>({
  url,
  body,
}: {
  url: string
  body?: TBody
}): Promise<T> {
  const response = await fetchFromApi(url, getPostParameters(body))
  const contentLength = Number(response.headers.get('content-length') ?? -1)
  if (contentLength === 0) {
    return {} as T
  }
  return response.status === 204 ? (response.text() as unknown as T) : response.json()
}

export async function deleteAsync<T>({ url, body }: { url: string; body?: unknown }): Promise<T> {
  const response = await fetchFromApi(url, getDeleteParameters(body))
  const contentLength = Number(response.headers.get('content-length') ?? -1)
  if (contentLength === 0) {
    return {} as T
  }
  return response.status === 204 ? (response.text() as unknown as T) : response.json()
}

function isRelativeUrl(url: string) {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i')
  return r.test(url) === false
}

function addCustomHeaders(headers: Headers) {
  const serverVersion = getServerVersion()
  if (serverVersion && !isReactNativeApp) {
    headers.append('X-TabTouch-Version', serverVersion)
  }
  headers.append('X-Location', getLastKnownLocationAsString())
  headers.append('X-Venue', getLastKnownVenueAsString())
}

export function getPostParameters<TRequest>(request: TRequest): RequestInit {
  return {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
}

export function getDeleteParameters<TRequest>(request: TRequest): RequestInit {
  return {
    method: 'DELETE',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
}
