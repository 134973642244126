import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export enum StructuredSearchTextLabelFontSize {
  Small,
  Large,
}

interface StructuredSearchTextLabelStyledProps {
  size?: StructuredSearchTextLabelFontSize
}

export const StructuredSearchTextLabelStyled = styled('span')<StructuredSearchTextLabelStyledProps>(
  ({ size }) => ({
    fontSize: getTextFontSize(size),
    color: colors.structuredSearch.initialText,
    textAlign: 'center',
    display: 'block',
    margin: '1rem 0 0.25rem 0',
  })
)

function getTextFontSize(size?: StructuredSearchTextLabelFontSize): string {
  let sizeString: string
  switch (size) {
    case StructuredSearchTextLabelFontSize.Large:
      sizeString = '1.8rem'
      break
    case StructuredSearchTextLabelFontSize.Small:
      sizeString = '1.2rem'
      break
    default:
      sizeString = '1.6rem'
  }
  return sizeString
}
