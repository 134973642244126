import {
  AccountSignUp,
  InternetProspectResponse,
  Passport,
  VerificationResponse,
} from '@mobi/api-types/src/account/signup'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import produce from 'immer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { FailedType } from '../Components/Message/FailedMessage'

type SignUpState = {
  signUp: State
}

export type State = {
  signUp?: AccountSignUp
  internetProspect?: InternetProspectResponse
  verification?: VerificationResponse
  // This state field is used to communicate the partial/fully verified
  // status of the signup process to the completed signup page.
  // Pseudonym: isCompletedSignupFullyVerified
  rwwaRanking?: number
  failedType?: FailedType
}

const initialState: State = {}

const slice = createSlice({
  name: 'sign-up',
  initialState: initialState,
  reducers: {
    setAccountSignUp(state, action: PayloadAction<AccountSignUp | undefined>) {
      state.signUp = action.payload
    },

    setInternetProspect(state, action: PayloadAction<InternetProspectResponse | undefined>) {
      state.internetProspect = action.payload
    },

    setVerification(state, action: PayloadAction<VerificationResponse | undefined>) {
      state.verification = action.payload
    },

    setRwwaRanking(state, action: PayloadAction<number | undefined>) {
      state.rwwaRanking = action.payload
    },

    setFailedType(state, action: PayloadAction<FailedType | undefined>) {
      state.failedType = action.payload
    },

    setPassportToAttemptVerification(
      state,
      action: PayloadAction<{ passportNumber: string; passportCountry: string }>
    ) {
      if (state.signUp) {
        state.signUp = produce(state.signUp, draft => {
          draft.AgeVerification = {
            Attempt: (state.signUp?.AgeVerification?.Attempt || 0) + 1,
            Passport: {
              PassportNumber: action.payload.passportNumber,
              BirthSurname: 'surname', //TODO: remove
              PlaceOfBirth: 'placeOfBirth', //TODO: remove
              CountryOfBirth: 'CountryOfBirth', //TODO: remove
              CitizenshipGivenName: 'CitizenshipGivenName', //TODO: remove
              CitizenshipSurname: 'CitizenshipSurname', //TODO: remove
              Country: action.payload.passportCountry,
            } as Passport,
            None: false, //TODO: whats this for?
            EnquiryId: '', //TODO: whats this for?
          }
        })
      }
    },
  },
})

export const {
  setAccountSignUp,
  setInternetProspect,
  setVerification,
  setRwwaRanking,
  setPassportToAttemptVerification,
  setFailedType,
} = slice.actions

export default persistReducer(
  {
    key: 'sign-up',
    whitelist: ['signUp', 'internetProspect'],
    storage,
    debug: !PRODUCTION,
  },
  slice.reducer
)

export function getSignUpState(state: SignUpState): State | undefined {
  return state.signUp
}

export function getRwwaRanking(state: SignUpState): number | undefined {
  return state.signUp.rwwaRanking
}

export function getSignUpFailedType(state: SignUpState): FailedType | undefined {
  return state.signUp.failedType
}
