import { ISelectionResultProcessor } from './ISelectionResultProcessor'
import { ISelectionResult } from './ISelectionResult'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { difference } from '../../../../AppUtils/Utils/Algorithms'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import CheckBoxSelectionResult from './CheckBoxSelectionResult'
import Guard from '../../../../AppUtils/Framework/Guard'
import ObservableSelectedRaceStarters from '../../../Model/Observables/ObservableSelectedRaceStarters'
import { sortNumberAscending } from '@core/Utils'
import { range } from '@mobi/utils'

class CheckBoxProjection {
  constructor(
    public starter: number,
    public selection: CheckBoxSelection
  ) {}
}

class CheckBoxValuesProjection {
  constructor(
    public starter: number,
    public selections: { checked: boolean; value: number }[]
  ) {}
}

export default class CheckBoxSelectionResultProcessor implements ISelectionResultProcessor {
  public constructor(private count: number) {
    Guard.greaterThanZero(count)
  }

  public getSelectionsResult(
    bettingContext: BettingInformation,
    raceNumber: number
  ): ISelectionResult {
    return this.selections(bettingContext, raceNumber, bettingContext.selections)
  }

  public selections(
    bettingContext: BettingInformation,
    raceNumber: number,
    selections: ObservableSelectedRaceStarters
  ): ISelectionResult {
    Guard.notNull(bettingContext)

    const starters = selections.getStartersForRace(raceNumber)

    if (!starters || starters().length === 0) return new CheckBoxSelectionResult([''])

    if (starters().length === 0 || starters().some(starter => !starter.selection))
      return new CheckBoxSelectionResult([''])

    const results = range(this.count).map(() => '')

    for (let column = 0; column < results.length; ++column) {
      const legNumber = column + 1
      const legHasSameAsSelections = bettingContext.sameAsSelectedAtIndex(legNumber)

      if (column !== 0 && legHasSameAsSelections) {
        const checkBoxes = starters().map(starter => starter.selection() as CheckBoxSelection)
        const sameAsCols = bettingContext.sameAsSelectedAtIndexString(legNumber)

        const nonSameAsSelections = this.additionalSelectedStarters(checkBoxes, sameAsCols, column)

        results[column] = this.calculateSameAsString(sameAsCols, nonSameAsSelections)
      } else if (
        bettingContext.fieldSelectedAtIndex(column) &&
        (bettingContext.rovingBanker() || bettingContext.isLegIn()) &&
        column === 0
      ) {
        results[column] = this.dotSeperate(starters, column)
      } else if (
        bettingContext.fieldSelectedAtIndex(column - 1) &&
        (bettingContext.rovingBanker() || bettingContext.isLegIn()) &&
        column === 1
      ) {
        results[column] = 'FD'
      } else if (bettingContext.fieldSelectedAtIndex(column)) {
        results[column] = 'FD'
      } else {
        results[column] = this.dotSeperate(starters, column)
      }
    }

    return new CheckBoxSelectionResult(results)
  }

  private dotSeperate(stream: ko.ObservableArray<IObservableStarter>, i: number): string {
    return stream()
      .map(
        starter =>
          new CheckBoxProjection(starter.number(), starter.selection() as CheckBoxSelection)
      )
      .map(
        checkbox =>
          new CheckBoxValuesProjection(
            checkbox.starter,
            range(checkbox.selection.count()).map(i => ({
              value: i,
              checked: checkbox.selection.selectedAt(i),
            }))
          )
      )
      .filter(checkbox =>
        checkbox.selections.some(selection => selection.checked && selection.value === i)
      )
      .map(checkbox => checkbox.starter)
      .sort(sortNumberAscending)
      .join('.')
  }

  /// Determine non-same-as selections
  private additionalSelectedStarters(
    checkboxes: CheckBoxSelection[],
    sameAsColumns: number[],
    column: number
  ): Set<number> {
    const columnSet = new Set<number>()

    // Non-Same-as selections from previous legs
    const sameAsSet = new Set<number>(
      sameAsColumns
        .map(sameAsColumn => this.getSelectedCheckboxesForLeg(checkboxes, sameAsColumn - 1))
        .flat()
    )

    // Selections for this leg
    for (const checkbox of checkboxes) {
      if (checkbox.selectedAt(column)) {
        columnSet.add(checkbox.starter.number())
      }
    }

    return difference(columnSet, sameAsSet)
  }

  private getSelectedCheckboxesForLeg(checkboxes: CheckBoxSelection[], column: number): number[] {
    const columnSet = new Array<number>()
    for (const checkbox of checkboxes) {
      if (checkbox.selectedAt(column)) {
        columnSet.push(checkbox.starter.number())
      }
    }
    return columnSet
  }

  private calculateSameAsString(sameAsCols: number[], additionals: Set<number>): string {
    if (sameAsCols.length === 0) {
      return ''
    }

    const f =
      (prefix: string): ((str: string, num: number) => string) =>
      (str: string, num: number) => {
        if (str === '') {
          return `${prefix}${num}`
        } else {
          return `${str}.${prefix}${num}`
        }
      }

    const result = sameAsCols.sort().reduce(f('S'), '')

    if (additionals.size === 0) {
      return result
    }

    return (
      result + '.' + Array.from(additionals.values()).sort(sortNumberAscending).reduce(f(''), '')
    )
  }
}
