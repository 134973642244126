import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import type { Dispatch } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { toMoney } from '@mobi/utils/money'
import { colors, spacing } from '@mobi/component-library/Theme/Common'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { LoadingSpinner } from '@mobi/component-library/Feedback/LoadingSpinner'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { clearAllReceiptsFromItems, removeItemsWithReceipt } from '@mobi/betslip/Store/Bets'
import {
  getBetsInMulti,
  getBetsToPlace,
  isFatalErrorType,
  isValidMulti,
} from '@mobi/betslip/helpers/state'
import { calculateTotalStake } from '@mobi/betslip/helpers/calculator/combined'
import { confirmAllBets, proposeAllBets } from '@mobi/betslip/Store/Workflow/asyncActions'
import { setWorkflowStatusToReady } from '@mobi/betslip/Store/Workflow'
import { ClearBetSlipButton } from './Components/ClearBetSlipButton'
import {
  selectIsBettingDisabled,
  selectIsHostUserLoggedIn,
} from '@mobi/betslip/Store/Workflow/selectors'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'

export const ButtonsSection: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const hostHelpers = getHostHelpers()

  const isLoggedIn = useAppSelector(selectIsHostUserLoggedIn)

  const items = useSelector(selectBetSlipItems)
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const isBettingDisabled = useSelector(selectIsBettingDisabled)
  const accountBalance = useAppSelector(state => state.betslip.workflow.hostAccountBalance)

  const multiReceipt = useAppSelector(state => state.betslip.bets.multiReceipt)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const hasProposed = workflowStatus === 'proposed'

  const isValidMultiPresent =
    !multiReceipt && isValidMulti(multiInvestment, multiBetError, getBetsInMulti(items))

  const singlesLeftToPlace = getBetsToPlace(items).length
  const numberOfBets = singlesLeftToPlace + (isValidMultiPresent ? 1 : 0)

  const totalStake = calculateTotalStake(items, multiInvestment, multiBetError)
  const isInsufficientFunds = accountBalance && accountBalance < totalStake

  const hasInsufficientFundsError =
    isInsufficientFunds ||
    (hasProposed && !!multiBetError && multiBetError.betErrorType === 'InsufficientFunds') ||
    items.some(x => x.betErrorType === 'InsufficientFunds')

  const hasSeriousMultiError =
    !!multiBetError &&
    (isFatalErrorType(multiBetError.betErrorType) ||
      multiBetError.betErrorType === 'BetPlacementFault' ||
      multiBetError.betErrorType === 'DuplicateBonusBet' ||
      multiBetError.betErrorType === 'Unspecified')

  const hasMultiLeftToPlace = !multiReceipt && !hasSeriousMultiError && isValidMultiPresent
  const hasBetsLeftToPlace = singlesLeftToPlace > 0 || hasMultiLeftToPlace

  const shouldShowClear = items.length > 0 && !hasProposed && !isBusy

  const handleDepositClick = () => {
    hostHelpers.toggleBetSlipDrawer?.(false)
    history.push(hostHelpers.hostRoutes.Deposit)
  }

  if (!isLoggedIn) {
    return (
      <WrapperStyled>
        {shouldShowClear && <ClearBetSlipButton />}

        <Button size='md' isFullWidth color='primary' onClick={() => hostHelpers.showLogin()}>
          Log in to place bets
        </Button>
      </WrapperStyled>
    )
  }

  if (workflowStatus === 'ready') {
    return (
      <WrapperStyled>
        {shouldShowClear && <ClearBetSlipButton />}
        {hasInsufficientFundsError ? (
          <Button color='primary' isFullWidth onClick={handleDepositClick}>
            Deposit
          </Button>
        ) : (
          <Button
            color='positive'
            size='md'
            isFullWidth
            disabled={!(numberOfBets > 0)}
            onClick={() => dispatch(proposeAllBets() || isBettingDisabled)}
          >
            {isBusy ? <LoadingSpinner size='2rem' color='white' /> : <>Bet {toMoney(totalStake)}</>}
          </Button>
        )}
      </WrapperStyled>
    )
  }

  if (workflowStatus === 'proposed') {
    return (
      <WrapperStyled>
        <Button
          size='md'
          isIconOnlyButton
          color='secondary_color'
          disabled={isBusy}
          onClick={() => dispatch(setWorkflowStatusToReady())}
        >
          <Icon name='SolidXClose' color={colors.studio[600]} size='2rem' />
        </Button>

        {hasInsufficientFundsError ? (
          <Button size='md' isFullWidth color='primary' onClick={handleDepositClick}>
            Deposit
          </Button>
        ) : (
          <Button
            size='md'
            isFullWidth
            color='positive'
            disabled={!hasBetsLeftToPlace}
            onClick={() => dispatch(confirmAllBets())}
          >
            {isBusy ? (
              <LoadingSpinner size='2rem' color='white' />
            ) : (
              `Confirm ${numberOfBets} Bet${numberOfBets > 1 ? 's' : ''}`
            )}
          </Button>
        )}
      </WrapperStyled>
    )
  }

  if (workflowStatus === 'bets-placed') {
    return (
      <WrapperStyled>
        <Button
          size='md'
          isFullWidth
          color='secondary_color'
          onClick={() => handleReuseSelectionsClick(dispatch)}
        >
          Reuse Selections
        </Button>

        <Button size='md' isFullWidth color='primary' onClick={() => handleDoneClick(dispatch)}>
          Done
        </Button>
      </WrapperStyled>
    )
  }

  return null
}

// =======
// Helpers
// =======

function handleDoneClick(dispatch: Dispatch) {
  dispatch(removeItemsWithReceipt())
  dispatch(setWorkflowStatusToReady())
}

function handleReuseSelectionsClick(dispatch: Dispatch) {
  dispatch(clearAllReceiptsFromItems())
  dispatch(setWorkflowStatusToReady())
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  gap: spacing.sm,
  paddingTop: spacing.md,
})
