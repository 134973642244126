import { get } from '@classic/Foundation/Services/ApiService'
import { groupBy } from '@mobi/utils'
import dayjs from 'dayjs'

export interface RaceEventGrouping {
  title: string
  events: RaceEvent[]
}

export interface RaceEvent {
  id: string
  title: string
  date: Date
  code: string
  racingStatus?: string
  product: string
}

export interface RaceProduct {
  productDetails: unknown // unused?
  code: string // unused?
  raceEvents: RacingEvent[]
}

export interface RacingEvent {
  racingProductId: string

  eventDescription: string
  eventStartTime: Date
  eventSequence: number

  isEnhancedFob: boolean // matched race
  fixtureDate?: string
  fixtureId?: string
  raceNumber?: number

  codeFromRequest: string
  codeFromEvent: string
  racingStatus: number
  racingStatusText: string

  markets: RacingMarket[]
  isAbandoned: boolean
  isOpen: boolean
  isClosed: boolean
  isSuspended: boolean
  isResulted: boolean
  hasSpecials: boolean
}

interface RacingMarket {
  marketName: string
}

interface RaceEventPageSpecifics {
  title: string
  getDataFunction: (product: string, code: string, date: string) => Promise<RaceEventGrouping[]>
}

const pageSpecifics: Record<string, RaceEventPageSpecifics> = {
  'FR/RACES': {
    title: 'FUTURES',
    getDataFunction: getFeatureRaces,
  },
  'FR/TROTS': {
    title: 'FUTURES',
    getDataFunction: getFeatureRaces,
  },
  'FR/DOGS': {
    title: 'FUTURES',
    getDataFunction: getFeatureRaces,
  },
  'SR/RACES': {
    title: 'RACING SPECIALS',
    getDataFunction: getChallengeAndRacingSpecials,
  },
  'SR/TROTS': {
    title: 'RACING SPECIALS',
    getDataFunction: getChallengeAndRacingSpecials,
  },
  'SR/DOGS': {
    title: 'RACING SPECIALS',
    getDataFunction: getChallengeAndRacingSpecials,
  },
  'CLG/RACES': {
    title: 'JOCKEY CHALLENGE',
    getDataFunction: getChallengeAndRacingSpecials,
  },
  'CLG/DOGS': {
    title: 'GREYS BOX CHALLENGE',
    getDataFunction: getChallengeAndRacingSpecials,
  },
}

function getPageSpecifics(product: string, code: string): RaceEventPageSpecifics | null {
  const key = `${product}/${code}`.toUpperCase()
  return Object.prototype.hasOwnProperty.call(pageSpecifics, key)
    ? (pageSpecifics[key] as RaceEventPageSpecifics)
    : null
}

export function getTitleUrl(code: string, date: string): string {
  return `tote?code=${code.toLowerCase()}&date=${date}`
}

export function getRaceEventTitle(product: string, code: string): string {
  const specifics = getPageSpecifics(product, code)
  if (specifics == null) return ''
  return specifics.title
}

export async function getRaceEventData(
  product: string,
  code: string,
  date: string
): Promise<RaceEventGrouping[]> {
  const specifics = getPageSpecifics(product, code)
  if (specifics == null) return []

  try {
    return specifics.getDataFunction(product, code, date)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Error retrieving race events: e=${e}`)
    throw e
  }
}

async function getChallengeAndRacingSpecials(
  product: string,
  code: string,
  date: string
): Promise<RaceEventGrouping[]> {
  const url = `/api/fobracing/GetRaceEvents/${product}/${code}/${dayjs(date).format('YYYY-MM-DD')}`

  const raceEvents = await get<RaceProduct>({ url })
  if (Object.keys(raceEvents).length === 0) {
    throw new Error('no race events returned')
  }

  const groupedRaceEvents = groupBy(raceEvents.raceEvents, x =>
    dayjs(x.eventStartTime).format('ddd, DD MMM YYYY')
  )

  const model = Object.entries(groupedRaceEvents).map(([eventStartDate, raceEvents]) => {
    const allEvents = raceEvents.map(e => {
      return {
        id: e.eventSequence.toString(),
        date: new Date(e.eventStartTime),
        code: e.codeFromRequest,
        title: e.eventDescription,
        racingStatus: e.racingStatusText,
        product: product,
        topGrouping: e.racingStatusText == 'Open' || e.racingStatusText == 'Suspended',
      }
    })

    // re-order the events to show topGrouping on top (fancy that!), whilst preserving the (event time) ordering provided by the backend
    const events = allEvents
      .filter(x => x.topGrouping)
      .concat(allEvents.filter(x => !x.topGrouping))

    return {
      title: `${eventStartDate}`,
      events,
    }
  })

  return model
}

async function getFeatureRaces(
  product: string,
  code: string,
  date: string
): Promise<RaceEventGrouping[]> {
  const url = `/api/fobracing/GetRaceEvents/FR/${code}/${dayjs(date).format('YYYY-MM-DD')}`

  const raceEvents = await get<RaceProduct>({ url })
  if (Object.keys(raceEvents).length === 0) {
    throw new Error('no race events returned')
  }

  const groupedRaceEvents = groupBy(raceEvents.raceEvents, x =>
    dayjs(x.eventStartTime).format('YYYY-MM')
  )

  const model = Object.entries(groupedRaceEvents).map(([key, value]) => {
    const GroupedDate = dayjs(key).format('MMMM YYYY')

    return {
      title: `${GroupedDate}`,
      events: value.map(e => {
        return {
          id: e.eventSequence.toString(),
          date: e.eventStartTime,
          code: e.codeFromRequest,
          title: e.eventDescription,
          product: product,
        }
      }),
    }
  })

  return model
}
