import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import { isReactNativeAndroid, isReactNativeApp } from '@mobi/web-native-comms/web'
import {
  TimePickerPopover,
  type RaceAlertAnalyticsData,
} from './TimePickerPopover/TimePickerPopover'
import { NotificationButtonStyled } from './NotificationButton.styles'
import {
  checkNotificationStatusAsync,
  ShowNotificationAuthPopup,
} from '@core/Areas/Settings/Components/PayoutNotification/PayoutNotificationPopup'
import { useClickOutside } from '@core/Utils/hooks/useClickOutside'
import { useSelector } from 'react-redux'
import { getRegisteredNotifications } from '../Store/selectors'
import { registerNotification, unregisterNotification } from '../Store'
import { store } from '@core/Store'
import { useValidNotificationOptions } from './hooks/useValidNotificationTimes'
import { NotificationTime, RaceNotificationEventId, SportNotificationEventId } from '../types'
import { addToast, ToastContent } from '@mobi/component-library/Feedback/Toast'

export function NotificationButton({
  eventId,
  ast,
  isHeaderPinned,
  deepLink,
  eventName,
  eventType,
}: {
  deepLink?: string
  /** the title shown in the push notification */
  eventName: string
  ast: string
  isHeaderPinned?: boolean
} & (
  | { eventType: Extract<NotificationEventType, 'Race'>; eventId: RaceNotificationEventId }
  | { eventType: Extract<NotificationEventType, 'Sport'>; eventId: SportNotificationEventId }
)) {
  if (!isReactNativeApp && process.env.NODE_ENV !== 'development') {
    return null
  }

  return (
    <NotificationButtonComponent
      eventId={eventId}
      eventName={eventName}
      eventType={eventType}
      deepLink={deepLink || null}
      ast={ast}
      isHeaderPinned={isHeaderPinned}
    />
  )
}

// ===============
// Local Component
// ===============

const NotificationButtonComponent: React.FC<
  NotificationDetails & {
    isHeaderPinned?: boolean
  }
> = props => {
  const { eventId, ast, isHeaderPinned } = props

  const registeredNotifications = useSelector(getRegisteredNotifications)
  const selectedNotificationTimes = registeredNotifications[eventId] || []

  const validNotificationOptions = useValidNotificationOptions(ast)

  const [selectedTimes, setSelectedTimes] = React.useState<Set<NotificationTime>>(
    () => new Set(selectedNotificationTimes)
  )
  const prevSelectedTimes = new Set(selectedTimes)

  const [shouldShake, setShouldShake] = React.useState(false)
  const [isTimeSelectPopoverOpen, setIsTimeSelectPopoverOpen] = React.useState<boolean>(false)

  const popoverRef = useRef<HTMLDivElement>(null)

  useClickOutside(popoverRef, () => {
    setIsTimeSelectPopoverOpen(false)
  })

  const analyticsDataRef = React.useRef<RaceAlertAnalyticsData>()
  analyticsDataRef.current = { selectedTimes, ast, isHeaderPinned, eventId }

  if (!validNotificationOptions.length) {
    return null
  }

  const isNotificationOn =
    Array.from(selectedTimes.values()).some(x => validNotificationOptions.includes(x)) ||
    selectedNotificationTimes.some(x => validNotificationOptions.includes(x))

  const onNotificationToggle = async (
    action: 'register' | 'deregister',
    _selectedTimes?: Set<NotificationTime>
  ) => {
    const notificationStatus = await checkNotificationStatusAsync()

    if (notificationStatus.status !== 'Authorized') {
      ShowNotificationAuthPopup({
        reason:
          'Notifications must be enabled on TABtouch to set a race alert. Enable notifications then try again.',
        notificationAuthStatus: notificationStatus.status,
      })
      return
    }

    const fcmToken = notificationStatus.fcmRegistrationToken

    if (!fcmToken) {
      addToast({
        id: 'alert-error',
        isDismissable: true,
        content: (
          <ToastContent
            title={`Unable to set alert. ${JSON.stringify(notificationStatus)}`}
            icon='error'
          />
        ),
      })

      return
    }

    let respSuccess = false
    if (action === 'register') {
      respSuccess = await register(
        props,
        fcmToken,
        Array.from(_selectedTimes ?? []).sort((a, b) => a - b),
        isReactNativeAndroid
      )
    } else if (action === 'deregister') {
      respSuccess = await unregister({ eventId, fcmToken })
    }

    if (!respSuccess) {
      setSelectedTimes(prevSelectedTimes)
      addToast({
        id: 'alert-error',
        isDismissable: true,
        content: <ToastContent title={`There was a problem. Please try later.`} icon='error' />,
      })
    }
  }

  const onRegister = (notificationTimes: Set<NotificationTime>) => {
    setShouldShake(true)
    setTimeout(() => setShouldShake(false), 820)
    onNotificationToggle('register', notificationTimes)
  }

  const onDeregister = () => onNotificationToggle('deregister')

  return (
    <div ref={popoverRef}>
      <ShakeWrapperStyled shake={shouldShake}>
        <NotificationButtonStyled
          data-testid='notification-button'
          style={{ background: isTimeSelectPopoverOpen ? colors.neutral[100] : 'none' }}
          onClick={e => {
            e.stopPropagation()
            setIsTimeSelectPopoverOpen(prev => !prev)
          }}
        >
          <Icon
            size='2rem'
            name={isNotificationOn ? 'SolidBellRinging01' : 'LineBellPlus'}
            color={isNotificationOn ? colors.studio[500] : colors.neutral[900]}
          />
        </NotificationButtonStyled>
      </ShakeWrapperStyled>

      {isTimeSelectPopoverOpen && (
        <TimePickerPopover
          data-testid='notification-popover'
          registerAlert={onRegister}
          deregisterAlert={onDeregister}
          selectedTimes={selectedTimes}
          setSelectedTimes={setSelectedTimes}
          validNotificationOptions={validNotificationOptions}
          popoverRef={popoverRef}
          analyticsDataRef={analyticsDataRef as React.RefObject<RaceAlertAnalyticsData>}
        />
      )}
    </div>
  )
}

// =============
// Local Helpers
// =============

function register(
  details: NotificationDetails,
  fcmToken: string,
  minutesBeforeStart: NotificationTime[],
  isAndroid: boolean
) {
  const payload: RaceRegistrationV2Body = {
    eventId: details.eventId,
    eventName: details.eventName,
    eventType: details.eventType,
    ast: details.ast,
    fcmToken,
    minutesBeforeStart: minutesBeforeStart.join(','),
    deepLink: details.deepLink,
    isAndroid,
  }

  // use fancy new v2
  return fetch('/api/notification/v2/register', {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(() => {
      store.dispatch(
        registerNotification({ eventId: details.eventId, notificationTimes: minutesBeforeStart })
      )
      addToast({
        id: 'alert-success',
        isDismissable: true,
        content: <ToastContent title={`Alert set.`} icon='success' />,
      })
      return true
    })
    .catch(() => false)
}

function unregister(raceDeregistration: { eventId: string; fcmToken: string }) {
  return fetch('/api/notification/deregister', {
    method: 'POST',
    body: JSON.stringify(raceDeregistration),
  })
    .then(() => {
      store.dispatch(unregisterNotification(raceDeregistration.eventId))
      addToast({
        id: 'alert-cancelled',
        isDismissable: true,
        content: <ToastContent title={`Alert has been cancelled.`} icon='success' />,
      })
      return true
    })
    .catch(() => false)
}

// =====
// Style
// =====

const ShakeWrapperStyled = styled.div<{ shake: boolean }>(
  {
    display: 'inline-block',

    '@keyframes shakeAnimation': {
      '10%, 90%': {
        transform: 'translate3d(-0.1rem, 0, 0)',
      },
      '20%, 80%': {
        transform: 'translate3d(0.1rem, 0, 0)',
      },
      '30%, 50%, 70%': {
        transform: 'translate3d(-0.1rem, 0, 0)',
      },
      '40%, 60%': {
        transform: 'translate3d(0.1rem, 0, 0)',
      },
    },
  },
  ({ shake }) =>
    shake && {
      animation: 'shakeAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both',
    }
)

// =====
// Types
// =====

export type NotificationEventType = 'Race' | 'Sport'

interface NotificationDetails {
  eventId: string
  deepLink: string | null
  eventType: NotificationEventType
  eventName: string
  ast: string
}

interface RaceRegistrationV2Body {
  /** This is the id of the race/sport game etc */
  eventId: string
  /** event start time */
  ast: string
  fcmToken: string
  minutesBeforeStart: string
  /** Name to show in notification text */
  eventName: string
  /** Optional Notification url link */
  deepLink: string | null
  isAndroid: boolean
  eventType: NotificationEventType
}
