import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'

export const unknownWeather = 'weatherUnkown'

export interface WeatherTypes {
  Fine: keyof typeof iconPaths
  Hot: keyof typeof iconPaths
  Cloudy: keyof typeof iconPaths
  Overcast: keyof typeof iconPaths
  Rainy: keyof typeof iconPaths
  Raining: keyof typeof iconPaths
  Storm: keyof typeof iconPaths
  Showery: keyof typeof iconPaths
  Awful: keyof typeof iconPaths
  Snowy: keyof typeof iconPaths
}

export const WeatherTypes: Record<string, keyof typeof iconPaths> = {
  Fine: 'weatherFine',
  Hot: 'weatherFine',
  Cloudy: 'weatherCloudy',
  Overcast: 'weatherOvercast',
  Rainy: 'weatherShowery',
  Raining: 'weatherShowery',
  Storm: 'weatherShowery',
  Showery: 'weatherShowery',
  Awful: 'weatherShowery',
  Snowy: 'weatherShowery',
}

export interface IconTypes {
  Races: keyof typeof iconPaths
  Trots: keyof typeof iconPaths
  Dogs: keyof typeof iconPaths
}

export const IconTypes: Record<string, keyof typeof iconPaths> = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}
