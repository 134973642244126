import { useQueries } from 'react-query'
import { useFeature } from '@core/Utils/hooks'
import {
  racingPendingTicketsQuery,
  racingPendingTicketsPollingQuery,
  kambiPendingTicketsQuery,
  kambiPendingTicketsPollingQuery,
} from '../queries'

const enum LocalConstants {
  DEFAULT_MAX_COUNT = 99,
}

export const usePendingTicketsCount = ({ enabled }: { enabled: boolean }) => {
  const isAppHeaderPendingBetsFeatureActive = useFeature('PENDING_BETS_BUTTON_IN_APP_HEADER')

  const [{ data: pendingTicketsCount }, { data: kambiPendingTicketsCount }] = useQueries([
    {
      ...(isAppHeaderPendingBetsFeatureActive
        ? racingPendingTicketsPollingQuery
        : racingPendingTicketsQuery),
      enabled,
    },
    {
      ...(isAppHeaderPendingBetsFeatureActive
        ? kambiPendingTicketsPollingQuery
        : kambiPendingTicketsQuery),
      enabled,
    },
  ])

  const racingPendingCount = pendingTicketsCount?.pendingTicketsCount ?? 0
  const sportsPendingCount = kambiPendingTicketsCount ?? 0

  const pendingBets = racingPendingCount + sportsPendingCount

  return {
    pendingBetsCount: Math.min(pendingBets, LocalConstants.DEFAULT_MAX_COUNT),
    doPendingBetsExceedMaxCount: pendingBets > LocalConstants.DEFAULT_MAX_COUNT,
    isSportsOnlyPending: sportsPendingCount > 0 && racingPendingCount === 0,
  }
}
