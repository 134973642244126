import { injectable } from 'inversify'
import { ISpecialsUrlProvider } from './ISpecialsUrlProvider'

@injectable()
export class SpecialsUrlProvider implements ISpecialsUrlProvider {
  constructor() {
    this.initialise()
  }

  public retrieveFobSpecialTokensUrl!: string
  public fobBetPreEnquiryUrl!: string
  public specialUrl!: string
  public canShowInducementsUrl!: string

  private initialise(): void {
    this.retrieveFobSpecialTokensUrl = '/api/pyos/RetrieveFobSpecialTokens'
    this.fobBetPreEnquiryUrl = '/api/pyos/fobbetpreenquiry'
    this.specialUrl = '/api/pyos/SpecialUrl'
    this.canShowInducementsUrl = '/api/pyos/canShowInducements'
  }

  public getSpecialUrl(tokenId: number): string {
    const url: string = this.specialUrl + '/' + tokenId
    return url
  }
}
