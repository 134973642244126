import React from 'react'
import { useHistory } from 'react-router-dom'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { RacingSportsTabs } from '@core/Areas/AccountActivity/Components/RacingSportsTabs'
import {
  KAMBI_BETHISTORY_HASH_ROUTE,
  KAMBI_BETSLIP_DIV_CLASS,
  KAMBI_CSS_HIDDEN_CLASS,
  KAMBI_DIV_ID,
  KAMBI_WRAPPER_DIV_ID,
} from './constants'
import {
  useToggleVisibility,
  useRouteEnterRedirect,
  useShowTransactionTabs,
  useShouldShowSpinner,
  useShowBrowserUnsupported,
  useKambiRouteGuard,
  useKambiMaintenanceFeature,
} from './hooks'
import { state$ as kambiState$ } from './driver'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  KambiCenteredContainerStyled as Container,
  KambiUnsupportedBrowserTextStyled as Text,
  LinkButton,
} from './KambiClient.styles'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { Redirect } from 'react-router-dom'
import { AppRoutes } from '@core/AppRoutes'
import { useIsFobSportsFeatureActive } from '@core/Areas/Sports/hooks/useIsFobSportsFeatureActive'

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
}

// Handles enter / leave events in react-router
export const KambiRouteEffects: React.FC = () => {
  const history = useHistory()
  const endDate = useKambiMaintenanceFeature()
  const isNewSportsFeatureEnabled = useIsFobSportsFeatureActive()

  useToggleVisibility(!!endDate)
  useRouteEnterRedirect(isNewSportsFeatureEnabled)
  useKambiRouteGuard()
  const shouldShowTransactionTabs = useShowTransactionTabs()
  const shouldShowSpinner = useShouldShowSpinner()
  const shouldShowBrowserUnsupported = useShowBrowserUnsupported()

  if (
    isNewSportsFeatureEnabled &&
    !history.location.pathname.includes(KAMBI_BETHISTORY_HASH_ROUTE.replace('#', ''))
  ) {
    return <Redirect to={AppRoutes.Sports} />
  }

  if (endDate) return KambiMaintenance(endDate)

  if (shouldShowSpinner) {
    return (
      <Container>
        <SpinnerInlineStyled data-testid='kambi-client-loading' color='dark' />
      </Container>
    )
  }

  if (shouldShowBrowserUnsupported) {
    const browserOrDeviceText = isReactNativeApp ? 'Device' : 'Browser'
    return (
      <Container>
        <NoticeBox
          testId='kambi-client-unsupported'
          title={`Unsupported ${browserOrDeviceText}`}
          noticeBoxType={NoticeBoxTypes.Error}
          hasBorder={false}
        >
          <Text>Sports is unavailable on this {browserOrDeviceText.toLowerCase()}.</Text>
          <Text>
            Please try a newer{' '}
            <a href='https://help.tabtouch.com.au/knowledge-base/faqs-mobile/'>version</a>.
          </Text>
        </NoticeBox>
      </Container>
    )
  }

  return shouldShowTransactionTabs ? <RacingSportsTabs activeTab='sports' /> : null
}

// Main component to render kambi into
export const KambiClient: React.FC = React.memo(() => {
  const kambiDivElRef = React.useRef<HTMLDivElement>(null)

  // Fix white space bug (MVPS-86: Kambi has inline styles overriding hidden class)
  React.useEffect(() => {
    if (!kambiDivElRef.current) return undefined
    const kambiDivEl = kambiDivElRef.current
    const subscription = kambiState$
      .map(state => state.get('isVisible'))
      .distinctUntilChanged()
      .subscribe(isVisible => !isVisible && kambiDivEl.removeAttribute('style'))
    return () => {
      subscription.dispose()
    }
  }, [])

  return (
    <div id={KAMBI_WRAPPER_DIV_ID} className='mobi__z-stack-bottom mobi__unset-globals'>
      <div className={`${KAMBI_BETSLIP_DIV_CLASS} mobi__unset-globals`} />

      <div
        ref={kambiDivElRef}
        id={KAMBI_DIV_ID}
        className={KAMBI_CSS_HIDDEN_CLASS}
        key='kambi-client'
      />
    </div>
  )
})

const KambiMaintenance: React.FC<string> = (endDate: string) => {
  return (
    <Container>
      <NoticeBox
        title='Sports is currently unavailable due to maintenance'
        noticeBoxType={NoticeBoxTypes.Info}
        hasBorder={false}
      >
        <Text>
          We expect to be back up and running by{' '}
          {new Date(endDate).toLocaleString('en-AU', dateOptions)}
        </Text>
        <Text>Our customer service centre can be contacted on 1300 36 36 88</Text>
        <LinkButton color='primary' href='/#'>
          Return to Home Page
        </LinkButton>
      </NoticeBox>
    </Container>
  )
}
