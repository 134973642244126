import React from 'react'
import styled from '@emotion/styled'
import { font, spacing } from '@mobi/component-library/Theme/Common'
import type { BetSlipItem } from '@mobi/betslip/types'
import { BetCardTitle } from '@mobi/betslip/Components/Common/BetCardTitle'
import { calculateMultiReturn } from '@mobi/betslip/helpers/calculator/multis'
import {
  getBetsInMulti,
  getMultiLegEventOrRaceKey,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
} from '@mobi/betslip/helpers/state'
import { isFobSportsDetails } from '@mobi/betslip/helpers/typeGuards'
import {
  MAX_LEGS_IN_RACE_MULTI,
  MAX_LEGS_IN_SPORT_MULTI,
  MIN_LEGS_IN_MULTI,
} from '@mobi/betslip/helpers/constants'
import { InfoBox } from '@mobi/component-library/Common/V2/Display/InfoBox'
import { Price } from '../../../Common/Price'
import { InvestmentSummary } from './Components/InvestmentSummary'
import { MultiItem } from './Components/MultiItem'

export const MultiItems: React.FC<{
  multiItems: BetSlipItem[]
  hasValidMultiInvestment: boolean
  isEditable: boolean
  children?: React.ReactNode
}> = ({ multiItems, children, hasValidMultiInvestment, isEditable }) => {
  const multiReturn = calculateMultiReturn(multiItems, { shouldRound: true }) || null

  const selectedMultiItems = getBetsInMulti(multiItems)
  const groupedItems = groupByEventOrRace(multiItems, isEditable)

  const isBelowMinLegCount = hasTooFewMultiLegs(selectedMultiItems)
  const isExceedingMaxLegCount = hasTooManyMultiLegs(selectedMultiItems)

  const isSportsMulti = selectedMultiItems.every(
    item => item.isInMulti && isFobSportsDetails(item.selectionDetails)
  )

  const maxLegCount = isExceedingMaxLegCount
    ? isSportsMulti
      ? MAX_LEGS_IN_SPORT_MULTI
      : MAX_LEGS_IN_RACE_MULTI
    : 0

  const MultiPriceKey = React.useMemo(
    () => selectedMultiItems.map(({ id, multiLegBetType }) => id + multiLegBetType).join('.'),
    [selectedMultiItems]
  )

  return (
    <MultiContentStyled data-testid='Multi'>
      <BetCardTitle
        title={`${selectedMultiItems.length} Leg Multi`}
        rightComponent={
          multiReturn ? <Price value={multiReturn} key={MultiPriceKey} roundDown /> : 'N/A'
        }
      />

      {(isBelowMinLegCount || isExceedingMaxLegCount) && (
        <InfoBox color='orange' size='sm'>
          {isBelowMinLegCount &&
            `Minimum of ${MIN_LEGS_IN_MULTI} legs allowed for a Multi bet. Please review your bet.`}

          {isExceedingMaxLegCount &&
            `Maximum of ${maxLegCount} legs allowed for a Multi bet. Please review your bet.`}
        </InfoBox>
      )}

      <section data-testid='Multi.Selections'>
        {Array.from(groupedItems).map(([groupId, items]) => (
          <MultiItem key={groupId} items={items} />
        ))}
      </section>

      {children}

      {!isEditable && hasValidMultiInvestment && (
        <InvestmentSummary selectedMultiItems={selectedMultiItems} />
      )}
    </MultiContentStyled>
  )
}

// =============
// Local Helpers
// =============

function groupByEventOrRace(items: BetSlipItem[], isEditable: boolean): GroupedItems {
  const groups: GroupedItems = new Map()

  return items.reduce((map: GroupedItems, item: BetSlipItem) => {
    if (!isEditable && !item.isInMulti) return map

    const groupKey = getMultiLegEventOrRaceKey(item.selectionDetails)
    if (!groupKey) return map.set(item.id, [item])

    if (map.has(groupKey)) {
      const currentGrouping = map.get(groupKey) as BetSlipItem[]
      map.set(groupKey, [...currentGrouping, item])
    } else {
      map.set(groupKey, [item])
    }

    return map
  }, groups)
}

// ======
// Styles
// ======

const MultiContentStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,
  fontFamily: font.family.primary,

  // Selections
  '> section': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.sm,
  },
})

// =====
// Types
// =====

type GroupedItems = Map<string, BetSlipItem[]>
