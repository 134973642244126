import { useQuery } from 'react-query'
import { useFeature } from '@core/Utils/hooks'
import { queryClient } from '@core/Data/ReactQuery'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { fetchRacePendingTicketsCount } from '@core/Data/Account/PendingTicketsCount/api'
import { racingPendingTicketsPollingQuery } from '@core/Data/Account/PendingTicketsCount/queries'

const racePendingTicketsCacheQueryKey = `${queryKeys.pendingTicketsCount}:cached`

export const useCachedRacingPendingTicketsCount = ({ enabled }: { enabled: boolean }) => {
  const isAppHeaderPendingBetsFeatureActive = useFeature('PENDING_BETS_BUTTON_IN_APP_HEADER')

  const { data: pendingTicketsCount } = useQuery(
    isAppHeaderPendingBetsFeatureActive
      ? { ...racingPendingTicketsPollingQuery, enabled }
      : {
          queryKey: racePendingTicketsCacheQueryKey,
          queryFn: fetchRacePendingTicketsCount,
          enabled,
          staleTime: 1_000 * 60 * 30,
        }
  )

  return pendingTicketsCount?.pendingTicketsCount ?? 0
}

export const clearCachedRacingPendingTicketsCount = () => {
  const queryKey = racePendingTicketsCacheQueryKey
  queryClient.removeQueries({ queryKey, exact: true })
}
