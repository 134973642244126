import * as amplify from '@classic/AppUtils/Framework/Messaging/amplifyjs'
import * as common from '@classic/Foundation/Common'
import { logDebug } from './LoggingService'

common.bindLiveEvent(
  'click',
  '[data-msg-publish]',
  function (this: { dataset: { msgPublish: string; msgData: unknown } }) {
    //Do not use an arrow function here (changes "this")
    const topic = this.dataset.msgPublish
    let data = this.dataset.msgData

    try {
      data = JSON.parse(data as string)
    } catch {
      //Assume a string
    }

    publish(topic, data)
  }
)

/** @deprecated Best to observe state changes or actions raised */
export function publish(topic: string, data?: unknown): void {
  amplify.publish(topic, data)
  logDebug(`MessageBusService.publish('${topic}')`)
}

/** @deprecated Best to observe state changes or actions raised */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function subscribe(topic: string, func: (x?: any) => void, context?: unknown): void {
  amplify.subscribe(topic, context, func)
  logDebug(`MessageBusService.subscribe('${topic}')`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unsubscribe(topic: string, func: (x?: any) => void): void {
  amplify.unsubscribe(topic, func)
  logDebug(`MessageBusService.unsubscribe('${topic}')`)
}
