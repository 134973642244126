import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { BaseRaceCodeBuilder } from './BaseRaceCodeBuilder'
import { IObservableStarter } from '../Model/Observables/IObservableStarter'
import ObservableTrotStarter from '../Model/Observables/ObservableTrotStarter'

export default class TrotBuilder extends BaseRaceCodeBuilder {
  build(dto: dtos.StarterDataTransferObject): IObservableStarter {
    const model = super.addBaseInformation(
      new ObservableTrotStarter(),
      dto
    ) as ObservableTrotStarter
    model.template('trot-starters-template')
    model.tag('Trot')
    model.handicap(dto.Handicap)
    return model
  }
}
