import React from 'react'
import styled from '@emotion/styled'
import { useMutation } from 'react-query'
import { hexColors } from '@mobi/settings'
import { dayjs } from '@mobi/utils'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ } from '@core/State/UserAccount/userAccountDriver'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Button } from '@mobi/component-library/Common/Buttons'
import { useClickOutside } from '@core/Utils/hooks/useClickOutside'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import type { BlackbookComment, BlackbookEntryRacingToday } from '@core/Areas/Blackbook/types'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { useContactDetails } from '@core/Data/Account/useContactDetails'
import { deleteBlackbookComment } from '@core/Areas/Blackbook/helpers/api'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { promptUserAndReturnDecision } from '@core/Components/Modal/Components/PromptForUserDecision/helpers'
import { addOrEditBlackbookComment } from '@core/Areas/Blackbook/helpers/addOrEditBlackbookComment'
import { radius } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  activeButtonClassName = 'comment__more--active',
}

export const SingleComment: React.FC<{
  comment: BlackbookComment
  commentCount?: number

  id: number
  name: BlackbookEntryRacingToday['StarterName']
  type: BlackbookEntryRacingToday['Code']
}> = ({
  comment: { Comment, BlackbookNotesSeq, BlackbookSeq, NotesModDateTime },
  commentCount,
  id,
  name,
  type,
}) => {
  const optionsMenuRef = React.useRef<HTMLDivElement>(null)
  const [isMenuExpanded, setIsMenuExpanded] = React.useState(false)

  const { accountNumber } = useObservableImmutable(state$, ['accountNumber'])
  const { isLoading: isLoadingName, data } = useContactDetails(accountNumber)

  useClickOutside(optionsMenuRef, () => isMenuExpanded && setIsMenuExpanded(false))

  const { mutate: deleteComment, isLoading: isWaitingOnDeleteComment } = useMutation<
    void,
    void,
    Pick<BlackbookComment, 'BlackbookSeq' | 'BlackbookNotesSeq'>
  >(
    ({ BlackbookSeq, BlackbookNotesSeq }) =>
      deleteBlackbookComment(BlackbookSeq, BlackbookNotesSeq),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.blackbookDetails, refetchActive: true })
        queryClient.invalidateQueries({
          queryKey: [queryKeys.blackbookComments, BlackbookSeq],
          refetchActive: true,
        })
      },
      onError: () => {
        RegisterToast({
          id: 'delete' + BlackbookNotesSeq,
          message: 'Error Deleting Comment',
          type: 'error',
        })
      },
    }
  )

  const toggleOptionsMenu = () => setIsMenuExpanded(curr => !curr)
  const nameToDisplay = data?.NameDetails.PreferredName || data?.NameDetails.FirstName

  const handleDeleteClick = async () => {
    const decision = await promptUserAndReturnDecision({
      id: 'blackbook-delete-comment',
      title: 'Delete Comment',
      subtitle: `"${Comment.slice(0, 20)}${
        Comment.length > 20 ? '...' : ''
      }" will be permanently deleted. Are you sure you want to delete?`,
      testId: 'blackbook-prompt-user-delete-comment',
      buttons: [
        { label: 'Cancel', color: 'secondary' },
        { label: 'Yes, delete', color: 'danger' },
      ],
    })
    if (decision === 'Yes, delete') deleteComment({ BlackbookSeq, BlackbookNotesSeq })
    else setIsMenuExpanded(false)
  }

  const handleEditClick = async () => {
    addOrEditBlackbookComment({ id, commentID: BlackbookNotesSeq, name, type, newComment: Comment })
  }

  const currentTime = dayjs().local()
  const noteTimeLocalised = dayjs.tz(NotesModDateTime).local()
  const noteTimeToDisplay = noteTimeLocalised.isAfter(currentTime.subtract(7, 'days'))
    ? noteTimeLocalised.fromNow()
    : noteTimeLocalised.isAfter(currentTime.format('YYYY'))
      ? noteTimeLocalised.format('ddd MMM Do')
      : noteTimeLocalised.format('ddd MMM Do YYYY')

  return (
    <CommentWrapperStyled data-testid='blackbook-comments-comment-block'>
      <h3>
        {isLoadingName ? (
          <div aria-hidden data-testid='blackbook-comment-loading'>
            <LoadingPlaceholder width='8rem' height='1.4rem' borderRadius={radius.lg} />
          </div>
        ) : (
          nameToDisplay && (
            <>
              <figure aria-hidden data-testid='blackbook-comment-profile-image'>
                <span>{nameToDisplay.slice(0, 1)}</span>
              </figure>{' '}
              <strong data-testid='blackbook-comment-profile-name'>
                <span>{nameToDisplay}</span>
              </strong>{' '}
            </>
          )
        )}
        <span data-testid='blackbook-comment-time-ago'>{noteTimeToDisplay}</span>

        <div ref={optionsMenuRef}>
          {commentCount ? (
            <div data-testid='blackbook-comment-count'>
              {commentCount > 1 ? `1 of ${commentCount}` : ''}
            </div>
          ) : (
            <>
              <button
                disabled={isWaitingOnDeleteComment}
                onClick={toggleOptionsMenu}
                data-testid='blackbook-comment-options-btn'
                className={isMenuExpanded ? LocalConstants.activeButtonClassName : ''}
              >
                {isWaitingOnDeleteComment ? (
                  <SpinnerInlineStyled size={1.6} color='dark' />
                ) : (
                  <Icon type='more' size='1.6rem' />
                )}
              </button>

              {isMenuExpanded && (
                <ul data-testid='blackbook-comment-options-menu'>
                  <li>
                    <Button
                      color='link'
                      disabled={isWaitingOnDeleteComment}
                      onClick={handleDeleteClick}
                      data-testid='blackbook-comment-delete-comment'
                    >
                      Delete Comment
                    </Button>
                  </li>
                  <li>
                    <Button
                      color='link'
                      onClick={handleEditClick}
                      data-testid='blackbook-comment-edit-comment'
                    >
                      Edit Comment
                    </Button>
                  </li>
                </ul>
              )}
            </>
          )}
        </div>
      </h3>

      <p data-testid='blackbook-comment-comment'>{Comment}</p>
    </CommentWrapperStyled>
  )
}

// ======
// Styles
// ======

const CommentWrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem 0 2rem 1rem',

  // Header
  '& > h3': {
    padding: 0,
    margin: 0,
    background: 0,
    backgroundColor: 'transparent',
    textTransform: 'none',

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    marginBottom: '0.5rem',
    lineHeight: 1,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: hexColors.nero,

    // Profile initial
    '& > figure': {
      flexShrink: 0,
      position: 'relative',
      width: '2.2rem',
      height: '2.2rem',
      padding: 0,
      margin: 0,
      marginLeft: '-0.3rem',
      marginRight: '0.5rem',
      borderRadius: '50%',
      background: hexColors.studio,
      lineHeight: 1,

      span: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        lineHeight: '2.2rem',
        textAlign: 'center',
        fontSize: '1.8rem',
        fontWeight: 'normal',
        color: hexColors.white,
      },
    },

    // Name
    '& > strong': {
      flexShrink: 1,
      display: 'flex',
      overflow: 'hidden',

      span: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },

    // Time
    '& > span': {
      flexShrink: 0,
      marginLeft: '1rem',
      paddingRight: '0.5rem',
      fontWeight: 'normal',
      color: hexColors.darkGrey,
    },

    // Right Aligned
    '& > div:last-of-type': {
      flexShrink: 0,
      marginLeft: 'auto',

      // Options toggle
      '& > button': {
        padding: 0,
        width: '3rem',
        height: '3rem',
        border: 0,
        borderRadius: '50%',
        color: hexColors.deepLilac,
        background: 0,

        ['&.' + LocalConstants.activeButtonClassName]: {
          background: hexColors.whiteSmoke,
        },
      },

      // Comment count
      '& > div': {
        paddingLeft: '0.5rem',
        paddingRight: '1rem',
        lineHeight: 1,
        fontWeight: 'normal',
        color: hexColors.darkGrey,

        '&:empty': { paddingLeft: 0 },
      },

      // Options menu
      ul: {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: '100%',

        listStyle: 'none',
        padding: 0,
        margin: 0,
        marginTop: '0.1rem',

        border: '0.1rem solid ' + hexColors.gainsboro,
        borderRadius: '0.5rem',
        background: hexColors.whiteSmoke,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0 0.5rem 0.3rem',

        li: { padding: 0, margin: 0 },

        button: {
          minHeight: '3rem',
          fontWeight: 'normal',
        },
      },
    },
  },

  // Comment
  '& > p': {
    position: 'relative',
    margin: 0,
    paddingRight: '3rem',
    textAlign: 'left',
    fontSize: '1.3rem',
    lineHeight: 1.4,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
})
