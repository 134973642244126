import * as ko from 'knockout'
import jQuery from 'jquery'

ko.bindingHandlers['slideIn'] = {
  init: function (element, valueAccessor) {
    const value = ko.utils.unwrapObservable(valueAccessor())
    if (!value) {
      element.style.display = 'none'
    }
  },
  update: function (element, valueAccessor, allBindings) {
    const duration = allBindings.get('slideDuration') || 400 // 400ms is default duration from jQuery
    const newVisibility = ko.utils.unwrapObservable(valueAccessor()),
      isCurrentlyVisible = !(element.style.display === 'none')
    if (newVisibility !== isCurrentlyVisible) {
      if (newVisibility) jQuery(element).slideDown(duration)
      else jQuery(element).slideUp(duration)
    }
  },
}
