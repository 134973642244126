import React from 'react'
import { useSelector } from 'react-redux'
import { useIsNewBetSlipFeatureActive } from '@core/Areas/BetSlipNew/hooks'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { SmartBetslipToast } from './Components/MultiToast/Components/SmartBetslipToast'

export const SmartBetSlipMultiToastLegacy: React.FC<object> = React.memo(() => {
  const isSmartBetslipSettingActive = useSelector(getEnhancedBetslipSetting)
  const isNewBetslipFeatureActive = useIsNewBetSlipFeatureActive()

  if (isNewBetslipFeatureActive || !isSmartBetslipSettingActive) {
    return null
  }
  return <SmartBetslipToast />
})
