import React from 'react'
import { postDeliveryMethods, postGenerateValidationCode, postValidateCode } from '../helpers/api'
import type { useLoginState } from '../../../hooks/useLoginState'
import type { DeliveryMethod } from '../../Shared/DeliveryMethods/types'

export const usePasswordReset = ({
  initialAccountNumber,
  setWorkflowState,
}: UserPasswordResetArgs) => {
  const currentValidationCodeRef = React.useRef<{ expires: string; key: string }>()

  const [currentStep, setCurrentStep] = React.useState<Steps>(1)

  const [accountNumber, setAccountNumber] = React.useState(initialAccountNumber)
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = React.useState<number>()
  const [deliveryMethods, setDeliveryMethods] = React.useState<DeliveryMethod[]>([])
  const [validationCode, setValidationCode] = React.useState<string>('')

  const [errorMessage, setErrorMessage] = React.useState<string>()
  const [isBusy, setIsBusy] = React.useState(false)

  const onAccountNumberChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAccountNumber(e.target.value)
    if (errorMessage) setErrorMessage(undefined)
  }

  const onValidationCodeChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setValidationCode(e.target.value)
    if (errorMessage) setErrorMessage(undefined)
  }

  const onDeliveryMethodChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSelectedDeliveryMethod(Number(e.target.value))
    if (errorMessage) setErrorMessage(undefined)
  }

  const handleNextClick = async () => {
    if (errorMessage) setErrorMessage(undefined)

    if (currentStep === 1 && !!accountNumber) {
      setIsBusy(true)
      const res = await postDeliveryMethods({ accountNumber })
      setIsBusy(false)
      if (typeof res === 'string') return setErrorMessage(res)

      setDeliveryMethods(res.deliveryMethods)
      if (res.deliveryMethods.length === 1) setSelectedDeliveryMethod(res.deliveryMethods[0].id)
      return setCurrentStep(2)
    }

    if (currentStep === 2 && !!selectedDeliveryMethod) {
      if (validationCode) setValidationCode('')
      const deliveryMethod = deliveryMethods.find(method => method.id === selectedDeliveryMethod)
      if (!deliveryMethod) return setCurrentStep(1)

      setIsBusy(true)
      const res = await postGenerateValidationCode({ accountNumber, deliveryMethod })
      setIsBusy(false)
      if (typeof res === 'string') return setErrorMessage(res)

      currentValidationCodeRef.current = { expires: res.expires, key: res.key }
      return setCurrentStep(3)
    }

    if (currentStep === 3 && !!validationCode && !!currentValidationCodeRef.current) {
      setIsBusy(true)
      const { key, expires } = currentValidationCodeRef.current
      const res = await postValidateCode({
        accountNumber,
        keys: [key],
        expiries: [expires],
        code: validationCode,
        providedCodes: [validationCode],
      })
      setIsBusy(false)
      if (typeof res === 'string') return setErrorMessage(res)
      return setCurrentStep(4)
    }
  }

  const handleBackClick = () => {
    if (currentStep === 1) setWorkflowState('Login')
    else setCurrentStep(curr => (curr - 1) as Steps)
    setErrorMessage(undefined)
  }

  return {
    state: {
      accountNumber,
      currentStep,
      currentValidationCodeRef,
      deliveryMethods,
      errorMessage,
      isBusy,
      selectedDeliveryMethod,
      validationCode,
    },
    handlers: {
      onAccountNumberChange,
      onDeliveryMethodChange,
      onValidationCodeChange,
      nextClick: handleNextClick,
      backClick: handleBackClick,
    },
  }
}

// =====
// Types
// =====
interface UserPasswordResetArgs {
  initialAccountNumber: string
  setWorkflowState: ReturnType<typeof useLoginState>['handlers']['setWorkflow']
}

type Steps = 1 | 2 | 3 | 4
