import React from 'react'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import {
  SameRaceMultiButtonsContainerStyled,
  SameRaceMultiScratchedStyled as SameRaceMultiErrorStyled,
} from './SameRaceMultiButtons.styles'

interface SameRaceMultiProp {
  prices: number[]
  shouldDisplayFixedScratched: boolean
  shouldDisplayFixedSuspended: boolean
  makeFobLegSelection(leg: number): void
  srmSelectedLegs: boolean[]
}

const buttonTitles = ['WIN', 'TOP 2', 'TOP 3', 'TOP 4']

export const SameRaceMultiButtons = ({
  prices,
  shouldDisplayFixedScratched,
  shouldDisplayFixedSuspended,
  makeFobLegSelection,
  srmSelectedLegs,
}: SameRaceMultiProp): JSX.Element => {
  if (shouldDisplayFixedSuspended) {
    return <SameRaceMultiErrorStyled>SUSPENDED</SameRaceMultiErrorStyled>
  }

  if (shouldDisplayFixedScratched) {
    return <SameRaceMultiErrorStyled>SCRATCHED</SameRaceMultiErrorStyled>
  }

  return (
    <SameRaceMultiButtonsContainerStyled>
      {buttonTitles
        .filter((_title, index) => !!prices[index])
        .map((title, index) => (
          <WinPlaceSelection
            key={index}
            title={title}
            priceWin={prices[index]?.toFixed(2)}
            isSelected={srmSelectedLegs[index]}
            onClick={() => makeFobLegSelection(index)}
            arrowLocation='IN'
            buttonStyleOverride={{ height: '4rem', flex: '1' }}
            centerContent
            isVisible
            isDisabled={false}
            isFavourite={false}
            pricePlace=''
            starterNumber={0}
            favouritePosition={undefined}
          />
        ))}
    </SameRaceMultiButtonsContainerStyled>
  )
}
