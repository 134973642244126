import * as ko from 'knockout'
import Decimal from 'decimal.js'
import { injectable, inject } from 'inversify'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { BetSpecialOffer, FobAmount } from '@classic/Specials/Model/BetSpecialOffer'
import { CurrencyConverter } from '@classic/AppUtils/Utils/CurrencyConverter'
import { LegTypeCode } from '@classic/Specials/Model/LegTypeCode'
import { PyosStore } from '@classic/Specials/Store/PyosStore'
import { Stake } from '@classic/Specials/Model/Stake'
import { PriceDetails } from '@classic/Specials/Model/PriceDetails'
import { IFixedWinPlaceSelectionsViewModel } from './IFixedWinPlaceSelectionsViewModel'

const priceBoostIncrementIntervalSpeed = 60

@injectable()
export class FixedWinPlaceSelectionsViewModel
  extends Disposable
  implements IFixedWinPlaceSelectionsViewModel
{
  private currencyConverter!: CurrencyConverter
  private pyosStore: PyosStore
  private stakeChangeSubscription!: ko.Subscription
  private maxProjectedPay!: decimal.Decimal

  public title!: string
  public selectionsString!: string
  public winPrice!: string
  public placePrice!: string
  public displayWinPrice!: ko.Observable<string>
  public displayPlacePrice!: ko.Observable<string>
  public hasFixedPlacePool!: boolean
  public projectedReturnString!: ko.Observable<string>
  public currentBoostedPrice!: ko.Observable<string>

  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('PyosStore') pyosStore: PyosStore
  ) {
    super(eventAggregator)
    this.pyosStore = pyosStore
  }

  public init(params: {
    selectionsString: string
    winPrice: string
    placePrice: string
    hasFixedPlacePool: boolean
  }) {
    this.maxProjectedPay = new Decimal('999999999.99')
    this.currencyConverter = new CurrencyConverter()

    this.selectionsString = params.selectionsString
    this.winPrice = params.winPrice
    this.placePrice = params.placePrice
    this.displayWinPrice = ko.observable<string>('')
    this.setDisplayPrice(this.displayWinPrice, this.winPrice)
    this.displayPlacePrice = ko.observable<string>('')
    this.setDisplayPrice(this.displayPlacePrice, this.placePrice)
    this.hasFixedPlacePool = params.hasFixedPlacePool
    this.projectedReturnString = ko.observable<string>('')
    this.currentBoostedPrice = ko.observable<string>('')

    this.initialiseTitle()
    this.updatePrices()
    this.updateReturn()

    this.configureDisposal()
    this.registerHandlers()
  }

  private registerHandlers() {
    this.safeSubscribe('specialoffer.selected', (specialOffer: BetSpecialOffer) => {
      if (specialOffer.isBoostOffer || specialOffer.isInsuranceOffer) {
        this.boostPrices(specialOffer)
        this.boostReturn(specialOffer)
      } else {
        this.clearBoostedPrices()
        this.clearBoostedReturn()
      }
    })

    this.safeSubscribe('specialoffer.cleared', () => {
      this.clearBoostedPrices()
      this.clearBoostedReturn()
    })

    this.stakeChangeSubscription = this.pyosStore.stake.subscribe(() => {
      this.updateReturn()
    })
  }

  private configureDisposal() {
    this.registerDisposals(() => {
      this.stakeChangeSubscription.dispose()
    })
  }

  private calculateProjectedReturn(): number {
    let projectedPay: decimal.Decimal = new Decimal(0)
    if (this.pyosStore.stake() && this.pyosStore.priceDetails()) {
      const winPay = (this.pyosStore.stake() as Stake).Win.times(
        (this.pyosStore.priceDetails() as PriceDetails).Win.DollarReturn
      )
      const placePay = (this.pyosStore.stake() as Stake).Place.times(
        (this.pyosStore.priceDetails() as PriceDetails).Place.DollarReturn
      )
      projectedPay = winPay.plus(placePay)
      projectedPay = Decimal.min(projectedPay, this.maxProjectedPay)
      projectedPay = Decimal.max(projectedPay, 0)
    }
    return projectedPay.toNumber()
  }

  private updatePrices(): void {
    const selectedSpecial = this.pyosStore.selectedSpecial()
    if (selectedSpecial && (selectedSpecial.isBoostOffer || selectedSpecial.isInsuranceOffer)) {
      this.boostPrices(selectedSpecial)
    }
  }

  public GetPriceIncrease(specialOffer: BetSpecialOffer): FobAmount | null {
    if (!specialOffer.isBoostOffer && !specialOffer.isInsuranceOffer) {
      return null
    }
    if (!specialOffer.elements) {
      return null
    }

    const boostElement = specialOffer.elements.find(element => element.isBoost)
    return boostElement?.priceIncrease ?? null
  }

  private boostPrices(specialOffer: BetSpecialOffer): void {
    const priceIncrease: FobAmount | null = this.GetPriceIncrease(specialOffer)
    if (!priceIncrease) {
      return
    }
    if (specialOffer.legTypeCode === LegTypeCode.Win) {
      this.currentBoostedPrice(LegTypeCode.Win)
      this.setDisplayPrice(this.displayPlacePrice, this.placePrice)
      this.boostPrice(this.displayWinPrice, this.winPrice, priceIncrease.win)
    } else {
      this.currentBoostedPrice(LegTypeCode.Place)
      this.setDisplayPrice(this.displayWinPrice, this.winPrice)
      this.boostPrice(this.displayPlacePrice, this.placePrice, priceIncrease.place)
    }
  }

  private clearBoostedPrices(): void {
    this.currentBoostedPrice('')
    this.setDisplayPrice(this.displayWinPrice, this.winPrice)
    this.setDisplayPrice(this.displayPlacePrice, this.placePrice)
  }

  private boostReturn(selectedSpecialOffer: BetSpecialOffer): void {
    if (this.calculateProjectedReturn() > 0) {
      const reward = this.pyosStore.projectedBoostReward(selectedSpecialOffer)
      if (reward) {
        this.boostPrice(this.projectedReturnString, this.calculateProjectedReturn(), reward)
      }
    }
  }

  private updateReturn(): void {
    let boostReward = 0
    const selectedSpecial = this.pyosStore.selectedSpecial()
    if (selectedSpecial && (selectedSpecial.isBoostOffer || selectedSpecial.isInsuranceOffer)) {
      const reward = this.pyosStore.projectedBoostReward(selectedSpecial)
      if (reward) {
        boostReward = reward.toNumber()
      }
    }

    const projReturnString = this.currencyConverter.toMoney(
      this.calculateProjectedReturn() + boostReward,
      2
    )
    this.projectedReturnString(projReturnString)
  }

  private clearBoostedReturn(): void {
    this.setDisplayPrice(this.projectedReturnString, this.calculateProjectedReturn())
  }

  private boostPrice(
    displayPrice: ko.Observable<string>,
    price: string | number,
    increase: decimal.Decimal | number
  ): void {
    const priceIncrease = new Decimal(increase || 0)
    let increment = priceIncrease.dividedBy(10)
    if (increment.lessThan(0.01)) {
      increment = new Decimal(0.01)
    }
    const basePrice = new Decimal(price || 0)
    if (priceIncrease.lessThanOrEqualTo(0)) {
      increment = new Decimal(0)
    }
    const targetPrice = basePrice.plus(priceIncrease)
    let incrementedPrice = new Decimal(basePrice)

    const animationTimerId = setInterval(() => {
      incrementedPrice = incrementedPrice.plus(increment)
      this.setDisplayPrice(displayPrice, incrementedPrice.toNumber())
      if (incrementedPrice.greaterThanOrEqualTo(targetPrice)) {
        clearInterval(animationTimerId)
      }
    }, priceBoostIncrementIntervalSpeed)
  }

  private setDisplayPrice(
    displayPrice: ko.Observable<string>,
    priceToDisplay: number | string
  ): void {
    let displayPriceString = ''
    if (typeof priceToDisplay === 'number') {
      displayPriceString = this.currencyConverter.toMoney(priceToDisplay, 2)
    }
    if (typeof priceToDisplay === 'string') {
      displayPriceString = this.currencyConverter.toMoney(+priceToDisplay, 2) //unary + operator converts string to number
    }

    displayPrice(displayPriceString)
  }

  public shouldShowPlacePrice(): boolean {
    return this.hasFixedPlacePool
  }

  private initialiseTitle(): void {
    this.title = 'Win & Place - FIXED'
  }
}
