import styled from '@emotion/styled'
import { ButtonStyled } from '../../Common/V2/Buttons/Button'
import { font, spacing } from '../../Theme/Common'

export const PaymentMethodSelectionButtonStyled = styled(ButtonStyled)({
  position: 'relative',
  minHeight: '4.8rem',
  padding: `0 ${spacing.smx1}`,
  ...font.size.sm,
})

export const PaymentMethodSelectionIconStyled = styled.span({
  justifyContent: 'center',
  display: 'inline-flex',
  minWidth: '4.8rem',
})

export const PaymentMethodSelectionTextStyled = styled.span({
  display: 'inline-flex',
  flex: '1 1 auto',
  textAlign: 'left',
})

export const PaymentMethodSelectionChevronStyled = styled.span({
  display: 'inline-flex',
  marginLeft: 'auto',
})
