import React, { type FC } from 'react'
import { useSelector } from 'react-redux'
import type { PanelProps } from '../../types'
import {
  Button,
  Centered,
  CopyField,
  CustomerServiceCentre,
  Icon,
  type ButtonColor,
} from '@mobi/component-library/Common/V2'
import { type PayIdDetails } from '@mobi/api-types/src/account/betAccount'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'
import { colors } from '@mobi/component-library/Theme/Common'
import { usePayID } from '../../../Hooks/PayID/usePayID'
import { track } from '../../../../../Utils'
import { selectDepositFlow } from '../../../Store'
import { InfoContainerStyled, MissingPayIDCardStyled, PayIDPanelStyled } from './PayIDPanel.styled'
import PayIDInstructions from './PayIDInstructions'

type Props = Pick<PanelProps, 'accountNumber'> & {
  payIdDetailList: PayIdDetails[]
}

export const PayIDPanel: FC<Props> = ({ accountNumber, payIdDetailList }) => {
  const flow = useSelector(selectDepositFlow)
  const { activePayID, generate, status } = usePayID({
    accountNumber,
    payIdDetailList,
  })

  const [text, heading, color] = ((): [string, string | React.ReactElement, ButtonColor] => {
    if (status === 'loading') {
      return [
        'Please wait...',
        "We're still working on generating your PayID. Feel free to leave this page and check back again in a few minutes to see if it's ready.",
        'secondary_color',
      ]
    }

    if (status === 'retry') {
      return [
        'Try again',
        "We're unable to generate your PayID. Please try again.",
        'secondary_destructive',
      ]
    }

    if (status === 'error') {
      return [
        'Try again',
        <CustomerServiceCentre
          prefixText='We’re unable to generate your PayID right now.'
          key='pay-id-error'
        />,
        'secondary_grey',
      ]
    }

    return ['Generate my PayID', 'Generate your unique TABtouch PayID.', 'secondary_color']
  })()

  return (
    <PayIDPanelStyled>
      <header>
        <p>
          Instantly transfer funds into your TABtouch account, direct from your banking app. It's
          fast and secure.
        </p>
      </header>

      {activePayID && (
        <div>
          <InfoContainerStyled>
            <span>PayID</span>
            <CopyField
              value={activePayID.PayId}
              onCopied={() =>
                track('deposit_copy_content', {
                  accountNumber,
                  paymentMethod: 'PayID',
                  copiedContent: 'PayID',
                  copiedLocation: 'PayID',
                  depositVariant: flow,
                })
              }
            />
          </InfoContainerStyled>

          <InfoContainerStyled>
            <span>Minimum Deposit</span>
            <span>$5.00</span>
          </InfoContainerStyled>
        </div>
      )}

      {!activePayID && (
        <MissingPayIDCardStyled>
          <Centered>
            <SvgLazy name='PayIDLogo' color={colors.white} width='5rem' />
          </Centered>

          <div>
            <p>{heading}</p>

            <Button
              type='button'
              color={color}
              size='xs'
              onClick={generate}
              disabled={status === 'error'}
            >
              {status === 'loading' && <Icon name='DuocolorLoading01' />}
              {text}
            </Button>
          </div>
        </MissingPayIDCardStyled>
      )}

      <PayIDInstructions accountNumber={accountNumber} />
    </PayIDPanelStyled>
  )
}

PayIDPanel.displayName = 'PayIDPanel'
