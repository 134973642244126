import React, { ButtonHTMLAttributes, memo, type PropsWithChildren } from 'react'
import { Icon } from '../../../Common/Icon'
import { ButtonColor } from '../../../Common/Buttons/themes'
import { VerificationButtonStyled } from './VerificationButton.styled'

type Props = PropsWithChildren<{
  disabled?: boolean
  color: Extract<ButtonColor, 'tab' | 'tab_invert'>
  showIcon?: boolean
}> &
  ButtonHTMLAttributes<HTMLButtonElement>

export const VerificationButton = memo<Props>(
  ({ children, disabled, color, showIcon = true, ...buttonProps }) => (
    <VerificationButtonStyled disabled={disabled} color={color} {...buttonProps}>
      <span>{children}</span>
      {showIcon && <Icon type='arrowright' size='1.4rem' color='white' />}
    </VerificationButtonStyled>
  )
)
