import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const appHeaderInitialState: AppHeaderState = {
  isAppHeaderVisible: true,
  isBalanceVisible: true,
  isBetSlipNewOpen: false,
  isDropDownOpen: false,
  isHamburgerMenuOpen: false,
  isStickyEnabled: true,
  isPreviousPendingBetsSportsOnly: false,
  shouldDisableAppHeaderRender: false,
}

const appHeaderSlice = createSlice({
  name: 'app-header',
  initialState: appHeaderInitialState,
  reducers: {
    toggleIsAppHeaderSticky(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isStickyEnabled = payload ?? !state.isStickyEnabled
    },

    toggleIsAppHeaderVisible(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isAppHeaderVisible = payload ?? !state.isAppHeaderVisible
    },

    toggleIsBalanceVisible(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isBalanceVisible = payload ?? !state.isBalanceVisible
    },

    toggleIsDropDownOpen(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isDropDownOpen = payload ?? !state.isDropDownOpen
    },

    toggleIsHamburgerMenuOpen(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isHamburgerMenuOpen = payload ?? !state.isHamburgerMenuOpen
    },

    setDisableAppHeaderRender(state, { payload }: PayloadAction<boolean>) {
      state.shouldDisableAppHeaderRender = payload
    },

    setBetSlipNewIsOpen(state, { payload }: PayloadAction<boolean>) {
      state.isBetSlipNewOpen = payload
    },

    setIsPreviousPendingBetsSportsOnly(state, { payload }: PayloadAction<boolean>) {
      state.isPreviousPendingBetsSportsOnly = payload
    },
  },
})

export const {
  toggleIsAppHeaderSticky,
  toggleIsAppHeaderVisible,
  toggleIsBalanceVisible,
  toggleIsDropDownOpen,
  toggleIsHamburgerMenuOpen,
  setDisableAppHeaderRender,
  setIsPreviousPendingBetsSportsOnly,
  setBetSlipNewIsOpen,
} = appHeaderSlice.actions

const presistKeys: (keyof AppHeaderState)[] = [
  'isBalanceVisible',
  'isPreviousPendingBetsSportsOnly',
]

export default persistReducer(
  {
    key: 'app-header',
    whitelist: presistKeys,
    storage,
    debug: !PRODUCTION,
  },
  appHeaderSlice.reducer
)

// =====
// Types
// =====

export interface AppHeaderState {
  isAppHeaderVisible: boolean
  isBalanceVisible: boolean
  isBetSlipNewOpen: boolean
  isDropDownOpen: boolean
  isHamburgerMenuOpen: boolean
  isStickyEnabled: boolean
  isPreviousPendingBetsSportsOnly: boolean
  shouldDisableAppHeaderRender: boolean
}
