import { BettingInformation } from '../Model/BettingInformation'
import { IBetTypeValidator } from './IBetTypeValidator'
import { CheckBoxSelection } from '../Components/Core/UIElements/CheckboxSelection'
import Guard from '../../AppUtils/Framework/Guard'

export default class QuinellaValidator implements IBetTypeValidator {
  isValid(bettingContext: BettingInformation): boolean {
    Guard.notNull(bettingContext)
    try {
      const selections = bettingContext.selections

      if (
        !selections ||
        selections.getTotalNumberOfStartersForRace(bettingContext.raceNumber) === 0
      ) {
        return false
      }

      const checkboxes = selections
        .getStartersForRace(bettingContext.raceNumber)()
        .filter(starter => starter && typeof starter.selection === 'function')
        .map(starter => starter.selection() as CheckBoxSelection)

      if (!bettingContext.isLegIn()) {
        if (checkboxes.some(cb => cb.anySelected() && !cb.onlyFirstSelected())) {
          return false
        }

        const selectedCount = checkboxes.filter(cb => cb.anySelected()).length
        return (
          selectedCount >= 2 &&
          selectedCount <= selections.getTotalNumberOfStartersForRace(bettingContext.raceNumber)
        )
      }

      if (bettingContext.isLegIn()) {
        const numLegIns = checkboxes.filter(checkBoxes => checkBoxes.selectedAt(0)).length,
          numWiths = checkboxes.filter(checkBoxes => checkBoxes.selectedAt(1)).length

        if (numLegIns !== 1) {
          return false
        }

        return numWiths >= 2
      }

      return false
    } catch {
      return false
    }
  }
}
