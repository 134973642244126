import { BetAccountRecord } from '@core/Data/Account/betAccount'
import { type PaymentMethodsAvailability } from '@mobi/component-library/Deposit/types'

type PaymentMethodAvailabilities = Record<PaymentMethodsAvailability, { blocked: boolean }>

export const PaymentAvailabilities = (
  betAccount?: BetAccountRecord
): PaymentMethodAvailabilities => ({
  CreditCard: { blocked: betAccount?.CreditCardBlockedInd === 'Y' },
  PayPal: { blocked: betAccount?.BlockPayPalInd === 'Y' },
  ApplePay: { blocked: betAccount?.BlockApplePayInd === 'Y' },
  GooglePay: { blocked: betAccount?.BlockGooglePayInd === 'Y' },
  PayID: { blocked: betAccount?.BlockEftInd === 'Y' },
})
