import React, { useState } from 'react'
import * as QueryString from 'query-string'
import { useLocation } from 'react-router-dom'
import type { State } from 'rwwa-data-access'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { EasyBetTab } from './EasyBet'
import { PotLuckTab } from './PotLuck'
import { NumberResultsTab } from './NumberResultsTab'
import {
  FavouriteNumbersPools,
  UpcomingFavouriteNumberPools,
  UpcomingPoolsKey,
} from '@core/Data/favouriteNumbers'
import { FavouriteNumberPoolResults } from '@core/Data/favouriteNumbersResults'
import { useObservableProperties } from '@core/Utils/hooks/useObservable'
import { FavNumbersNavigationStyled } from './FavouriteNumbers.styles'
import { state$ } from './driver'

type Selection = 'easyBet' | 'potLuck' | 'numberResults'

const useQuery = () => QueryString.parse(useLocation().search)

const SelectedTab = (selection: Selection) => {
  switch (selection) {
    case 'numberResults':
      return <NumberResultsTab />
    case 'potLuck':
      return <PotLuckTab />
    default:
      return <EasyBetTab />
  }
}

export const FavouriteNumbersPage = (): JSX.Element => {
  const { code = 'easyBet' }: { code: Selection } = useQuery()

  const [selection, setSelection] = useState<Selection>(code)
  const { resultKey } = useObservableProperties(state$, ['resultKey'], {
    resultKey: 'current',
  })

  const onEasyBetSelection = () => {
    setSelection('easyBet')
  }

  const onPotLuckTabSelection = () => {
    setSelection('potLuck')
  }

  const onNumberResultsTabSelection = () => {
    setSelection('numberResults')
  }

  const doRefresh = () => {
    const repo = selection === 'numberResults' ? FavouriteNumberPoolResults : FavouriteNumbersPools
    const key = selection === 'numberResults' ? resultKey : UpcomingPoolsKey
    repo.hardInvalidate(null as unknown as State<UpcomingFavouriteNumberPools>, key)
  }

  return (
    <MainContainer data-tid-favourite-numbers=''>
      <ContentHeader title='FAVOURITE NUMBERS' refreshCallback={doRefresh} />
      <FavNumbersNavigationStyled>
        <li data-tid-easy-bet-tab data-js-favnum-active={selection === 'easyBet'}>
          <a style={{ padding: '0 0.5rem' }} onClick={onEasyBetSelection}>
            Easy Bet
          </a>
        </li>
        <li data-tid-pot-luck-tab data-js-favnum-active={selection === 'potLuck'}>
          <a
            style={{
              padding: '0 0.5rem',
              borderLeft: '1px solid #454545',
              borderRight: '1px solid #454545',
            }}
            onClick={onPotLuckTabSelection}
          >
            Pot Luck
          </a>
        </li>
        <li data-tid-number-results-tab data-js-favnum-active={selection === 'numberResults'}>
          <a style={{ padding: '0 0.5rem' }} onClick={onNumberResultsTabSelection}>
            NO.S &amp; Results
          </a>
        </li>
      </FavNumbersNavigationStyled>
      {SelectedTab(selection)}
    </MainContainer>
  )
}
