import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const FooterMessageStyled = styled.p({
  alignItems: 'center',
  color: hexColors.grey,
  display: 'flex',
  flexShrink: 0,
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: '300',
  height: '4rem',
  justifyContent: 'center',
  lineHeight: 'normal',
  margin: 0,
  textAlign: 'center',
})
