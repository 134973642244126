import React from 'react'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'

export const FobToteSwitch: React.FC<{
  isFixedOddsOn: boolean
}> = ({ isFixedOddsOn }) => {
  const centerXForFixedOdds = 15
  const centerXForTote = 35

  const centerX = React.useMemo(
    () => (isFixedOddsOn ? centerXForFixedOdds : centerXForTote),
    [isFixedOddsOn]
  )

  return (
    <SvgStyled
      viewBox='0 0 50 30'
      data-testid='fob-tote-switch'
      data-state={isFixedOddsOn ? 'fob' : 'tote'}
    >
      <rect x='0' y='0' width='50' height='30' fill={colors.surface[300]} rx='15' ry='15' />
      <text x='15' y='20' textAnchor='middle' fill={colors.neutral[700]}>
        F
      </text>
      <text x='39' y='20' textAnchor='end' fill={colors.neutral[700]}>
        T
      </text>
      <defs>
        <clipPath id='clipPathId'>
          <circle cx={centerX} cy='15' r='12' />
        </clipPath>
        <filter id='shadow'>
          <feDropShadow dx='0' dy='0.5' stdDeviation='0.5' floodColor='black' floodOpacity='0.3' />
        </filter>
      </defs>
      <g filter='url(#shadow)'>
        <g clipPath='url(#clipPathId)'>
          <rect x='0' y='0' width='100' height='30' fill={colors.white} />
          <text x='15' y='20' textAnchor='middle' fill={colors.bondi[500]}>
            F
          </text>
          <text x='39' y='20' textAnchor='end' fill={colors.studio[600]}>
            T
          </text>
        </g>
      </g>
    </SvgStyled>
  )
}

const SvgStyled = styled.svg({
  padding: '0.3rem',
  verticalAlign: 'middle',
  display: 'inline-block',
  width: '4.4rem',
  height: '2.4rem',

  text: {
    fontFamily: font.family.primary,
    fontWeight: font.weight.bold,
    fontSize: '13px',
    lineHeight: 1,
  },

  circle: {
    transitionProperty: 'cx',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-in-out',
  },
})
