import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { ErrorMessage } from '@core/Components/Messages'
import { HomeScrollListItemsStyled } from '@core/Areas/Home/Home.styles'
import { fetchFromApi } from '@classic/Foundation/Services/ApiService'
import { TrendingBetsList, TrendingBetsType } from './Components/TrendingBetsList'
import { ToteRaceEventPushEvent, isToteRacePushEvent } from 'tabtouch-push-contract'
import { event$ } from '@mobi/utils/awsiot'
import {
  HeaderContainer,
  Header,
  HeaderContent,
  HomeTrendingBetsScrollableContainerStyled,
  Wrapper,
  SubheadingContainer,
} from './Components/TrendingBets.styles'
import { isToteRaceChangePushEvent } from '@core/Areas/NextEvents/utils'
import {
  TrendingBetFilterEnum,
  TrendingBetsFilter,
  TrendingBetsFilterAsTitle,
} from './Components/TrendingBetsFilter'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { trackTrendingBetSortingChanged } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { TrendingBetsQueryResponse } from './types'

const getTrendingBets = async (sortingOptions?: string[]): Promise<TrendingBetsQueryResponse> => {
  let url = `/api/search/trendingBets`
  if (sortingOptions && sortingOptions.length) url += `?${sortingOptions.join('&')}`
  const results = await fetchFromApi(url)
  return await results.json()
}

const useQueryOptions = {
  refetchInterval: 30_000,
  queryKey: queryKeys.trendingBets,
  refetchOnWindowFocus: true,
}

interface TrendingBetsListConfig {
  localStorageKey: string
  headerContentText: string
  trackSortingChanged: (input: unknown) => unknown
  sortingParameterName: string
}

const config: Record<TrendingBetsType, TrendingBetsListConfig> = {
  Single: {
    localStorageKey: 'TRENDING_BETS_FILTERBY',
    headerContentText: 'Trending Bets',
    trackSortingChanged: filter => trackTrendingBetSortingChanged({ sorting: filter }),
    sortingParameterName: 'sortSingle',
  },
  SameRaceMulti: {
    localStorageKey: 'TRENDING_BETS_SAMERACEMULTI_FILTERBY',
    headerContentText: 'Trending Same Race Multis',
    trackSortingChanged: filter => filter + '', // TODO implementing later, no sorting change now
    sortingParameterName: 'sortSrm',
  },
}

export const TrendingBets: React.FC<{
  type: TrendingBetsType
}> = ({ type }) => {
  const { localStorageKey, headerContentText, trackSortingChanged, sortingParameterName } =
    config[type]

  const persistedValue =
    (getFromLocalStorage(localStorageKey) as TrendingBetFilterEnum) ??
    TrendingBetFilterEnum.TotalBets

  const [selectedFilter, setSelectedFilter] = React.useState<TrendingBetFilterEnum>(persistedValue)

  const sortingParameter = `${sortingParameterName}=${selectedFilter}`

  const { isLoading, isError, data, refetch } = useQuery({
    ...useQueryOptions,
    queryFn: () => getTrendingBets([sortingParameter]),
    queryKey: [useQueryOptions.queryKey, sortingParameter],
  })

  useEffect(() => {
    const subscription = event$
      .filter(
        x =>
          // trending bets are about fob, so that should really read isFobRacePushEvent
          // but tote and fob events are closely coupled => the tote events will typically occur before the fob events
          isToteRacePushEvent(x.payload) &&
          isToteRaceChangePushEvent((x.payload as ToteRaceEventPushEvent).status)
      )
      .delay(2_000) // a small delay to cater for potential race condition between mobi push and bet ingestion
      .subscribe(() => refetch())

    return () => subscription.dispose()
  }, [refetch])

  if (
    isLoading ||
    !data ||
    (type === 'Single' && !data.singles.results.length) ||
    (type === 'SameRaceMulti' && !data.srm.results.length)
  ) {
    return null
  }

  if (isError) {
    return <ErrorMessage>Error loading trending bets.</ErrorMessage>
  }

  return (
    <>
      <Wrapper>
        <HeaderContainer>
          <Header>
            <HeaderContent>{headerContentText}</HeaderContent>
          </Header>
          <SubheadingContainer>
            {type === 'SameRaceMulti' ? (
              <TrendingBetsFilterAsTitle>Total Bets</TrendingBetsFilterAsTitle>
            ) : (
              <TrendingBetsFilter
                initialFilter={selectedFilter}
                onChanged={(filter: TrendingBetFilterEnum) => {
                  setSelectedFilter(filter)
                  setInLocalStorage(localStorageKey, filter.toString())
                  trackSortingChanged(filter)
                }}
              />
            )}
          </SubheadingContainer>
        </HeaderContainer>
        <HomeTrendingBetsScrollableContainerStyled>
          <HomeScrollListItemsStyled>
            <TrendingBetsList type={type} response={data} selectedFilter={selectedFilter} />
          </HomeScrollListItemsStyled>
        </HomeTrendingBetsScrollableContainerStyled>
      </Wrapper>
    </>
  )
}
