import React from 'react'
import anime from 'animejs'
import { isSwipeDisabled } from '@core/Areas/RaceCard/Components/RaceSwipeContainer/helpers'

const enum LocalConstants {
  // Minimum horizontal pixels to move before registering swipe
  XThreshold = 50,
}

export const useSwipeable = ({
  onSwipeBack,
  onSwipeForward,
  isBackDisabled,
  isForwardDisabled,
  swipeableElRef,
  isSwipeActive,
}: UseSwipeableArgs) => {
  const capturedArgsRef = React.useRef<CapturedArgs>({
    onSwipeBack,
    onSwipeForward,
    isForwardDisabled,
    isBackDisabled,
  })
  capturedArgsRef.current = { onSwipeBack, onSwipeForward, isForwardDisabled, isBackDisabled }

  const [isSwiping, setIsSwiping] = React.useState(false)
  const [isChangingRace, setIsChangingRace] = React.useState(false)

  React.useEffect(() => {
    const container = swipeableElRef.current
    if (!isSwipeActive || !container) return

    let startX = 0
    let startY = 0
    let moveX = 0
    let moveY = 0

    let isScrolling: boolean | null = null
    let isAnimating = false
    let shouldDisableSwipe = false
    let hasThresholdExceeded: boolean | null = null

    let elWidth: DOMRect['width'] = container.getBoundingClientRect().width

    // ===========
    // TOUCH START
    // ===========
    const handleTouchStart = (e: TouchEvent) => {
      startX = 0
      startY = 0
      moveX = 0
      moveY = 0
      hasThresholdExceeded = null
      isScrolling = null
      shouldDisableSwipe = isSwipeDisabled(e)

      if (shouldDisableSwipe || isAnimating) return

      startX = e.touches[0].clientX
      startY = e.touches[0].clientY

      elWidth = container.getBoundingClientRect().width
    }

    // ==========
    // TOUCH MOVE
    // ==========
    const handleTouchMove = (e: TouchEvent) => {
      moveX = e.touches[0].clientX - startX
      moveY = e.touches[0].clientY - startY

      isScrolling ??= !!(Math.abs(moveX) < Math.abs(moveY))
      hasThresholdExceeded ??= Math.abs(moveX) < LocalConstants.XThreshold

      if (!hasThresholdExceeded || isScrolling || Math.abs(moveX) > elWidth * 0.5) return

      e.preventDefault()
      hasThresholdExceeded = true

      setIsSwiping(true)

      anime.set(container, { translateX: `${moveX}px` })
    }

    // =========
    // TOUCH END
    // =========
    const handleTouchEnd = () => {
      if (isScrolling) return

      isAnimating = true
      const resetIsAnimating = () => (isAnimating = false)

      const cancel = () => {
        anime({ targets: container, translateX: '0px', duration: 400, complete: resetIsAnimating })
        setIsSwiping(false)
      }

      const hasMovedEnoughToTriggerSwipe = Math.abs(moveX) > LocalConstants.XThreshold * 2.5
      if (!hasMovedEnoughToTriggerSwipe) {
        cancel()
        return
      }

      const finishXSign = Math.sign(Math.abs(moveX) / moveX) || 0
      if (
        finishXSign === 0 ||
        (finishXSign === 1 && capturedArgsRef.current.isBackDisabled) ||
        (finishXSign === -1 && capturedArgsRef.current.isForwardDisabled)
      ) {
        cancel()
        return
      }

      setIsChangingRace(true)

      anime({
        targets: container,
        keyframes: [
          { translateX: `${elWidth * finishXSign}px`, duration: 300, easing: 'easeOutExpo' },
          { opacity: 0, translateX: `${elWidth * -finishXSign}px`, duration: 0 },
          { opacity: 1, duration: 0 },
          { translateX: '0px', duration: 200 },
        ],
        duration: 500,
        easing: 'linear',
        complete: () => {
          window.scrollTo({ top: 0 })
          resetIsAnimating()

          if (finishXSign === -1) {
            capturedArgsRef.current.onSwipeForward()
          } else {
            capturedArgsRef.current.onSwipeBack()
          }

          setIsSwiping(false)
          setIsChangingRace(false)
        },
      })
    }

    container.addEventListener('touchstart', handleTouchStart)
    container.addEventListener('touchmove', handleTouchMove)
    container.addEventListener('touchend', handleTouchEnd)
    return () => {
      container.removeEventListener('touchstart', handleTouchStart)
      container.removeEventListener('touchmove', handleTouchMove)
      container.removeEventListener('touchend', handleTouchEnd)
    }
  }, [isSwipeActive, swipeableElRef])

  return { isSwiping, isChangingRace }
}

// =====
// Types
// =====

type UseSwipeableArgs = {
  onSwipeForward: () => void
  onSwipeBack: () => void
  isForwardDisabled: boolean
  isBackDisabled: boolean
  swipeableElRef: React.RefObject<HTMLDivElement>
  isSwipeActive: boolean
}

type CapturedArgs = Pick<
  UseSwipeableArgs,
  'onSwipeBack' | 'onSwipeForward' | 'isBackDisabled' | 'isForwardDisabled'
>
