import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { InfoBox } from '@mobi/component-library/Common/V2/Display/InfoBox'
import { toMoney } from '@mobi/utils/money'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import {
  calculateEstReturn,
  calculateReceiptReturn,
  calculateTotalStake,
  calculateTotalStakeReceipt,
} from '@mobi/betslip/helpers/calculator/combined'
import { getBetsToPlace, getBetsInMulti, isValidMulti } from '@mobi/betslip/helpers/state'
import { BetSlipContext } from '@mobi/betslip/context'
import { BetsPlacedSuccessToast } from './Components/BetsPlacedSuccessToast'
import { filterItemsForWorkflow } from '@mobi/betslip/Components/Singles/helpers/filterItemsForWorkflow'

export const SummarySection: React.FC<{ isReceiptView: boolean }> = ({ isReceiptView = false }) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const apiErrorMessage = useAppSelector(state => state.betslip.workflow.apiErrorMessage)
  const _items = useAppSelector(state => state.betslip.bets.items)
  const items = isReceiptView ? filterItemsForWorkflow(_items, workflowStatus) : _items
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)
  const multiReceipt = useAppSelector(state => state.betslip.bets.multiReceipt)
  const accountBalance = useAppSelector(state => state.betslip.workflow.hostAccountBalance)

  const toastEmitter = React.useContext(BetSlipContext)?.toastEmitter

  React.useEffect(() => {
    if (workflowStatus !== 'bets-placed' || !toastEmitter) return
    toastEmitter.emit('addToast', {
      id: 'success',
      content: <BetsPlacedSuccessToast />,
      isDismissable: true,
      timeoutMs: Infinity,
    })
    return () => {
      toastEmitter.emit('removeToast', { id: 'success' })
    }
  }, [workflowStatus, toastEmitter])

  const totalStake = isReceiptView
    ? calculateTotalStakeReceipt(items, multiReceipt)
    : calculateTotalStake(items, multiInvestment, multiBetError)

  const isInsufficientFunds = accountBalance && accountBalance < totalStake

  const hasInsufficientFundsError =
    isInsufficientFunds ||
    (!!multiBetError && multiBetError.betErrorType === 'InsufficientFunds') ||
    items.some(x => x.betErrorType === 'InsufficientFunds')

  const multiItems = getBetsInMulti(items)
  const placeableItems = getBetsToPlace(items)
  const placeableMultis =
    !multiReceipt && isValidMulti(multiInvestment, null, multiItems) ? multiItems : []
  const estimatedReturn = isReceiptView
    ? calculateReceiptReturn(items, multiReceipt)
    : calculateEstReturn(placeableItems, placeableMultis, multiInvestment)

  const displayValue = Number.isFinite(estimatedReturn) && estimatedReturn > 0

  return (
    <WrapperStyled data-testid='summary-section'>
      {hasInsufficientFundsError && (
        <InfoBox size='md' color='red'>
          <span>Low balance</span>
          <div>Please make a deposit or adjust stake to place a bet.</div>
        </InfoBox>
      )}

      {!!apiErrorMessage && (
        // TODO: Determine where error messaging will go, refer to logic
        <span style={{ background: 'white ', display: 'none' }}>
          <InfoBox size='md' color='red'>
            <span>Error</span>
            <div>{apiErrorMessage}</div>
          </InfoBox>
        </span>
      )}

      <div>
        <span>Total Cost</span>
        <span>{toMoney(totalStake)}</span>
      </div>

      <div>
        <span>Potential Returns</span>
        <span>{displayValue ? toMoney(estimatedReturn) : 'N/A'}</span>
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    fontWeight: font.weight.regular,

    '> span': {
      color: colors.white,
    },

    '> span:last-of-type': {
      fontWeight: font.weight.semibold,
    },
  },
})
