import { isRacePageDto, RacePageDTO } from '@mobi/api-types'

export function getRaceStarter(acceptorNumber: number, race?: RacePageDTO) {
  if (!race || !isRacePageDto(race)) {
    return null
  }
  const raceKey = race?.MeetingInformation.SelectedRace.Key
  const raceStarters = race.RaceStarters.find(x => x.RaceKey.Key === raceKey)
  if (!raceStarters) {
    return null
  }
  const starter = raceStarters.Starters.find(x => x.Number === acceptorNumber)
  return starter
}
