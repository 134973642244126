import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import ObservableSubstitutePool from '../Model/Observables/ObservableSubstitutePool'
import ObservableSubstitute from '../Model/Observables/ObservableSubstitute'
import { SubstitutePoolDataTransferObject } from '@mobi/api-types'

export default {
  map(dto: SubstitutePoolDataTransferObject[], model: ObservableResultsPage) {
    dto.forEach(availablePool => {
      //Create new observable
      const pool = new ObservableSubstitutePool()
      pool.number(availablePool.RaceNumber)
      pool.name(availablePool.Name)

      //Convert legs
      // @ts-expect-error Type issue
      for (const leg of availablePool.RaceLegs) {
        pool.legs().push(leg)
      }

      //Convert DTO subs into observable subs
      for (const subInPool of availablePool.AvailableSubstitutesInPool) {
        const sub = new ObservableSubstitute()
        sub.starterName = subInPool.Name
        sub.starterNumber = subInPool.StarterNumber.toString()

        sub.isAbandoned = subInPool.IsAbandoned
        sub.isWinner = subInPool.IsWinner
        sub.isLoser = subInPool.IsLoser
        sub.isUnallocated = subInPool.IsUnallocated

        sub.updateDisplayVars()

        pool.subs().push(sub)
      }

      //Finally add it to model for template
      model.addSubstitutePool(pool)
    })
  },
}
