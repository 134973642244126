// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any

type DebouncedFunction<T extends AnyFunction> = (...args: Parameters<T>) =>
  | (ReturnType<T> | undefined)
  | {
      /** Cancel yet-to-be-called function by clearing timeout */
      cancel: () => void
    }

export interface CancelableDebouncedFunction<T extends AnyFunction> extends DebouncedFunction<T> {
  cancel: () => void
}

export function debounceFn<T extends AnyFunction>(
  func: T,
  waitMilliseconds = 133
): CancelableDebouncedFunction<T> {
  let timeoutId: number | undefined
  let result: ReturnType<T> | undefined

  function setUpDebounce(this: object, ...args: Parameters<T>) {
    // Reset the timer on every call
    clearTimeout(timeoutId)
    timeoutId = undefined

    // Restart the debounce waiting period
    timeoutId = window.setTimeout(() => {
      timeoutId = undefined
      result = func.apply(this, args)
    }, waitMilliseconds)

    return result
  }

  setUpDebounce.cancel = () => {
    clearTimeout(timeoutId)
    timeoutId = undefined
  }

  return setUpDebounce
}

/**
 * Debounce a function and allow a value to be returned as a promise. The promise will be resolved after the wait time has expired.
 *
 * @param funcToDebounce The function that should be debounced.
 * @param delay The amount of time to wait before resolving the promise. Every call to the returned function will reset the countdown.
 */
export function debounceWithReturn<TFunc extends (...args: never[]) => unknown>(
  funcToDebounce: (...args: Parameters<TFunc>) => unknown,
  delay: number
): (...args: Parameters<TFunc>) => Promise<unknown> {
  let timeoutId: number | undefined = undefined

  const debouncedFunc = (...argsFromCall: Parameters<TFunc>): Promise<unknown> => {
    if (timeoutId != undefined) {
      clearTimeout(timeoutId)
      timeoutId = undefined
    }

    return new Promise<unknown>((resolve, reject) => {
      timeoutId = window.setTimeout(() => {
        try {
          resolve(funcToDebounce(...argsFromCall))
        } catch (error) {
          reject(error)
        }
      }, delay)
    })
  }

  return debouncedFunc
}
