import * as ko from 'knockout'
import { injectable } from 'inversify'
import Guard from '@classic/AppUtils/Framework/Guard'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import type { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import SortFunction from '@classic/Betting-v2/Sorting/SortFunction'
import { RaceStatuses } from '@classic/Betting-v2/Model/RaceStatuses'
import type { IResultsViewModel } from './IResultsViewModel'

@injectable()
export class ResultsViewModel implements IResultsViewModel {
  public showProtest!: ko.Computed<boolean>
  public displayFixedDivMessage!: ko.Computed<boolean>
  public raceInformation!: ObservableResultsPage
  public raceFinishers!: ko.Computed<IObservableFinisher[]>
  public isAbandoned!: ko.Computed<boolean>
  public init(params: { raceInformation: ObservableResultsPage }) {
    Guard.notNull(params)
    Guard.notNull(params.raceInformation)

    this.raceInformation = params.raceInformation
    this.raceFinishers = ko.pureComputed<IObservableFinisher[]>(() => {
      const firstFourFinishers = params.raceInformation.raceFinishers
        .finishers()
        .filter((f: IObservableFinisher) => f.placing() !== 0)
      return firstFourFinishers.sort(SortFunction.sortPlacing('Ascending'))
    })
    this.showProtest = ko.pureComputed<boolean>(() => {
      const protestStatus = params.raceInformation.protestStatus()
      return protestStatus !== undefined && protestStatus.length > 0
    })
    this.displayFixedDivMessage = ko.pureComputed<boolean>(() => {
      return params.raceInformation.meetingInformation.selectedRace.isFixedOddsRace()
    })
    this.isAbandoned = ko.pureComputed<boolean>(() => {
      return params.raceInformation.raceStatus() == RaceStatuses[RaceStatuses.Abandoned]
    })
  }
}
