import { injectable, inject } from 'inversify'
import type { ISpecialsUrlProvider } from '../Utils/ISpecialsUrlProvider'
import { IPyosService } from './IPyosService'
import type { IApiService } from '../../Foundation/Services/ApiServiceInjectable'
import { ServiceResponseDto } from '../Dtos/ServiceResponseDto'
import {
  RetrieveFobSpecialTokensResponseDto,
  PreEnquireSingleResultDto,
  SpecialUrlResponseDto,
} from '../Dtos/PyosServiceResponseDto'
import { FobBetPreEnquiryRequestDto } from '../Dtos/PyosServiceRequestDto'

@injectable()
export class PyosService implements IPyosService {
  private urlProvider: ISpecialsUrlProvider
  private apiService: IApiService

  constructor(
    @inject('ISpecialsUrlProvider') urlProvider: ISpecialsUrlProvider,
    @inject('IApiService') apiService: IApiService
  ) {
    this.urlProvider = urlProvider
    this.apiService = apiService
  }

  private request(url: string): Promise<ServiceResponseDto> {
    return this.apiService.get<ServiceResponseDto>({ url })
  }

  public retrieveFobSpecialTokens(): Promise<RetrieveFobSpecialTokensResponseDto> {
    const url: string = this.urlProvider.retrieveFobSpecialTokensUrl
    const promise = this.request(url)
    return promise.then()
  }

  public getSpecialUrl(tokenId: number): Promise<SpecialUrlResponseDto> {
    const url: string = this.urlProvider.getSpecialUrl(tokenId)
    const promise = this.request(url)
    return promise.then()
  }

  public fobBetPreEnquiry(request: FobBetPreEnquiryRequestDto): Promise<PreEnquireSingleResultDto> {
    const url = this.urlProvider.fobBetPreEnquiryUrl
    return this.apiService.post<PreEnquireSingleResultDto>({ url, body: request }).then()
  }
}
