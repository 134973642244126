import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { BoxedViewModel } from '@classic/Betting-v2/Components/Selection/Allways/BoxedViewModel'
import { LegInViewModel } from '@classic/Betting-v2/Components/Selection/LegIn/LegInViewModel'
import { RovingBankerViewModel } from '@classic/Betting-v2/Components/Selection/RovingBanker/RovingBankerViewModel'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { useAppDispatch } from '@core/Store/hooks'
import { setActiveToteSwap } from '@core/Areas/RaceCard/Store'
import { useToteSwapToggle } from './Hooks/useToteSwapToggle'

type BetModifierTypes = 'all-ways' | 'roving-banker' | 'insurance' | 'leg-in' | 'starting-price'

const enum LocalConstants {
  BetModifierActiveClassName = 'js-bet-modifier-btn--active',
}

export const BetModifiers: React.FC<{
  bettingContext: BettingInformation
  betType: BetType
}> = ({ betType, bettingContext }) => {
  const dispatch = useAppDispatch()
  const [selected, setSelected] = React.useState<BetModifierTypes | null>(null)

  const { isStartingPriceAvailable, isInsuranceAvailable, activeToteSwapOption } =
    useToteSwapToggle()

  React.useEffect(() => {
    const isAllwaysSubscription = bettingContext.isBoxed.subscribe(isAllwaysActive => {
      if (selected == 'all-ways' && !isAllwaysActive) {
        setSelected(null)
      }
    })

    const isRovingBankerSubscription = bettingContext.rovingBanker.subscribe(
      isRovingBankerActive => {
        if (selected == 'roving-banker' && !isRovingBankerActive) {
          setSelected(null)
        }
      }
    )

    return () => {
      isAllwaysSubscription.dispose()
      isRovingBankerSubscription.dispose()
    }
  }, [bettingContext, selected])

  // Sync BetModifiers state with tote swap SP and CON state
  React.useEffect(() => {
    if (activeToteSwapOption !== 'SP' && selected === 'starting-price') {
      setSelected(null)
      return
    }
    if (activeToteSwapOption === 'SP' && selected !== 'starting-price') {
      setSelected('starting-price')
      return
    }
    if (activeToteSwapOption === 'CON' && selected !== 'insurance') {
      setSelected('insurance')
      return
    }
  }, [selected, activeToteSwapOption])

  return (
    <>
      {[BetType.Exacta, BetType.First4, BetType.Trifecta].includes(betType) && (
        <BetModifierButtonStyled
          data-testid='bet-modifier-roving-banker'
          className={selected === 'roving-banker' ? LocalConstants.BetModifierActiveClassName : ''}
          onClick={() => {
            handleButtonClick('roving-banker', setSelected)
            dispatch(setActiveToteSwap(null))
            new RovingBankerViewModel({ context: { bettingContext } }).toggle()
          }}
        >
          Roving Banker
        </BetModifierButtonStyled>
      )}

      {[BetType.Exacta, BetType.First4, BetType.Trifecta].includes(betType) && (
        <BetModifierButtonStyled
          data-testid='bet-modifier-all-ways'
          className={selected === 'all-ways' ? LocalConstants.BetModifierActiveClassName : ''}
          onClick={() => {
            handleButtonClick('all-ways', setSelected)
            dispatch(setActiveToteSwap(null))
            new BoxedViewModel({ context: { bettingContext } }).toggle()
          }}
        >
          Allways
        </BetModifierButtonStyled>
      )}

      {isStartingPriceAvailable && BetType.WinPlace === betType && (
        <BetModifierButtonStyled
          data-testid='bet-modifier-starting-price'
          className={selected === 'starting-price' ? LocalConstants.BetModifierActiveClassName : ''}
          onClick={() => {
            handleButtonClick('starting-price', setSelected, isSelected =>
              dispatch(setActiveToteSwap(isSelected ? null : 'SP'))
            )
          }}
        >
          Starting Price
        </BetModifierButtonStyled>
      )}

      {isInsuranceAvailable && BetType.WinPlace === betType && (
        <BetModifierButtonStyled
          data-testid='bet-modifier-insurance'
          className={selected === 'insurance' ? LocalConstants.BetModifierActiveClassName : ''}
          onClick={() => {
            handleButtonClick('insurance', setSelected, isSelected =>
              dispatch(setActiveToteSwap(isSelected ? null : 'CON'))
            )
          }}
        >
          Ins. 2nd & 3rd
        </BetModifierButtonStyled>
      )}

      {BetType.Quinella === betType && (
        <BetModifierButtonStyled
          data-testid='bet-modifier-leg-in'
          className={selected === 'leg-in' ? LocalConstants.BetModifierActiveClassName : ''}
          onClick={() => {
            handleButtonClick('leg-in', setSelected)
            dispatch(setActiveToteSwap(null))
            new LegInViewModel({ context: { bettingContext } }).toggle()
          }}
        >
          Leg In
        </BetModifierButtonStyled>
      )}
    </>
  )
}

// =============
// Local Helpers
// =============

const handleButtonClick = (
  key: BetModifierTypes,
  setState: React.Dispatch<React.SetStateAction<BetModifierTypes | null>>,
  callBackWithSelectedState?: (isSelected: boolean) => void
) => {
  setState(prev => {
    const isSelected = prev === key
    callBackWithSelectedState?.(isSelected)
    return isSelected ? null : key
  })
  trackBetModifierButtonPressed(key)
}

const trackBetModifierButtonPressed = (type: BetModifierTypes) =>
  trackEvent('bet-modifier-button-pressed', { type })

// ======
// Styles
// ======

const BetModifierButtonStyled = styled.button({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '3rem',
  fontSize: font.size.sm.fontSize,
  lineHeight: font.size.sm.lineHeight,
  fontWeight: font.weight.regular,
  fontFamily: font.family.primary,
  marginLeft: spacing.smx2,
  minHeight: '3rem',
  padding: '0 1rem',
  whiteSpace: 'nowrap',
  userSelect: 'none',
  WebkitUserSelect: 'none',

  backgroundColor: colors.white,
  border: `0.1rem solid ${colors.neutral[200]}`,
  color: colors.black,
  transition: 'all 0.2s ease',

  [`&.${LocalConstants.BetModifierActiveClassName}`]: {
    backgroundColor: colors.studio[500],
    borderColor: colors.studio[500],
    color: colors.white,
  },
})
