import ko from 'knockout'
import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { getEventAggregator } from '@core/Utils/events'
import { IRaceReplayViewModel } from '@classic/Betting-v2/Components/General/RaceReplay/IRaceReplayViewModel'
import ObservableRaceReplay from '@classic/Betting-v2/Model/Observables/ObservableRaceReplay'
import type { RacesFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { useFeature } from '@core/Utils/hooks'
import {
  SkyVideoButton,
  SkyVideoButtonRace,
} from '@core/Areas/Racing/Components/Navigation/Components/RaceInfoHeader/Components/SkyVideoButton'
import { Provider } from 'react-redux'
import { store } from '@core/Store'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

export const RaceReplayKnockout: React.FC<{
  RaceReplay: RacesFormRaceResponse['Forms'][0]['RaceHistories'][0]['RaceReplay']
  RaceOverlay?: Pick<
    RacesFormRaceResponse['Forms'][0]['RaceHistories'][0],
    'Place' | 'RaceNumber' | 'TrackName' | 'StarterName' | 'StarterNumber' | 'FixtureDate'
  >
  RaceCode: RaceCodesLowerCase
  StarterName?: string | undefined
}> = ({ RaceReplay, RaceOverlay, RaceCode }) => {
  const koElementRef = React.useRef<HTMLDivElement>(null)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)

  React.useEffect(() => {
    if (!koElementRef.current || !RaceReplay.HasVideo) return undefined
    const el = koElementRef.current
    const { HasVideo, VideoKey, RaceLocation, Date } = RaceReplay

    const raceReplayData = new ObservableRaceReplay()
    raceReplayData.hasVideo(HasVideo)
    raceReplayData.videoKey(VideoKey)
    raceReplayData.raceLocation(RaceLocation)
    raceReplayData.date(Date)

    const params: Parameters<IRaceReplayViewModel['init']>[0] = {
      id: VideoKey,
      raceReplayData,
    }

    const isKoComponentBound = !!ko.dataFor(el)
    if (isKoComponentBound) ko.cleanNode(el)

    ko.applyBindings({ model: params }, el)
    return () => {
      ko.cleanNode(el)
    }
  }, [RaceReplay])

  React.useEffect(() => {
    if (!RaceReplay.HasVideo) return undefined

    const key = RaceReplay.VideoKey
    const setIsOn = () => setIsVideoPlaying(true)
    const setIsOff = () => setIsVideoPlaying(false)
    getEventAggregator().subscribe(`race-replay-started-for-${key}`, setIsOn)
    getEventAggregator().subscribe(`race-replay-stopped-for-${key}`, setIsOff)

    return () => {
      getEventAggregator().unsubscribe(`race-replay-started-for-${key}`, setIsOn)
      getEventAggregator().unsubscribe(`race-replay-stopped-for-${key}`, setIsOff)
    }
  }, [RaceReplay])

  const hasRaceReplayImprovements = useFeature('RACE_REPLAY_IMPROVEMENTS')

  const raceProp: SkyVideoButtonRace = {
    ...(RaceReplay.HasVideo &&
      RaceOverlay && {
        raceReplay: {
          raceNumber: Number(RaceOverlay.RaceNumber.slice(1)),
          trackName: RaceOverlay.TrackName,
          raceReplayKey: RaceReplay.VideoKey,
          raceCode: RaceCode,
          meetingDate: RaceOverlay.FixtureDate,
          formReplay: {
            starterName: RaceOverlay.StarterName ?? '',
            starterNumber: RaceOverlay.StarterNumber,
            racePosition: RaceOverlay.Place,
          },
        },
      }),
    advertisedStartTime: '',
    raceStatus: 'Released',
  }

  return (
    <>
      {RaceReplay.HasVideo && (
        <div data-testid='form-history-race-replay'>
          {hasRaceReplayImprovements ? (
            <Provider store={store}>
              <SkyVideoButton race={raceProp} isHeaderPinned={false} openLocation='form' />
            </Provider>
          ) : (
            <ButtonBlock
              color='secondary'
              data-testid='form-history-race-replay-button'
              onClick={() =>
                getEventAggregator().publish(`toggle-race-replay-for-${RaceReplay.VideoKey}`)
              }
            >
              <Icon type={isVideoPlaying ? 'stop' : 'play'} size='inherit' /> Replay
            </ButtonBlock>
          )}
          <div ref={koElementRef}>
            {/* @ts-expect-error KNOCKOUT element */}
            <race-replay params='id: model.id, raceReplayData: model.raceReplayData, source: "form"'></race-replay>
          </div>
        </div>
      )}
    </>
  )
}
