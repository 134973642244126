/* istanbul ignore file */
import * as React from 'react'
import anime from 'animejs'
import { throttle } from '@core/Utils'
import { store } from '@core/Store'
import { toggleIsAppHeaderVisible } from '../Store'

export const useStickyOnScroll = (
  appHeaderRef: React.RefObject<HTMLDivElement>,
  isVisible: boolean
): void => {
  React.useEffect(() => {
    const watchScrollEvent = throttle(updateStickyState, 70)
    window.addEventListener('scroll', watchScrollEvent)

    return () => {
      watchScrollEvent.cancel()
      window.removeEventListener('scroll', watchScrollEvent)
    }
  }, [])

  React.useEffect(() => {
    hideOrShowAppHeader({ appHeaderRef, isVisible })
  }, [appHeaderRef, isVisible])
}

interface UpdateStickState {
  appHeaderRef: React.RefObject<HTMLDivElement>
  isVisible: boolean
  setState: (isVisible: boolean) => void
}

const hideOrShowAppHeader = ({
  appHeaderRef,
  isVisible,
}: Omit<UpdateStickState, 'setState'>): void => {
  anime({
    targets: appHeaderRef.current,
    translateY: isVisible ? ['-100%', 0] : [0, '-100%'],
    easing: 'easeInOutQuad',
    duration: 125,
  })
}

// Module scoped variables
let scrollYCurrent = 0
let scrollYPrevious = 0

function updateStickyState(): void {
  scrollYCurrent = window.pageYOffset

  if (scrollYCurrent < 61) {
    store.dispatch(toggleIsAppHeaderVisible(true))
    return
  }

  if (isBottomOverscroll(scrollYCurrent)) {
    return
  }

  if (scrollYCurrent > 60) {
    const scrollDirection = scrollYCurrent >= scrollYPrevious ? 'down' : 'up'
    const distanceScrolled = Math.abs(scrollYCurrent - scrollYPrevious)

    const upTolerance = 5
    const downTolerance = 15

    if (
      // Going UP & not pinned, set to pinned
      scrollDirection === 'up' &&
      distanceScrolled > upTolerance
    ) {
      store.dispatch(toggleIsAppHeaderVisible(true))
    } else if (
      // Going DOWN & already pinned, set to unpinned
      scrollDirection === 'down' &&
      distanceScrolled > downTolerance
    ) {
      store.dispatch(toggleIsAppHeaderVisible(false))
    }
  }
  scrollYPrevious = scrollYCurrent
}

function isBottomOverscroll(currentScrollY: number) {
  return currentScrollY + window.innerHeight > document.body.scrollHeight
}
