import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

type Props = {
  invertColors?: boolean
}

export const LinkStyled = styled.a<Props>(({ invertColors = false }) => ({
  color: invertColors ? hexColors.white : hexColors.blueDiamond,
  display: 'inline-block',
  fontSize: '1.6rem',
  fontWeight: 'bold',
  textAlign: 'center',
}))
