import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

const meetingsInitialState: MeetingsState = {
  currentCodeTab: 'races',
  favourites: [],
}

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: meetingsInitialState,
  reducers: {
    toggleMeetingsFavourite(state, { payload }: PayloadAction<string>) {
      if (state.favourites.includes(payload))
        state.favourites = state.favourites.filter(fav => fav !== payload)
      else state.favourites.push(payload)
    },

    setMeetingsRaceCode(state, { payload }: PayloadAction<RaceCodesLowerCase>) {
      state.currentCodeTab = payload
    },
  },
})

export const { toggleMeetingsFavourite, setMeetingsRaceCode } = meetingsSlice.actions

export default persistReducer(
  {
    key: 'meetings',
    storage,
    debug: !PRODUCTION,
    whitelist: ['favourites'],
  },
  meetingsSlice.reducer
)

// =====
// Types
// =====

export interface MeetingsState {
  currentCodeTab: RaceCodesLowerCase
  favourites: string[]
}
