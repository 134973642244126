import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { Input } from '@mobi/component-library/Common/V2/Inputs'
import { setUserPresetDenomination } from '@mobi/betslip/Store/Config'
import { selectPresetDenominations } from '@mobi/betslip/Store/Config/selectors'
import { addToast, ToastContent } from '@mobi/component-library/Feedback/Toast'

const enum LocalConstants {
  ActiveInputClass = 'js-preset-input--active',
}

export const EditPresetTrayContent: React.FC<{ hideTray: () => void }> = ({ hideTray }) => {
  const dispatch = useDispatch()
  const presetDenominations = useSelector(selectPresetDenominations)

  const initialInputState = presetDenominations.map((value, index) => ({
    index,
    value: value.user || value.default,
  }))

  const [inputTypeValues, setInputTypeValues] = React.useState(initialInputState)
  const [activeInput, setActiveInput] = React.useState<number | null>(null)

  const submitBetValues = () => {
    dispatch(setUserPresetDenomination(inputTypeValues))
    hideTray()
    addToast({
      id: 'Betslip keyboard updated',
      content: <ToastContent title={'Betslip keyboard updated'} icon='success' />,
      isDismissable: true,
      isFullWidth: true,
    })
  }

  const handleInputChange = (idx: number, value: number) => {
    setInputTypeValues(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues[idx].value = value
      return updatedValues
    })
  }

  const isSaveActive = inputTypeValues?.every(
    (input, index) =>
      input.value === null || input.value === 0 || presetDenominations[index].user === input.value
  )

  return (
    <WrapperStyled>
      <div>
        <p>Save up to 3 custom amounts for one tap selection when placing a bet</p>
        <div>
          {inputTypeValues?.map((config, idx) => (
            <Input
              key={idx}
              prefix='$'
              name={`button ${idx + 1}`}
              label={`Button ${idx + 1}`}
              type='number'
              value={'' + config.value}
              onMouseDown={() => setActiveInput(config.index + 1)}
              onMouseLeave={() => {
                if (config.value === 0) handleInputChange(idx, presetDenominations?.[idx]?.default)
                setActiveInput(null)
              }}
              onChange={e => {
                const value = parseFloat(e.target.value)
                handleInputChange(
                  idx,
                  isNaN(value) ? 0 : Math.max(0, Math.min(10000, Math.round(value * 100) / 100))
                )
              }}
            />
          ))}
        </div>
      </div>
      <KeyPadStyled>
        <button disabled>1</button>
        <button disabled>2</button>
        <button disabled>3</button>
        <button disabled>4</button>
        <button disabled>5</button>
        <button disabled>6</button>
        <button disabled>7</button>
        <button disabled>8</button>
        <button disabled>9</button>
        <button disabled>.</button>
        <button disabled>0</button>
        <button disabled>
          <Icon name='DuocolorDelete' size='1.6rem' color='inherit' />
        </button>

        <div>
          <button disabled>Clear</button>

          {inputTypeValues?.map((input, idx) => (
            <button
              key={idx}
              className={activeInput === idx + 1 ? LocalConstants.ActiveInputClass : ''}
            >
              + ${input.value === 0 ? presetDenominations[idx].default : input.value}
            </button>
          ))}
        </div>
      </KeyPadStyled>

      <Button disabled={isSaveActive} onClick={submitBetValues} isFullWidth={true}>
        Save
      </Button>
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  marginBottom: spacing.md,

  '> div:first-child': {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: font.family.primary,
    gap: spacing.smx2,
    marginBottom: spacing.smx1,
    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,

    'div, label': {
      fontSize: font.size.md.fontSize,
      lineHeight: font.size.md.lineHeight,
    },

    '> p': {
      color: colors.neutral[800],
      fontSize: font.size.md.fontSize,
      fontWeight: font.weight.medium,
      textAlign: 'left',
      marginTop: 0,
    },

    '> div:first-of-type': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: spacing.smx2,
    },
  },
})

const KeyPadStyled = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(4, auto)',
  gap: spacing.smx2,
  padding: spacing.smx2,
  borderRadius: radius.lg,
  backgroundColor: colors.studio[50],

  '> div:first-of-type': {
    gridColumnStart: '4',
    gridColumnEnd: '5',
    gridRowStart: '1',
    gridRowEnd: '5',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.smx2,
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.5rem',
    fontFamily: font.family.primary,
    fontSize: font.size.xl.fontSize,
    letterSpacing: font.size.xl.letterSpacing,
    lineHeight: font.size.xl.lineHeight,
    fontWeight: font.weight.medium,
    borderRadius: radius.md,
    border: '0.1rem solid ' + colors.studio[50],
    backgroundColor: colors.white,
    color: colors.lavender[900],
    boxShadow: shadow.sm,
    userSelect: 'none',
    pointerEvents: 'none',

    [`&.${LocalConstants.ActiveInputClass}`]: {
      backgroundColor: colors.studio[500],
      color: colors.white,
    },

    ':disabled': {
      color: colors.neutral[500],
    },
  },
})
