import React, { type CSSProperties, type ReactNode } from 'react'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import styled from '@emotion/styled'
import { colors, font, measurements, radius, spacing } from '@mobi/component-library/Theme/Common'
import { PillButton } from '@mobi/component-library/Common/V2/Buttons/PillButton'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

const enum LocalConstants {
  AnimationTimingMs = 300,
  UnmountClassName = 'js-modal--unmounting',
}

type Props = {
  /** The header title of the modal */
  headerTitle?: string
  /** Whether the modal is open */
  isOpen: boolean
  /**
   * How the close button behave
   *
   * - allowed - Clicking the button dismisses the modal (default)
   * - disallowed - The button is hidden.
   * - disabled - The button is visible, but disabled.
   */
  dismissStatus?: 'allowed' | 'disallowed' | 'disabled'
  /** Callback when the user has dismissed the modal */
  onDismiss?: () => void
  /** The children to render */
  children?: ReactNode
  /** Overrides for the modal styling */
  modalStyle?: Partial<CSSProperties>
}

export const Modal: React.FC<Props> = ({
  headerTitle,
  isOpen,
  dismissStatus = 'allowed',
  onDismiss,
  children,
  modalStyle = {},
}) => {
  const shouldRenderModal = useRenderTimeoutControl({
    shouldRender: isOpen,
    timeoutMs: LocalConstants.AnimationTimingMs as number,
  })

  if (!shouldRenderModal) return null
  const containerClassName = !isOpen ? (LocalConstants.UnmountClassName as string) : ''

  const canBeDismissed = dismissStatus === 'allowed'
  const isCloseButtonVisible = dismissStatus !== 'disallowed'
  const shouldShowHeader = headerTitle || isCloseButtonVisible

  return (
    <ModalContainerStyled className={containerClassName} role='dialog' data-testid='Overlay.Modal'>
      <ModalStyled style={{ ...modalStyle }}>
        {shouldShowHeader && (
          <header>
            {headerTitle && <h2>{headerTitle}</h2>}

            {isCloseButtonVisible && (
              <PillButton
                color='secondary_grey'
                isIconOnlyButton
                onClick={onDismiss}
                aria-label='Close modal'
                disabled={!canBeDismissed}
                size='sm'
              >
                <Icon name='SolidXClose' size='2rem' />
              </PillButton>
            )}
          </header>
        )}
        {children}
      </ModalStyled>
    </ModalContainerStyled>
  )
}

// #region Styles

export const ModalContainerStyled = styled.section({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  zIndex: 5,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  opacity: 0,
  backdropFilter: 'blur(1px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  fontFamily: font.family.primary,
  animation: `fadeIn ${LocalConstants.AnimationTimingMs}ms ease forwards`,

  [`&.${LocalConstants.UnmountClassName}`]: {
    animation: `fadeOut ${LocalConstants.AnimationTimingMs}ms ease forwards`,
  },

  '@keyframes fadeIn': { from: { opacity: 0 }, to: { opacity: 1 } },
  '@keyframes fadeOut': { from: { opacity: 1 }, to: { opacity: 0 } },
})

export const ModalStyled = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  minWidth: `calc(${measurements.mobi.minWidth} - ${spacing.md} * 2)`,
  maxWidth: '60rem',
  margin: `0 ${spacing.md}`,
  padding: `${spacing.big} ${spacing.md} ${spacing.md}`,
  borderRadius: radius.lgx4,
  backgroundColor: colors.white,
  textAlign: 'center',

  '> header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing.md,

    '> h2': {
      padding: 0,
      margin: 0,
      background: 0,
      color: colors.black,
      fontFamily: font.family.primary,
      fontSize: font.size.xl2.fontSize,
      letterSpacing: font.size.xl2.letterSpacing,
      lineHeight: font.size.xl2.lineHeight,
      fontWeight: font.weight.semibold,
      textShadow: 'none',
      textTransform: 'unset',
    },
  },
})

// #endregion
