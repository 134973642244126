import { ISelectionProcessor } from './ISelectionProcessor'
import { BettingInformation } from '../../../Model/BettingInformation'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { ISelectionContext } from './ISelectionContext'
import Guard from '../../../../AppUtils/Framework/Guard'
import ko, { Observable } from 'knockout'

export abstract class BaseCheckBoxSelectionProcessor implements ISelectionProcessor {
  selectionsErrorMessage: Observable<string> = ko.observable('')

  public constructor(protected count: number) {
    Guard.greaterThanZero(count)
  }

  public tag(): string {
    return 'BaseCheckBoxSelectionProcessor'
  }
  public abstract generateSelections(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    selectionContext: ISelectionContext
  ): IObservableStarter[]
  public abstract processFieldSelection(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    index: number,
    selected: boolean
  ): void
  public abstract processSameAsSelection(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    row: number,
    column: number,
    selected: boolean
  ): void
  public abstract clearAllSelections(
    starters: ko.ObservableArray<IObservableStarter>[],
    selections: ko.ObservableArray<IObservableStarter>[]
  ): void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStarterSelections(_starters: IObservableStarter[]): boolean[][] {
    return []
  }
}
