import React, { useContext } from 'react'
import styled from '@emotion/styled'
import type { Dispatch } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import type { InvestmentType } from '@mobi/betslip/types'
import {
  BetSlipWorkflowState,
  setActiveInvestment,
  setKeypadActiveValue,
  type KeypadInput,
} from '@mobi/betslip/Store/Workflow'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { BetSlipContext } from '@mobi/betslip/context'
import { usePhysicalKeyboardEntry } from './hooks/usePhysicalKeyboardEntry'
import { useSyncActiveValueWithSelection } from './hooks/useSyncActiveValueWithSelection'
import { selectPresetDenominations } from '@mobi/betslip/Store/Config/selectors'

export const Keypad: React.FC = () => {
  const dispatch = useDispatch()
  const activeInvestment = useSelector(selectActiveInvestment)
  const presetDenominations = useSelector(selectPresetDenominations)

  const registeredInvestmentInputsRef = useContext(BetSlipContext)?.registeredInvestmentInputsRef

  const keypadClickHandler = React.useCallback(
    (keypadInput: KeypadInput) => dispatch(setKeypadActiveValue({ keypadInput })),
    [dispatch]
  )

  useSyncActiveValueWithSelection(activeInvestment)
  usePhysicalKeyboardEntry(keypadClickHandler)

  const switchInputClickHandler = (direction: 'UP' | 'DOWN') => {
    handleSwitchInputClick({ registeredInvestmentInputsRef, activeInvestment, direction, dispatch })
  }

  const denominationKeys: KeypadInputMap[] = presetDenominations.map(value => {
    return {
      label: `+ $${value.user || value.default}`,
      type: 'denomation',
      value: value.user || value.default,
    }
  })

  return (
    <WrapperStyled data-testid='Keypad'>
      <div>
        <div>
          <Button
            onClick={() => switchInputClickHandler('UP')}
            color='tertiary_grey'
            isIconOnlyButton
            size='xs'
          >
            <Icon name='SolidChevronUp' size='1.6rem' color='inherit' />
          </Button>

          <Button
            onClick={() => switchInputClickHandler('DOWN')}
            color='tertiary_grey'
            isIconOnlyButton
            size='xs'
          >
            <Icon name='SolidChevronDown' size='1.6rem' color='inherit' />
          </Button>
        </div>

        <div>
          <Button
            data-testid='Keypad.Done'
            size='xs'
            color='tertiary_grey'
            onClick={() => dispatch(setActiveInvestment(null))}
          >
            Done
          </Button>
        </div>
      </div>

      <div>
        {numericKeys.map(key => (
          <button key={key.label} onClick={() => keypadClickHandler(key)}>
            {key.label}
          </button>
        ))}

        <button
          data-testid='Keypad.Delete'
          onClick={() => keypadClickHandler({ type: 'action', value: 'del' })}
        >
          <Icon name='DuocolorDelete' size='1.6rem' color='inherit' />
        </button>

        <div>
          <button
            data-testid='Keypad.Clear'
            onClick={() => keypadClickHandler({ type: 'action', value: 'clear' })}
          >
            Clear
          </button>

          {denominationKeys &&
            denominationKeys.map(key => (
              <button key={key.label} onClick={() => keypadClickHandler(key)}>
                {key.label}
              </button>
            ))}
        </div>
      </div>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

const numericKeys: KeypadInputMap[] = [
  { label: '1', type: 'numeric', value: '1' },
  { label: '2', type: 'numeric', value: '2' },
  { label: '3', type: 'numeric', value: '3' },
  { label: '4', type: 'numeric', value: '4' },
  { label: '5', type: 'numeric', value: '5' },
  { label: '6', type: 'numeric', value: '6' },
  { label: '7', type: 'numeric', value: '7' },
  { label: '8', type: 'numeric', value: '8' },
  { label: '9', type: 'numeric', value: '9' },
  { label: '.', type: 'action', value: '.' },
  { label: '0', type: 'numeric', value: '0' },
]

function handleSwitchInputClick({
  registeredInvestmentInputsRef,
  activeInvestment,
  dispatch,
  direction,
}: HandleInputClickArgs) {
  if (!registeredInvestmentInputsRef?.current || !activeInvestment) return

  const currentIndex = registeredInvestmentInputsRef.current.findIndex(
    ({ itemId, investmentType }) =>
      itemId === activeInvestment.itemId && investmentType === activeInvestment.investmentType
  )
  const newIndex = direction === 'UP' ? currentIndex - 1 : currentIndex + 1
  const registeredLength = registeredInvestmentInputsRef.current.length
  const safeIndex =
    newIndex < 0 ? registeredLength - 1 : newIndex === registeredLength ? 0 : newIndex
  dispatch(setActiveInvestment(registeredInvestmentInputsRef.current[safeIndex]))
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.md,

  // Input cycling, reverse stake and done
  '> div:first-of-type': {
    display: 'flex',
    justifyContent: 'space-between',

    button: {
      color: colors.white,
      ':focus, :hover': { background: 0 },
      ':active': { backgroundColor: colors.studio[800], color: colors.studio[200], border: 0 },
    },
  },

  // Keypad buttons
  '> div:last-of-type': {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(4, auto)',
    gap: spacing.smx2,
    padding: spacing.smx2,
    borderRadius: radius.lg,
    backgroundColor: colors.studio[800],

    '> div:first-of-type': {
      gridColumnStart: '4',
      gridColumnEnd: '5',
      gridRowStart: '1',
      gridRowEnd: '5',
      display: 'flex',
      flexDirection: 'column',
      gap: spacing.smx2,
    },

    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '4.5rem',
      fontSize: font.size.xl.fontSize,
      letterSpacing: font.size.xl.letterSpacing,
      lineHeight: font.size.xl.lineHeight,
      fontWeight: font.weight.medium,
      borderRadius: radius.md,
      border: '0.1rem solid ' + colors.lavender[700],
      backgroundColor: colors.studio[700],
      color: colors.white,
      boxShadow: shadow.sm,
      touchAction: 'manipulation',
      userSelect: 'none',

      '&:active': {
        backgroundColor: colors.studio[800],
        borderColor: colors.lavender[800],
      },
    },
  },
})

// =====
// Types
// =====

type KeypadInputMap = KeypadInput & { label: string }

type HandleInputClickArgs = {
  registeredInvestmentInputsRef?: React.MutableRefObject<
    { itemId: string; investmentType: InvestmentType }[]
  >
  activeInvestment: BetSlipWorkflowState['activeInvestment']
  dispatch: Dispatch
  direction: 'UP' | 'DOWN'
}
